import Button from "../../../../elements/Button";
import theme from "../../../../styles/theme";
import styled from "styled-components/macro";

export const ResetButton = styled(Button)`
  background-color: transparent;
  margin-left: ${theme.spacing(3)}px;
  &: {
    background-color: transparent;
  }
`;

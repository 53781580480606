import React from "react";
import { Route, Switch } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import New from "./New";
import View from "./View";
import List from "./List";

const Users = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/view/:uid`} component={View} />
      <Route path={`${match.url}/new`} component={New} />

      <Route path={`${match.url}`} component={List} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  users: state.firestore.ordered.users,
});

Users.propTypes = {};

export default compose(
  firestoreConnect(() => [
    {
      collection: "users",
      where: [
        ["role", "==", "admin"],
        ["isSuper", "==", false],
      ],
    },
  ]),
  connect(mapStateToProps)
)(Users);

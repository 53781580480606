import styled from "styled-components/macro";

const GuideContent = styled.div`
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  &&:focus {
    outline: none;
  }
`;

export default GuideContent;

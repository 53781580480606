import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Drawer from "@material-ui/core/Drawer";
import { color, util, zIndex } from "../../styles/style";
import { mediaMinDevice } from "../../styles/responsive";

const ToolDrawerControl = styled.div`
  padding: ${({ padding }) => (padding >= 0 ? padding + "px" : util.padding)};
  display: flex;
  justify-content: ${({ justify }) => justify || "unset"};
`;
const ToolDrawerTextControl = styled.div`
  padding-top: ${util.padding};
  padding-left: ${util.padding};
  padding-right: ${util.padding};
`;
const ToolDrawerInfoText = styled.div`
  color: ${color.dark};
  font-size: 14px;
`;
const ToolDrawerContent = styled.div`
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  ${mediaMinDevice.tabletL} {
    max-width: 300px;
    margin-top: 1rem;
  }
`;
const ToolDrawer = styled(({ isDesktop, isReview, ...other }) => (
  <Drawer classes={{ paper: "paper" }} {...other}>
    <ToolDrawerContent>{other.children}</ToolDrawerContent>
  </Drawer>
))`
  & .paper {
    top: ${(props) => (props.isDesktop ? "4rem" : "112px")};
    bottom: ${(props) => (props.isReview ? "56px" : "126px")};
    z-index: ${zIndex.toolDrawer};
    background: ${color.white};
  }
`;

ToolDrawer.propTypes = {
  isDesktop: PropTypes.bool,
  isReview: PropTypes.bool,
};
ToolDrawer.default = {
  isReview: false,
};

export {
  ToolDrawer,
  ToolDrawerControl,
  ToolDrawerTextControl,
  ToolDrawerInfoText,
};

export const centerAligment = {
  vertical: "center",
  horizontal: "center",
  wrapText: true
}

export const fontStyle = {
  outline: true,
  sz: "12",
  bold: true,
}

export const borderStyle = {
  style: 'thin', color: 'FF000000'
}

export const titleStyle1Study = {
  alignment: { ...centerAligment, wrapText: false },
  font: fontStyle,
  fill: {
    patternType: "solid",
    fgColor: { rgb: "338EA9DB" }
  },
  border: {
    top: borderStyle,
    bottom: borderStyle,
  }
};

export const titleStyle1Tests = {
  alignment: { ...centerAligment, wrapText: false },
  font: fontStyle,
  fill: {
    patternType: "solid",
    fgColor: { rgb: "33FF0000" }
  },
  border: {
    top: borderStyle,
    bottom: borderStyle,
  }
};

export const titleStyle2 = {
  alignment: {
    vertical: "center",
    horizontal: "center",
    wrapText: true
  },
  font: {
    outline: true,
    sz: "12",
    bold: true,
    color: {
      rgb: "FF44227A",
    },
  },
  fill: {
    patternType: "solid",
    fgColor: { rgb: "33F3E5A9" }
  },
  border: {
    top: borderStyle,
    bottom: borderStyle,
  }
};

export const headerStyle = {
  alignment: {
    vertical: "center",
    horizontal: "center",
    wrapText: true
  },
  font: {
    sz: "11",
    bold: true,
  },
  border: {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
  },
  fill: {
    patternType: "solid",
    fgColor: { rgb: "268FBC8F" }
  },
};

export const textStyle = {
  font: {
    sz: "10",
  },
  alignment: {
    horizontal: "center"
  },
  border: {
    right: borderStyle,
  },
};
import styled from "styled-components/macro";
import { fontType, fontWeight } from "../../styles/style";

const CtaLanding = styled.div`
  font-size: ${fontType.copy};
  font-weight: ${fontWeight.semiBold};
  margin: 1.5rem 0;
  text-align: center;
`;

export default CtaLanding;

import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  Label,
  Stat,
  StatProgressBar,
  StatWrapper,
} from "./StyledResultBoxOverall";

const ResultBoxOverall = (props) => {
  const statValue = (props.correct / props.total) * 100;
  const statResult = statValue ? `${statValue.toFixed(1)}%` : "0%";

  return (
    <>
      <StatWrapper>
        <Stat>{statResult}</Stat>
        <Label>{props.label}</Label>
        <StatProgressBar>
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={statValue}
          />
        </StatProgressBar>
      </StatWrapper>
    </>
  );
};

// ResultBoxOverall.propTypes = {};

export default ResultBoxOverall;

import {
  borderStyle,
  headerStyle,
  textStyle,
  titleStyle1Study,
  titleStyle1Tests,
  titleStyle2
} from "../utils/exel-style";

const studyAidTitle = ['', '', '', '', '', '', '', '', '',
  'Study', ' Aids', '', '', '', '', '', '', '', '', '', '',]
  .map((title, index) => {
    let currentStyle = {}

    if (index === 20) currentStyle = {
      ...titleStyle1Study, border: {
        top: borderStyle,
        bottom: borderStyle,
        right: borderStyle,
      },
    }
    else if (index === 0) {
      currentStyle = {
        ...titleStyle1Study, border: {
          top: borderStyle,
          bottom: borderStyle,
          left: borderStyle,
        }
      }
    } else currentStyle = { ...titleStyle1Study }

    return {
      title: title,
      style: currentStyle,
      width: { wch: 20 },
    }
  })

const practiceTestTitle = ['', '', '', '', '', '', '', '', '', '', '',
  'Practice', ' Exams', '', '', '', '', '', '', '', '', '', '', '', '',
].map((title, index) => {
  let currentStyle = {}

  if (index === 24) currentStyle = {
    ...titleStyle1Tests, border: {
      top: borderStyle,
      bottom: borderStyle,
      right: borderStyle,
    },
  }
  else if (index === 0) {
    currentStyle = {
      ...titleStyle1Tests, border: {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
      }
    }
  } else currentStyle = { ...titleStyle1Tests }

  return {
    title: title,
    style: currentStyle,
    width: { wch: 20 },
  }
})

export const firstRowTitles = [...studyAidTitle, ...practiceTestTitle]

export const secondRowTitles = [
  'OVERALL', ' SCORES', '',
  'Constitutional', ' Law', '', '',
  'Contracts', '',
  "Criminal Law", "/    Criminal", "Procedure",
  'Civil', ' Procedure', '', '',
  'Evidence', '', '',
  'Torts', '',
  'Real', 'Property', '',
  'Practice', ' Test ', '1',
  'Practice', ' Test ', '2',
  'Practice', ' Test ', '3',
  'Practice', ' Test ', '4',
  'Final', ' Test ', '1',
  'Final', ' Test ', '2',
  'Final', ' Test ', '3',
  'Final', ' Test ', '4'].map((title, index) => {
    let style = {}

    if (index === 56) style = {
      ...titleStyle2, border: {
        top: borderStyle,
        bottom: borderStyle,
        right: borderStyle,
      },
    }

    else if ((index) % 3 === 0) {
      style = {
        ...titleStyle2, border: {
          top: borderStyle,
          bottom: borderStyle,
          left: borderStyle,
        }, alignment: {
          vertical: "center",
          horizontal: "right",
        },
      }
    }

    else if ((index + 1) % 3 === 0) {
      style = {
        ...titleStyle2, border: {
          top: borderStyle,
          bottom: borderStyle,
        }, alignment: {
          vertical: "center",
          horizontal: "left",
        },
      }
    }

    else style = { ...titleStyle2 }

    return {
      title: title,
      style: style,
    }
  })

const statisticColumnsText = Array.from(Array(15).keys()).map(() => [
  { title: 'Total Correct', style: headerStyle, width: { wch: 15 }, },
  { title: 'Total Completed', style: headerStyle, width: { wch: 15 }, },
  { title: 'Percentage Correct', style: headerStyle, width: { wch: 15 }, }]
)

export const thirdRowTitles = [
  { title: 'Organization name', style: headerStyle, width: { wch: 20 }, },
  { title: 'Student First Name', style: headerStyle, width: { wch: 20 }, },
  { title: 'Student Last Name', style: headerStyle, width: { wch: 20 }, },
  { title: 'Student Email', style: headerStyle, width: { wch: 21 }, },
  { title: 'OVERALL TOTAL CORRECT', style: headerStyle, width: { wch: 15 }, },
  { title: 'OVERALL TOTAL COMPLETED', style: headerStyle, width: { wch: 15 }, },
  { title: 'OVERALL PERCENTAGE CORRECT', style: headerStyle, width: { wch: 15 }, },
  ...statisticColumnsText.flat()
];
import { UserActions } from "../actions/studentActions";
import {TStateAction} from "../../types/TYPES";

type TUserState = {
  status: string;
  error: string;
};

const initState: TUserState = {
  status: "",
  error: "",
};

const userStatus = (state = initState, action: TStateAction): TUserState => {
  const { payload } = action;

  switch (action.type) {
    case UserActions.CREATE.REQUESTED:
    case UserActions.IMPORT.REQUESTED:
    case UserActions.DELETE.REQUESTED:
    case UserActions.UPGRADE.REQUESTED:
    case UserActions.UPDATE.REQUESTED:
    case UserActions.SEND_EMAIL.REQUESTED:
      return {
        ...state,
        status: "loading",
        error: "",
      };

    case UserActions.CREATE.SUCCESS:
    case UserActions.IMPORT.SUCCESS:
    case UserActions.DELETE.SUCCESS:
    case UserActions.UPGRADE.SUCCESS:
    case UserActions.UPDATE.SUCCESS:
    case UserActions.SEND_EMAIL.SUCCESS:
      return {
        ...state,
        status: "success",
        error: "",
      };

    case UserActions.CREATE.FAILURE:
    case UserActions.IMPORT.FAILURE:
    case UserActions.DELETE.FAILURE:
    case UserActions.UPGRADE.FAILURE:
    case UserActions.UPDATE.FAILURE:
    case UserActions.SEND_EMAIL.FAILURE:
      return {
        ...state,
        status: "failure",
        error: payload,
      };

    case UserActions.RESET:
      return {
        ...state,
        status: "",
        error: "",
      };

    default:
      return state;
  }
};

export default userStatus;

import styled from "styled-components/macro";
import Button from "../../../../elements/Button";
import { color, fontWeight } from "../../../../styles/style";
import { mediaMinDevice } from "../../../../styles/responsive";

export const styles = (theme) => ({
  colorSwitchBase: {
    color: `${color.warning}`,
    "&$colorChecked": {
      color: `${color.primary}`,
      "& + $colorBar": {
        backgroundColor: `${color.danger}`,
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  iOSSwitchBase: {
    "&$iOSChecked": {
      color: `${color.white}`,
      "& + $iOSBar": {
        backgroundColor: `${color.secondary}`,
      },
    },
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: "translateX(15px)",
    "& + $iOSBar": {
      opacity: 1,
      border: "none",
    },
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    border: "solid 1px",
    borderColor: `${color.blueDarkest}`,
    backgroundColor: `${color.blueDarkest}`,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  iOSIcon: {
    width: 24,
    height: 24,
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1],
  },
});

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StepHeaderIcon = styled.div`
  display: flex;
  padding-right: 8px;
`;

export const StepHeaderLabel = styled.div`
  font-size: 16px;
  color: ${color.blueDarkest};
  width: 100%;
  ${mediaMinDevice.mobileL} {
    font-size: 24px;
  }
`;

export const StepSvg = styled.svg`
  width: 1.5rem;
  height: 1.5rem;
`;

export const StepSvgCircle = styled.circle`
  fill: ${color.dark};
`;

export const StepSvgTxt = styled.text`
  fill: #ffffff;
  font-size: 0.75rem;
  font-weight: ${fontWeight.extraBold};
`;

export const CancelBtn = styled(Button)`
  && {
    margin-top: 24px;
  }
`;

export const SubmitBtn = styled(Button)`
  && {
    margin-top: 24px;
  }
`;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Card, CardContent, CardHeader } from "../../containers/Card";
import { color, fontWeight } from "../../styles/style";

const styles = {
  card: {
    display: "flex",
    width: "100%",
  },
  content: {
    paddingTop: 0,
    fontSize: 14,
    fontWeight: fontWeight.semiBold,
    color: color.blueDarkest,
    height: "100%",
  },
  questionCopy: {
    backgroundColor: color.grey,
    paddingLeft: "8px",
    paddingRight: "8px",
    fontSize: 14,
    lineHeight: "initial",
    fontWeight: fontWeight.regular,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

class CardFieldNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      oldContent: props.content,
      newContent: props.content,
    };
  }

  toggleEditMode() {
    this.setState((state) => ({
      editMode: !state.editMode,
    }));
  }

  render() {
    const { classes, label } = this.props;

    return (
      <Grid item xs={12}>
        <Card>
          <CardHeader title={label} />

          <CardContent className={classes.content}>
            {this.props.children}
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

CardFieldNew.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardFieldNew);

import { Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/createReduxStore";

const OrganizationLink: React.FC = () => {
  const organization = useSelector(
    (state: RootState) => state.firestore.data.currentOrganization
  );
  const link = organization.name.toLowerCase().replace(/\s+/g, "-");

  return (
    <Grid container>
      <Grid item>
        <Typography variant={'body1'} gutterBottom>Shareable Signup Link</Typography>
        <Typography variant={'body1'}>
          <Link>https://jdadvising.app/sign-up/{link}</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OrganizationLink;

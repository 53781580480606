import styled from "styled-components/macro";

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
`;

export const Bordered = styled.div`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  width: 100%;
`;

export const Content = styled.div`
  padding: 25px 0;
`;
// @ts-nocheck

import { actionCreator } from "../../utils/actionCreator";
import { getAsyncActions } from "../../utils/getAsyncActions";
import firebase from "../api/firebaseAdmin";
import { TAsyncAction } from "../../types/TYPES";
import { Dispatch } from "redux";

type TCreateQuestion = {
  title: string;
  body: string;
};

type TUpdateQuestion = {
  id: string;
  title: string;
  body: string;
};

type TFaqActions = {
  RESET_ERROR: string;
  FETCH: TAsyncAction;
  UPDATE: TAsyncAction;
  CREATE: TAsyncAction;
  DELETE: TAsyncAction;
};

export const FaqActions: TFaqActions = {
  RESET_ERROR: "faq/errorReset",
  ...getAsyncActions({
    FETCH: "faq/fetched",
    UPDATE: "faq/updated",
    CREATE: "faq/created",
    DELETE: "faq/delete",
  }),
} as TFaqActions;

export const fetchQuestions = () => {
  return async (dispatch: Dispatch) => {
    dispatch(actionCreator(FaqActions.FETCH.REQUESTED));
    const firestore = firebase.firestore();

    try {
      const questions = [];
      const snapshot = await firestore.collection("faq").get();
      snapshot.forEach((doc) => {
        const data = doc.data();

        questions.push({
          id: doc.id,
          ...data,
        });
      });
      dispatch(actionCreator(FaqActions.FETCH.SUCCESS, { questions }));
    } catch (error) {
      dispatch(
        actionCreator(
          FaqActions.FETCH.FAILURE,
          error.message || JSON.stringify(error),
          true
        )
      );
    }
  };
};

export const createQuestion = (data: TCreateQuestion) => {
  return async (dispatch: Dispatch) => {
    dispatch(actionCreator(FaqActions.CREATE.REQUESTED));
    const firestore = firebase.firestore();

    try {
      const questionDoc = firestore.collection("faq").doc();
      const question = {
        ...data,
        id: questionDoc.id,
        createdDate: new Date(),
      };

      await questionDoc.set({
        ...question,
        createdDate: new Date(),
      });

      dispatch(actionCreator(FaqActions.CREATE.SUCCESS, { question }));
    } catch (error) {
      dispatch(
        actionCreator(
          FaqActions.CREATE.FAILURE,
          error.message || JSON.stringify(error),
          true
        )
      );
    }
  };
};

export const updateQuestion = ({ id, ...data }: TUpdateQuestion) => {
  return async (dispatch: Dispatch) => {
    dispatch(actionCreator(FaqActions.UPDATE.REQUESTED));
    const firestore = firebase.firestore();
    try {
      const question = data;
      await firestore.collection("faq").doc(id).update(question);

      dispatch(
        actionCreator(FaqActions.UPDATE.SUCCESS, {
          question: { id, ...question },
        })
      );
    } catch (error) {
      dispatch(
        actionCreator(
          FaqActions.UPDATE.FAILURE,
          error.message || JSON.stringify(error),
          true
        )
      );
    }
  };
};

export const deleteQuestion = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(actionCreator(FaqActions.DELETE.REQUESTED));
    const firestore = firebase.firestore();

    try {
      await firestore.collection("faq").doc(id).delete();

      dispatch(actionCreator(FaqActions.DELETE.SUCCESS, id));
    } catch (error) {
      dispatch(
        actionCreator(
          FaqActions.DELETE.FAILURE,
          error.message || JSON.stringify(error),
          true
        )
      );
    }
  };
};

export const resetError = () => actionCreator(FaqActions.RESET_ERROR);

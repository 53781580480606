import {
  CircularProgress,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { color } from "../../../styles/style";

export const Subtitle = styled(Typography)`
  margin-top: 1.75rem;
  color: ${color.dark};
  font-size: 1.25rem;
  text-decoration: underline;
  font-weight: bold;
`;

export const Price = styled(Subtitle)`
  color: ${color.secondary};
  text-decoration: none;
`;

export const OldPrice = styled(Subtitle)`
  color: ${color.blueDark};
  text-decoration: line-through;
`;

export const FormWrapper = styled(Grid)`
  width: 23rem;
`;

export const SelectFormControll = styled(FormControl)`
  flex: 1;
  margin-right: 2rem;
`;

export const CompleteButtonWrapper = styled.div`
  margin-top: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ButtonLoader = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

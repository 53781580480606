export const color = {
  /* Colors */
  primaryLightest: "#e0e4ff",
  primaryLight: "#ADB2F3",
  primary: "#5c67e5",
  primaryDark: "#4952b8",
  primaryDarker: "#34329F",
  secondaryLight: "#81ffd7",
  secondary: "#01df9a",
  secondaryDark: "#00BF83",
  secondaryDarker: "#00A572",
  /* Shades */
  white: "#ffffff",
  grey: "#f5f7fa",
  greyLight: "#ECEEF1",
  blueLightest: "#cfdae6",
  blueLight: "#bfccd9",
  blueDark: "#adbdcc",
  blueDarkest: "#8595a6",
  dark: "#465359",
  darkest: "#272f32",
  greenLightest: "#c8e6c9",
  greenLight: "#81c784",
  greenMain: "#4caf50",
  greenDark: "#388e3c",
  greenDarkest: "#1b5e20",

  redLightest: "#ffebee",
  redLight: "#ffcdd2",
  redMain: "#f44336",
  redDark: "#d32f2f",
  redDarkest: "#b71c1c",

  goldLightest: "#ffee58",
  goldLight: "#ffeb3b",
  goldMain: "#fdd835",
  goldDark: "#fbc02d",
  goldDarkest: "#f9a825",

  /* Errors */
  succcess: "#a8e619",
  info: "#54ccee",
  warning: "#ff7a37",
  danger: "#e52254",
  dangerDark: "#C1103D",
  /* Utilities */
  links: "#5c67e5",
};

export const util = {
  radiusSmall: "3px",
  radius: "12px",
  radiusBig: "290486px",
  borderRadius: "0.8rem",
  fontFamily: "Open Sans",
  padding: "calc(1rem + 10px)",
  paddingSide: "2.4rem",
  paddingTop: "2.4rem",
  BtnPaddingVert: `calc(0.5rem - 1px)`,
  BtnPaddingHorz: "3rem",
};

export const fontType = {
  copy: "14px",
  title: "2rem",
  subTitle: "18px",
  answerLetter: "36px",
  answerCopy: "18px",
  feedbackCopy: "14px",
  questionCopy: "14px",
};
export const fontSize = {
  title: "2rem",
  answerLetter: "36px",
  answerCopy: "18px",
  feedbackCopy: "14px",
  questionCopy: "14px",
};

export const fontWeight = {
  extraLight: 200,
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export const zIndex = {
  mobileStepper: 1000,
  toolBar: 1050,
  toolDrawer: 1040,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
};

export default {
  util,
  color,
  fontWeight,
  fontSize,
  zIndex,
};

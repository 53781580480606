import React from "react";
import styled from "styled-components/macro";
import { color } from "../../styles/style";
import msToStringHuman from "../../utils/msToStringHuman";

const Text = styled.p`
  margin: 0;
  font-size: 1rem;
  color: ${() => color.blueDarkest};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "right")};
  font-weight: 600;
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;
const DurationText = (props) => {
  if (props.children === undefined) {
    return <Text>&#8216;error&#8216;</Text>;
  }
  return <Text {...props}>Duration: {msToStringHuman(props.children)}</Text>;
};

DurationText.propTypes = {};

export default DurationText;

import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../../assets/logos/JD-Advising_Logo.svg";
import UserMenu from "../UserMenu";
import Help from "./Help";
import { LogoImage, Nav, NavRight } from "./StyledNavBar";

const NavAppBar = ({ auth, children }) => {
  return (
    <AppBar position="relative" color="default">
      <Toolbar variant="regular">
        <LogoImage src={Logo} alt="logo" />

        <Nav>{children}</Nav>

        <NavRight>
          <Help />
          {auth && <UserMenu />}
        </NavRight>
      </Toolbar>
    </AppBar>
  );
};

NavAppBar.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default NavAppBar;

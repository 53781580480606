import { v4 as uuid } from "uuid";
import firebase from "../api/firebaseAdmin";
import seedTests from "../../_CONS/seedTests";
import { study, test } from "../../_CONS/qbanks";

export const resetSetupStatus = () => ({
  type: "SETUP_STATUS",
  payload: "",
});

export const startStudentSetup = () => {
  return async (dispatch, getState) => {
    const accountType = getState().firebase.profile.accountType;

    await dispatch({ type: "SETUP_STATUS", payload: "start" });
    await dispatch(_setStudentRecord());

    await dispatch({
      type: "SETUP_STATUS",
      payload: "Adding latest questions...",
    });

    Promise.all(
      study.map(async code => {
        await dispatch(_populateSingleQbank("study", code));
      })
    ).then(() => {
      dispatch({
        type: "SETUP_STATUS",
        payload: "added Study Questions to QBank",
      });
    });
    Promise.all(
      test.map(async code => {
        await dispatch(_populateSingleQbank("test", code));
      })
    ).then(() => {
      dispatch({
        type: "SETUP_STATUS",
        payload: "added Test Questions to QBank",
      });
      dispatch({ type: "SETUP_STATUS", payload: "building practice tests..." });
      dispatch(_createTestGuides(accountType));
    });
  };
};

const _createTestGuides = accountType => {
  return (dispatch, getState) => {
    const authId = getState().firebase.auth.uid;
    const accountLevel = getState().firebase.profile.accountLevel;
    const levelQbank = getState().firebase.profile.studentBank;
    const firestore = firebase.firestore();

    const studentRef = firestore.collection(levelQbank).doc(authId);

    studentRef.get().then(function (sfDoc) {
      if (!sfDoc.exists) {
        throw new Error("Document does not exist!");
      }

      const totalsArray = sfDoc.data().testTotals;

      const qbankTotals = totalsArray.reduce((acc, item) => {
        return { ...acc, ...item };
      });

      const guideRef = studentRef.collection("testguides");
      const batch = firestore.batch();

      seedTests.forEach(function (element) {
        const docRef = guideRef.doc();
        const qbank = element.qbank;
        const qMax = element.maxQuestions;

        const totalQuestionsInQbank = Number(qbankTotals[qbank]);
        const totalQuestions = totalQuestionsInQbank < qMax ? totalQuestionsInQbank : qMax;

        const totalTime = totalQuestions * 1.8;
        batch.set(docRef, {
          ...element,
          accountType: accountType,
          accountLevel,
          time: totalTime,
          questionsCorrect: 0,
          questionsWrong: 0,
          questionsTotal: totalQuestions,
          showFeedback: false,
          showTimer: true,
          showNotes: false,
          duration: 0,
          qbank: qbank,
          uid: docRef.id,
        });
      });

      return batch.commit().then(function () {
        dispatch(_updateUser()).then(() => {
          dispatch({ type: "SETUP_STATUS", payload: "complete" });
          setTimeout(() => {
            dispatch({ type: "SETUP_STATUS", payload: "" });
          }, 3000);
        });
      });
    });
  };
};

const _setStudentRecord = () => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();
    const authId = getState().firebase.auth.uid;
    const levelQbank = await getState().firebase.profile.studentBank;
    const studentRef = firestore.collection(levelQbank).doc(authId);
    return studentRef.set({
      status: "setup",
      activeDate: new Date(),
      all: {
        total: 0,
        correct: 0,
        wrong: 0,
      },
      study: {
        total: 0,
        correct: 0,
        wrong: 0,
      },
      test: {
        total: 0,
        correct: 0,
        wrong: 0,
      },
    });
  };
};

const _updateUser = () => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();
    const authId = getState().firebase.auth.uid;
    const userRef = firestore.collection("users").doc(authId);
    const batch = firestore.batch();
    batch.update(userRef, { status: "current" });

    return batch.commit();
  };
};

const _populateSingleQbank = (type, code) => {
  return async (dispatch, getState) => {
    const authId = getState().firebase.auth.uid;
    const accountLevel = getState().firebase.profile.accountLevel;
    const levelQbank = getState().firebase.profile.studentBank;

    const rtdbRef = firebase.database().ref(accountLevel);
    const firestore = firebase.firestore();

    const studentRef = firestore.collection(levelQbank).doc(authId);

    const totals = {};
    const promises = [];
    const batch = firestore.batch();

    const qbank = code.value;
    promises.push(
      rtdbRef
        .child(qbank)
        .once("value")
        .then(function (snapshot) {
          // then execute a promise on the snapshot
          let qCount = 0;
          snapshot.forEach(function (childSnapshot) {
            qCount = qCount + 1;
            const { subject, subjectCode, classification, ...qData } = childSnapshot.val();
            const docRef = studentRef.collection(qbank).doc(childSnapshot.key);
            if (!classification) {
              console.log("childSnapchot", childSnapshot);
              return;
            }
            batch.set(docRef, {
              guideId: null,
              qid: childSnapshot.key,
              qdata: qData,
              qbank,
              subject,
              subjectCode,
              classification,
              topics: classification
                .filter(item => typeof item === "string")
                .map(item => item.substr(0, item.indexOf("-") >= 0 ? item.indexOf("-") : undefined))
                .filter((item, index, arr) => arr.indexOf(item) === index),
              correctAnswer: qData.correctAnswer,
              userAnswer: null,
              userNotes: null,
              isCorrect: false,
              answered: false,
              selected: false,
              qnum: null,
              random: uuid(),
            });
          });

          totals[`${qbank}`] = qCount;
        })
    );

    return await Promise.all(promises).then(() => {
      if (type === "study") {
        batch.update(studentRef, {
          studyTotals: firebase.firestore.FieldValue.arrayUnion({ ...totals }),
        });
      }
      if (type === "test") {
        batch.update(studentRef, {
          testTotals: firebase.firestore.FieldValue.arrayUnion({ ...totals }),
        });
      }
      return batch.commit().then(async function () {
        return await Promise.resolve();
      });
    });
  };
};

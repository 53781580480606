import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import ReviewResultBox from "../../elements/ReviewResultBox/ReviewResultBox";
import UniversalResultBox from "../../elements/ResultBox";

const StatWrapper = styled.div`
  margin: 1rem 0;
`;

const StatItem = styled(Grid)`
  padding: 0 0.5rem;
`;

const StatsRowFlat = ({ stats, appSection = "" }) => {
  const ResultBox =
    appSection === "review" ? ReviewResultBox : UniversalResultBox;

  return (
    <StatWrapper>
      <Grid container item spacing={3}>
        <StatItem item xs={3}>
          <ResultBox stat={stats.overall} label="Overall" percent />
        </StatItem>
        <StatItem item xs={3}>
          <ResultBox stat={stats.total} label="Questions" />
        </StatItem>
        <StatItem item xs={3}>
          <ResultBox
            stat={stats.correct && stats.correct > 0 ? stats.correct : 0}
            label="Correct"
            type="correct"
          />
        </StatItem>
        <StatItem item xs={3}>
          <ResultBox stat={stats.wrong} label="Incorrect" type="wrong" />
        </StatItem>
      </Grid>
    </StatWrapper>
  );
};

StatsRowFlat.propTypes = {
  section: PropTypes.string,
  stats: PropTypes.shape({
    overall: PropTypes.string,
    total: PropTypes.number,
    correct: PropTypes.number,
    wrong: PropTypes.number,
  }),
};

export default StatsRowFlat;

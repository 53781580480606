import React, { ChangeEvent, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { MainScreen } from "../../../../../containers/Main";
import { Card, CardContent } from "../../../../../containers/Card";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "./StyledNew";
import {
  validate,
  validateEmail,
  validateName,
  validatePhoneNumber,
} from "../../../../../utils/validator";
import { createNewAdmin } from "../../../../../store/actions/adminActions";
import { formatPhoneNumber } from "../../../../../utils/format";
import LoadingModal from "../../../../../components/LoadingModal";
import { RootState } from "../../../../../store/createReduxStore";
import BackButton from "../../../../../elements/BackButton";

type TFields = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  jobTitle: string;
  phoneNumber: string;
};

type TUrlParams = {
  id: string;
};

const NewOrganizationUser: React.FC = () => {
  const history = useHistory();
  const params: TUrlParams = useParams();
  const dispatch = useDispatch();

  const status = useSelector((state: RootState) => state.userStatus.status);

  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: Math.random().toString(36).slice(-8),
    jobTitle: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState<Partial<TFields>>({});

  const goBack = () => {
    history.goBack();
  };

  const validateFields = () => {
    return validate(fields, {
      firstName: [validateName],
      lastName: [validateName],
      email: [validateEmail],
      phoneNumber: [validatePhoneNumber],
    });
  };

  const onChange =
    (field: string) => (event: ChangeEvent<HTMLInputElement>) => {
      let val = event.target.value;

      if (field === "phoneNumber" && val.length > fields.phoneNumber.length) {
        val = formatPhoneNumber(val);
      }

      setFields({
        ...fields,
        [field]: val,
      });
    };

  const onCreate = () => {
    const validationResult = validateFields();

    if (Object.keys(validationResult).length) {
      setErrors(validationResult);
    } else {
      setErrors({});

      dispatch(
        createNewAdmin({
          ...fields,
          phoneNumber: fields.phoneNumber.replace(/\D/g, ""),
          organization: params.id,
        })
      );
    }
  };

  const onReset = () => {
    setErrors({});
    setFields({
      firstName: "",
      lastName: "",
      email: "",
      password: Math.random().toString(36).slice(-8),
      jobTitle: "",
      phoneNumber: "",
    });
  };

  return (
    <MainScreen
      title="New User"
      leftSideItem={<BackButton onClick={goBack} />}
      spacing={3}
    >
      <LoadingModal open={status === "loading"} />
      <Grid item xs={12}>
        <Card>
          <form onSubmit={onCreate}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item container spacing={3}>
                  <Grid item>
                    <TextField
                      label="First Name *"
                      value={fields.firstName}
                      error={!!errors.firstName}
                      helperText={errors.firstName || " "}
                      inputProps={{ maxLength: 255 }}
                      onChange={onChange("firstName")}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      label="Last Name *"
                      value={fields.lastName}
                      error={!!errors.lastName}
                      helperText={errors.lastName || " "}
                      inputProps={{ maxLength: 255 }}
                      onChange={onChange("lastName")}
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={3}>
                  <Grid item>
                    <TextField
                      label="Email *"
                      value={fields.email}
                      error={!!errors.email}
                      helperText={errors.email || " "}
                      inputProps={{ maxLength: 255 }}
                      onChange={onChange("email")}
                    />
                  </Grid>

                  <Grid item>
                    <TextField label="Password" value={fields.password} />
                  </Grid>
                </Grid>

                <Grid container item spacing={3}>
                  <Grid item>
                    <TextField
                      label="Job Title"
                      value={fields.jobTitle}
                      error={!!errors.jobTitle}
                      inputProps={{ maxLength: 255 }}
                      onChange={onChange("jobTitle")}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      label="Phone Number"
                      value={fields.phoneNumber}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber || " "}
                      inputProps={{ maxLength: 17 }}
                      onChange={onChange("phoneNumber")}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onCreate}
                  >
                    Create
                  </Button>
                </Grid>

                <Grid item>
                  <Button variant="outlined" color="primary" onClick={onReset}>
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Grid>
    </MainScreen>
  );
};

export default NewOrganizationUser;

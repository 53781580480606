import { actionCreator } from "../../utils/actionCreator";
import { getAsyncActions } from "../../utils/getAsyncActions";
import firebase from "../api/firebaseAdmin";

export const PromoActions = getAsyncActions({
  UPDATE: "promo/update",
  CREATE: "promo/create",
});

export const updatePromo = (key, promo) => {
  return async (dispatch) => {
    dispatch(actionCreator(PromoActions.UPDATE.REQUESTED));

    try {
      const promosRef = firebase.database().ref("promos");
      await promosRef.child(key).update(promo);

      dispatch(actionCreator(PromoActions.UPDATE.SUCCESS));
    } catch (e) {
      dispatch(
        actionCreator(PromoActions.UPDATE.FAILURE, JSON.stringify(e), true)
      );
    }
  };
};

export const addPromo = (key, promo) => {
  return async (dispatch) => {
    dispatch(actionCreator(PromoActions.CREATE.REQUESTED));

    try {
      const promosRef = firebase.database().ref("promos");
      await promosRef.child(key).set(promo);

      dispatch(actionCreator(PromoActions.CREATE.SUCCESS));
    } catch (e) {
      dispatch(
        actionCreator(PromoActions.CREATE.FAILURE, JSON.stringify(e), true)
      );
    }
  };
};

import React from "react";
// import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { color, fontSize, fontWeight, util } from "../../../styles/style";
import { mediaMinDevice } from "../../../styles/responsive";

const Card = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 1rem;
  padding: 0;
  height: 100%;
  background: ${color.white};
  color: ${color.blueDarkest};
  box-shadow: 0 2px 7px 0 ${color.blueLightest};
  border-radius: ${util.radiusSmall};
  cursor: pointer;
  overflow: hidden;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
  &:hover {
    box-shadow: 0 5px 20px 0 ${color.blueLightest};
  }
`;
const CardSuccess = styled(Card)`
  background: ${color.secondary};
  color: ${color.white};
`;
const CardSelected = styled(Card)`
  background: ${color.primary};
  color: ${color.white};
`;
const CardWrong = styled(Card)`
  background: ${color.redLighter};
  color: ${color.redDark};
`;

const AnswerLetter = styled.div`
  font-weight: ${fontWeight.semiBold};
  color: ${color.blueDarkest};
  box-shadow: 0 1px 10px -5px ${color.blueDarkest};
  text-align: center;
  font-size: 24px;
  padding: 1rem 1.5rem;
  min-width: 65px;
  height: auto;
  display: flex;
  align-items: center;
  ${mediaMinDevice.tabletL} {
    padding: 2rem 2.5rem;
    font-size: ${fontSize.answerLetter};
    min-width: 110px;
  }
`;

const AnswerLetterSuccess = styled(AnswerLetter)`
  background: ${color.secondaryDark};
  color: ${color.white};
`;
const AnswerLetterSelected = styled(AnswerLetter)`
  background: ${color.primaryDark};
  color: ${color.white};
`;
const AnswerLetterWrong = styled(AnswerLetter)`
  background: ${color.redLight};
  color: ${color.redDark};
`;

const AnswerCopy = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 1rem;
  font-weight: ${fontWeight.semiBold};
  color: ${color.blueDarkest};
  letter-spacing: 0;
  ${mediaMinDevice.tabletL} {
    padding: 0 2rem;
    font-size: ${fontSize.answerCopy};
  }
`;
const AnswerCopySuccess = styled(AnswerCopy)`
  color: ${color.white};
`;
const AnswerCopySelected = styled(AnswerCopy)`
  color: ${color.white};
`;
const AnswerCopyWrong = styled(AnswerCopy)`
  color: ${color.redDark};
`;

const AnswerItem = (props) => {
  if (props.type === "success") {
    return (
      <CardSuccess>
        <AnswerLetterSuccess>{props.itemLetter}</AnswerLetterSuccess>
        <AnswerCopySuccess
          dangerouslySetInnerHTML={{ __html: props.itemCopy }}
        />
      </CardSuccess>
    );
  }
  if (props.type === "selected") {
    return (
      <CardSelected onClick={props.onClick}>
        <AnswerLetterSelected>{props.itemLetter}</AnswerLetterSelected>
        <AnswerCopySelected
          dangerouslySetInnerHTML={{ __html: props.itemCopy }}
        />
      </CardSelected>
    );
  }
  if (props.type === "wrong") {
    return (
      <CardWrong>
        <AnswerLetterWrong>{props.itemLetter}</AnswerLetterWrong>
        <AnswerCopyWrong dangerouslySetInnerHTML={{ __html: props.itemCopy }} />
      </CardWrong>
    );
  }
  return (
    <Card onClick={props.onClick}>
      <AnswerLetter>{props.itemLetter}</AnswerLetter>
      <AnswerCopy dangerouslySetInnerHTML={{ __html: props.itemCopy }} />
    </Card>
  );
};
AnswerItem.propTypes = {};

export default AnswerItem;

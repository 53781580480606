export function formatPhoneNumber(phone: string) {
  if (!phone) return "";

  const result = phone.replace(/\D/g, "");

  if (result.length < 3) {
    return result.replace(/^(\d{1,2})/, "($1");
  } else if (result.length < 6) {
    return result.replace(/^(\d{3})/, "($1) ");
  } else {
    return result.replace(/^(\d{3})(\d{3})/, "($1) $2-");
  }
}

import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import Button from "../../elements/Button";
import { Card, CardContent, CardHeader } from "../../containers/Card";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router";
import getSectionFromUrl from "../../utils/getSectionFromUrl";

type TStudentFAQ = {
  buttonLabel: string;
};

const StudentFAQ: React.FC<TStudentFAQ> = ({
  buttonLabel = "Learn More!",
  ...props
}) => {
  const { location } = useHistory();
  const urlSection = getSectionFromUrl(location.pathname, 0);

  return (
    <Grid item xs={12} {...props}>
      <Card>
        <CardHeader
          action={
            <IconButton component={Link} to={`/faq`}>
              <ChevronRight />
            </IconButton>
          }
          title="Frequently Asked Questions"
        />
        <Divider />

        <CardContent>
          <Typography gutterBottom>
            Thank you for enrolling in our MBE Qbank! We look forward to helping
            you pass the bar exam!
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Below are some frequently asked questions:
          </Typography>

          <ul>
            <li>
              <Typography gutterBottom>
                How many questions are in your Qbank?
              </Typography>
            </li>
            <li>
              <Typography gutterBottom>
                What is the difference between a &lsquo;Study Aid&lsquo; and a
                &lsquo;Test&lsquo;?
              </Typography>
            </li>
            <li>
              <Typography gutterBottom>
                Is there overlap between the &lsquo;Study Aid&lsquo; questions
                and &lsquo;Test&lsquo; questions?
              </Typography>
            </li>
          </ul>

          <Grid container spacing={3} justify={"center"}>
            <Grid item>
              <Button color="success" component={Link} to={`${urlSection}/faq`}>
                {buttonLabel}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default StudentFAQ;

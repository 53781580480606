import { useCallback, useState } from "react";
import firebase from "../store/api/firebaseAdmin";
import { TOffer } from "../../types";

type TProps = {
  [key: string]: any;
};

type TResponse<TOffer> = {
  isLoading: boolean;
  error: string;
  data: TOffer[];
  get: (params?: TProps) => void;
};

function useOffers(props: TProps): TResponse<TOffer> {
  const [state, setState] = useState({
    isLoading: true,
    error: "",
    data: [],
  });

  const get = (params?: any) => {
    if (!params) params = props;

    let query: any = firebase.firestore().collection("offers");
    setState({ ...state, isLoading: true });

    Object.keys(params).forEach((key) => {
      query = query.where(key, "==", params[key]);
    });

    query
      .get()
      .then((querySnapshot) => {
        const offers = [];

        querySnapshot.forEach((doc) => {
          offers.push(doc.data());
        });

        setState({
          error: "",
          isLoading: false,
          data: offers,
        });
      })
      .catch((e) => {
        setState({
          error: e.message,
          isLoading: false,
          data: [],
        });
      });
  };

  return {
    ...state,
    get: useCallback(get, []),
  };
}

export default useOffers;

import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as routes from "../_CONS/routes";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../styles/theme";
import StudentPathway from "../scenes/StudentPathway";
import AdminPathway from "../scenes/AdminPathway";
import LandingPathway from "../scenes/LandingPathway";
import { userIsAdminChain, userStudentChain } from "../sessions/auth";
import SetupPathway from "../scenes/SetupPathway";
import Alert from "../components/Alert";
import { screenResize } from "../store/actions/appActions";

const Student = userStudentChain(StudentPathway);
const Admin = userIsAdminChain(AdminPathway);

function NoMatch({ location }) {
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

class App extends React.Component {
  componentDidMount() {
    const { screenResize } = this.props;

    screenResize(window.innerWidth);
    window.addEventListener("resize", () => {
      screenResize(window.innerWidth);
    });
  }

  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />

          <Alert />

          <Switch>
            <Route path={routes.STUDENT} component={Student} />
            <Route path={routes.ADMIN} component={Admin} />
            <Route path={routes.SETUP} component={SetupPathway} />
            <Route path={routes.LANDING} component={LandingPathway} />
            <Route component={NoMatch} />
          </Switch>
        </MuiThemeProvider>
      </Router>
    );
  }
}

const mapDispatchToProps = { screenResize };

export default connect(null, mapDispatchToProps)(App);

import styled from "styled-components/macro";

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 10rem;
  z-index: 1;
`;
Main.propTypes = {};

export default Main;

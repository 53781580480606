import styled from "styled-components/macro";
import BackupIcon from "@material-ui/icons/Backup";
import { color } from "../../styles/style";

export const Wrapper = styled.div`
  position: relative;
`;

export const Upload = styled.label`
  display: block;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 2rem;
  height: 2rem;
  color: ${color.primary};
`;

export const UploadIcon = styled(BackupIcon)`
  font-size: inherit;
  position: relative;
  z-index: 1;
`;

export const BackupIconBackground = styled.div`
  height: 1rem;
  width: 0.85rem;
  position: absolute;
  bottom: 0.1rem;
  left: 0.6rem;
  background: #fff;
  z-index: 0;
`;

export const Hidden = styled.div`
  visibility: hidden;
  position: absolute;
  top: -100%;
  left: -100%;
`;

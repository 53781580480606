import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { MainScreen } from "../../../../containers/Main";
import Loading from "../../../../components/Loading";
import StudentTable from "../../../../components/StudentTable";
import TextEmptyState from "../../../../elements/TextEmptyState";
import Button from "../../../../elements/Button";
import firebaseAdmin from "../../../../store/api/firebaseAdmin";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ExcelExport from "../ExelExport";
import { bulkStudentsUpdate, resetStudentView } from "../../../../store/actions/studentActions";
import {
  AddButton,
  BulkButton,
  ButtonsGroup,
  ImportButtonWrapper,
  ImportRow,
  ResetButton,
  WideButton,
} from "./StyledList";
import LoadingModal from "../../../../components/LoadingModal";
import { get } from "lodash";
import { compose } from "redux";

const newCols = [
  { id: "placeholder", align: "left", disablePadding: false, label: "" },
  { id: "lastName", align: "left", disablePadding: false, label: "Last Name" },
  {
    id: "firstName",
    align: "left",
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "email",
    align: "left",
    disablePadding: false,
    label: "email",
  },

  { id: "accountType", align: "left", disablePadding: false, label: "type" },
  { id: "isActive", align: "left", disablePadding: false, label: "active" },
  { id: "menu", align: "center", disablePadding: false, label: "" },
];

class List extends Component {
  constructor(props) {
    super(props);
    this.refUsers = null;
    this.newUsers = null;
    this.state = {
      isLoading: true,
      filteredStudents: [],
      students: [],
      searchPerformed: false,
      selectedStudents: [],
      showBulkActions: false,
      searchOptions: {
        startDate: null,
        endDate: null,
        showInactives: true,
        accountType: "all",
        firstName: "",
        lastName: "",
      },
    };
    // is kept not in state for better performance
    this.searchName = {
      firstName: "",
      lastName: "",
    };
    this.goToScreen = this.goToScreen.bind(this);
  }

  onCollectionUpdate = querySnapshot => {
    const students = [];

    querySnapshot.forEach(doc => {
      const student = doc.data();
      student.uid = doc.id;

      students.push(student);
    });

    this.filterStudents(students);
    this.setState({ students });
  };

  componentDidMount() {
    const userOrganization = get(this.props.profile, "token.claims.organization");
    let query = firebaseAdmin.firestore().collection("users").where("role", "!=", "admin");

    if (userOrganization) {
      query = query.where("organization", "==", userOrganization);
    }

    this.refUsers = query.onSnapshot(this.onCollectionUpdate);

    this.props.resetStudentView();
  }

  componentWillUnmount() {
    this.refUsers();
  }

  goToScreen(e, route) {
    const { match, history } = this.props;

    history.push(`${match.url}/${route}`);
    e.preventDefault();
  }

  updateSearchOptions = (option, value) => {
    this.setState(state => ({
      searchPerformed: false,
      searchOptions: {
        ...state.searchOptions,
        [option]: value,
      },
    }));
  };

  onToggleInactives = e => {
    this.updateSearchOptions("showInactives", e.target.checked);
  };

  onAccountTypeChange = e => {
    this.updateSearchOptions("accountType", e.target.value);
  };

  onNameChange = e => {
    this.searchName[e.target.name] = e.target.value;
  };

  onStartDateChange = momentDate => {
    this.updateSearchOptions("startDate", momentDate);
  };

  onEntDateChange = momentDate => {
    this.updateSearchOptions("endDate", momentDate);
  };

  resetSearchOptions = () => {
    this.searchName = {
      firstName: "",
      lastName: "",
    };

    this.setState(
      {
        searchOptions: {
          showInactives: true,
          startDate: null,
          endDate: null,
          accountType: "all",
        },
      },
      this.filterStudents
    );
  };

  handleStudentSelect = id => {
    const { selectedStudents, filteredStudents } = this.state;
    const selectedIndex = selectedStudents.findIndex(student => student.id === id);
    let newSelected = [];

    if (selectedIndex === -1) {
      const item = filteredStudents.find(student => student.uid === id);

      newSelected = [
        ...selectedStudents,
        {
          id: id,
          isActive: item.isActive,
        },
      ];
    } else if (selectedIndex === 0) {
      newSelected = selectedStudents.slice(1);
    } else if (selectedIndex === selectedStudents.length - 1) {
      newSelected = selectedStudents.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = [...selectedStudents.slice(0, selectedIndex), ...selectedStudents.slice(selectedIndex + 1)];
    }

    this.setState({ selectedStudents: newSelected });
  };

  selectAllStudents = () => {
    const { filteredStudents, selectedStudents } = this.state;

    if (filteredStudents.length !== selectedStudents.length) {
      this.setState({
        selectedStudents: filteredStudents.map(student => ({
          id: student.uid,
          isActive: student.isActive,
        })),
      });
    } else {
      this.setState({
        selectedStudents: [],
      });
    }
  };

  toggleBulkActions = () => {
    this.setState(state => ({
      showBulkActions: !state.showBulkActions,
    }));
  };

  onBulkUpdate = event => {
    const { selectedStudents } = this.state;
    const val = event.target.value;
    const changes = {};

    if (val === "trialToPro") {
      changes.accountType = "pro";
    } else if (val === "proToTrial") {
      changes.accountType = "trial";
    } else if (val === "archive") {
      changes.isActive = false;
    } else if (val === "unarchive") {
      changes.isActive = true;
    }

    this.setState({
      selectedStudents: selectedStudents.map(student => ({
        id: student.id,
        isActive: changes.isActive !== undefined ? changes.isActive : student.isActive,
      })),
    });

    this.props.bulkStudentsUpdate(
      this.state.selectedStudents.map(student => student.id),
      changes
    );
  };

  filterStudents = (students = this.state.students) => {
    const { showInactives, accountType, startDate, endDate } = this.state.searchOptions;
    const firstName = this.searchName.firstName.toLowerCase();
    const lastName = this.searchName.lastName.toLowerCase();
    const startSeconds = startDate && startDate.isValid() ? startDate._d.getTime() / 1000 : 0;
    const endSeconds = endDate && endDate.isValid() ? endDate._d / 1000 : 0;

    const filteredStudents = students.filter(student => {
      if (firstName && student.firstName.toLowerCase().indexOf(firstName) === -1) {
        return false;
      }
      if (lastName && student.lastName.toLowerCase().indexOf(lastName) === -1) {
        return false;
      }
      if (accountType !== "all") {
        if (accountType === "new" && student.status !== "new") {
          return false;
        } else if (accountType === "trial" && student.accountType !== "trial") {
          return false;
        } else if (accountType === "pro" && student.accountType !== "pro") {
          return false;
        }
      }
      if (!showInactives && !student.isActive) {
        return false;
      }
      if (startSeconds && student.startDate.seconds < startSeconds) {
        return false;
      }
      if (endSeconds && student.startDate.seconds > endSeconds) {
        return false;
      }

      return true;
    });

    this.setState({
      filteredStudents: filteredStudents,
      searchPerformed: true,
      searchOptions: {
        showInactives,
        accountType,
        startDate,
        endDate,
        firstName,
        lastName,
      },
    });
  };

  handleDialog = () => {
    this.props.resetStudentView();
  };

  render() {
    const { filteredStudents, searchOptions, searchPerformed, selectedStudents, showBulkActions } = this.state;
    const { status, organization, profile, firestoreStatus } = this.props;

    const isOrganizationUser = organization && organization.id === profile.token.claims.organization;
    const isOrganizationAdmin = organization && organization.primaryContact === profile.uid;
    const showArchiveAction = selectedStudents.find(student => student.isActive);
    const showUnarchiveAction = selectedStudents.find(student => !student.isActive);

    return (
      <MainScreen title="Students">
        <LoadingModal open={status === "loading" || !!firestoreStatus.requesting.currentOrganization} />

        <Grid item xs={12}>
          <form>
            <Grid container spacing={3}>
              <ImportRow container item>
                <Grid item xs={8}>
                  <Grid container spacing={3} alignItems={"flex-end"}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid item>
                        <DatePicker
                          clearable
                          format="MM/DD/yyyy"
                          placeholder="mm/dd/yyyy"
                          value={searchOptions.startDate}
                          onChange={this.onStartDateChange}
                        />
                      </Grid>

                      <Grid item>
                        <DatePicker
                          clearable
                          disableFuture
                          format="MM/DD/yyyy"
                          placeholder="mm/dd/yyyy"
                          value={searchOptions.endDate}
                          onChange={this.onEntDateChange}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>

                    <Grid item>
                      <Select
                        name={"accountType"}
                        value={searchOptions.accountType}
                        onChange={this.onAccountTypeChange}
                      >
                        <MenuItem value="all">All Students</MenuItem>
                        <MenuItem value="new">New Students</MenuItem>
                        <MenuItem value="trial">Trial Students</MenuItem>
                        <MenuItem value="pro">Pro Students</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                <ImportButtonWrapper item container xs>
                  <Grid item>
                    {isOrganizationAdmin && (
                      <Button
                        color="primary"
                        variant="contained"
                        aria-label="Import Students"
                        onClick={e => this.goToScreen(e, "import")}
                      >
                        Import Students
                      </Button>
                    )}
                  </Grid>
                </ImportButtonWrapper>
              </ImportRow>

              <Grid item container xs={12} spacing={3}>
                <Grid item>
                  <FormControl>
                    <InputLabel htmlFor="firstName">Student First Name</InputLabel>
                    <Input id="firstName" name="firstName" onChange={this.onNameChange} />
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl>
                    <InputLabel htmlFor="lastName">Student Last Name</InputLabel>
                    <Input id="lastName" name="lastName" onChange={this.onNameChange} />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  checked={searchOptions.showInactives}
                  label="View inactive students"
                  onChange={this.onToggleInactives}
                  control={<Checkbox value={"showInactives"} />}
                />
              </Grid>

              <Grid container item direction="row" justify="space-between" xs={12}>
                <Grid item md={5} xs={12}>
                  <Button color="primary" variant="contained" aria-label="Search" onClick={() => this.filterStudents()}>
                    Search
                  </Button>

                  <ResetButton
                    type="reset"
                    color="primary"
                    variant="outlined"
                    aria-label="Reset"
                    onClick={this.resetSearchOptions}
                  >
                    Reset
                  </ResetButton>
                </Grid>

                <Grid item md={7} xs={12}>
                  <ButtonsGroup>
                    <ExcelExport
                      searchPerformed={searchPerformed}
                      searchOptions={[
                        {
                          label: "Start Date",
                          value: searchOptions.startDate && searchOptions.startDate._d,
                        },
                        {
                          label: "End Date",
                          value: searchOptions.endDate && searchOptions.endDate._d,
                        },
                        {
                          label: "Show inactive",
                          value: searchOptions.showInactives,
                        },
                        {
                          label: "Student type",
                          value: searchOptions.accountType,
                        },
                        {
                          label: "Student First Name",
                          value: searchOptions.firstName,
                        },
                        {
                          label: "Student Last Name",
                          value: searchOptions.lastName,
                        },
                      ]}
                      students={filteredStudents}
                      button={<WideButton color="secondary">Export to excel</WideButton>}
                    />

                    {(!isOrganizationUser || isOrganizationAdmin) && (
                      <AddButton
                        color="primary"
                        variant="contained"
                        aria-label="Add Student"
                        onClick={e => this.goToScreen(e, "new")}
                      >
                        + Add Student
                      </AddButton>
                    )}
                  </ButtonsGroup>
                </Grid>
              </Grid>

              {(!isOrganizationUser || isOrganizationAdmin) && (
                <Grid item container direction="row" spacing={3}>
                  <Grid item xs={12}>
                    <ButtonsGroup>
                      <WideButton color="golden" aria-label="Select all students" onClick={this.selectAllStudents}>
                        {selectedStudents.length === filteredStudents.length ? "Deselect All" : "Select All"}
                      </WideButton>

                      {showBulkActions ? (
                        <Box position={"relative"}>
                          <Select
                            MenuProps={{
                              id: "menu-bulk-edit",
                            }}
                            value={""}
                            style={{
                              opacity: 0,
                              width: 0,
                              maxWidth: 0,
                              position: "absolute",
                              top: 60,
                              left: 5,
                            }}
                            onClose={this.toggleBulkActions}
                            open={showBulkActions}
                            onChange={this.onBulkUpdate}
                          >
                            <MenuItem selected value="trialToPro">
                              Update accounts to Pro
                            </MenuItem>
                            <MenuItem value="proToTrial">Update accounts to Trial</MenuItem>
                            {showArchiveAction && <MenuItem value="archive">Archive students</MenuItem>}
                            {showUnarchiveAction && <MenuItem value="unarchive">Unarchive students</MenuItem>}
                          </Select>
                        </Box>
                      ) : null}

                      <BulkButton color="golden" aria-label="Bulk Edit" onClick={this.toggleBulkActions}>
                        Bulk Edit
                      </BulkButton>
                    </ButtonsGroup>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </form>
        </Grid>

        <Grid item xs={12}>
          <Typography variant={"subtitle2"}># of Rows: {filteredStudents.length}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            {!isLoaded(filteredStudents) ? (
              <Loading />
            ) : isEmpty(filteredStudents) ? (
              <TextEmptyState>No Students</TextEmptyState>
            ) : (
              <StudentTable
                selected={selectedStudents.map(student => student.id)}
                onSelect={this.handleStudentSelect}
                withCheckbox={true}
                colHeaders={newCols}
                data={filteredStudents}
                section="new"
              />
            )}
          </Card>
        </Grid>
      </MainScreen>
    );
  }
}

const mapStateToProps = ({ userStatus, firebase, firestore }) => ({
  error: userStatus.error,
  status: userStatus.status,
  profile: firebase.profile,
  firestoreStatus: firestore.status,
  organization: firestore.data.currentOrganization,
});

const mapDispatchToProps = dispatch => ({
  resetStudentView: appObj => dispatch(resetStudentView(appObj)),
  bulkStudentsUpdate: (studentIds, changes) => dispatch(bulkStudentsUpdate(studentIds, changes)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    const userOrganization = get(props.profile, "token.claims.organization");

    const toConnect =
      !!userOrganization && !props.organization
        ? [
            {
              collection: "organizations",
              doc: userOrganization,
              storeAs: "currentOrganization",
            },
          ]
        : [];

    return toConnect;
  })
)(List);

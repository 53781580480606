import { firestore } from "firebase";
import format from "date-fns/format";
import isDate from "date-fns/is_date";

const formatDateTime = (inputVal) => {
  let timestamp

  if (inputVal === undefined) return "no date time"

  if (inputVal.seconds && inputVal.nanoseconds && !isDate(inputVal)) {
    timestamp = new firestore.Timestamp(inputVal.seconds, inputVal.nanoseconds)
    return format(timestamp.toDate(), "MM/DD/YY - HH:mmA")
  }

  if ((!isDate(inputVal) && !inputVal.toDate)) {
    return "no date time";
  }

  return format(timestamp, "MM/DD/YY - HH:mmA");
};

export const timeToMinutes = (time) => {
  if (time) {
    const isValid = /\d{2}:\d{2}:\d{2}/.test(time);

    if (isValid) {
      const parts = time.split(":");
      const result = +parts[0] * 60 + +parts[1] + +parts[2] / 60;

      return result;
    }
  }

  return 0;
};

export const minutesToTime = (totalMinutes) => {
  if (totalMinutes >= 0) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes) - hours * 60;
    const seconds = Math.round((totalMinutes - Math.floor(totalMinutes)) * 60);

    const HH = hours < 10 ? "0" + hours : hours;
    const mm = minutes < 10 ? "0" + minutes : minutes;
    const ss = seconds < 10 ? "0" + seconds : seconds;

    return `${HH}:${mm}:${ss}`;
  }

  return "00:00:00";
};

export { formatDateTime };

import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import IconEdit from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import Button from "../../elements/Button";
import subjectTopics from "../../_CONS/subjectTopics";
import { Card, CardContent, CardHeader } from "../../containers/Card";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 1000 - 2 * theme.spacing(1),
  },
  textField: {
    width: "100%",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const listSubjects = Object.keys(subjectTopics);
const topicObject = {
  topicCode: "",
  subTopicCode: "",
  subSubTopicCode: "",
  subSubSubTopicCode: "",
};

const listTopics = (subjectCode) => {
  const topics = subjectTopics[subjectCode].topics;
  const topicList = Object.keys(topics).map((topic) => {
    return Object.assign({ key: topic, label: topics[topic].label }, {});
  });

  return topicList;
};

const listSubTopics = (subjectCode, topicCode) => {
  const subtopics = subjectTopics[subjectCode].topics[topicCode].subtopics;
  if (subtopics) {
    const subtopicsList = Object.keys(subtopics).map((topic) => {
      return Object.assign({ key: topic, label: subtopics[topic].label }, {});
    });

    return subtopicsList;
  }
  return [];
};

const listSubSubTopics = (subjectCode, topicCode, subTopicCode) => {
  const topicObj = subjectTopics[subjectCode].topics[topicCode];
  if (Object.prototype.hasOwnProperty.call(topicObj, "subtopics")) {
    const subTopicObj = topicObj.subtopics[subTopicCode];
    if (Object.prototype.hasOwnProperty.call(subTopicObj, "subsubtopics")) {
      const subSubTopics = subTopicObj.subsubtopics;
      const subsubtopicsList = Object.keys(subSubTopics).map((topic) => {
        return Object.assign(
          { key: topic, label: subSubTopics[topic].label },
          {}
        );
      });
      return subsubtopicsList;
    }
  }
  return [];
};

const listSubSubSubTopics = (
  subjectCode,
  topicCode,
  subTopicCode,
  subSubTopicCode
) => {
  const subTopicObject =
    subjectTopics[subjectCode].topics[topicCode].subtopics[subTopicCode];
  if (Object.prototype.hasOwnProperty.call(subTopicObject, "subsubtopics")) {
    const subsubtopicObj = subTopicObject.subsubtopics[subSubTopicCode];
    if (
      Object.prototype.hasOwnProperty.call(subsubtopicObj, "subsubsubtopics")
    ) {
      const subsubsubtopics = subsubtopicObj.subsubsubtopics;
      const subsubsubtopicsList = Object.keys(subsubsubtopics).map((code) => {
        return Object.assign(
          { key: code, label: subsubsubtopics[code].label },
          {}
        );
      });
      return subsubsubtopicsList;
    }
  }
  return [];
};

class SelectClassification extends Component {
  state = {
    editMode: this.props.isEditMode,
    subjectCode: this.props.subjectCode ? this.props.subjectCode : "",
    oldSubjectCode: this.props.subjectCode ? this.props.subjectCode : "",
    subject: this.props.subjectCode
      ? subjectTopics[this.props.subjectCode].label
      : "",
    oldSubject: this.props.subjectCode
      ? subjectTopics[this.props.subjectCode].label
      : "",
    topicCode: this.props.topicCode ? this.props.topicCode : "",
    subTopicCode: this.props.subTopicCode ? this.props.subTopicCode : "",
    classification: this.props.classification ? this.props.classification : "",
    oldClassification: this.props.classification
      ? this.props.classification
      : "",
    ...topicObject,
    topicCodes: [],
    subTopicCodes: [],
    subSubTopicCodes: [],
    subSubSubTopicCodes: [],
  };

  static getDerivedStateFromProps(props, state) {
    const classif = props.classification;
    const oldClassif = state.oldClassification;
    if (classif === oldClassif) {
      return null;
    }
    const subCode = state.subjectCode;

    if (classif.length < 1 || subCode === "") {
      return {};
    }

    const classificationArray = classif.split("-");
    let topicCode = "",
      subTopicCode = "",
      subSubTopicCode = "",
      subSubSubTopicCode = "",
      topicCodes = "",
      subTopicCodes = "",
      subSubTopicCodes = "",
      subSubSubTopicCodes = "";

    if (classificationArray) {
      if (classificationArray.length > 0) {
        topicCodes = listTopics(subCode);
        topicCode = classificationArray[0];
      }
      if (classificationArray.length > 1) {
        subTopicCodes = listSubTopics(subCode, topicCode);
        subTopicCode = classificationArray[1];
      }
      if (classificationArray.length > 2) {
        subSubTopicCodes = listSubSubTopics(subCode, topicCode, subTopicCode);
        subSubTopicCode = classificationArray[2];
      }
      if (classificationArray.length > 3) {
        subSubSubTopicCodes = listSubSubSubTopics(
          subCode,
          topicCode,
          subTopicCode,
          subSubTopicCode
        );
        subSubSubTopicCode = classificationArray[3];
      }

      return {
        topicCode,
        topicCodes,
        subTopicCode,
        subTopicCodes,
        subSubTopicCode,
        subSubTopicCodes,
        subSubSubTopicCode,
        subSubSubTopicCodes,
      };
    } else {
      return {
        ...topicObject,
        topicCodes: [],
        subTopicCodes: [],
        subSubTopicCodes: [],
        subSubSubTopicCodes: [],
      };
    }
  }

  handleSubjectCodeChange = (event) => {
    const code = event.target.value;
    const label = subjectTopics[code].label;
    const topicCodes = listTopics(code);
    this.setState({
      subjectCode: event.target.value,
      subject: label,
      topicCodes: topicCodes,
      ...topicObject,
      classification: "",
    });
  };

  handleChangeTopicCode = (event) => {
    const { subjectCode } = this.state;
    const newcode = event.target.value;
    const subTopicCodes = listSubTopics(subjectCode, newcode);

    this.setState({
      topicCode: newcode,
      classification: newcode,
      subTopicCodes: subTopicCodes,
      subTopicCode: "",
      subSubTopicCode: "",
      subSubSubTopicCode: "",
    });
  };

  handleChangeSubTopicCode = (event) => {
    const newcode = event.target.value;
    const { subjectCode, topicCode } = this.state;

    const subSubTopicCodes = listSubSubTopics(subjectCode, topicCode, newcode);
    this.setState({
      subTopicCode: newcode,
      classification: `${topicCode}-${newcode}`,
      subSubTopicCodes: subSubTopicCodes,
      subSubTopicCode: "",
      subSubSubTopicCode: "",
    });
  };

  handleChangeSubSubTopicCode = (event) => {
    const newcode = event.target.value;
    const { subjectCode, topicCode, subTopicCode } = this.state;
    const subSubSubTopicCodes = listSubSubSubTopics(
      subjectCode,
      topicCode,
      subTopicCode,
      newcode
    );
    const classification = `${topicCode}-${subTopicCode}-${newcode}`;
    this.setState({
      subSubTopicCode: newcode,
      classification,
      subSubSubTopicCodes,
      subSubSubTopicCode: "",
    });
  };

  handleChangeSubSubSubTopicCode = (event) => {
    const newcode = event.target.value;
    const { topicCode, subTopicCode, subSubTopicCode } = this.state;
    const classification = `${topicCode}-${subTopicCode}-${subSubTopicCode}-${newcode}`;
    this.setState({ subSubSubTopicCode: event.target.value, classification });
  };

  cancelFieldValue = () => {
    const { oldSubjectCode, oldSubject, oldClassification } = this.state;

    this.setState({
      subjectCode: oldSubjectCode,
      subject: oldSubject,
      classification: oldClassification,
      editMode: false,
    });
  };

  saveFieldValue = () => {
    const { subjectCode, subject, classification } = this.state;
    const newCodes = {
      subjectCode,
      subject,
      classification,
    };
    this.props.fieldChange(newCodes);
    this.setState({
      editMode: false,
      oldSubjectCode: subjectCode,
      oldSubject: subject,
      oldClassification: classification,
    });
  };

  toggleEditMode = () => {
    this.setState((state) => ({
      editMode: !state.editMode,
    }));
  };

  render() {
    const { classes, label } = this.props;
    const {
      editMode,
      subjectCode,
      classification,
      oldClassification,
      subject,
      topicCode,
      subTopicCode,
      subSubTopicCode,
      subSubSubTopicCode,
      topicCodes,
      subTopicCodes,
      subSubTopicCodes,
      subSubSubTopicCodes,
    } = this.state;

    return (
      <Grid item xs={12}>
        {editMode ? (
          <Card>
            <CardHeader
              title={label}
              action={
                <IconButton onClick={this.toggleEditMode} disabled={editMode}>
                  <IconEdit />
                </IconButton>
              }
            />
            <CardContent className={classes.content}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink={true} htmlFor="select-subject-code">
                      Subject Code
                    </InputLabel>
                    <Select
                      value={subjectCode}
                      onChange={this.handleSubjectCodeChange}
                    >
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      {listSubjects.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel shrink={true} htmlFor="show-subject">
                      Subject
                    </InputLabel>
                    <TextField
                      id="show-subject"
                      value={subject}
                      className={classes.textField}
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                        shrink: "true",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink={true} htmlFor="classification">
                      Classification
                    </InputLabel>
                    <TextField
                      id="classification"
                      value={classification}
                      className={classes.textField}
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                        shrink: "true",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justify="flex-start" alignItems="center">
                <Grid item>
                  {subjectCode && (
                    <>
                      {topicCodes.length > 0 && (
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="select-multiple">
                            Topic Code
                          </InputLabel>
                          <Select
                            value={topicCode}
                            onChange={this.handleChangeTopicCode}
                          >
                            <MenuItem value="" disabled>
                              Select...
                            </MenuItem>
                            {topicCodes.map((item) => (
                              <MenuItem key={item.key} value={item.key}>
                                {`(${item.key}) ${item.label}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item>
                  {topicCode && (
                    <>
                      {subTopicCodes.length > 0 && (
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="select-multiple">
                            Sub Topic Code
                          </InputLabel>
                          <Select
                            value={subTopicCode}
                            onChange={this.handleChangeSubTopicCode}
                          >
                            <MenuItem value="" disabled>
                              Select...
                            </MenuItem>
                            {subTopicCodes.map((item) => (
                              <MenuItem key={item.key} value={item.key}>
                                {`(${item.key}) ${item.label}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item>
                  {subTopicCode && (
                    <>
                      {subSubTopicCodes.length > 0 && (
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="select-multiple">
                            Sub Sub Topic Code
                          </InputLabel>
                          <Select
                            value={subSubTopicCode}
                            onChange={this.handleChangeSubSubTopicCode}
                          >
                            <MenuItem value="" disabled>
                              Select...
                            </MenuItem>
                            {subSubTopicCodes.map((item) => (
                              <MenuItem key={item.key} value={item.key}>
                                {`(${item.key}) ${item.label}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item>
                  {subSubTopicCode && (
                    <>
                      {subSubSubTopicCodes.length > 0 && (
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="select-multiple">
                            Sub Sub Sub Topic Code
                          </InputLabel>
                          <Select
                            value={subSubSubTopicCode}
                            onChange={this.handleChangeSubSubSubTopicCode}
                          >
                            <MenuItem value="" disabled>
                              Select...
                            </MenuItem>
                            {subSubSubTopicCodes.map((item) => (
                              <MenuItem key={item.key} value={item.key}>
                                {`(${item.key}) ${item.label}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            {classification !== oldClassification && (
              <CardContent className={classes.actions}>
                <Button
                  variant="text"
                  color="default"
                  onClick={this.cancelFieldValue}
                >
                  CANCEL
                </Button>
                <Button
                  color="secondary"
                  variant="text"
                  onClick={this.saveFieldValue}
                  disabled={oldClassification === classification}
                >
                  SAVE
                </Button>
              </CardContent>
            )}
          </Card>
        ) : (
          <Card>
            <CardHeader
              title={label}
              action={
                <IconButton onClick={this.toggleEditMode}>
                  <IconEdit />
                </IconButton>
              }
            />
            <CardContent>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink={true} htmlFor="subjectCode">
                      Subject Code
                    </InputLabel>
                    <TextField
                      id="subjectCode"
                      value={subjectCode}
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                        shrink: "true",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel shrink={true} htmlFor="show-subject">
                      Subject
                    </InputLabel>
                    <TextField
                      id="show-subject"
                      value={subject}
                      className={classes.textField}
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                        shrink: "true",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink={true} htmlFor="classification">
                      Classification
                    </InputLabel>
                    <TextField
                      id="classification"
                      value={classification}
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                        shrink: "true",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Grid>
    );
  }
}

SelectClassification.propTypes = {
  classes: PropTypes.object.isRequired,
  subjectCode: PropTypes.string,
  classification: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(SelectClassification);

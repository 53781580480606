import React, { Component } from "react";
import { connect } from "react-redux";
import { signOut } from "../../../store/actions/authActions";
import { MainScreen } from "../../../containers/Main";
import ProfileScreen from "../../../screens/ProfileScreen";

class Profile extends Component {
  render() {
    return (
      <MainScreen title="Profile">
        <ProfileScreen logout={this.props.signOut} user={this.props.user} />
      </MainScreen>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.firebase.profile,
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

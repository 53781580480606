import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const headerStyle = {
  font: {
    sz: "11",
    bold: true,
  },
};

const textStyle = {
  font: {
    sz: "11",
  },
};

const studentColumns = [
  {
    title: "Email",
    style: headerStyle,
  },
  {
    title: "Reason",
    style: headerStyle,
  },
];

function ExportReport({ data, button }) {
  const titleDate = new Date().toLocaleDateString().replace(/\//g, "-");

  const errors = data.map((row) => [
    { value: row.email, style: textStyle },
    { value: row.message, style: textStyle },
  ]);

  const table = [
    {
      ySteps: 1,
      columns: studentColumns,
      data: errors,
    },
  ];

  return (
    <div>
      <ExcelFile
        filename={"Student Export Report " + titleDate}
        element={button}
      >
        <ExcelSheet dataSet={table} name="Organization" />
      </ExcelFile>
    </div>
  );
}

export default ExportReport;

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import Button from "../../../../elements/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/createReduxStore";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { test } from "../../../../_CONS/qbanks";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { lockTests } from "../../../../store/actions/organizationsActions";
import { TOrganization } from "../../../../../types";
import firebase from "firebase";
import LoadingModal from "../../../../components/LoadingModal";

export type TTest = {
  qbank: string;
  until: null | MaterialUiPickersDate;
  value: string;
  isLocked: boolean;
};

type TTests = {
  organizationId: string;
};

const Tests: React.FC<TTests> = ({ organizationId }) => {
  const dispatch = useDispatch();
  const status = useSelector((state: RootState) => state.organizationsReducer.status);
  const tests: Array<TTest> = test.map(item => ({
    qbank: item.label,
    until: null,
    value: item.value,
    isLocked: false,
  }));
  const [organization, setOrganization] = useState<TOrganization | null>(null);
  const [lockedTests, setLockedTests] = useState<any>(tests);

  const onLockChange = () => {
    setOrganization(prev => ({
      ...prev,
      isTestsLocked: !prev.isTestsLocked,
    }));
  };

  useEffect(() => {
    if (organization && organization.lockedTests) {
      setLockedTests(
        organization.lockedTests.map(test => ({
          ...test,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          until: test.until ? new Date(test.until.seconds * 1000) : null,
        }))
      );
    }
  }, [organization]);

  const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setLockedTests(prev => prev.map(test => (test.value === name ? { ...test, isLocked: !test.isLocked } : test)));
  };

  const onDateChange = (date, value: string) => {
    setLockedTests(prev => prev.map(test => (test.value === value ? { ...test, until: date._d } : test)));
  };

  const onSave = () => {
    dispatch(
      lockTests({
        id: organizationId,
        lockedTests,
        isTestsLocked: !!organization.isTestsLocked,
      })
    );
  };

  const getOrganization = async () => {
    return await firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .get()
      .then(doc => doc.data());
  };

  useEffect(() => {
    getOrganization().then(setOrganization);
  }, []);

  return (
    <React.Fragment>
      <LoadingModal open={status === "loading"} />
      {organization && (
        <Grid container direction={"column"} spacing={2}>
          <Grid item>
            <Typography variant={"h6"}>Lock out QBank Tests</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              label="Lock QBank tests from students"
              control={
                <Checkbox
                  onChange={onLockChange}
                  checked={organization && !!organization.isTestsLocked}
                  color="secondary"
                />
              }
            />
          </Grid>
          {organization && organization.isTestsLocked && (
            <Grid item>
              {/* <Typography
                variant={"h6"}
                color={"primary"}
                style={{ marginBottom: 15 }}
              >
                Practice Tests
              </Typography>
              {organization &&
                lockedTests
                  .filter((item) => item.qbank.startsWith("Practice"))
                  .map((test) => (
                    <Grid
                      container
                      item
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                      key={test.value}
                    >
                      <Grid item>
                        <FormControlLabel
                          label={test.qbank}
                          name={test.value}
                          control={
                            <Checkbox
                              onChange={onCheck}
                              name={test.value}
                              checked={test.isLocked}
                              color="secondary"
                            />
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Typography>
                          Make test available to student on
                        </Typography>
                      </Grid>
                      <Grid item>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            disabled={!test.isLocked}
                            fullWidth
                            minDate={new Date()}
                            format="MM/DD/yyyy"
                            value={test.until}
                            onChange={(date) => onDateChange(date, test.value)}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  ))} */}
              <Typography variant={"h6"} color={"primary"} style={{ margin: "15px 0" }}>
                Final Tests
              </Typography>
              {organization &&
                lockedTests
                  .filter(item => item.qbank.startsWith("Final"))
                  .map(test => (
                    <Grid container item direction={"row"} alignItems={"center"} spacing={1} key={test.value}>
                      <Grid item>
                        <FormControlLabel
                          label={test.qbank}
                          name={test.value}
                          control={
                            <Checkbox
                              onChange={onCheck}
                              name={test.value}
                              checked={!!test.isLocked}
                              color="secondary"
                            />
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Typography>Make test available to student on</Typography>
                      </Grid>
                      <Grid item>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            disabled={!test.isLocked}
                            fullWidth
                            minDate={new Date()}
                            format="MM/DD/yyyy"
                            value={test.until}
                            onChange={date => onDateChange(date, test.value)}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  ))}
            </Grid>
          )}
          <Grid item>
            <Button onClick={onSave}>Update</Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default Tests;

import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";
import { mediaMinDevice } from "../../styles/responsive";

export const NavWrapper = styled.footer`
  width: 100%;
`;

export const NavContainer = styled.div`
  width: 100%;
  background: #cfdae6;
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  height: 3.5rem;
  align-items: center;
  ${mediaMinDevice.tabletL} {
    height: 6rem;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
`;

export const QuestionButton = styled(Button)`
  padding-left: 1.75em;
  padding-right: 1.75em;
`;

export const ButtonLabel = styled.span`
  display: none;
  ${mediaMinDevice.tabletL} {
    display: block;
  }
`;

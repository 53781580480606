import { AccordionDetails, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Chevron from "@material-ui/icons/KeyboardArrowDown";
import { MainScreen } from "../../containers/Main";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/createReduxStore";
import { Accordion, AccordionSummary } from "./StyledStudentFAQ";
import Button from "../../elements/Button";
import { get } from "lodash";
import FaqForm from "../../components/FaqForm";
import {
  createQuestion,
  deleteQuestion,
  fetchQuestions,
  resetError,
  updateQuestion,
} from "../../store/actions/faqActions";
import Dialog from "../../components/Dialog";

type TAccordionItem = {
  id: string;
  expanded: boolean;
  title: string;
  body: string;
  editMode: boolean;
};

const StudentFAQ: React.FC = () => {
  const dispatch = useDispatch();
  const faq = useSelector(({ faqReducer }: RootState) => faqReducer.questions);
  const error = useSelector(({ faqReducer }: RootState) => faqReducer.error);
  const status = useSelector(({ faqReducer }: RootState) => faqReducer.status);
  const isAdmin = useSelector(
    ({ firebase }: RootState) =>
      get(firebase, "profile.token.claims.role") === "admin"
  );
  const [questions, setQuestions] = useState<TAccordionItem[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState("");

  useEffect(() => {
    dispatch(fetchQuestions());
  }, []);

  useEffect(() => {
    if (faq) {
      const result = faq
        .map((item) => ({
          id: item.id,
          title: item.title,
          body: item.body,
          expanded: false,
          editMode: false,
        }))
        .sort((a, b) => (a.title < b.title ? -1 : 1));

      setQuestions(result);
    }
  }, [faq]);

  const onExpand = (id: string) => () => {
    const result = questions.map((question) =>
      id === question.id
        ? {
            ...question,
            expanded: !question.expanded,
          }
        : question
    );

    setQuestions(result);
  };

  const cancelEdit = (id: string) => () => {
    const result = questions.map((question) =>
      id === question.id
        ? {
            ...question,
            editMode: false,
          }
        : question
    );

    setQuestions(result);
  };

  const enterEditMode = (id: string) => () => {
    const result = questions.map((question) =>
      id === question.id
        ? {
            ...question,
            editMode: true,
          }
        : question
    );

    setQuestions(result);
  };

  const onUpdate =
    (id: string) => (question: { title: string; body: string }) => {
      dispatch(
        updateQuestion({
          id,
          ...question,
        })
      );
    };

  const onCreate = (question: { title: string; body: string }) => {
    dispatch(createQuestion(question));
  };

  const onDelete = (id: string) => () => {
    setShowDialog(true);
    setQuestionToDelete(id);
  };

  const cancelDelete = () => {
    setShowDialog(false);

    if (error) {
      dispatch(resetError());
    }
  };

  const confirmDelete = () => {
    dispatch(deleteQuestion(questionToDelete));
    setQuestionToDelete(null);
    setShowDialog(false);
  };

  const dialog = {
    title: error ? "Error" : "Confirm Delete",
    type: (error ? "alert" : "confirm") as "alert" | "confirm",
    content: error || "Are you sure you want to delete the question?",
    open: showDialog || status === "loading" || error,
    buttonLabels: error ? [] : ["Cancel", "Confirm"],
    isLoading: status === "loading",
  };

  return (
    <MainScreen title="Frequently Asked Questions">
      <Dialog {...dialog} onCancel={cancelDelete} onConfirm={confirmDelete} />

      {isAdmin && (
        <Grid item xs={12}>
          <Typography variant={"h6"} gutterBottom>
            Create a New Question
          </Typography>

          <FaqForm onSave={onCreate} />
        </Grid>
      )}

      <Grid item xs={12}>
        {isAdmin && (
          <Typography variant={"h6"} gutterBottom>
            All Frequently Asked Questions
          </Typography>
        )}
        {questions.map((item) =>
          item.editMode && isAdmin ? (
            <FaqForm
              question={item}
              onSave={onUpdate(item.id)}
              onCancel={cancelEdit(item.id)}
            />
          ) : (
            <Accordion expanded={item.expanded} onChange={onExpand(item.id)}>
              <AccordionSummary expandIcon={<Chevron />}>
                <Typography variant={"subtitle1"}>{item.title}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                {item.body.split(/\r?\n/).map((chunk, index) => (
                  <Typography key={index} variant="body2" gutterBottom>
                    {chunk}
                  </Typography>
                ))}

                {isAdmin && (
                  <Grid container justify="flex-end" className={"mt-3"}>
                    <Grid item>
                      <Button
                        color={"danger"}
                        className={"mr-3"}
                        onClick={onDelete(item.id)}
                      >
                        Delete
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button onClick={enterEditMode(item.id)}>Edit</Button>
                    </Grid>
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          )
        )}
      </Grid>
    </MainScreen>
  );
};

export default StudentFAQ;

import React, { ReactNode } from "react";
import styled from "styled-components/macro";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { mediaMinDevice } from "../../styles/responsive";
import { Card, CardContent, CardHeader } from "../../containers/Card";

const InfoCardFooter = styled.div`
  display: block;
  & a {
    margin-bottom: 1rem;
  }
  & a:last-child {
    margin-bottom: 0;
  }
  ${mediaMinDevice.mobileL} {
    display: flex;
    justify-content: space-between;
    & a {
      margin-bottom: 0;
    }
  }
`;

type TProps = {
  title?: string;
  txt?: string | ReactNode;
  txtTwo?: string | ReactNode;
  txtThree?: string | ReactNode;
  divider?: boolean;
};

const InfoCard: React.FC<TProps> = ({ title = "", txt = "", divider = false, children, txtTwo = "", txtThree = '' }) => {
  const info = typeof txt === "string" ? <Typography>{txt}</Typography> : txt;
  const info2 = typeof txtTwo === "string" ? <Typography>{txtTwo}</Typography> : txtTwo;
  const info3 = typeof txtThree === "string" ? <Typography>{txtThree}</Typography> : txtThree;

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>{info}</CardContent>
      {txtTwo ? <CardContent>{info2}</CardContent> : null}
      {txtThree ? <CardContent>{info3}</CardContent> : null}
      {divider ? <Divider /> : ""}
      <CardContent>
        <InfoCardFooter>{children}</InfoCardFooter>
      </CardContent>
    </Card>
  );
};

export default InfoCard;

import firebase from "../api/firebaseAdmin";
import { TOrganization } from "../../../types";
import { actionCreator } from "../../utils/actionCreator";
import { getAsyncActions } from "../../utils/getAsyncActions";
import { showAlert } from "./alertActions";
import { TOffer } from "../../types/TYPES";
import { TTest } from "../../scenes/AdminPathway/Organizations/Tests/Tests";

export const OrganizationActions: any = {
  RESET_STATUS: "organization/reset",
  ...getAsyncActions({
    UPDATE: "organization/updated",
    CREATE: "organization/created",
    DELETE: "organization/delete",
    ADD_OFFER: "organization/addOffer",
    UPDATE_OFFER: "organization/updateOffer",
    SET_PRO_ACCOUNT: "organization/setProAccount",
    LOCK_TESTS: "organization/lockTests",
  }),
};

export const resetOrganizationsStatus = () =>
  actionCreator(OrganizationActions.RESET_STATUS);

export const createOrganization = (
  data: { name: string; type: string },
  avatarFile?: any
) => {
  return async (dispatch) => {
    dispatch(actionCreator(OrganizationActions.CREATE["REQUESTED"]));
    let avatarUrl = null;

    try {
      const docRef = firebase.firestore().collection("organizations").doc();
      const sameNameOrganization = await firebase
        .firestore()
        .collection("organizations")
        .where("name", "==", data.name)
        .get();

      if (sameNameOrganization.empty) {
        if (avatarFile) {
          const logoRef = firebase
            .storage()
            .ref()
            .child(`organizationsLogo/${docRef.id}`);
          await logoRef.put(avatarFile);
          avatarUrl = await logoRef.getDownloadURL();
          avatarUrl = avatarUrl.replace("?alt=media", "_200x200?alt=media");
        }

        await docRef.set({
          ...data,
          id: docRef.id,
          avatarUrl,
          isActive: true,
          createdDate: new Date(),
          updatedDate: new Date(),
        });

        dispatch(
          showAlert({
            title: "Success",
            message: "The Organization has been successfully created.",
          })
        );
        dispatch(actionCreator(OrganizationActions.CREATE["SUCCESS"]));
      } else {
        dispatch(
          showAlert({
            title: "Error",
            message: "The Organization already exists.",
          })
        );
        dispatch(actionCreator(OrganizationActions.CREATE["FAILURE"]));
      }
    } catch (e) {
      dispatch(
        showAlert({
          title: "Error",
          message: "The Organization has not been created.",
        })
      );
      dispatch(
        actionCreator(
          OrganizationActions.CREATE["FAILURE"],
          e.message || JSON.stringify(e)
        )
      );
    }
  };
};

export const updateOrganization = (
  data: TOrganization,
  avatarFile?: Blob | Uint8Array | ArrayBuffer
) => {
  return async (dispatch) => {
    dispatch(actionCreator(OrganizationActions.UPDATE["REQUESTED"]));
    const payload = {
      ...data,
      updatedDate: new Date(),
    };

    try {
      if (avatarFile) {
        const logoRef = firebase
          .storage()
          .ref()
          .child(`organizationsLogo/${data.id}`);
        await logoRef.put(avatarFile);
        payload.avatarUrl = await logoRef.getDownloadURL();
        payload.avatarUrl = payload.avatarUrl.replace(
          "?alt=media",
          "_200x200?alt=media"
        );
      }

      await firebase
        .firestore()
        .collection("organizations")
        .doc(data.id)
        .update(payload);

      dispatch(
        showAlert({
          title: "Success",
          message: "The Organization has been successfully updated.",
        })
      );
      dispatch(actionCreator(OrganizationActions.UPDATE["SUCCESS"]));
    } catch (e) {
      console.log(e);
      dispatch(
        showAlert({
          title: "Error",
          message: "The Organization has not been updated.",
        })
      );
      dispatch(
        actionCreator(
          OrganizationActions.UPDATE["FAILURE"],
          e.message || JSON.stringify(e)
        )
      );
    }
  };
};

export const deleteOrganization = (id: string) => {
  return async (dispatch) => {
    dispatch(actionCreator(OrganizationActions.DELETE["REQUESTED"]));

    try {
      await firebase.firestore().collection("organizations").doc(id).delete();

      dispatch(
        showAlert({
          title: "Success",
          message: "The Organization has been successfully deleted.",
        })
      );
      dispatch(actionCreator(OrganizationActions.DELETE["SUCCESS"]));
    } catch (e) {
      dispatch(
        showAlert({
          title: "Error",
          message: "The Organization has not been deleted.",
        })
      );
      dispatch(
        actionCreator(
          OrganizationActions.DELETE["FAILURE"],
          e.message || JSON.stringify(e)
        )
      );
    }
  };
};

export const addOffer = (offer: Omit<TOffer, "id" | "isActive">) => {
  return async (dispatch) => {
    dispatch(actionCreator(OrganizationActions.ADD_OFFER["REQUESTED"]));

    try {
      const collection = firebase.firestore().collection("offers");
      const batch = firebase.firestore().batch();
      const offerRef = collection.doc();

      const prevOffers = await collection
        .where("organizationId", "==", offer.organizationId)
        .where("isActive", "==", true)
        .get();

      prevOffers.forEach((offer) => {
        batch.update(collection.doc(offer.id), { isActive: false });
      });

      batch.set(offerRef, {
        ...offer,
        id: offerRef.id,
        isActive: true,
        createdDate: new Date(),
      });

      await batch.commit();

      dispatch(
        actionCreator(OrganizationActions.ADD_OFFER["SUCCESS"], {
          ...offer,
          id: offerRef.id,
        })
      );
      dispatch(
        showAlert({
          title: "Success",
          message: "The Offer has been successfully created.",
        })
      );
    } catch (e) {
      dispatch(actionCreator(OrganizationActions.ADD_OFFER["FAILURE"]));

      dispatch(
        showAlert({
          title: "Error",
          message: "The Offer has not been created.",
        })
      );
    }
  };
};

export const updateOffer = (
  offer: { id: string; organizationId: string } & Partial<TOffer>
) => {
  return async (dispatch) => {
    dispatch(actionCreator(OrganizationActions.UPDATE_OFFER["REQUESTED"]));

    try {
      const collection = firebase.firestore().collection("offers");
      const batch = firebase.firestore().batch();

      if (offer.isActive) {
        const prevOffers = await collection
          .where("organizationId", "==", offer.organizationId)
          .where("isActive", "==", true)
          .get();

        prevOffers.forEach((offer) => {
          batch.update(collection.doc(offer.id), { isActive: false });
        });
      }

      batch.update(collection.doc(offer.id), offer);

      await batch.commit();

      dispatch(actionCreator(OrganizationActions.UPDATE_OFFER["SUCCESS"]));
      dispatch(
        showAlert({
          title: "Success",
          message: "The Offer has been successfully updated.",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(actionCreator(OrganizationActions.UPDATE_OFFER["FAILURE"]));

      dispatch(
        showAlert({
          title: "Error",
          message: "The Offer has not been updated.",
        })
      );
    }
  };
};

export const setProAccount = ({
  id,
  willFundPro,
}: {
  id: string;
  willFundPro: boolean;
}) => {
  return async (dispatch) => {
    dispatch(actionCreator(OrganizationActions.SET_PRO_ACCOUNT["REQUESTED"]));
    try {
      const collection = await firebase.firestore().collection("organizations");
      const batch = firebase.firestore().batch();

      batch.update(collection.doc(id), { willFundPro: !willFundPro });
      await batch.commit();
      dispatch(actionCreator(OrganizationActions.SET_PRO_ACCOUNT["SUCCESS"]));
    } catch (e) {
      dispatch(actionCreator(OrganizationActions.SET_PRO_ACCOUNT["FAILURE"]));

      dispatch(
        showAlert({
          title: "Error",
          message: e,
        })
      );
    }
  };
};

export const lockTests = ({
  id,
  lockedTests,
  isTestsLocked,
}: {
  id: string;
  lockedTests: TTest[];
  isTestsLocked: boolean;
}) => {
  return async (dispatch) => {
    dispatch(actionCreator(OrganizationActions.LOCK_TESTS["REQUESTED"]));

    try {
      const collection = await firebase.firestore().collection("organizations");
      const batch = firebase.firestore().batch();

      batch.update(collection.doc(id), {
        lockedTests: isTestsLocked
          ? lockedTests.map((test) => ({
              ...test,
              until: test.isLocked ? test.until : null,
            }))
          : null,
        isTestsLocked,
      });
      await batch.commit();

      dispatch(
        showAlert({
          title: "Success",
          message: "Tests were updated successfully.",
        })
      );
      dispatch(actionCreator(OrganizationActions.LOCK_TESTS["SUCCESS"]));
    } catch (error) {
      dispatch(actionCreator(OrganizationActions.LOCK_TESTS["FAILURE"]));
      dispatch(
        showAlert({
          title: "Error",
          message: "Failed to update tests.",
        })
      );
    }
  };
};

const TITLES = {
  STUDY: {
    title: "Study Aid Builder",
    copy: "The following practice tests are multiple choice questions designed emulate the MBE testing experience. Full versions of MBE practice tests are available on the paid version of this application. Please note that these tests are timed and will be scored automatically upon exiting the online exam environment.",
    note: "these Study Aid questions are different questions than the Practice Test questions.",
  },
  TEST: {
    TRIAL: {
      OPE: {
        title: "Practice Tests:",
        copy: "The following practice tests are multiple choice questions designed emulate the MBE testing experience. Full versions of MBE practice tests are available on the paid version of this application. Please note that these tests are timed and will be scored automatically upon exiting the online exam environment.",
        note: "these Practice Test questions are different questions than the Study Aid questions.",
      },
      FINAL: {
        title: "Final Tests:",
        copy: "The following final tests are multiple choice questions designed emulate the MBE testing experience. Full versions of MBE final tests are available on the paid version of this application. Please note that these tests are timed and will be scored automatically upon exiting the online exam environment.",
      },
    },
    PRO: {
      OPE: {
        title: "Practice Tests",
        copy: "The following practice tests consist of the National Conference of Bar Examiners (NCBE) Online Practice Exams (OPEs). Please note that these exams are timed and will be scored automatically upon exiting the online exam environment.",
        note: "these Practice Test questions are different questions than the Study Aid questions.",
      },
      FINAL: {
        title: "Final Tests",
        copy: "The following practice tests consist of the NCBE's latest release of multiple choice questions. Please note that these exams consist of two parts, are timed and will be scored automatically upon exiting the online exam environment.",
      },
    },
  },
};
export default TITLES;

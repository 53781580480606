import React from "react";
import { Route, Switch } from "react-router-dom";
import New from "./New";
import View from "./View";
import List from "./List";
import ImportStudents from "./ImportStudents";

const Students = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/view/:studentId`} component={View} />
      <Route path={`${match.url}/new`} component={New} />
      <Route path={`${match.url}/import`} component={ImportStudents} />

      <Route path={`${match.url}`} component={List} />
    </Switch>
  );
};

Students.propTypes = {};

export default Students;

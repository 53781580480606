import React from "react";
import styled from "styled-components/macro";
import { color, util } from "../../styles/style";

const SideBarControls = styled.div`
  padding: ${util.padding};
`;

const QuestionCountText = styled.span`
  display: block;
  color: ${color.primary};
  padding: 0.5rem;
  text-align: center;
  font-weight: 700;
  font-size: 1.2em;
`;

type TProps = {
  totalQuestions: number;
  currentQuestionNumber: number;
};

const QuestionCount: React.FC<TProps> = (props) => {
  const { currentQuestionNumber, totalQuestions } = props;

  return (
    <SideBarControls>
      <QuestionCountText>
        {currentQuestionNumber > 0 &&
          `Question ${currentQuestionNumber} of ${totalQuestions}`}
      </QuestionCountText>
    </SideBarControls>
  );
};

export default QuestionCount;

import React from "react";
import ReactExport from "react-data-export";

import { TOrganization } from "../../../../../types";

import { firstRowTitles, secondRowTitles, thirdRowTitles } from "./columns";

import {
  headerStyle,
  textStyle,
} from "../utils/exel-style";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

interface IExcelExport {
  students: any[];
  organizations: {
    allOrganizations: TOrganization[],
    chosenOrganization: string
  }
}

function ReportExport({ students, organizations: { allOrganizations, chosenOrganization } }: IExcelExport) {
  const titleDate = new Date().toLocaleDateString().replace(/\//g, "-");

  const studentsData = students.map((student, index) => student.map(value => {
    if (index % 2 === 0)
      return {
        value, style: {
          ...textStyle, fill: {
            patternType: "solid",
            fgColor: { rgb: "26DCDCDC" }
          },
        }
      }

    return { value, style: textStyle }
  }));

  const table = [
    {
      columns: [
        {
          title: "Student Test ",
          style: { ...headerStyle, alignment: { horizontal: "right" }, border: {} },
          width: { wch: 20 },
        },
        {
          title: " Results Report",
          style: { ...headerStyle, alignment: { horizontal: "left" }, border: {} },
          width: { wch: 20 },
        },
      ],
      data: [[{}]]
    },
    {
      columns: [
        {
          title: `Organization:`,
          style: { aligment: headerStyle.alignment, border: headerStyle.border, font: headerStyle.font },
          width: { wch: 20 },
        },
        {
          title: `${chosenOrganization === "all" ?
            "All" :
            allOrganizations.find((org) => org.id === chosenOrganization).name}`,
          style: { aligment: headerStyle.alignment, border: headerStyle.border, font: headerStyle.font },
          width: { wch: 20 },
        },
      ],
      data: [[{}]]
    },
    {
      ySteps: 5,
      xSteps: 7,
      columns: firstRowTitles,
      data: [[{}, {}]]
    },
    {
      ySteps: -1,
      xSteps: 4,
      columns: secondRowTitles,
      data: [secondRowTitles.map(() => ({}))]
    },
    {
      ySteps: -1,
      columns: thirdRowTitles,
      data: studentsData
    },
  ];

  return (
    <div>
      <ExcelFile
        filename={"Student Export Report " + titleDate}
        hideElement="true"
      >
        <ExcelSheet dataSet={table} name="Students report" />
      </ExcelFile>
    </div>
  );
}

export default React.memo(ReportExport);

import styled, { css } from "styled-components/macro";
import { color, fontType, fontWeight } from "../../styles/style";
import { mediaMinDevice } from "../../styles/responsive";

export const StatWrapper = styled.div`
  text-align: center;
`;

export const Stat = styled.div`
  color: ${color.dark};
  font-size: 1.5rem;
  font-weight: ${fontWeight.bold};

  ${(props) =>
    props.type === "correct" &&
    css`
      color: ${color.secondary};
      font-weight: ${fontWeight.medium};
    `};
  ${(props) =>
    props.type === "wrong" &&
    css`
      color: ${color.redMain};
      font-weight: ${fontWeight.medium};
    `};
  ${mediaMinDevice.mobileL} {
    font-size: 2rem;
  }
  ${mediaMinDevice.tablet} {
    font-size: 3rem;
  }
`;

export const Label = styled.div`
  color: ${color.blueLight};
  font-size: 11px;
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
  margin-bottom: 10px;
  ${(props) =>
    props.type === "correct" &&
    css`
      color: ${color.secondary};
      font-weight: ${fontWeight.medium};
    `};
  ${(props) =>
    props.type === "wrong" &&
    css`
      color: ${color.redMain};
      font-weight: ${fontWeight.medium};
    `};
  @media (min-width: 425px) {
    font-size: 13px;
  }
  @media (min-width: 768px) {
    font-size: ${fontType.copy};
  }
`;

export const StatProgressBar = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

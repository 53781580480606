import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import { color, fontWeight } from "../../styles/style";

export const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabsIndicator: {
    backgroundColor: "#1890ff",
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$tabSelected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing(3),
  },
  emptyState: {
    textAlign: "center",
    padding: "2rem",
    color: "#BFCCD9",
    fontWeight: 700,
  },
  tableCell: {
    color: color.dark,
    fontWeight: fontWeight.semiBold,
  },
  tableHeadCell: {
    color: color.blueDarkest,
    fontWeight: 700,
  },
  moreVert: {
    textAlign: "right",
    color: color.blueDarkest,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: color.primary,
    "&:hover": {
      backgroundColor: color.primaryDarker,
    },
  },

  buttonProgress: {
    color: color.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

import React, { Component } from "react";
import { chunk } from "lodash";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import {
  GuideContent,
  GuideFooter,
  GuideQuestion,
  GuideWrapper,
} from "../../containers/Guide";
import {
  ToolDrawer,
  ToolDrawerControl,
  ToolDrawerInfoText,
  ToolDrawerTextControl,
} from "../../components/ToolDrawer";
import {
  ToolBar,
  ToolBarBtn,
  ToolBarHandle,
  ToolBarTimer,
} from "../../components/ToolBar";
import Loading from "../../components/Loading";
import firebase from "../../store/api/firebaseAdmin";
import QuestionCount from "../../elements/QuestionCount";
import QuestionNotes from "../../components/QuestionNotes";
import PanelGuideTopics from "../../components/PanelGuideTopics";
import QuestionFooterNav from "../../components/QuestionFooterNav";
import QuestionReview from "../../screens/Question/QuestionReview";
import QuestionNavigator from "../../components/QuestionNavigator";
import * as routes from "../../_CONS/routes";
import { updateGuideQuestion } from "../../store/actions/guideActions";
import { Redirect } from "react-router";
import getSectionFromUrl from "../../utils/getSectionFromUrl";

class GuideReview extends Component {
  constructor(props) {
    super(props);

    this.ref = firebase
      .firestore()
      .collection(this.props.levelQbank)
      .doc(this.props.studentId)
      .collection(this.props.qColl);
    this.unsubscribe = null;
    this.state = {
      questionIndex: 0,
      questionNumber: null,
      questionId: null,
      savedAnswer: null,
      userNotes: "",
      totalQuestions: 0,
      currentQuestion: null,
      isReview: true,
      expanded: true,
      savingNotesStatus: false,
      isPaused: false,
      showNotes: true,
      showFeedback: true,
      showTimer: false,
      isToolDrawerOpen: false,
      questionsLeft: null,
      questionFocus: true,
      qlist: [],
      isReady: false,
    };
    this.continueToNext = this.continueToNext.bind(this);
    this.continueToPrev = this.continueToPrev.bind(this);
    this.gotoQuestion = this.gotoQuestion.bind(this);
    this.saveUserNotes = this.saveUserNotes.bind(this);
    this.changeSideBarNotesToggle = this.changeSideBarNotesToggle.bind(this);
    this.toggleToolDrawer = this.toggleToolDrawer.bind(this);
    this.changeUserNotes = this.changeUserNotes.bind(this);
    this.toggleKeyFocus = this.toggleKeyFocus.bind(this);
    this.toggleKeyBlur = this.toggleKeyBlur.bind(this);
    this.child = React.createRef();
    this.focusRef = React.createRef();
    this.guideRef = React.createRef();
  }

  onCollectionUpdate = (querySnapshot) => {
    const qlist = [];
    querySnapshot.forEach((doc) => {
      qlist.push({
        ...doc.data(),
      });
    });

    const { questionIndex } = this.state;
    const currentQuestion = qlist[questionIndex];
    const totalQuestions = qlist.length;
    this.setState({
      qlist,
      currentQuestion,
      totalQuestions,
      userNotes: currentQuestion.userNotes,
    });
  };

  componentDidMount() {
    const { qQuery } = this.props;

    if (!qQuery) return;

    if (qQuery.length === 1) {
      this.unsubscribe = this.ref
        .where(qQuery[0][0], "==", qQuery[0][2])
        .orderBy("qnum")
        .onSnapshot(this.onCollectionUpdate);
    }

    if (qQuery.length === 2) {
      this.unsubscribe = this.ref
        .where(qQuery[0][0], "==", qQuery[0][2])
        .where(qQuery[1][0], "==", qQuery[1][2])
        .orderBy("qnum")
        .onSnapshot(this.onCollectionUpdate);
    }

    if (qQuery.length === 3) {
      this.unsubscribe = this.ref
        .where(qQuery[0][0], "==", qQuery[0][2])
        .where(qQuery[1][0], "==", qQuery[1][2])
        .where(qQuery[2][0], "==", qQuery[2][2])
        .orderBy("qnum")
        .onSnapshot(this.onCollectionUpdate);
    }

    this.guideRef.current.focus();
  }

  gotoQuestion(qid) {
    const { qlist } = this.state;
    const index = qlist.findIndex((question) => {
      if (question.qid === qid) {
        return true;
      }
      return false;
    });

    const qindex = index < 0 ? 0 : index;

    this.loadGuideQuestion(qindex);
  }

  loadGuideQuestion(qindex) {
    const { isDesktop } = this.props;
    const { isToolDrawerOpen, qlist } = this.state;
    const tdo = isDesktop ? isToolDrawerOpen : false;
    const current = qlist[qindex];

    this.setState({
      questionIndex: qindex,
      questionNumber: qindex + 1,
      currentQuestion: current,
      userNotes: current.userNotes,
      isToolDrawerOpen: tdo,
    });

    this.focusRef.current.focus();
    window.scrollTo(0, 0);
  }

  continueToNext() {
    const { questionIndex, totalQuestions } = this.state;
    const finalIndex = totalQuestions - 1;
    const nextQuestionIndex = questionIndex + 1;
    if (nextQuestionIndex > finalIndex) {
      this.loadGuideQuestion(0);
    } else {
      this.loadGuideQuestion(nextQuestionIndex);
    }
  }

  continueToPrev() {
    const { questionIndex } = this.state;
    const nextQuestionIndex = questionIndex - 1;
    this.loadGuideQuestion(nextQuestionIndex);
  }

  toggleToolDrawer(e) {
    e.preventDefault();
    this.setState((state) => ({
      isToolDrawerOpen: !state.isToolDrawerOpen,
    }));
  }

  changeUserNotes(event) {
    this.setState({ userNotes: event.target.value });
  }

  changeSideBarNotesToggle() {
    this.setState((state) => ({
      showNotes: !state.showNotes,
    }));
  }

  saveUserNotes(note) {
    this.setState({ savingNotesStatus: true });
    this.updateUserNotes(note);
  }

  updateUserNotes(note) {
    const { currentQuestion } = this.state;
    const { qColl } = this.props;
    const updateObject = {
      userNotes: note,
    };
    const questionId = currentQuestion.qid;

    this.props.updateGuideQuestion(questionId, qColl, updateObject).then(() => {
      this.setState({ userNotes: note, savingNotesStatus: false });
    });
  }

  toggleKeyFocus() {
    this.setState({ questionFocus: false });
  }

  toggleKeyBlur() {
    this.setState({ questionFocus: true });
    if (this.focusRef && this.state.phase === "live") {
      this.focusRef.current.focus();
    }
  }

  captureKeyDown(e) {
    const { questionFocus } = this.state;
    if (!questionFocus) {
      return;
    }
    const keypressed = e.key;
    const keyval = keypressed.toUpperCase();
    switch (keyval) {
      case "ARROWRIGHT":
        this.continueToNext();
        break;
      case "ARROWLEFT":
        this.continueToPrev();
        break;
      default:
        break;
    }
  }

  render() {
    const { isDesktop, guideId, match } = this.props;
    const {
      questionNumber,
      questionIndex,
      userNotes,
      showNotes,
      showFeedback,
      totalQuestions,
      savingNotesStatus,
      isToolDrawerOpen,
      qlist,
      currentQuestion,
    } = this.state;

    if (!guideId) {
      return (
        <Redirect to={`${routes.STUDENT}/${getSectionFromUrl(match.path)}`} />
      );
    }

    return (
      <GuideWrapper tabIndex={-1} ref={this.guideRef}>
        {currentQuestion ? (
          <GuideContent
            ref={this.focusRef}
            tabIndex={-1}
            onKeyDown={(event) => this.captureKeyDown(event)}
          >
            <>
              {isDesktop ? (
                <ToolBarHandle
                  isOpen={isToolDrawerOpen}
                  onClick={(e) => this.toggleToolDrawer(e)}
                />
              ) : (
                <ToolBar>
                  <ToolBarTimer />
                  <ToolBarBtn
                    variant="outlined"
                    disableRipple={true}
                    isActive={isToolDrawerOpen}
                    onClick={(e) => this.toggleToolDrawer(e)}
                  />
                </ToolBar>
              )}

              <ToolDrawer
                isDesktop={isDesktop}
                isReview={true}
                anchor={isDesktop ? "right" : "top"}
                variant="persistent"
                open={isToolDrawerOpen}
                onClose={this.toggleToolDrawer}
              >
                <ToolDrawerControl>
                  <QuestionCount
                    totalQuestions={totalQuestions}
                    currentQuestionNumber={questionIndex + 1}
                  />
                </ToolDrawerControl>
                <Divider />
                <ToolDrawerTextControl>
                  <ToolDrawerInfoText>
                    <strong>ID:</strong> {currentQuestion.qid}
                  </ToolDrawerInfoText>
                </ToolDrawerTextControl>
                <ToolDrawerControl>
                  <PanelGuideTopics qObj={currentQuestion} />
                </ToolDrawerControl>
                <Divider />
                <QuestionNavigator
                  totalQuestions={totalQuestions}
                  showFeedback={showFeedback}
                  pageSize={25}
                  pages={chunk(qlist, 25)}
                  currentQuestionId={currentQuestion.qid}
                  questionIndex={questionIndex}
                  navAction={this.gotoQuestion}
                />
                <QuestionNotes
                  key={currentQuestion.qid}
                  notes={userNotes}
                  loading={savingNotesStatus}
                  notesExpanded={showNotes}
                  setToggleExpand={this.changeSideBarNotesToggle}
                  handleNoteChange={this.changeUserNotes}
                  handleNoteSave={this.saveUserNotes}
                  toggleFocus={this.toggleKeyFocus}
                  toggleBlur={this.toggleKeyBlur}
                />
              </ToolDrawer>

              <GuideQuestion
                isReview={true}
                isDesktop={isDesktop}
                isToolDrawerOpen={isToolDrawerOpen}
              >
                <QuestionReview content={currentQuestion} />
              </GuideQuestion>
              <GuideFooter isDesktop={isDesktop && isToolDrawerOpen}>
                <QuestionFooterNav
                  isReview={true}
                  totalQuestions={totalQuestions}
                  currentQuestionNumber={questionNumber}
                  nextQuestion={this.continueToNext}
                  prevQuestion={this.continueToPrev}
                />
              </GuideFooter>
            </>
          </GuideContent>
        ) : (
          <Loading />
        )}
      </GuideWrapper>
    );
  }
}

GuideReview.propTypes = {};

const mapStateToProps = (state) => {
  const isDesktop = state.appReducer.isDesktop;
  const gColl = state.guideStatus.guideType;
  const qColl = gColl === "testguides" ? "tgquestions" : "sgquestions";
  const levelQbank = state.firebase.profile.studentBank;
  const studentId = state.firebase.auth.uid;

  return {
    guideId: state.guideStatus.guideId,
    qQuery: state.guideStatus.queries,
    levelQbank,
    gColl: gColl,
    qColl: qColl,
    studentId,
    isDesktop,
  };
};

const mapDispatchToProps = { updateGuideQuestion };

export default connect(mapStateToProps, mapDispatchToProps)(GuideReview);

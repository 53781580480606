import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Input from "@material-ui/core/Input";
import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import SignUpCta from "../../../elements/SignUpCta";
import AuthErrorTxt from "../../../elements/AuthErrorTxt";
import { forgotPassword, signIn } from "../../../store/actions/authActions";
import LoadingModal from "../../../components/LoadingModal";

const ForgotLink = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin: 1.5rem 0;
  text-align: center;
  & a {
    color: #8595a6;
  }
`;

class SignIn extends Component {
  state = {
    emailOnly: false,
    email: "",
    password: "",
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state);
  };

  render() {
    const { error, auth, role, status } = this.props;

    if (auth.uid && role !== undefined)
      return (
        <Redirect
          to={{
            pathname: `/${role}`,
            state: { role: role },
          }}
        />
      );

    return (
      <form onSubmit={this.handleSubmit}>
        <LoadingModal open={status === "loading"} />

        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Email Address</InputLabel>
          <Input
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={this.handleChange}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.handleChange}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <Button
            type="submit"
            variant="contained"
            fullWidth={true}
            color="primary"
            data-testid="signIn"
          >
            Sign in
          </Button>
          <AuthErrorTxt data-testid="authErrorText">
            {error ? <p>{error}</p> : null}
          </AuthErrorTxt>

          <ForgotLink>
            <Link
              to={{
                pathname: "/forgot-password",

                state: { isSignUp: false },
              }}
            >
              Forgot Password?
            </Link>
          </ForgotLink>
          <SignUpCta>
            Don&#8216;t have an account?{" "}
            <Link
              to={{
                pathname: "/sign-up",

                state: { isSignUp: true },
              }}
            >
              Click here to Sign up.
            </Link>
          </SignUpCta>
        </FormControl>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.auth.error,
  status: state.auth.status,
  auth: state.firebase.auth,
  role: state.firebase.profile.role,
});

const mapDispatchToProps = {
  signIn,
  forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

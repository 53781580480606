import React from "react";
import styled from "styled-components/macro";
import { color } from "../../styles/style";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { mediaMinDevice } from "../../styles/responsive";
import BgImg from "../../assets/images/landing_image.png";
import LogoTall from "../../assets/logos/JD-Advising_logo-Tall.svg";
import { Route, Switch, withRouter } from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import Hidden from "@material-ui/core/Hidden";
import ResetPassword from "./ResetPassword";

const FormWrapper = styled.div`
  background: ${color.grey};
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-width: 500px;
  width: 100%;
  margin: auto;
  & img {
    margin-bottom: 2rem;
    ${mediaMinDevice.mobileL} {
      margin-bottom: 5rem;
    }
  }
  ${mediaMinDevice.mobileL} {
    padding: 4rem 5rem;
    height: 100vh;
  }
`;
const SignInFormBg = styled.div`
  background-image: url(${BgImg});
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  & p {
    margin-top: 1rem;
    color: ${color.primaryLight};
  }
  & h2 {
    color: white;
  }
`;

const LandingPathway = ({ location }) => (
  <Grid container spacing={0}>
    <Grid item xs={12} sm={12} md={5}>
      <FormWrapper>
        <img alt="logo" src={LogoTall} />
        <Switch>
          <Route path="/sign-up/:school?" component={SignUp} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route component={SignIn} />
        </Switch>
      </FormWrapper>
    </Grid>

    <Grid item xs={12} sm={12} md={7}>
      <Hidden smDown implementation="css">
        <SignInFormBg>
          <Typography component="h2" variant="h3" align="center">
            WELCOME TO <br />
            JD ADVISING`S MBE QBANK!
          </Typography>

          <Typography component="p" variant="h6">
            {location.pathname === "/sign-up"
              ? "Sign up to get started"
              : "Sign in to get started"}
          </Typography>
        </SignInFormBg>
      </Hidden>
    </Grid>
  </Grid>
);

export default withRouter(LandingPathway);

import React from "react";
// import PropTypes from 'prop-types';
import MuiCard from "@material-ui/core/Card";

const Card = ({ children, ...others }) => (
  <MuiCard {...others}>{children}</MuiCard>
);
Card.propTypes = {};

export default Card;

import styled from "styled-components/macro";
import { color, fontWeight } from "../../../../../styles/style";

export const styles = {
  subjectRow: {
    marginBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    flexWrap: "nowrap",
  },
  topicRow: {
    flexWrap: "nowrap",
  },
};

export const SubjectRow = styled.div`
  margin-top: 15px;
  border-width: 1px;
  border-style: none;
  border-bottom-style: solid;
  border-color: ${color.blueLightest};
  min-width: 708px;
`;

export const TopicRow = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${color.grey};
  flex-wrap: nowrap;
`;

export const SubjectTitles = styled.p`
  margin: 0;
  color: ${color.dark};
  font-size: 0.8rem;
  font-weight: ${fontWeight.bold};
  text-align: ${(props) => (props.textalign ? props.textalign : "left")};
  text-transform: uppercase;
`;

export const TopicTitles = styled.p`
  margin: 0;
  color: ${color.darkest};
  font-size: 0.8rem;
  text-align: ${(props) => (props.textalign ? props.textalign : "left")};
`;

export const SubjectBtn = styled.div`
  text-align: center;
`;

export const TopicBtn = styled.a`
  margin: 0;
  color: ${color.primaryDark};
  font-size: 0.8rem;
  font-weight: ${fontWeight.semiBold};
  cursor: pointer;
  &:hover {
    color: ${color.primaryDarker};
  }
`;

import { breakpoints } from "../../styles/responsive";
import { AppActions } from "../actions/appActions";

type TAppState = {
  isDesktop: boolean;
};

const initState: TAppState = {
  isDesktop: false,
};

const appReducer = (state: TAppState = initState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case AppActions.SCREEN_RESIZE:
      return {
        ...state,
        isDesktop: payload.screenWidth >= breakpoints.lg,
      };

    default:
      return state;
  }
};

export default appReducer;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Grid from "@material-ui/core/Grid";
import { color, fontWeight } from "../../styles/style";
import ResultBox from "../ResultBox";

const StatWrapper = styled.div`
  margin: 1rem 0;
  border-bottom: 1px solid ${color.blueLightest};
  &:last-child {
    border-bottom: 0 none;
  }
`;

const ResultBoxWrapper = styled.div`
  padding: 1rem;
`;

const StatLabel = styled.div`
  color: ${color.dark};
  font-size: 1rem;
  font-weight: ${fontWeight.semiBold};
  padding-left: 2rem;
`;

const StatsRowFlat = ({ stats, label }) => {
  const overallRaw = stats.correct / stats.total;
  const overall = isNaN(overallRaw) ? 0 : (overallRaw * 100).toFixed(1);

  return (
    <StatWrapper>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <StatLabel>{label}</StatLabel>
        </Grid>
        <Grid item xs>
          <ResultBoxWrapper>
            <ResultBox stat={overall} label="Overall" percent />
          </ResultBoxWrapper>
        </Grid>
        <Grid item xs>
          <ResultBoxWrapper>
            <ResultBox stat={stats.total} label="Questions" />
          </ResultBoxWrapper>
        </Grid>
        <Grid item xs>
          <ResultBoxWrapper>
            <ResultBox
              stat={stats.correct && stats.correct > 0 ? stats.correct : 0}
              label="Correct"
              type="correct"
            />
          </ResultBoxWrapper>
        </Grid>
        <Grid item xs>
          <ResultBoxWrapper>
            <ResultBox stat={stats.wrong} label="Incorrect" type="wrong" />
          </ResultBoxWrapper>
        </Grid>
      </Grid>
    </StatWrapper>
  );
};

StatsRowFlat.propTypes = {
  stats: PropTypes.shape({
    overall: PropTypes.string,
    total: PropTypes.number,
    correct: PropTypes.number,
    wrong: PropTypes.number,
  }),
};

export default StatsRowFlat;

import styled from "styled-components/macro";
import { color, fontWeight } from "../../../styles/style";

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 10rem;
  z-index: 1;
`;

export const MainContent = styled.main`
  flex: 1;
  width: 80%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
`;

export const ScreenHeader = styled.div`
  height: 90px;
`;

export const HeaderInfo = styled.div`
  font-size: 2.2rem;
  margin-bottom: 0;
  letter-spacing: 0.5px;
  color: ${color.blueDarkest};
  text-align: right;
  font-weight: ${fontWeight.semiBold};
`;

export const HeaderTitle = styled.div`
  font-size: 2.2rem;
  letter-spacing: 0.5px;
  color: ${color.blueDarkest};
  text-align: left;
  font-weight: ${fontWeight.semiBold};
`;

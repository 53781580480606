import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import {
  ButtonLabel,
  Nav,
  NavContainer,
  NavWrapper,
  QuestionButton,
} from "./StyledQuestionFooterNav";

type TProps = RouteComponentProps & {
  totalQuestions: number;
  currentQuestionNumber: number;
  isReview: boolean;
  isLoading?: boolean;
  completeTest: () => void;
  nextQuestion: () => void;
  prevQuestion: () => void;
};

class QuestionFooterNav extends Component<TProps> {
  nextQuestion = () => {
    const { totalQuestions, currentQuestionNumber, isReview } = this.props;

    if (currentQuestionNumber === totalQuestions && !isReview) {
      this.props.completeTest();
    } else {
      this.props.nextQuestion();
    }
  };

  handleDone = () => {
    this.props.history.goBack();
  };

  render() {
    const { totalQuestions, currentQuestionNumber, isReview, isLoading } = this.props;
    const isLast = currentQuestionNumber === totalQuestions;

    return (
      <NavWrapper>
        <NavContainer>
          <Nav>
            <QuestionButton
              disabled={currentQuestionNumber === 1}
              variant="contained"
              color="primary"
              onClick={this.props.prevQuestion}
            >
              <ArrowBack /> <ButtonLabel>PREVIOUS QUESTION</ButtonLabel>
            </QuestionButton>

            {isReview && (
              <Button variant="outlined" onClick={this.handleDone}>
                Done
              </Button>
            )}

            <QuestionButton
              disabled={(isLast && isReview) || isLoading}
              variant="contained"
              color="primary"
              onClick={this.nextQuestion}
            >
              <ButtonLabel>
                {isLast && !isReview ? "SUBMIT TEST" : "NEXT QUESTION"}
              </ButtonLabel>{" "}
              <ArrowForward />
            </QuestionButton>
          </Nav>
        </NavContainer>
      </NavWrapper>
    );
  }
}

export default withRouter(QuestionFooterNav);

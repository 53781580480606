import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Loading from "../../components/Loading";

const DialogContentWorking = withStyles({
  root: {
    margin: 0,
    height: "100%",

    padding: "6rem",
  },
})(MuiDialogContent);

class LoadingModal extends React.Component {
  render() {
    const { open } = this.props;

    return (
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
      >
        <DialogContentWorking>
          <Loading />
        </DialogContentWorking>
      </Dialog>
    );
  }
}

LoadingModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default LoadingModal;

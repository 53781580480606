import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, isEmpty, isLoaded } from "react-redux-firebase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loading from "../../components/Loading";
import EnhancedTable from "../../components/EnhancedTable";
import TextEmptyState from "../../elements/TextEmptyState";

class QuestionsTable extends React.Component {
  state = {
    tabIndex: this.props.isDraft ? 0 : 1,
  };

  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  };

  render() {
    const { tabIndex } = this.state;
    const { questions, pubQuestions, section, level, qbank, superAdmin } =
      this.props;

    return (
      <>
        <Tabs value={tabIndex} onChange={this.handleChange}>
          <Tab disableRipple label="DRAFTS" />
          <Tab disableRipple label="PUBLISHED" />
        </Tabs>

        {tabIndex === 0 &&
          (!isLoaded(questions) ? (
            <Loading />
          ) : isEmpty(questions) ? (
            <TextEmptyState>No Questions</TextEmptyState>
          ) : (
            <EnhancedTable
              data={questions}
              section={section}
              level={level}
              qbank={qbank}
              isDraft={true}
              superAdmin={superAdmin}
            />
          ))}
        {tabIndex === 1 &&
          (!isLoaded(pubQuestions) ? (
            <Loading />
          ) : isEmpty(pubQuestions) ? (
            <TextEmptyState>No Published Questions</TextEmptyState>
          ) : (
            <EnhancedTable
              data={pubQuestions}
              section={section}
              level={level}
              qbank={qbank}
              isDraft={false}
              superAdmin={superAdmin}
            />
          ))}
      </>
    );
  }
}

export default compose(
  firebaseConnect((props) => [
    {
      path: get(props, "qpath"),
      storeAs: "draftQs",
      queryParams: ["orderByChild=published", "equalTo=false"],
    },
    {
      path: get(props, "qpath"),
      storeAs: "pubQs",
      queryParams: ["orderByChild=published", "equalTo=true"],
    },
  ]),
  connect(({ firebase }) => ({
    questions: get(firebase, "ordered.draftQs"),
    pubQuestions: get(firebase, "ordered.pubQs"),
  }))
)(QuestionsTable);

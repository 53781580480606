import React, { MouseEvent, useEffect, useState } from "react";
import { AccordionDetails, Grid, IconButton, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Chevron from "@material-ui/icons/KeyboardArrowDown";

import { RootState } from "../../../../store/createReduxStore";
import subjectTopics from "../../../../_CONS/subjectTopics";
import { Accordion, AccordionSummary, List, NavButton } from "./StyledStudyAidInfo";
import { getTopicsFromClassification } from "../../../../utils/getTopicsFromClassification";
import RotateLeft from "@material-ui/icons/RotateLeft";
import Button from "../../../../elements/Button";
import Dialog from "../../../../components/Dialog";
import { deleteStudyGuide, deleteTopicQuestions } from "../../../../store/actions/guideActions";
import { HeaderTitle } from "../../../../containers/Main/MainScreen/StyledMainScreen";
import { useHistory } from "react-router";
import { MainScreen } from "../../../../containers/Main";

type TSubjectCode = keyof typeof subjectTopics;

type TTopic = {
  label: string;
  total: number;
  used: number;
};

type TSubjectTotals = {
  [key in TSubjectCode]: {
    label: string;
    total: number;
    used: number;
    topics: {
      [key: string]: TTopic;
    };
  };
};

type TGuideStat = {
  id: string;
  label: string;
  subjects: {
    [key in TSubjectCode]: {
      label: string;
      number: number;
      topics: {
        [key: string]: {
          label: string;
          number: number;
        };
      };
    };
  };
};

type TAccordion = {
  [key in TSubjectCode]: boolean;
};

const StudyAidInfo: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const rawStudyGuides = useSelector((state: RootState) => state.firestore.ordered.studyGuides);
  const status = useSelector((state: RootState) => state.guideStatus.status);
  const studentId = useSelector((state: RootState) => state.firebase.auth.uid);
  const studyBank = useSelector((state: RootState) => state.firebase.profile.studentBank);
  const [studyGuides, setStudyGuides] = useState([]);
  const [openedGuides, setOpenedGuides] = useState([]);
  const [dialog, setDialog] = useState(null);

  useEffect(() => {
    if (rawStudyGuides) {
      const guides = rawStudyGuides
        .map(guide => {
          if (!guide.questions) return undefined;
          const subjects = guide.questions.reduce((result: any, question: any) => {
            const topics = getTopicsFromClassification(question.classification);
            if (!subjectTopics[question.subjectCode]) return result;
            if (!result[question.subjectCode]) {
              result[question.subjectCode] = {
                total: 1,
                label: subjectTopics[question.subjectCode].label,
                code: question.subjectCode,
                topics: {},
              };
            } else {
              result[question.subjectCode].total++;
            }

            topics.forEach(topic => {
              if (!result[question.subjectCode]["topics"][topic]) {
                result[question.subjectCode]["topics"][topic] = {
                  code: topic,
                  label: subjectTopics[question.subjectCode]["topics"][topic]["label"],
                  total: 1,
                };
              } else {
                result[question.subjectCode]["topics"][topic].total++;
              }
            });

            return result;
          }, {});

          return {
            id: guide.id,
            name: guide.name,
            total: guide.questions.length,
            subjects: subjects,
          };
        })
        .filter(item => !!item);

      setStudyGuides(guides);

      if (guides.length) {
        setOpenedGuides([guides[0].id]);
      }
    }
  }, [rawStudyGuides]);

  const onToggleGuide = (id: string) => () => {
    if (openedGuides.includes(id)) {
      setOpenedGuides(openedGuides.filter(item => item !== id));
    } else {
      setOpenedGuides([...openedGuides, id]);
    }
  };

  const handleDialogCancel = (type: string) => {
    setDialog(null);
  };

  const handleDialogConfirm = (type: string, payload?: any) => {
    if (type === "topic") {
      dispatch(deleteTopicQuestions(studentId, payload.guideId, studyBank, payload.subjectCode, payload.topicCode));
    } else {
      dispatch(deleteStudyGuide(studentId, payload, studyBank));
    }
    setDialog(null);
  };

  const resetStudyGuideRequest = (id: string) => (e: MouseEvent) => {
    e.stopPropagation();

    const guide = studyGuides.find(item => item.id === id);

    if (guide) {
      setDialog({
        open: true,
        payload: id,
        typeId: "studyGuide",
        title: "Delete Entire Study Aid",
        content: `Are you sure you want to Reset ${guide.name}? Doing so will return all associated questions back to the Question Bank – making them available for new Study Aids.`,
      });
    }
  };

  const resetTopicRequest = (guideId: string, subjectCode: string, topicCode: string) => () => {
    const guide = studyGuides.find(({ id }) => id === guideId);
    const topicName = subjectTopics[subjectCode]["topics"][topicCode]["label"];

    if (guide && topicName) {
      setDialog({
        payload: {
          guideId,
          subjectCode,
          topicCode,
        },
        open: true,
        typeId: "topic",
        title: `Are you sure you want to Reset the Topic: ${topicName} in ${guide.name}?`,
        content: `Doing so will return all associated Topic questions back to the Question Bank – making them available for new Study Aids.`,
      });
    }
  };

  const openReview = () => {
    history.push("/student/review");
  };

  return (
    <MainScreen withoutHeader>
      <Dialog
        {...dialog}
        type={"confirm"}
        open={(dialog && dialog.open) || status === "loading"}
        isLoading={status === "loading"}
        buttonLabels={["Cancel", "Reset"]}
        onCancel={handleDialogCancel}
        onConfirm={handleDialogConfirm}
      />

      <Grid container item>
        <Grid item>
          <NavButton onClick={openReview}>Review Tests</NavButton>
        </Grid>

        <Grid item className={"ml-3"}>
          <NavButton disabled>Study Aid Info</NavButton>
        </Grid>
      </Grid>

      <Grid container item justify="space-between" alignItems="flex-end">
        <Grid item>
          <HeaderTitle>Study Aid Info</HeaderTitle>
        </Grid>
      </Grid>

      <Grid container item spacing={3} direction={"column"}>
        <Grid item>
          <Typography variant={"body1"}>
            If you reset an entire Study Aid or specific Study Aid Topics, then any questions associated will again be
            available when creating new Study Aids.
          </Typography>
        </Grid>

        {studyGuides.map(guide => (
          <Grid item key={guide.id}>
            <Accordion expanded={openedGuides.includes(guide.id)} onChange={onToggleGuide(guide.id)}>
              <AccordionSummary expandIcon={<Chevron />}>
                <Grid container alignItems={"center"}>
                  <Grid item>
                    <Typography className="title" variant="body1">
                      {guide.name}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Button className={"ml-3"} color={"danger"} onClick={resetStudyGuideRequest(guide.id)}>
                      Reset Study Aid
                    </Button>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <List>
                  <Grid container>
                    <Grid item>
                      {Object.values(guide.subjects)
                        .filter(item => !!item)
                        .map((subject: any) => (
                          <React.Fragment key={subject.code}>
                            <Typography variant={"subtitle1"}>
                              {subject.label} ({subject.total})
                            </Typography>

                            <List>
                              {Object.values(subject.topics).map((topic: any) => (
                                <Grid key={topic.code} container alignItems={"center"} className={"mb-3"}>
                                  <Grid item>
                                    <Typography variant={"body1"} gutterBottom>
                                      {topic.label} ({topic.total})
                                    </Typography>
                                  </Grid>

                                  <Grid item>
                                    <IconButton
                                      size="small"
                                      title={"Reset score"}
                                      aria-label={"Reset topic questions"}
                                      onClick={resetTopicRequest(guide.id, subject.code, topic.code)}
                                    >
                                      <RotateLeft color={"error"} />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ))}
                            </List>
                          </React.Fragment>
                        ))}
                    </Grid>
                  </Grid>
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </MainScreen>
  );
};

export default StudyAidInfo;

import React, { useState } from "react";
import { FormControl, Grid } from "@material-ui/core";
import { AnswerInput, QuestionCard, TitleInput } from "./StyledFaqForm";
import { CardContent } from "../../containers/Card";
import Button from "../../elements/Button";

type TQuestion = {
  title: string;
  body: string;
};

type TFaqForm = {
  question?: TQuestion;
  onChange?: (value: TQuestion) => void;
  onSave: (value: TQuestion) => void;
  onCancel?: () => void;
};

const FaqForm: React.FC<TFaqForm> = ({
  question,
  onChange,
  onSave,
  onCancel,
  ...props
}) => {
  const [title, setTitle] = useState(question ? question.title : "");
  const [body, setBody] = useState(question ? question.body : "");

  const handleSave = () => {
    if (typeof onSave === "function") {
      onSave({
        title,
        body,
      });
    }
  };

  const handleChange = (field: string) => (e: any) => {
    const value = e.target.value;

    if (field === "title") {
      setTitle(value);
    } else if (field === "body") {
      setBody(value);
    }

    if (typeof onChange === "function") {
      onChange({
        title,
        body,
        [field]: value,
      });
    }
  };

  return (
    <QuestionCard {...props}>
      <CardContent>
        <FormControl fullWidth>
          <TitleInput
            placeholder={"Enter the question…"}
            name="title"
            value={title}
            onChange={handleChange("title")}
          />
        </FormControl>

        <FormControl fullWidth className={"mb-3"}>
          <AnswerInput
            placeholder={"Enter the answer…"}
            name="body"
            multiline
            rows={4}
            value={body}
            onChange={handleChange("body")}
          />
        </FormControl>

        <Grid container justify="flex-end">
          {onCancel && (
            <Grid item className={"mr-3"}>
              <Button color={"danger"} onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
          )}

          <Grid item>
            <Button
              disabled={!title || !body}
              color={"success"}
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </QuestionCard>
  );
};

export default FaqForm;

import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import QuestionIndicator from "./QuestionIndicator";
import QiPager from "./QiPager";
import {
  HLine,
  QI,
  QiBox,
  QiBoxWrapper,
  QuestionSetBox,
} from "./StyledQuestionNavigator";

class QuestionNavigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: this.paginate(this.props.questionIndex),
    };
    this.changeTheQuestion = this.changeTheQuestion.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentQuestionId !== prevProps.currentQuestionId) {
      const pIndex = this.paginate(this.props.questionIndex);
      this.setState({
        pageIndex: pIndex,
      });
    }
  }

  paginate(qindex) {
    const { totalQuestions, pageSize } = this.props;
    const totalPages = Math.ceil(totalQuestions / pageSize);
    const qnum = qindex + 1;
    const cPage = Math.ceil(qnum / pageSize);

    let currentPage;
    if (cPage < 1) {
      currentPage = 1;
    } else if (cPage > totalPages) {
      currentPage = totalPages;
    } else {
      currentPage = cPage;
    }
    const pageIndex = currentPage - 1;
    return pageIndex;
  }

  changeTheQuestion = (newQ, e) => {
    e.preventDefault();
    this.props.navAction(newQ);
  };

  pagerNav = (direction) => {
    const { pageIndex } = this.state;

    let newIndex;
    if (direction === "next") {
      newIndex = pageIndex + 1;
    } else {
      newIndex = pageIndex - 1;
    }
    this.setState({
      pageIndex: newIndex,
    });
  };

  render() {
    const {
      currentQuestionId,
      pages,
      showFeedback,
      isExpanded,
      setToggleExpand,
    } = this.props;
    const { pageIndex } = this.state;
    return (
      <QuestionSetBox>
        <Accordion expanded={isExpanded} onChange={setToggleExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Question Navigation
          </AccordionSummary>

          <AccordionDetails>
            <QiBoxWrapper>
              {pages && (
                <QiBox>
                  {pages[pageIndex].map((q) => {
                    return (
                      <QI
                        key={q.qnum}
                        onClick={(e) => this.changeTheQuestion(q.qid, e)}
                      >
                        <QuestionIndicator
                          question={q}
                          label={q.qnum}
                          isActive={q.qid === currentQuestionId}
                          showFeedback={showFeedback}
                        />
                      </QI>
                    );
                  })}
                </QiBox>
              )}

              <QiPager
                pages={pages}
                pageIndex={pageIndex}
                navAction={this.pagerNav}
              />
            </QiBoxWrapper>
          </AccordionDetails>
        </Accordion>
        <HLine />
      </QuestionSetBox>
    );
  }
}

QuestionNavigator.propTypes = {};

export default QuestionNavigator;

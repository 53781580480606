import firebase from "../store/api/firebaseAdmin";
import { TFirebaseQuestion } from "../../types";

export type FirebaseQuestion = {
  A: string;
  B: string;
  C: string;
  D: string;
  classification: Array<string>;
  correctAnswer: string;
  correctFeedback: string;
  currentSection: string;
  feedback1: string;
  feedback2: string;
  feedback3: string;
  isSaved: boolean;
  level: string;
  published: boolean;
  publishedDate: Date;
  qbank: string;
  question: string;
  section: string;
  showFeedback2: boolean;
  showFeedback3: boolean;
  subject: string;
  subjectCode: string;
  testCode: string;
  type: string;
  uid: string;
};

export type ReportThemes = {
  "T: Criminal Law and Procedure": number;
  "ST: Homicide": number;
  "ST: Other Crimes": number;
  "ST: Inchoate Crimes; Parties": number;
  "ST: General Principles": number;
  "ST: Constitutional Protection of Accused Persons": number;
  "T: Civil Procedure": number;
  "ST: Jurisdiction and Venue": number;
  "ST: Law Applied by Federal Courts": number;
  "ST: Pretrial Procedures": number;
  "ST: Jury Trials": number;
  "ST: Motions": number;
  "ST: Verdicts and Judgements": number;
  "ST: Appealability and Review": number;
  "T: Evidence": number;
  "ST: Presentation of Evidence": number;
  "ST: Relevancy and Reasons for Excluding Relevant Evidence": number;
  "ST: Priviledges and Other Policy Exclusions": number;
  "ST: Writing, Recordings, and Photographs": number;
  "ST: Hearsay and Circumstances of its Admissibility": number;
  "T: Torts": number;
  "ST: Intentional Tors": number;
  "ST: Negligence": number;
  "ST: Strict Liability and Products Liability": number;
  "ST: Other Torts": number;
  "T: Constitutional Law": number;
  "ST: The Nature of Judicial Review": number;
  "ST: The Separation of Powers": number;
  "ST: The Relation of Nation and States in a Federal System": number;
  "ST: Individual Rights": number;
  "T: Contracts": number;
  "ST: Formation of Contracts": number;
  "ST: Defenses to Enforceability": number;
  "ST: Contract Content and Meaning": number;
  "ST: Performance, Breach and Discharge": number;
  "ST: Remedies": number;
  "ST: Third-Party Rights": number;
  "T: Real Property": number;
  "ST: Ownership of Property": number;
  "ST: Rights in Real Property": number;
  "ST: Real Estate Contracts": number;
  "ST: Mortgages/Security Devices": number;
  "ST: Titles": number;
};

// Classification === "III-B-2" "IV-A-4"
const getClassificationTheme = (item: Array<string>) => {
  return item[0].split("-")[0];
};

export const getTotalThemes = async (studentLevel: string) => {
  const isPaid = studentLevel === "students-paid" ? "paid" : "free";
  const totalThemes: ReportThemes = {
    "T: Criminal Law and Procedure": 0,
    "ST: Homicide": 0,
    "ST: Other Crimes": 0,
    "ST: Inchoate Crimes; Parties": 0,
    "ST: General Principles": 0,
    "ST: Constitutional Protection of Accused Persons": 0,
    "T: Civil Procedure": 0,
    "ST: Jurisdiction and Venue": 0,
    "ST: Law Applied by Federal Courts": 0,
    "ST: Pretrial Procedures": 0,
    "ST: Jury Trials": 0,
    "ST: Motions": 0,
    "ST: Verdicts and Judgements": 0,
    "ST: Appealability and Review": 0,
    "T: Evidence": 0,
    "ST: Presentation of Evidence": 0,
    "ST: Relevancy and Reasons for Excluding Relevant Evidence": 0,
    "ST: Priviledges and Other Policy Exclusions": 0,
    "ST: Writing, Recordings, and Photographs": 0,
    "ST: Hearsay and Circumstances of its Admissibility": 0,
    "T: Torts": 0,
    "ST: Intentional Tors": 0,
    "ST: Negligence": 0,
    "ST: Strict Liability and Products Liability": 0,
    "ST: Other Torts": 0,
    "T: Constitutional Law": 0,
    "ST: The Nature of Judicial Review": 0,
    "ST: The Separation of Powers": 0,
    "ST: The Relation of Nation and States in a Federal System": 0,
    "ST: Individual Rights": 0,
    "T: Contracts": 0,
    "ST: Formation of Contracts": 0,
    "ST: Defenses to Enforceability": 0,
    "ST: Contract Content and Meaning": 0,
    "ST: Performance, Breach and Discharge": 0,
    "ST: Remedies": 0,
    "ST: Third-Party Rights": 0,
    "T: Real Property": 0,
    "ST: Ownership of Property": 0,
    "ST: Rights in Real Property": 0,
    "ST: Real Estate Contracts": 0,
    "ST: Mortgages/Security Devices": 0,
    "ST: Titles": 0,
  };
  try {
    await firebase
      .database()
      .ref(isPaid)
      .once("value")
      .then(doc => {
        for (const [key, value] of Object.entries(doc.val())) {
          if (key.startsWith("study")) {
            if (key === "study-cri") {
              totalThemes["T: Criminal Law and Procedure"] = Object.values(value).length;
              Object.values(value).map(({ published, classification }: FirebaseQuestion) => {
                if (published)
                  switch (getClassificationTheme(classification)) {
                    case "I":
                      totalThemes["ST: Homicide"] = totalThemes["ST: Homicide"] + 1;
                      break;
                    case "II":
                      totalThemes["ST: Other Crimes"] = totalThemes["ST: Other Crimes"] + 1;
                      break;
                    case "III":
                      totalThemes["ST: Inchoate Crimes; Parties"] = totalThemes["ST: Inchoate Crimes; Parties"] + 1;
                      break;
                    case "IV":
                      totalThemes["ST: General Principles"] = totalThemes["ST: General Principles"] + 1;
                      break;
                    case "V":
                      totalThemes["ST: Constitutional Protection of Accused Persons"] =
                        totalThemes["ST: Constitutional Protection of Accused Persons"] + 1;
                      break;
                  }
              });
            }
            if (key === "study-civ") {
              totalThemes["T: Civil Procedure"] = Object.values(value).length;
              Object.values(value).map(({ classification, published }: FirebaseQuestion) => {
                if (published)
                  switch (getClassificationTheme(classification)) {
                    case "I":
                      totalThemes["ST: Jurisdiction and Venue"] = totalThemes["ST: Jurisdiction and Venue"] + 1;
                      break;
                    case "II":
                      totalThemes["ST: Law Applied by Federal Courts"] =
                        totalThemes["ST: Law Applied by Federal Courts"] + 1;
                      break;
                    case "III":
                      totalThemes["ST: Pretrial Procedures"] = totalThemes["ST: Pretrial Procedures"] + 1;
                      break;
                    case "IV":
                      totalThemes["ST: Jury Trials"] = totalThemes["ST: Jury Trials"] + 1;
                      break;
                    case "V":
                      totalThemes["ST: Motions"] = totalThemes["ST: Motions"] + 1;
                      break;
                    case "VI":
                      totalThemes["ST: Verdicts and Judgements"] = totalThemes["ST: Verdicts and Judgements"] + 1;
                      break;
                    case "VII":
                      totalThemes["ST: Appealability and Review"] = totalThemes["ST: Appealability and Review"] + 1;
                      break;
                  }
              });
            }
            if (key === "study-evd") {
              totalThemes["T: Evidence"] = Object.values(value).length;
              Object.values(value).map(({ published, classification }: FirebaseQuestion) => {
                if (published)
                  switch (getClassificationTheme(classification)) {
                    case "I":
                      totalThemes["ST: Presentation of Evidence"] = totalThemes["ST: Presentation of Evidence"] + 1;
                      break;
                    case "II":
                      totalThemes["ST: Relevancy and Reasons for Excluding Relevant Evidence"] =
                        totalThemes["ST: Relevancy and Reasons for Excluding Relevant Evidence"] + 1;
                      break;
                    case "III":
                      totalThemes["ST: Priviledges and Other Policy Exclusions"] =
                        totalThemes["ST: Priviledges and Other Policy Exclusions"] + 1;
                      break;
                    case "IV":
                      totalThemes["ST: Writing, Recordings, and Photographs"] =
                        totalThemes["ST: Writing, Recordings, and Photographs"] + 1;
                      break;
                    case "V":
                      totalThemes["ST: Hearsay and Circumstances of its Admissibility"] =
                        totalThemes["ST: Hearsay and Circumstances of its Admissibility"] + 1;
                      break;
                  }
              });
            }
            if (key === "study-trt") {
              totalThemes["T: Torts"] = Object.values(value).length;
              Object.values(value).map(({ published, classification }: FirebaseQuestion) => {
                if (published)
                  switch (getClassificationTheme(classification)) {
                    case "I":
                      totalThemes["ST: Intentional Tors"] = totalThemes["ST: Intentional Tors"] + 1;
                      break;
                    case "II":
                      totalThemes["ST: Negligence"] = totalThemes["ST: Negligence"] + 1;
                      break;
                    case "III":
                      totalThemes["ST: Strict Liability and Products Liability"] =
                        totalThemes["ST: Strict Liability and Products Liability"] + 1;
                      break;
                    case "IV":
                      totalThemes["ST: Other Torts"] = totalThemes["ST: Other Torts"] + 1;
                      break;
                  }
              });
            }
            if (key === "study-con") {
              totalThemes["T: Constitutional Law"] = Object.values(value).length;
              Object.values(value).map(({ published, classification }: FirebaseQuestion) => {
                if (published)
                  switch (getClassificationTheme(classification)) {
                    case "I":
                      totalThemes["ST: The Nature of Judicial Review"] =
                        totalThemes["ST: The Nature of Judicial Review"] + 1;
                      break;
                    case "II":
                      totalThemes["ST: The Separation of Powers"] = totalThemes["ST: The Separation of Powers"] + 1;
                      break;
                    case "III":
                      totalThemes["ST: The Relation of Nation and States in a Federal System"] =
                        totalThemes["ST: The Relation of Nation and States in a Federal System"] + 1;
                      break;
                    case "IV":
                      totalThemes["ST: Individual Rights"] = totalThemes["ST: Individual Rights"] + 1;
                      break;
                  }
              });
            }
            if (key === "study-ksl") {
              totalThemes["T: Contracts"] = Object.values(value).length;
              Object.values(value).map(({ published, classification }: FirebaseQuestion) => {
                if (published)
                  switch (getClassificationTheme(classification)) {
                    case "I":
                      totalThemes["ST: Formation of Contracts"] = totalThemes["ST: Formation of Contracts"] + 1;
                      break;
                    case "II":
                      totalThemes["ST: Defenses to Enforceability"] = totalThemes["ST: Defenses to Enforceability"] + 1;
                      break;
                    case "III":
                      totalThemes["ST: Contract Content and Meaning"] =
                        totalThemes["ST: Contract Content and Meaning"] + 1;
                      break;
                    case "IV":
                      totalThemes["ST: Performance, Breach and Discharge"] =
                        totalThemes["ST: Performance, Breach and Discharge"] + 1;
                      break;
                    case "V":
                      totalThemes["ST: Third-Party Rights"] = totalThemes["ST: Third-Party Rights"] + 1;
                      break;
                  }
              });
            }
            if (key === "study-rpl") {
              totalThemes["T: Real Property"] = Object.values(value).length;
              Object.values(value).map(({ published, classification }: FirebaseQuestion) => {
                if (published)
                  switch (getClassificationTheme(classification)) {
                    case "I":
                      totalThemes["ST: Ownership of Property"] = totalThemes["ST: Ownership of Property"] + 1;
                      break;
                    case "II":
                      totalThemes["ST: Rights in Real Property"] = totalThemes["ST: Rights in Real Property"] + 1;
                      break;
                    case "III":
                      totalThemes["ST: Real Estate Contracts"] = totalThemes["ST: Real Estate Contracts"] + 1;
                      break;
                    case "IV":
                      totalThemes["ST: Mortgages/Security Devices"] = totalThemes["ST: Mortgages/Security Devices"] + 1;
                      break;
                    case "V":
                      totalThemes["ST: Titles"] = totalThemes["ST: Titles"] + 1;
                  }
              });
            }
          }
        }
      });
    return totalThemes;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export const calculateStudentTotals = (
  questions: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
) => {
  const studentTotals: ReportThemes = {
    "T: Criminal Law and Procedure": 0,
    "ST: Homicide": 0,
    "ST: Other Crimes": 0,
    "ST: Inchoate Crimes; Parties": 0,
    "ST: General Principles": 0,
    "ST: Constitutional Protection of Accused Persons": 0,
    "T: Civil Procedure": 0,
    "ST: Jurisdiction and Venue": 0,
    "ST: Law Applied by Federal Courts": 0,
    "ST: Pretrial Procedures": 0,
    "ST: Jury Trials": 0,
    "ST: Motions": 0,
    "ST: Verdicts and Judgements": 0,
    "ST: Appealability and Review": 0,
    "T: Evidence": 0,
    "ST: Presentation of Evidence": 0,
    "ST: Relevancy and Reasons for Excluding Relevant Evidence": 0,
    "ST: Priviledges and Other Policy Exclusions": 0,
    "ST: Writing, Recordings, and Photographs": 0,
    "ST: Hearsay and Circumstances of its Admissibility": 0,
    "T: Torts": 0,
    "ST: Intentional Tors": 0,
    "ST: Negligence": 0,
    "ST: Strict Liability and Products Liability": 0,
    "ST: Other Torts": 0,
    "T: Constitutional Law": 0,
    "ST: The Nature of Judicial Review": 0,
    "ST: The Separation of Powers": 0,
    "ST: The Relation of Nation and States in a Federal System": 0,
    "ST: Individual Rights": 0,
    "T: Contracts": 0,
    "ST: Formation of Contracts": 0,
    "ST: Defenses to Enforceability": 0,
    "ST: Contract Content and Meaning": 0,
    "ST: Performance, Breach and Discharge": 0,
    "ST: Remedies": 0,
    "ST: Third-Party Rights": 0,
    "T: Real Property": 0,
    "ST: Ownership of Property": 0,
    "ST: Rights in Real Property": 0,
    "ST: Real Estate Contracts": 0,
    "ST: Mortgages/Security Devices": 0,
    "ST: Titles": 0,
  };
  try {
    questions.map(question => {
      const data = <TFirebaseQuestion>question.data();
      if (data.subjectCode === "CRI" && data.topicCode === "I" && data.answered && data.isCorrect) {
        studentTotals["ST: Homicide"] = studentTotals["ST: Homicide"] + 1;
        studentTotals["T: Criminal Law and Procedure"] = studentTotals["T: Criminal Law and Procedure"] + 1;
      }
      if (data.subjectCode === "CRI" && data.topicCode === "II" && data.answered && data.isCorrect) {
        studentTotals["ST: Other Crimes"] = studentTotals["ST: Other Crimes"] + 1;
        studentTotals["T: Criminal Law and Procedure"] = studentTotals["T: Criminal Law and Procedure"] + 1;
      }
      if (data.subjectCode === "CRI" && data.topicCode === "III" && data.answered && data.isCorrect) {
        studentTotals["ST: Inchoate Crimes; Parties"] = studentTotals["ST: Inchoate Crimes; Parties"] + 1;
        studentTotals["T: Criminal Law and Procedure"] = studentTotals["T: Criminal Law and Procedure"] + 1;
      }
      if (data.subjectCode === "CRI" && data.topicCode === "IV" && data.answered && data.isCorrect) {
        studentTotals["ST: General Principles"] = studentTotals["ST: General Principles"] + 1;
        studentTotals["T: Criminal Law and Procedure"] = studentTotals["T: Criminal Law and Procedure"] + 1;
      }
      if (data.subjectCode === "CRI" && data.topicCode === "V" && data.answered && data.isCorrect) {
        studentTotals["ST: Constitutional Protection of Accused Persons"] =
          studentTotals["ST: Constitutional Protection of Accused Persons"] + 1;
        studentTotals["T: Criminal Law and Procedure"] = studentTotals["T: Criminal Law and Procedure"] + 1;
      }
      if (data.subjectCode === "CIV" && data.topicCode === "I" && data.answered && data.isCorrect) {
        studentTotals["ST: Jurisdiction and Venue"] = studentTotals["ST: Jurisdiction and Venue"] + 1;
        studentTotals["T: Civil Procedure"] = studentTotals["T: Civil Procedure"] + 1;
      }
      if (data.subjectCode === "CIV" && data.topicCode === "II" && data.answered && data.isCorrect) {
        studentTotals["ST: Law Applied by Federal Courts"] = studentTotals["ST: Law Applied by Federal Courts"] + 1;
        studentTotals["T: Civil Procedure"] = studentTotals["T: Civil Procedure"] + 1;
      }
      if (data.subjectCode === "CIV" && data.topicCode === "III" && data.answered && data.isCorrect) {
        studentTotals["ST: Pretrial Procedures"] = studentTotals["ST: Pretrial Procedures"] + 1;
        studentTotals["T: Civil Procedure"] = studentTotals["T: Civil Procedure"] + 1;
      }
      if (data.subjectCode === "CIV" && data.topicCode === "IV" && data.answered && data.isCorrect) {
        studentTotals["ST: Jury Trials"] = studentTotals["ST: Jury Trials"] + 1;
        studentTotals["T: Civil Procedure"] = studentTotals["T: Civil Procedure"] + 1;
      }
      if (data.subjectCode === "CIV" && data.topicCode === "V" && data.answered && data.isCorrect) {
        studentTotals["ST: Motions"] = studentTotals["ST: Motions"] + 1;
        studentTotals["T: Civil Procedure"] = studentTotals["T: Civil Procedure"] + 1;
      }
      if (data.subjectCode === "CIV" && data.topicCode === "VI" && data.answered && data.isCorrect) {
        studentTotals["ST: Verdicts and Judgements"] = studentTotals["ST: Verdicts and Judgements"] + 1;
        studentTotals["T: Civil Procedure"] = studentTotals["T: Civil Procedure"] + 1;
      }
      if (data.subjectCode === "CIV" && data.topicCode === "VII" && data.answered && data.isCorrect) {
        studentTotals["ST: Appealability and Review"] = studentTotals["ST: Appealability and Review"] + 1;
        studentTotals["T: Civil Procedure"] = studentTotals["T: Civil Procedure"] + 1;
      }
      if (data.subjectCode === "EVD" && data.topicCode === "I" && data.answered && data.isCorrect) {
        studentTotals["ST: Presentation of Evidence"] = studentTotals["ST: Presentation of Evidence"] + 1;
        studentTotals["T: Evidence"] = studentTotals["T: Evidence"] + 1;
      }
      if (data.subjectCode === "EVD" && data.topicCode === "II" && data.answered && data.isCorrect) {
        studentTotals["ST: Relevancy and Reasons for Excluding Relevant Evidence"] =
          studentTotals["ST: Relevancy and Reasons for Excluding Relevant Evidence"] + 1;
        studentTotals["T: Evidence"] = studentTotals["T: Evidence"] + 1;
      }
      if (data.subjectCode === "EVD" && data.topicCode === "III" && data.answered && data.isCorrect) {
        studentTotals["ST: Priviledges and Other Policy Exclusions"] =
          studentTotals["ST: Priviledges and Other Policy Exclusions"] + 1;
        studentTotals["T: Evidence"] = studentTotals["T: Evidence"] + 1;
      }
      if (data.subjectCode === "EVD" && data.topicCode === "IV" && data.answered && data.isCorrect) {
        studentTotals["ST: Writing, Recordings, and Photographs"] =
          studentTotals["ST: Writing, Recordings, and Photographs"] + 1;
        studentTotals["T: Evidence"] = studentTotals["T: Evidence"] + 1;
      }
      if (data.subjectCode === "EVD" && data.topicCode === "V" && data.answered && data.isCorrect) {
        studentTotals["ST: Hearsay and Circumstances of its Admissibility"] =
          studentTotals["ST: Hearsay and Circumstances of its Admissibility"] + 1;
        studentTotals["T: Evidence"] = studentTotals["T: Evidence"] + 1;
      }
      if (data.subjectCode === "TRT" && data.topicCode === "I" && data.answered && data.isCorrect) {
        studentTotals["ST: Intentional Tors"] = studentTotals["ST: Intentional Tors"] + 1;
        studentTotals["T: Torts"] = studentTotals["T: Torts"] + 1;
      }
      if (data.subjectCode === "TRT" && data.topicCode === "II" && data.answered && data.isCorrect) {
        studentTotals["ST: Negligence"] = studentTotals["ST: Negligence"] + 1;
        studentTotals["T: Torts"] = studentTotals["T: Torts"] + 1;
      }
      if (data.subjectCode === "TRT" && data.topicCode === "III" && data.answered && data.isCorrect) {
        studentTotals["ST: Strict Liability and Products Liability"] =
          studentTotals["ST: Strict Liability and Products Liability"] + 1;
        studentTotals["T: Torts"] = studentTotals["T: Torts"] + 1;
      }
      if (data.subjectCode === "TRT" && data.topicCode === "IV" && data.answered && data.isCorrect) {
        studentTotals["ST: Other Torts"] = studentTotals["ST: Other Torts"] + 1;
        studentTotals["T: Torts"] = studentTotals["T: Torts"] + 1;
      }
      if (data.subjectCode === "CON" && data.topicCode === "I" && data.answered && data.isCorrect) {
        studentTotals["ST: The Nature of Judicial Review"] = studentTotals["ST: The Nature of Judicial Review"] + 1;
        studentTotals["T: Constitutional Law"] = studentTotals["T: Constitutional Law"] + 1;
      }
      if (data.subjectCode === "CON" && data.topicCode === "II" && data.answered && data.isCorrect) {
        studentTotals["ST: The Separation of Powers"] = studentTotals["ST: The Separation of Powers"] + 1;
        studentTotals["T: Constitutional Law"] = studentTotals["T: Constitutional Law"] + 1;
      }
      if (data.subjectCode === "CON" && data.topicCode === "III" && data.answered && data.isCorrect) {
        studentTotals["ST: The Relation of Nation and States in a Federal System"] =
          studentTotals["ST: The Relation of Nation and States in a Federal System"] + 1;
        studentTotals["T: Constitutional Law"] = studentTotals["T: Constitutional Law"] + 1;
      }
      if (data.subjectCode === "CON" && data.topicCode === "IV" && data.answered && data.isCorrect) {
        studentTotals["ST: Individual Rights"] = studentTotals["ST: Individual Rights"] + 1;
        studentTotals["T: Constitutional Law"] = studentTotals["T: Constitutional Law"] + 1;
      }
      if (data.subjectCode === "KSL" && data.topicCode === "I" && data.answered && data.isCorrect) {
        studentTotals["ST: Formation of Contracts"] = studentTotals["ST: Formation of Contracts"] + 1;
        studentTotals["T: Contracts"] = studentTotals["T: Contracts"] + 1;
      }
      if (data.subjectCode === "KSL" && data.topicCode === "II" && data.answered && data.isCorrect) {
        studentTotals["ST: Defenses to Enforceability"] = studentTotals["ST: Defenses to Enforceability"] + 1;
        studentTotals["T: Contracts"] = studentTotals["T: Contracts"] + 1;
      }
      if (data.subjectCode === "KSL" && data.topicCode === "III" && data.answered && data.isCorrect) {
        studentTotals["ST: Contract Content and Meaning"] = studentTotals["ST: Contract Content and Meaning"] + 1;
        studentTotals["T: Contracts"] = studentTotals["T: Contracts"] + 1;
      }
      if (data.subjectCode === "KSL" && data.topicCode === "IV" && data.answered && data.isCorrect) {
        studentTotals["ST: Performance, Breach and Discharge"] =
          studentTotals["ST: Performance, Breach and Discharge"] + 1;
        studentTotals["T: Contracts"] = studentTotals["T: Contracts"] + 1;
      }
      if (data.subjectCode === "KSL" && data.topicCode === "V" && data.answered && data.isCorrect) {
        studentTotals["ST: Remedies"] = studentTotals["ST: Remedies"] + 1;
        studentTotals["T: Contracts"] = studentTotals["T: Contracts"] + 1;
      }
      if (data.subjectCode === "KSL" && data.topicCode === "VI" && data.answered && data.isCorrect) {
        studentTotals["ST: Third-Party Rights"] = studentTotals["ST: Third-Party Rights"] + 1;
        studentTotals["T: Contracts"] = studentTotals["T: Contracts"] + 1;
      }
      if (data.subjectCode === "RPL" && data.topicCode === "I" && data.answered && data.isCorrect) {
        studentTotals["ST: Ownership of Property"] = studentTotals["ST: Ownership of Property"] + 1;
        studentTotals["T: Real Property"] = studentTotals["T: Real Property"] + 1;
      }
      if (data.subjectCode === "RPL" && data.topicCode === "II" && data.answered && data.isCorrect) {
        studentTotals["ST: Rights in Real Property"] = studentTotals["ST: Rights in Real Property"] + 1;
        studentTotals["T: Real Property"] = studentTotals["T: Real Property"] + 1;
      }
      if (data.subjectCode === "RPL" && data.topicCode === "III" && data.answered && data.isCorrect) {
        studentTotals["ST: Real Estate Contracts"] = studentTotals["ST: Real Estate Contracts"] + 1;
        studentTotals["T: Real Property"] = studentTotals["T: Real Property"] + 1;
      }
      if (data.subjectCode === "RPL" && data.topicCode === "IV" && data.answered && data.isCorrect) {
        studentTotals["ST: Mortgages/Security Devices"] = studentTotals["ST: Mortgages/Security Devices"] + 1;
        studentTotals["T: Real Property"] = studentTotals["T: Real Property"] + 1;
      }
      if (data.subjectCode === "RPL" && data.topicCode === "V" && data.answered && data.isCorrect) {
        studentTotals["ST: Titles"] = studentTotals["ST: Titles"] + 1;
        studentTotals["T: Real Property"] = studentTotals["T: Real Property"] + 1;
      }
    });
    return studentTotals;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export const mergeTotals = (themeTotals: ReportThemes, studentTotals: ReportThemes) => {
  const merged = {};
  Object.keys(themeTotals).forEach((key: string) => (merged[key] = `${studentTotals[key]}/${themeTotals[key]}`));
  return merged;
};

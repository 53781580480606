import React from "react";
import PropTypes from "prop-types";
import MuiBtn from "@material-ui/core/Button";
import { color as colors } from "../../styles/style";
import styled from "styled-components/macro";

const custom = {
  dark: {
    main: colors.dark,
    disabled: colors.blueDarkest,
    hover: colors.darkest,
    text: colors.white,
  },
  success: {
    main: colors.secondary,
    disabled: colors.secondaryLight,
    hover: colors.secondaryDark,
    text: colors.white,
  },
  danger: {
    main: colors.redMain,
    disabled: colors.redLight,
    hover: colors.dangerDark,
    text: colors.white,
  },
  golden: {
    main: colors.goldMain,
    disabled: colors.goldLightest,
    hover: colors.goldDark,
    text: colors.white,
  },
};

const CustomButton = styled(({ color, ...props }) => <MuiBtn {...props} />)`
  background-color: ${(props) => custom[props.color].main};

  &:hover {
    background-color: ${(props) => custom[props.color].hover};
    color: ${(props) => custom[props.color].text};
  }

  &.MuiButton-contained.Mui-disabled {
    background-color: ${(props) => custom[props.color].disabled};
  }
`;

const Button = ({ children, ...props }) => {
  if (custom[props.color]) {
    return <CustomButton {...props}>{children}</CustomButton>;
  }

  return <MuiBtn {...props}>{children}</MuiBtn>;
};

Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  variant: "contained",
  color: "primary",
  className: "",
  disabled: false,
  onClick: () => false,
};

export default Button;

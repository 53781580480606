import styled from "styled-components/macro";
import MuiToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const ToggleBtnGroup = styled(MuiToggleButtonGroup)`
  &&&&&& {
    display: flex;
    justify-content: space-between;
    box-shadow: none;
  }
`;

export default ToggleBtnGroup;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogContentText from "@material-ui/core/DialogContentText";
import Loading from "../../components/Loading";
import Content from "./Content";
import {
  publishQuestions,
  removeQuestion,
  unpublishQuestion,
} from "../../store/actions/questionActions";

const DialogContentText = withStyles((theme) => ({
  root: {
    marginBottom: 10,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
}))(MuiDialogContentText);

const DialogContentWorking = withStyles(() => ({
  root: {
    margin: 0,
    height: "100%",

    padding: "6rem",
  },
}))(MuiDialogContent);

class DialogModal extends React.Component {
  state = {
    status: "ready",
  };

  componentDidMount() {
    this.setState({ status: "ready" });
  }

  beginPublish = (event) => {
    this.setState({ status: "working" });

    event.preventDefault();
    this.callPublish();
  };

  callPublish() {
    const { level, qbank, questions } = this.props;
    this.props.publishQuestions(level, qbank, questions).then(() => {
      this.setState({ status: "done" });
    });
  }

  beginUnpublish = (event) => {
    const { level, qbank, qid } = this.props;
    this.setState({ status: "working" });

    this.props.unpublishQuestion(level, qbank, qid).then(() => {
      this.setState({ status: "done" });
    });
    event.preventDefault();
  };

  beginDelete = (event) => {
    const { level, qbank, qid } = this.props;
    this.setState({ status: "working" });
    this.props.removeQuestion(level, qbank, qid).then(() => {
      this.setState({ status: "done" });
    });
    event.preventDefault();
  };
  handlePreDone = () => {
    this.setState({ status: "ready" });
    this.props.handleDone();
  };
  handlePreAction = () => {
    this.props.handleAction();
  };

  render() {
    const { open, qStatus, type, handleClose, handleCancel } = this.props;

    const { status } = this.state;
    const titleType = type ? type.charAt(0).toUpperCase() + type.slice(1) : "";
    const title = `${titleType} Question`;

    return (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        {status === "working" && (
          <Content title={title} hasActions={false}>
            <DialogContentWorking>
              <Loading />
            </DialogContentWorking>
          </Content>
        )}
        {status === "done" && (
          <>
            {qStatus === "published" && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Done"
                leftAction={this.handlePreDone}
                rightLabel="Add Another"
                rightAction={this.handlePreAction}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    You have successfully PUBLISHED the question. What would you
                    like to do now?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {qStatus === "unpublished" && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Done"
                leftAction={this.handlePreDone}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    You have successfully UNPUBLISHD the question.
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {qStatus === "removed" && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Done"
                leftAction={this.handlePreDone}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    You have successfully REMOVED the question.
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
          </>
        )}

        {status === "ready" && (
          <>
            {type === "delete" && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Cancel"
                leftAction={handleCancel}
                rightLabel="Delete"
                rightAction={this.beginDelete}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    Are you sure you want to DELETE the Question?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {type === "unpublish" && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Cancel"
                leftAction={handleCancel}
                rightLabel="UNPUBLISH"
                rightAction={this.beginUnpublish}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    Are you sure you want to UNPUBLISH the Question?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {type === "publish" && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Cancel"
                leftAction={handleCancel}
                rightLabel="Publish"
                rightAction={this.beginPublish}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    Are you sure you want to PUBLISH the Question?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
          </>
        )}
      </Dialog>
    );
  }
}

DialogModal.propTypes = {
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  qStatus: PropTypes.string.isRequired,
  qid: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleDone: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  qbank: state.questionStatus.qbank,
  level: state.questionStatus.level,
  view: state.questionStatus.view,
  qid: state.questionStatus.qid,
  qStatus: state.questionStatus.status,
  isDraft: state.questionStatus.isDraft,
});

const mapDispatchToProps = {
  publishQuestions,
  removeQuestion,
  unpublishQuestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogModal);

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { MainScreen } from "../../../../containers/Main";
import Button from "../../../../elements/Button";
import GenericTable from "../../../../components/GenericTable";
import BaseDialog from "../../../../components/BaseDialog";
import modalTypes from "../../../../_CONS/modalTypes";
import { removeUser } from "../../../../store/actions/adminActions";
import { useHistory, useLocation } from "react-router";
import LoadingModal from "../../../../components/LoadingModal";

const newCols = [
  {
    id: "lastName",
    align: "left",
    disablePadding: false,
    label: "Last Name",
    selector: "lastName",
  },
  {
    id: "firstName",
    align: "left",
    disablePadding: false,
    label: "First Name",
    selector: "firstName",
  },
  {
    id: "email",
    align: "left",
    disablePadding: false,
    label: "email",
    selector: "email",
  },
];

const List = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [dialog, setDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const users = useSelector((state) => state.firestore.ordered.users) || [];
  const status = useSelector((state) => state.userStatus.status) || [];

  const goToScreen = (e) => {
    history.push(`${location.pathname}/new`);

    e.preventDefault();
  };

  const handleDialogCancel = () => {
    setDialog(null);
  };

  const onMenuAction = (event, uid, view) => {
    event.preventDefault();

    if (view === "view") {
      history.push(`${location.pathname}/view/${uid}`);
    } else {
      setDialog(modalTypes.DELETE_USER);
      setUserToDelete(uid);
    }
  };

  const handleDialogConfirm = (type) => {
    switch (type) {
      case "delete-user-error":
        handleDialogCancel();
        break;
      case "delete-user":
        dispatch(removeUser(userToDelete));
        handleDialogCancel();
        break;
      default:
        break;
    }
  };

  return (
    <MainScreen
      title="Users"
      rightSideItem={
        <Button
          color="primary"
          variant="contained"
          aria-label="Add User"
          onClick={goToScreen}
        >
          Add User
        </Button>
      }
    >
      <LoadingModal open={status === "loading"} />

      <BaseDialog
        {...dialog}
        open={!!dialog && dialog.open}
        onCancelAction={handleDialogCancel}
        onConfirmAction={handleDialogConfirm}
      />

      <Grid item xs={12}>
        <Card>
          <Tabs value={0}>
            <Tab disableRipple label="USERS" />
          </Tabs>

          {users.length > 0 && (
            <GenericTable
              menuItems={["view", "delete"]}
              colHeaders={newCols}
              data={users}
              onMenuAction={onMenuAction}
            />
          )}
        </Card>
      </Grid>
    </MainScreen>
  );
};

export default List;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components/macro";
import Grid from "@material-ui/core/Grid";
import sortBy from "lodash/sortBy";
import { MainScreen } from "../../../../containers/Main";
import Loading from "../../../../components/Loading";
import InfoCard from "../../../../components/InfoCard";
import TestGridBox from "../../../../components/TestGridBox";
import { buildTestGuide } from "../../../../store/actions/builderActions";
import { getOrganization, openGuide, reviewGuide } from "../../../../store/actions/guideActions";
import TITLES from "../../../../_CONS/TITLES";
import { Typography } from "@material-ui/core";
import moment from "moment";
import seedTests from "../../../../_CONS/seedTests";
import firebase from "../../../../store/api/firebaseAdmin";

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

class TestGuideList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guideId: null,
      isBuilding: false,
      organization: null,
    };
  }

  componentDidMount() {
    const { getOrganization, organizationId, setupStatus } = this.props;
    if (organizationId) {
      getOrganization(organizationId).then(organization => this.setState({ organization }));
    }
    if(!setupStatus){
      this.hasAllTests();
    }
  }

  hasAllTests = async () => {
    const firestore = firebase.firestore();
    const seedFinalTests = seedTests.filter(test => test.qbank.startsWith("test-final"));
    const studentLevel = this.props.accountType === "pro" ? "paid" : "free";
    const { accountId } = this.props;

    const dbFinalTests = await firestore
      .collection(`students-${studentLevel}`)
      .doc(accountId.trim())
      .collection("testguides")
      .get()
      .then(doc => doc.docs.filter(item => item.data().qbank.startsWith("test-final")));

    const compareTests = dbFinalTests.map(item => item.data());

    const newNotAddedTests = seedFinalTests.filter(i => !compareTests.some(j => j.qbank === i.qbank));

    const docRef = firestore.collection(`students-${studentLevel}`).doc(accountId.trim()).collection("testguides");

    const batch = firestore.batch();

    if (newNotAddedTests.length) {
      for (const item of newNotAddedTests) {
        const doc = docRef.doc();

        let totalQ = 0;
        await firebase
          .database()
          .ref()
          .child(studentLevel)
          .child(item.qbank)
          .once("value", snapshot => {
            snapshot.forEach(data => {
              const question = data.val();
              if (question.published) {
                totalQ++;
              }
            });
          });

        if (totalQ) {
          batch.set(doc, {
            ...item,
            accountType: this.props.accountType,
            accountLevel: studentLevel,
            time: 0,
            questionsCorrect: 0,
            questionsWrong: 0,
            questionsTotal: totalQ,
            showFeedback: false,
            showTimer: true,
            showNotes: false,
            duration: 0,
            qbank: item.qbank,
            uid: doc.id,
            id: doc.id,
          });
        }
      }
    }
    for (const item of compareTests) {
      const doc = docRef.doc(item.uid);
      let totalQ = 0;
      await firebase
        .database()
        .ref()
        .child(studentLevel)
        .child(item.qbank)
        .once("value", snapshot => {
          snapshot.forEach(data => {
            const question = data.val();
            if (question.published) {
              totalQ++;
            }
          });
        });
      if (totalQ) {
        batch.set(doc, {
          ...item,
          questionsTotal: totalQ,
        });
      } else {
        await firestore
          .collection(`students-${studentLevel}`)
          .doc(accountId.trim())
          .collection("testguides")
          .doc(item.uid)
          .delete();
      }
    }
    return batch.commit();
  };

  buildNewGuide = (item, e) => {
    this.setState({ isBuilding: true });
    e.preventDefault();
    const gObj = {
      guideType: "testguides",
      status: "loading",
    };
    this.props.buildTestGuide(item, gObj).then(() => {
      this.props.history.push(`${this.props.location.pathname}/guide`);
    });
  };

  continueGuide = (item, e) => {
    e.preventDefault();
    const obj = {
      guideType: "testguides",
      guideId: item.id,
      status: "loading",
    };
    this.props.openGuide(obj).then(() => {
      this.props.history.push(`${this.props.location.pathname}/guide`);
    });
  };

  reviewGuide = (item, e) => {
    e.preventDefault();
    const obj = {
      guideType: "testguides",
      guideId: item.id,
      status: "",
      queries: null,
    };
    this.props.reviewGuide(obj).then(() => {
      this.props.history.push(`${this.props.location.pathname}/testguides/overview`);
    });
  };

  checkIsTestLocked = (tests, blockedTests) => {
    const today = moment(new Date().toDateString());
    return tests.filter(
      test =>
        !blockedTests.some(locked => {
          if (test.qbank === locked.value && locked.isLocked) {
            if (!locked.until) {
              return true;
            }
            if (locked.until && today.diff(moment(new Date(locked.until.seconds * 1000).toDateString()), "day") < 0) {
              return true;
            }
          }
        })
    );
  };

  render() {
    let { buildStatus, practiceTests, finalTests, accountType, action } = this.props;
    const { isBuilding, organization } = this.state;
    if (organization && !!organization.isTestsLocked && organization.lockedTests) {
      finalTests = this.checkIsTestLocked(finalTests, organization.lockedTests);
      practiceTests = this.checkIsTestLocked(practiceTests, organization.lockedTests);
    }

    const completedFinalTests = finalTests.filter(test => test.completed);
    const readyFinalTests = completedFinalTests.length < 1 ? 5 : 6;
    const screenCopy = TITLES.TEST[accountType.toUpperCase()];

    if (buildStatus === "success" && action === "build") {
      return <Redirect to={{ pathname: "/student/test/guide" }} />;
    }

    if (isBuilding) {
      return (
        <MainScreen title="Tests">
          <Grid item xs={12}>
            <Loading />
          </Grid>
        </MainScreen>
      );
    }

    return (
      <MainScreen title="Tests">
        {/* <Grid item xs={12}>
          <InfoCard
            title={screenCopy.OPE.title}
            txt={
              <>
                <Typography paragraph>{screenCopy.OPE.copy}</Typography>
                <Typography variant="subtitle1" display="inline">
                  Please note:{" "}
                </Typography>
                <Typography display="inline">{screenCopy.OPE.note}</Typography>
              </>
            }
          />
        </Grid>
        {practiceTests &&
          sortBy(practiceTests, ['name']).map(test => (
            <TestGridBox
              key={test.uid}
              item={test}
              title={test.name}
              completed={test.completed}
              guideActionBuild={e => this.buildNewGuide(test, e)}
              guideActionContinue={e => this.continueGuide(test, e)}
              guideActionReview={e => this.reviewTestGuide(test, e)}
            />
          ))} */}

        <Grid item xs={12}>
          <InfoCard
            title={screenCopy.FINAL.title}
            txt={
              "The first two practice exams (Test 1 and Test 2) contain questions released by the National Conference of Bar Examiners in 2020. "
            }
            txtTwo={
              "The second two practice exams (Test 3 and Test 4) contain questions released by the National Conference of Bar Examiners in 2022."
            }
            txtThree={
              "Please note that these exams are timed and will be scored automatically upon exiting the online exam environment."
            }
          />
        </Grid>
        {finalTests &&
          sortBy(finalTests, ["name"]).map(test => {
            if (test.accountType !== this.props.accountType && this.props.accountType !== "pro") {
              return null;
            }
            return (
              <TestGridBox
                key={test.uid}
                item={test}
                title={test.name}
                completed={test.completed}
                ready={readyFinalTests >= test.guideNum}
                guideActionBuild={e => this.buildNewGuide(test, e)}
                guideActionContinue={e => this.continueGuide(test, e)}
                guideActionReview={e => this.reviewGuide(test, e)}
              />
            );
          })}
      </MainScreen>
    );
  }
}

const mapStateToProps = ({ firebase, guideStatus, auth, firestore, name , setupStatus, ...rest}) => {
  const testGuides = firestore.ordered.testGuides || [];
  return {
    accountType: firebase.profile.accountType,
    organizationId: firebase.profile.organization,
    accountId: firebase.profile.uid,
    buildStatus: guideStatus.status,
    action: guideStatus.action,
    newGuideId: guideStatus.guideId ? guideStatus.guideId : null,
    studentId: auth.uid,
    practiceTests: testGuides.filter(test => test.testType === "ope"),
    finalTests: testGuides.filter(test => test.testType === "final"),
    testGuides: testGuides,
    firebase: firebase,
    firestore: firestore,
    setupStatus: setupStatus
  };
};

const mapDispatchToProps = {
  buildTestGuide,
  openGuide,
  reviewGuide,
  getOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestGuideList);

import styled from "styled-components/macro";
import { color, fontWeight } from "../../styles/style";

const LandingInfoText = styled.div`
  color: ${color.dark};
  font-weight: ${fontWeight.semiBold};
  font-size: 1rem;
  text-align: center;
`;

export default LandingInfoText;

import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router";
import { isLoaded } from "react-redux-firebase";
import styled from "styled-components/macro";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Loading from "../../../../components/Loading";
import { MainScreen } from "../../../../containers/Main";
import AvatarCard from "../../../../components/AvatarCard";
import { Card, CardContent } from "../../../../containers/Card";
import { formatDateTime } from "../../../../utils/formatDateTime";
import { get } from "lodash";
import { AdminRoutes } from "../../../../_CONS/routes";
import Tabs from "../../../../elements/Tabs";
import Tab from "../../../../elements/Tab";
import { RootState } from "../../../../store/createReduxStore";
import BackButton from "../../../../elements/BackButton";

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;

type TParams = {
  uid: string;
};

const View = (props) => {
  const history = useHistory();
  const params = useParams<TParams>();
  const user = useSelector(({ firestore }: RootState) =>
    get(firestore.data, `users.${params.uid}`)
  );

  if (!user && isLoaded(user)) {
    return <Redirect to={AdminRoutes.USERS} />;
  }

  if (!isLoaded(user) || !user) {
    return <Loading />;
  }

  return (
    <Screen>
      <MainScreen
        spacing={3}
        title="User Profile"
        leftSideItem={<BackButton onClick={history.goBack} />}
      >
        <Grid item xs={4}>
          <Grid container spacing={3} direction={"column"}>
            <Grid item>
              <AvatarCard user={user} />
            </Grid>
            <Grid item />
          </Grid>
        </Grid>

        <Grid item xs>
          <Card>
            <Tabs value={0}>
              <Tab label="PROFILE" />
            </Tabs>

            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField label="First Name" value={user.firstName} />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField label="Last Name" value={user.lastName} />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Email"
                      value={user.email}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Role"
                      value={user.role}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Start Date"
                      value={formatDateTime(user.startDate)}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </MainScreen>
    </Screen>
  );
};

View.displayName = "UserView";

export default View;

import React from "react";
/*** ui and style ***/
import styled from "styled-components/macro";
import {color} from "../../../styles/style";

// const ArrowWrapper = styled.div`
//   transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//   transition: all 300ms;
//   transform: ${props =>
//     props.isActive === true ? 'rotate(180deg)' : 'rotate(0)'};
// `;

const ArrowWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms;
  transform: ${(props) =>
    props.isActive === true ? "rotate(180deg)" : "rotate(0)"};
`;
const IconArrowsUp = ({ isActive }) => {
  return (
    <ArrowWrapper isActive={isActive}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="13"
        viewBox="0 0 14 13"
      >
        <g fill={isActive ? color.white : color.primary} fillRule="nonzero">
          <path d="M0 6.664l.824.748L7 1.649l6.176 5.763.824-.748L7 0z" />
          <path d="M0 11.664l.824.748L7 6.649l6.176 5.763.824-.748L7 5z" />
        </g>
      </svg>
    </ArrowWrapper>
  );
};

export default IconArrowsUp;

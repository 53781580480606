import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
} from "@material-ui/core";
import styled from "styled-components/macro";
import Button from "../../../../elements/Button";
import theme from "../../../../styles/theme";

export const NavButton = styled(Button)`
  margin-right: ${theme.spacing(3)}px;
`;

export const Accordion = styled(MuiAccordion)`
  width: 100%;

  .mb-3 {
    margin-bottom: ${theme.spacing(3)}px;
  }

  .ml-3 {
    margin-left: ${theme.spacing(3)}px;
  }

  .mr-3 {
    margin-right: ${theme.spacing(3)}px;
  }

  .mt-3 {
    margin-top: ${theme.spacing(3)}px;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)``;

export const List = styled.div`
  padding: 1rem;
`;

import React from "react";
import { color } from "../../../styles/style";

const PagerDot = ({ isActive }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <circle
        cx="7"
        cy="7"
        r="4"
        fill={isActive ? color.primary : color.blueDark}
      />
    </svg>
  );
};
export default PagerDot;

import React from "react";
import PropTypes from "prop-types";
import CardFieldEdit from "../../../../components/CardFieldEdit";
import CardClassifyEdit from "../../../../components/CardClassifyEdit";
import CardSelectEdit from "../../../../components/CardSelectEdit";
import { convertToHtml } from "../../../../utils/convertToHtml";

class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      level: props.currentQuestion.level,
      published: props.currentQuestion.published,
      type: props.currentQuestion.type,
      testCode: props.currentQuestion.testCode,
      subject: props.currentQuestion.subject,
      subjectCode: props.currentQuestion.subjectCode,
      classification: props.currentQuestion.classification
        ? props.currentQuestion.classification
        : [],
      question: convertToHtml(props.currentQuestion.question),
      A: convertToHtml(props.currentQuestion.A),
      B: convertToHtml(props.currentQuestion.B),
      C: convertToHtml(props.currentQuestion.C),
      D: convertToHtml(props.currentQuestion.D),
      correctAnswer: props.currentQuestion.correctAnswer,
      correctFeedback: convertToHtml(props.currentQuestion.correctFeedback),
      feedback1: convertToHtml(props.currentQuestion.feedback1),
      feedback2: props.currentQuestion.feedback2
        ? convertToHtml(props.currentQuestion.feedback2)
        : "",
      feedback3: props.currentQuestion.feedback3
        ? convertToHtml(props.currentQuestion.feedback3)
        : "",
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCardAction = (name) => (event) => {
    this.setState({
      [name]: event,
    });
  };

  render() {
    const {
      subject,
      subjectCode,
      classification,
      type,
      question,
      A,
      B,
      C,
      D,
      correctAnswer,
      correctFeedback,
      feedback1,
      feedback2,
      feedback3,
    } = this.state;
    return (
      <>
        <CardClassifyEdit
          label="Subject and Classification"
          type={type}
          subjectCode={subjectCode}
          subject={subject}
          classification={classification}
        />

        <CardFieldEdit
          inputProps={{ maxLength: 10000 }}
          label="Question"
          fieldKey="question"
          cardAction={this.handleCardAction("question")}
          isHTML={true}
          content={question}
        />

        <CardFieldEdit
          inputProps={{ maxLength: 2000 }}
          label="Answer A"
          fieldKey="A"
          cardAction={this.handleCardAction("A")}
          isHTML={true}
          content={A}
        />

        <CardFieldEdit
          inputProps={{ maxLength: 2000 }}
          label="Answer B"
          fieldKey="B"
          cardAction={this.handleCardAction("B")}
          isHTML={true}
          content={B}
        />
        <CardFieldEdit
          inputProps={{ maxLength: 2000 }}
          label="Answer C"
          fieldKey="C"
          cardAction={this.handleCardAction("C")}
          isHTML={true}
          content={C}
        />
        <CardFieldEdit
          inputProps={{ maxLength: 2000 }}
          label="Answer D"
          fieldKey="D"
          cardAction={this.handleCardAction("D")}
          isHTML={true}
          content={D}
        />
        <CardSelectEdit
          label="Correct Answer"
          fieldKey="correctAnswer"
          cardAction={this.handleCardAction("correctAnswer")}
          isHTML={false}
          content={correctAnswer}
        />
        <CardFieldEdit
          inputProps={{ maxLength: 5000 }}
          label="Correct Feedback"
          fieldKey="correctFeedback"
          cardAction={this.handleCardAction("correctFeedback")}
          isHTML={true}
          content={correctFeedback}
        />
        <CardFieldEdit
          inputProps={{ maxLength: 5000 }}
          label="Feedback1"
          fieldKey="feedback1"
          cardAction={this.handleCardAction("feedback1")}
          isHTML={true}
          content={feedback1}
        />
        <CardFieldEdit
          inputProps={{ maxLength: 5000 }}
          label="Feedback2"
          fieldKey="feedback2"
          cardAction={this.handleCardAction("feedback2")}
          isHTML={true}
          content={feedback2}
        />
        <CardFieldEdit
          inputProps={{ maxLength: 5000 }}
          label="Feedback3"
          fieldKey="feedback3"
          cardAction={this.handleCardAction("feedback3")}
          isHTML={true}
          content={feedback3}
        />
      </>
    );
  }
}

EditForm.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
};

export default EditForm;

import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { MainScreen } from "../../../../../containers/Main";
import { Card, CardContent } from "../../../../../containers/Card";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/createReduxStore";
import { TextField } from "./StyledView";
import {
  validate,
  validateEmail,
  validateName,
  validatePassword,
  validatePhoneNumber,
} from "../../../../../utils/validator";
import { formatPhoneNumber } from "../../../../../utils/format";
import { useFirestoreConnect } from "react-redux-firebase";
import { updateAdmin } from "../../../../../store/actions/adminActions";
import Button from "../../../../../elements/Button";
import LoadingModal from "../../../../../components/LoadingModal";
import BackButton from "../../../../../elements/BackButton";

type TFields = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  jobTitle: string;
  phoneNumber: string;
};

type TUrlParams = {
  id: string;
  userId: string;
};

const ViewOrganizationUser: React.FC = () => {
  const history = useHistory();
  const params: TUrlParams = useParams();
  const dispatch = useDispatch();

  useFirestoreConnect([
    { collection: "users", doc: params.userId, storeAs: "currentUser" },
    {
      collection: "organizations",
      doc: params.id,
      storeAs: "currentOrganization",
    },
  ]);

  const user = useSelector(
    (state: RootState) => state.firestore.data.currentUser
  );
  const organization = useSelector(
    (state: RootState) => state.firestore.data.currentOrganization
  );
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const status = useSelector((state: RootState) => state.userStatus.status);
  const isOrganizationUser =
    organization && organization.id === profile.token.claims.organization;
  const isUsersProfile = user && profile && user.email === profile.email;

  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    jobTitle: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState<Partial<TFields>>({});

  useEffect(() => {
    if (user) {
      setFields({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        password: "",
        jobTitle: user.jobTitle || "",
        phoneNumber: formatPhoneNumber(user.phoneNumber) || "",
      });
    }
  }, [user]);

  const goBack = () => {
    history.goBack();
  };

  const validateFields = () => {
    const passwordValidator = fields.password ? [validatePassword] : [];

    return validate(fields, {
      firstName: [validateName],
      lastName: [validateName],
      email: [validateEmail],
      password: passwordValidator,
      phoneNumber: [validatePhoneNumber],
    });
  };

  const onChange =
    (field: string) => (event: ChangeEvent<HTMLInputElement>) => {
      let val = event.target.value;

      if (field === "phoneNumber" && val.length > fields.phoneNumber.length) {
        val = formatPhoneNumber(val);
      }

      setFields({
        ...fields,
        [field]: val,
      });
    };

  const onUpdate = () => {
    const validationResult = validateFields();

    if (Object.keys(validationResult).length) {
      setErrors(validationResult);
    } else {
      setErrors({});

      dispatch(
        updateAdmin(params.userId, {
          ...fields,
          isActive: !!user.isActive,
          phoneNumber: fields.phoneNumber.replace(/\D/g, ""),
        })
      );
    }
  };

  const toggleActiveStatus = () => {
    dispatch(
      updateAdmin(params.userId, {
        isActive: !user.isActive,
      })
    );
  };

  return (
    <MainScreen
      title="User"
      spacing={3}
      leftSideItem={<BackButton onClick={goBack} />}
    >
      <LoadingModal open={status === "loading"} />
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container alignItems={"flex-start"}>
              <Grid
                item
                container
                spacing={3}
                direction={"column"}
                xs={12}
                md={6}
              >
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Personal Information
                  </Typography>
                </Grid>

                <Grid item>
                  <TextField
                    disabled={isOrganizationUser}
                    label="First Name *"
                    value={fields.firstName}
                    error={!!errors.firstName}
                    helperText={errors.firstName || " "}
                    inputProps={{ maxLength: 255 }}
                    onChange={onChange("firstName")}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    disabled={isOrganizationUser}
                    label="Last Name *"
                    value={fields.lastName}
                    error={!!errors.lastName}
                    helperText={errors.lastName || " "}
                    inputProps={{ maxLength: 255 }}
                    onChange={onChange("lastName")}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    disabled={isOrganizationUser}
                    label="Job Title"
                    value={fields.jobTitle}
                    error={!!errors.jobTitle}
                    helperText={errors.phoneNumber || " "}
                    inputProps={{ maxLength: 255 }}
                    onChange={onChange("jobTitle")}
                  />
                </Grid>

                <Grid item container spacing={3} direction={"column"}>
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Account Information
                    </Typography>
                  </Grid>

                  <Grid item>
                    <TextField
                      disabled={isOrganizationUser}
                      label="Phone Number"
                      value={fields.phoneNumber}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber || " "}
                      inputProps={{ maxLength: 17 }}
                      onChange={onChange("phoneNumber")}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      disabled={isOrganizationUser}
                      label="Email *"
                      value={fields.email}
                      error={!!errors.email}
                      helperText={errors.email || " "}
                      inputProps={{ maxLength: 255 }}
                      onChange={onChange("email")}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      disabled={isOrganizationUser && !isUsersProfile}
                      label="Password"
                      type="password"
                      value={fields.password}
                      error={!!errors.password}
                      helperText={errors.password || " "}
                      inputProps={{ maxLength: 255 }}
                      onChange={onChange("password")}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                spacing={3}
                xs={12}
                md={6}
                direction={"column"}
              >
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Associated Organization
                  </Typography>
                </Grid>

                <Grid item>
                  <TextField
                    disabled
                    label="Organization Name"
                    value={organization ? organization.name : ""}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              {!isOrganizationUser ||
                (isOrganizationUser && isUsersProfile && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onUpdate}
                    >
                      Update
                    </Button>
                  </Grid>
                ))}

              {!isOrganizationUser && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="danger"
                    onClick={toggleActiveStatus}
                  >
                    {user && user.isActive ? "MARK INACTIVE" : "MARK ACTIVE"}
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </MainScreen>
  );
};

export default ViewOrganizationUser;

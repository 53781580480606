export const REPORT = {
  REPORTS: "Reports",
  STUDENT_TEST_REPORT: "Student Test Result Report",
  GENERATE_REPORT: "Generate report",
  SESSION_REPORT: "MBE Qbank Session Report",
  CHOOSE_ORGANIZATION: "Choose organization",
  ALL_ORGANIZATIONS: "All organizations",
  GENERATE: "Generate",
  WARNING_INFO: "It might take some time to generate report for all organizations",
  CHOOSE_STUDENT: "Choose student",
  LAST_NAME: "Student Last Name",
  FIRST_NAME: "Student First Name",
  EMAIL: "Student Email",
  TOTAL_SESSION_TIME: "Total Session Time",
} as const

export const STUDENT_ROLE = {
  PAID: "students-paid",
  FREE: 'students-free'
} as const

export const STATUS = {
  LOADING: "loading",
  DEFAULT: ""
} as const

export const defaultErrorState = {
  show: false,
  error: null,
  title: "Error",
}

export const testsInfoDefault = {
  ["Practice Test 1"]: ['-', '-', '-'],
  ["Practice Test 2"]: ['-', '-', '-'],
  ["Practice Test 3"]: ['-', '-', '-'],
  ["Practice Test 4"]: ['-', '-', '-'],
  ["Practice Final Part 1"]: ['-', '-', '-'],
  ["Practice Final Part 2"]: ['-', '-', '-'],
  ["Practice Final Part 3"]: ['-', '-', '-'],
  ["Practice Final Part 4"]: ['-', '-', '-'],
} as const

export const studyInfoDefault = {
  ["Constitutional Law"]: ['-', '-', '-'],
  ["Contracts"]: ['-', '-', '-'],
  ["Criminal Law and Procedure"]: ['-', '-', '-'],
  ["Civil Procedure"]: ['-', '-', '-'],
  ["Evidence"]: ['-', '-', '-'],
  ["Torts"]: ['-', '-', '-'],
  ["Real Property"]: ['-', '-', '-'],
  ["Business Organization"]: ['-', '-', '-'],
  ["Secured Transactions"]: ['-', '-', '-'],
  ["Wills and Trusts"]: ['-', '-', '-'],
} as const
type TAction = {
  type: string;
  payload?:
    | {
        [key: string]: any;
      }
    | string;
  error?: boolean;
};

export const actionCreator = (type: string, payload?: any, error?: boolean) => {
  const action: TAction = {
    type: type,
  };

  if (payload !== undefined) {
    action.payload = payload;
  }

  if (error) {
    action.error = error;
  }

  return action;
};

import styled from "styled-components/macro";
import { color, fontSize, fontWeight } from "../../../../../styles/style";

export const QuestionCopy = styled.p`
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Open Sans", sans-serif;
  font-size: ${fontSize.questionCopy};
  font-weight: ${fontWeight.semiBold};
  color: ${color.dark};
  letter-spacing: 0;
  vertical-align: baseline;
  line-height: 1.5;
  margin-bottom: 1rem;
`;

QuestionCopy.propTypes = {};

export default QuestionCopy;

import styled from "styled-components/macro";
import { color, fontSize, fontWeight } from "../../../styles/style";

export const FeedbackTitle = styled.h3`
  font-family: "Open Sans", sans-serif;
  font-size: ${fontSize.feedbackTitle};
  font-weight: ${fontWeight.bold};
  line-height: 1.125;
  margin-top: 0;
  letter-spacing: 0;
  color: ${color.darkest};
`;

export default FeedbackTitle;

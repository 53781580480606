import React, { Component } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, getVal } from "react-redux-firebase";
import classNames from "classnames";
import styled from "styled-components/macro";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { ReactComponent as ArrowsLeft } from "../../../assets/icons/ArrowsLeft.svg";
import PanelGuideTopics from "../../../components/PanelGuideTopics";
import QuestionCount from "../../../elements/QuestionCount";
import { setQuestionById } from "../../../store/actions/questionActions";
import Question from "./Question";
import PreviewFooter from "./PreviewFooter";
import PreviewSidebar from "./PreviewSidebar";
import * as routes from "../../../_CONS/routes";
import { Redirect } from "react-router";
import getSectionFromUrl from "../../../utils/getSectionFromUrl";

const drawerWidth = 300;
const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    borderLeft: 0,
    boxShadow: "0 2px 7px 0 #cfdae6",
    paddingTop: "4rem",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    transition: theme.transitions.create(["all"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["all"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

export const Guide = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;

const QuestionContent = styled.div`
  padding-top: 2rem;
  padding-bottom: 4rem;
  width: 100%;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;
const QuestionArea = styled.div`
  width: 100%;
`;
const QuestionWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;
const FooterArea = styled.div`
  width: 100%;
  background: #cfdae6;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const SideBarHandle = styled.div`
  position: fixed;
  right: ${(props) => (props.isOpen ? "299px" : "0")};
  background-color: #fff;
  padding: 1.25rem 0.75rem;
  box-shadow: -5px 2px 7px -5px #cfdae6;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;
  top: 8.5rem;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1290;
  & svg {
    transform: rotate(${(props) => (props.isOpen ? "180deg" : "0")});
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
`;

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guideId: null,
      questionIndex: 0,
      questionNumber: 0,
      questionId: null,
      question: null,
      userAnswer: null,
      userNotes: null,
      answered: false,
      isSubmitted: true,
      totalQuestions: props.questions ? props.questions.length : 0,
      currentQuestion: null,
      showFeedback: true,
      isSideBarOpen: true,
      isReview: true,
      expanded: true,
    };
    this.continueToNext = this.continueToNext.bind(this);
    this.continueToPrev = this.continueToPrev.bind(this);

    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.focusRef = React.createRef();
  }

  componentDidUpdate() {
    this.focusRef.current.focus();
  }

  toggleSidebar() {
    this.setState((state) => ({
      isSideBarOpen: !state.isSideBarOpen,
    }));
  }

  continueToPrev() {
    const { questionIndex } = this.state;
    if (questionIndex === 0) {
      this.endStudyGuide();
    } else {
      this.loadQuestion(questionIndex - 1);
    }
  }

  continueToNext() {
    const { questions } = this.props;
    const { questionIndex } = this.state;

    const finalIndex = questions.length - 1;
    const nextQuestionIndex = questionIndex + 1;
    if (nextQuestionIndex > finalIndex) {
      this.endStudyGuide();
    } else {
      this.loadQuestion(nextQuestionIndex);
    }
  }

  loadQuestion(qindex) {
    const { questions } = this.props;
    const qid = questions[qindex];
    this.props.setQuestionById(qid).then(() => {
      this.setState({
        phase: "live",
        questionIndex: qindex,
      });
    });
  }

  endStudyGuide() {
    this.props.history.goBack();
  }

  captureKeyDown(e) {
    const keypressed = e.key;
    const keyval = keypressed.toUpperCase();
    switch (keyval) {
      case "ARROWRIGHT":
        this.continueToNext();
        break;
      case "ARROWLEFT":
        this.continueToPrev();
        break;
      case "ENTER":
        this.continueToNext();
        break;
      default:
    }
  }

  render() {
    const { currentQuestion, qid, classes, section, questions, match } =
      this.props;

    const { questionIndex, isSideBarOpen } = this.state;
    const questionNumber = questionIndex + 1;

    if (!questions) {
      return (
        <Redirect to={`${routes.ADMIN}/${getSectionFromUrl(match.path)}`} />
      );
    }

    return (
      <Guide>
        <>
          <QuestionContent
            ref={this.focusRef}
            tabIndex={-1}
            onKeyDown={(event) => this.captureKeyDown(event)}
            className={classNames(classes.content, {
              [classes.contentShift]: isSideBarOpen,
            })}
          >
            <QuestionArea>
              <SideBarHandle
                isOpen={isSideBarOpen}
                onClick={this.toggleSidebar}
              >
                <ArrowsLeft />
              </SideBarHandle>
              <QuestionWrapper>
                {currentQuestion && (
                  <Question content={currentQuestion} qnum={questionNumber} />
                )}
              </QuestionWrapper>
            </QuestionArea>
            <FooterArea
              className={classNames(classes.content, {
                [classes.contentShift]: isSideBarOpen,
              })}
            >
              <PreviewFooter
                isDraft={!!currentQuestion && !currentQuestion.published}
                section={section}
                view="preview"
                qid={qid}
                questions={questions}
                questionNumber={questionNumber}
                postGuideAction={this.continueToPrev}
                nextQuestion={this.continueToNext}
              />
            </FooterArea>
          </QuestionContent>

          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={isSideBarOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <QuestionCount
              totalQuestions={questions.length}
              currentQuestionNumber={questionNumber}
            />

            <PreviewSidebar key={qid}>
              {currentQuestion && <PanelGuideTopics qObj={currentQuestion} />}
            </PreviewSidebar>
          </Drawer>
        </>
      </Guide>
    );
  }
}

Preview.propTypes = {};

const mapStateToProps = (state) => ({
  qpath: `${state.questionStatus.level}/${state.questionStatus.qbank}/${state.questionStatus.qid}`,
  qbank: state.questionStatus.qbank,
  section: state.questionStatus.section,
  level: state.questionStatus.level,
  questions: state.questionStatus.questions,
  status: state.questionStatus.status,
  qid: state.questionStatus.qid,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setQuestionById: (qid) => dispatch(setQuestionById(qid)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect((props) => {
    if (!props.qpath) return [];
    return [
      {
        path: get(props, "qpath"),
      },
    ];
  }),
  connect(({ firebase }, props) => ({
    currentQuestion: getVal(firebase, `data/${props.qpath}`), // lodash's get can also be used
  })),
  withStyles(styles)
)(Preview);

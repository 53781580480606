import { TGuide, TGuideQuestion, TStudent } from "../../../types";
import { actionCreator } from "../../utils/actionCreator";
import { getTopicsFromClassification } from "../../utils/getTopicsFromClassification";
import { showAlert } from "./alertActions";
import { getAsyncActions } from "../../utils/getAsyncActions";
import firebase from "../api/firebaseAdmin";
import { calculateStudentTotals, getTotalThemes, mergeTotals } from "../../utils/getTotalThemes";
import { TAsyncAction, TDispatch, TStatus } from "../../types/TYPES";
import { updateStudent } from "./studentActions";
import useUser from "./../../hooks/useUser";

type TPauseGuideObject = {
  questionIndex: number;
  paused: boolean;
  pausedDate: Date;
  duration: number;
  questions: any[];
  questionsLeft: number;
  showQuestionNavigator: boolean;
  showTimer: boolean;
  showNotes: boolean;
};

type TEndGuideObject = {
  questionIndex: number;
  duration: number;
  questions: any[];
  questionsLeft: number;
  completed: boolean;
  completedDate: Date;
  questionsTotal: number;
  questionsCorrect: number;
  questionsWrong: number;
};

type TReviewGuide = {
  status: "loading" | "success" | "failure" | "";
  guideId: string;
  guideType: string;
  queries: any[];
};

export type TUpdateGuide = {
  uid: string;
  type: "test" | "study";
  [key: string]: any;
};

type TGuideActions = {
  FINISH: TAsyncAction;
  BUILD: string;
  REVIEW: string;
  UPDATE: TAsyncAction;
  VIEW: string;
  CLEAR: string;
  DELETE: TAsyncAction;
  RESET: TAsyncAction;
  RESET_STATUS: string;
};

export const GuideActions: TGuideActions = {
  BUILD: "guide/built",
  REVIEW: "guide/review",
  VIEW: "guide/view",
  CLEAR: "guide/cleared",
  RESET_STATUS: "guide/resetStatus",
  ...getAsyncActions({
    FINISH: "guide/finish",
    DELETE: "guide/deleted",
    UPDATE: "guide/updated",
    RESET: "guide/reset",
  }),
} as TGuideActions;

const updateTimeSpend = async (guideId: string, guideType: string, uObj: any, getState: any) => {
  const firestore = firebase.firestore();
  const authId = getState().firebase.auth.uid;
  const usertRef = firestore.collection("users").doc(authId);
  const levelQbank = getState().firebase.profile.studentBank;
  const studentRef = firestore.collection(levelQbank).doc(authId);
  const duration = uObj.duration || 0;

  const timings = (await getState().firebase.profile.timings) || null;
  const userInfo = await studentRef
    .collection(guideType)
    .doc(guideId)
    .get()
    .then(data => data.data());

  if (!timings) {
    usertRef.set({ timings: { [`${guideType}`]: { [`${userInfo.name}`]: 0 } } }, { merge: true });

    return;
  }

  timings[`${guideType}`] = { [`${userInfo.name}`]: duration };
  usertRef.set({ timings }, { merge: true });
};

export const updateGuide = (guide: TUpdateGuide) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(GuideActions.UPDATE.REQUESTED));

    const { uid, type, ...guideData } = guide;

    const firestore = firebase.firestore();
    const studentId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;

    const guideRef = firestore
      .collection(levelQbank)
      .doc(studentId)
      .collection(type + "guides")
      .doc(uid);

    updateTimeSpend(uid, type + "guides", guideData, getState);

    return guideRef.update(guideData).then(() => {
      dispatch(actionCreator(GuideActions.UPDATE.SUCCESS));
    });
  };
};

export const resetGuide = (guide: TGuide) => {
  return (dispatch: TDispatch, getState): Promise<void> => {
    const firestore = firebase.firestore();
    const studentId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;
    const questions = guide.questions.map(question => ({
      ...question,
      answered: false,
      userAnswer: null,
      userNotes: null,
      isCorrect: false,
    }));

    const guideRef = firestore
      .collection(levelQbank)
      .doc(studentId)
      .collection(guide.type + "guides")
      .doc(guide.uid);

    return guideRef.update({
      started: false,
      showTimer: false,
      duration: 0,
      questionIndex: 0,
      time: -1,
      customTime: 0,
      questionsCorrect: 0,
      questionsWrong: 0,
      questions,
    });
  };
};

export const resetGuideById = (guideId: string, guideType: "study" | "test") => {
  return async (dispatch: TDispatch, getState): Promise<void> => {
    if (guideType !== "study") return;

    dispatch(actionCreator(GuideActions.RESET.REQUESTED));

    const firestore = firebase.firestore();
    const studentId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;
    let guide = null;
    const batch = firestore.batch();

    const guideRef = firestore
      .collection(levelQbank)
      .doc(studentId)
      .collection(guideType + "guides")
      .doc(guideId);

    const questionsCollection = firestore.collection(levelQbank).doc(studentId).collection("sgquestions");

    try {
      guide = (await guideRef.get()).data();
    } catch (e) {
      dispatch(actionCreator(GuideActions.RESET.FAILURE, "The guide has not been reset", true));

      dispatch(
        showAlert({
          title: "Error",
          message: "The guide has not been reset",
        })
      );
    }

    const questions = guide.questions.map(question => ({
      ...question,
      answered: false,
      userAnswer: null,
      userNotes: null,
      isCorrect: false,
    }));

    guide.questions.forEach(({ qid }) => {
      batch.update(questionsCollection.doc(qid), {
        answered: false,
        userAnswer: "",
      });
    });

    batch.update(guideRef, {
      completed: false,
      started: false,
      showTimer: false,
      duration: 0,
      questionIndex: 0,
      time: -1,
      customTime: 0,
      questionsCorrect: 0,
      questionsWrong: 0,
      questions,
    });

    return batch
      .commit()
      .then(data => {
        dispatch(actionCreator(GuideActions.RESET.SUCCESS));

        dispatch(
          showAlert({
            title: "Success",
            message: "The guide has been reset.",
          })
        );
      })
      .catch(() => {
        dispatch(actionCreator(GuideActions.RESET.FAILURE, "The guide has not been reset.", true));

        dispatch(
          showAlert({
            title: "Error",
            message: "The guide has not been reset.",
          })
        );
      });
  };
};

export const resetTopicQuestions = (guideId: string, subjectCode: string, topicCode: string) => {
  return async (dispatch, getState): Promise<void> => {
    dispatch(actionCreator(GuideActions.RESET.REQUESTED, { status: "loading" }));

    const firestore = firebase.firestore();
    const studentId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;
    let guide = null;
    const batch = firestore.batch();

    const guideRef = firestore.collection(levelQbank).doc(studentId).collection("studyguides").doc(guideId);

    const questionsCollection = firestore.collection(levelQbank).doc(studentId).collection("sgquestions");

    try {
      guide = (await guideRef.get()).data();
    } catch (e) {
      showAlert({
        title: "Error",
        message: "Can't find the Study Aid.",
      });

      return dispatch(actionCreator(GuideActions.RESET.FAILURE, "Can't find the Study Aid.", true));
    }

    const questionsToReset = guide.questions.reduce((result, question) => {
      if (question.subjectCode === subjectCode) {
        const topics = getTopicsFromClassification(question.classification);

        if (topics.includes(topicCode)) {
          result[question.qid] = question;

          return result;
        }
      }

      return result;
    }, {});

    const questions = guide.questions.map(question => {
      if (questionsToReset[question.qid]) {
        return {
          ...question,
          answered: false,
          userAnswer: null,
          userNotes: null,
          isCorrect: false,
        };
      }

      return question;
    });

    guide.questions.forEach(question => {
      if (questionsToReset[question.qid]) {
        batch.update(questionsCollection.doc(question.qid), {
          answered: false,
          userAnswer: "",
        });
      }
    });

    type QuestionStatusCount = { correct: number; wrong: number };

    const questionsToResetStatus: QuestionStatusCount = Object.values(questionsToReset).reduce(
      (result: QuestionStatusCount, question: any) => {
        question.isCorrect ? result.correct++ : result.wrong++;
        return result;
      },
      {
        correct: 0,
        wrong: 0,
      }
    ) as QuestionStatusCount;

    batch.update(guideRef, {
      completed: false,
      started: false,
      showTimer: false,
      duration: 0,
      questionIndex: 0,
      time: -1,
      customTime: 0,
      questionsCorrect: guide.questionsCorrect - questionsToResetStatus.correct,
      questionsWrong: guide.questionsWrong - questionsToResetStatus.wrong,
      questions,
    });

    return batch
      .commit()
      .then(data => {
        dispatch(actionCreator(GuideActions.RESET.SUCCESS, { status: "" }));
      })
      .catch(() => {
        showAlert({
          title: "Error",
          message: "Connection error happened",
        });

        dispatch(actionCreator(GuideActions.RESET.FAILURE, "Connection error happened", true));
      });
  };
};

export const getOrganization = (id: string) => async (dispatch: TDispatch) => {
  try {
    return await firebase
      .firestore()
      .collection("organizations")
      .doc(id.trim())
      .get()
      .then(doc => doc.data());
  } catch (error) {
    dispatch(
      showAlert({
        title: "Error",
        message: "The guide has not been reset.",
      })
    );
    throw error;
  }
};

export const openGuide = (guide: { guideType: string; guideId: string; status: TStatus }) => dispatch => {
  dispatch({
    type: GuideActions.VIEW,
    payload: {
      status: guide.status,
      guideType: guide.guideType,
      guideId: guide.guideId,
    },
  });

  return Promise.resolve();
};

export const pauseGuide = (guideId: string, guideType: string, uObj: TPauseGuideObject) => {
  return async (dispatch: any, getState) => {
    const firestore = firebase.firestore();
    const authId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;
    const studentRef = firestore.collection(levelQbank).doc(authId);

    await updateTimeSpend(guideId, guideType, uObj, getState);

    return studentRef.collection(guideType).doc(guideId).update(uObj);
  };
};

export const updateGuideQuestionList = (guideId: string, guideType: string, questions: any[]) => {
  return async (dispatch: any, getState) => {
    const firestore = firebase.firestore();
    const authId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;
    const studentRef = firestore.collection(levelQbank).doc(authId);

    try {
      await studentRef.collection(guideType).doc(guideId).update({ questions });

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
};

export const endGuide = (guideId: string, guideType: string, uObj: TEndGuideObject) => {
  return async (dispatch: TDispatch, getState) => {
    dispatch(actionCreator(GuideActions.FINISH.REQUESTED));

    const firestore = firebase.firestore();
    const authId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;
    const { questions } = uObj;

    const studentRef = firestore.collection(levelQbank).doc(authId);
    try {
      await studentRef.collection(guideType).doc(guideId).update(uObj);
      await dispatch(_updateQuestionPool(studentRef, guideType, questions));
      await dispatch(_addTotalsToUser(authId, guideType, uObj));
      await updateTimeSpend(guideId, guideType, uObj, getState);
      dispatch(actionCreator(GuideActions.FINISH.SUCCESS));

      await dispatch(_calculateStudentTestResult(authId, levelQbank));
      return true;
    } catch (error) {
      dispatch(
        actionCreator(GuideActions.FINISH.FAILURE, error.message || "Error happened while saving your results", true)
      );

      return false;
    }
  };
};

function _calculateStudentTestResult(studentId: string, studentLevel: string) {
  return async () => {
    try {
      const studentStudyThemes = await getTotalThemes(studentLevel);
      let studentReport = null;

      const db = firebase.firestore();
      const studyGuide = db.collection(studentLevel).doc(studentId).collection("sgquestions").get();

      studyGuide
        .then(snapshot => {
          studentReport = calculateStudentTotals(snapshot.docs);
        })
        .then(() => {
          const reportResult = mergeTotals(studentStudyThemes, studentReport);
          db.collection("users").doc(studentId).update({ report: reportResult });
        });
    } catch (error) {
      throw new Error("Error happened while saving your results");
    }
  };
}

function _updateQuestionPool(studentRef, guideType: string, qlist: any[]) {
  return async () => {
    const collection = guideType === "testguides" ? "tgquestions" : "sgquestions";

    const firestore = firebase.firestore();
    const batch = firestore.batch();
    const qColRef = studentRef.collection(collection);
    qlist.forEach(q => {
      const docRef = qColRef.doc(q.qid);
      batch.update(docRef, {
        ...q,
        answered: true,
        completedDate: new Date(),
      });
    });
    await batch.commit();
  };
}

function _addTotalsToUser(
  userId: string,
  guideType: string,
  totals: {
    questionsTotal: number;
    questionsCorrect: number;
    questionsWrong: number;
  }
) {
  return () => {
    const fstore = firebase.firestore();
    const userDocRef = fstore.collection("users").doc(userId);
    fstore.runTransaction(async function (transaction) {
      try {
        const userDoc = await transaction.get(userDocRef);
        if (!userDoc.exists) {
          throw new Error("Document does not exist!");
        }
        const { questionsTotal, questionsCorrect, questionsWrong } = totals;
        const qTotal = Number(questionsTotal);
        const qCorrect = Number(questionsCorrect);
        const qWrong = Number(questionsWrong);
        let currentTotal = {
          total: 0,
          correct: 0,
          wrong: 0,
        };
        if (userDoc.get("all") != null) {
          currentTotal = userDoc.get("all");
        }
        const allTotal = currentTotal.total + qTotal; //Number(questionsTotal);
        const allCorrect = currentTotal.correct + qCorrect; //Number(questionsCorrect);
        const allWrong = currentTotal.wrong + qWrong; // Number(questionsWrong);
        const all = {
          total: allTotal,
          correct: allCorrect,
          wrong: allWrong,
        };
        if (guideType === "studyguides") {
          let currentStudy = {
            total: 0,
            correct: 0,
            wrong: 0,
          };
          if (userDoc.get("study") != null) {
            currentStudy = userDoc.get("study");
          }
          const studyTotal = currentStudy.total + qTotal; // Number(questionsTotal);
          const studyCorrect = currentStudy.correct + qCorrect; //Number(questionsCorrect);
          const studyWrong = currentStudy.wrong + qWrong; // Number(questionsWrong);
          const study = {
            total: studyTotal,
            correct: studyCorrect,
            wrong: studyWrong,
          };
          // Commit to Firestore
          transaction.update(userDocRef, {
            all,
            study,
          });
        } else if (guideType === "testguides") {
          let currentTest = {
            total: 0,
            correct: 0,
            wrong: 0,
          };
          if (userDoc.get("test") != null) {
            currentTest = userDoc.get("test");
          }

          const testTotal = currentTest.total + qTotal; //Number(questionsTotal);
          const testCorrect = currentTest.correct + qCorrect; // questionsCorrect;
          const testWrong = currentTest.wrong + qWrong; // questionsWrong;
          const test = {
            total: testTotal,
            correct: testCorrect,
            wrong: testWrong,
          };
          // Commit to Firestore
          transaction.update(userDocRef, {
            all,
            test,
          });
        } else {
          throw new Error("no guideType!");
          // throw 'no guideType!';
        }
      } catch (err) {
        console.log(`err=${err}`);
      }
    });
  };
}

export const clearGuide = () => ({
  type: GuideActions.CLEAR,
});

export const reviewGuide = (guideObj: TReviewGuide) => {
  return (dispatch: any) => {
    const { status, guideType, queries, guideId } = guideObj;

    dispatch({
      type: GuideActions.REVIEW,
      payload: {
        status,
        guideId,
        guideType,
        queries,
      },
    });
    return Promise.resolve();
  };
};

export const fetchQuestionById = (qid: string, guideColl: string) => {
  return (dispatch: TDispatch, getState) => {
    const firestore = firebase.firestore();
    const authId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;

    return firestore.collection(levelQbank).doc(authId).collection(guideColl).doc(qid).get();
  };
};

export const updateGuideQuestion = (qid: string, guideColl: string, uObj: Partial<TGuideQuestion>) => {
  return (dispatch: TDispatch, getState) => {
    const firestore = firebase.firestore();
    const authId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;

    return firestore.collection(levelQbank).doc(authId).collection(guideColl).doc(qid).update(uObj);
  };
};

export const deleteStudyGuide = (studentId: string, guideId: string, studyBank: string) => {
  return async (dispatch: TDispatch) => {
    dispatch(actionCreator(GuideActions.DELETE.REQUESTED));

    try {
      const { data } = await firebase.functions().httpsCallable("deleteStudyGuide")({
        studentId,
        guideId,
        studyBank,
      });

      if (data.success) {
        dispatch(
          showAlert({
            title: "Success",
            message: "The guide has been reset.",
          })
        );

        dispatch(actionCreator(GuideActions.DELETE.SUCCESS));
        await dispatch(_calculateStudentTestResult(studentId, studyBank));
      } else {
        dispatch(
          showAlert({
            title: "Error",
            message: "The guide has not been reset.",
          })
        );

        dispatch(actionCreator(GuideActions.DELETE.FAILURE, data.error, true));
      }
    } catch (error) {
      console.log(error);
      dispatch(actionCreator(GuideActions.DELETE.FAILURE, error.message || JSON.stringify(error), true));
    }
  };
};

export const deleteTopicQuestions = (
  studentId: string,
  guideId: string,
  studyBank: string,
  subjectCode: string,
  topicCode: string
) => {
  return async dispatch => {
    dispatch(actionCreator(GuideActions.DELETE.REQUESTED, { status: "loading" }));

    try {
      const { data } = await firebase.functions().httpsCallable("deleteTopicQuestionsStudyGuide")({
        studentId,
        guideId,
        studyBank,
        subjectCode,
        topicCode,
      });

      if (data.success) {
        dispatch(
          showAlert({
            title: "Success",
            message: "The Topic successfully deleted.",
          })
        );

        dispatch(actionCreator(GuideActions.DELETE.SUCCESS, { status: "success" }));
        await dispatch(_calculateStudentTestResult(studentId, studyBank));
      } else {
        dispatch(
          showAlert({
            title: "Error",
            message: "The Topic was not deleted.",
          })
        );

        dispatch(actionCreator(GuideActions.DELETE.FAILURE, data.error, true));
      }
    } catch (error) {
      console.log(error);

      dispatch(
        showAlert({
          title: "Error",
          message: "The Study Aid was not deleted.",
        })
      );
      dispatch(actionCreator(GuideActions.DELETE.FAILURE, error.message || JSON.stringify(error), true));
    }
  };
};

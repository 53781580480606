import { get } from "lodash";
import { actionCreator } from "../../utils/actionCreator";
import { getMessageFromError } from "../../utils/firebaseErrorMessage";
import firebase from "../api/firebaseAdmin";
import { RootState } from "../createReduxStore";
import { TDispatch, TNewUser, TPaymentData, TUser } from "../../types/TYPES";
import { showAlert } from "./alertActions";
import { UserActions } from "./studentActions";

export const resetUserView = () => ({
  type: UserActions.RESET,
});

export const removeUser = (uid: string) => {
  return async (dispatch: TDispatch) => {
    dispatch(actionCreator(UserActions.DELETE.REQUESTED));

    try {
      const removeStudent = firebase.functions().httpsCallable("removeStudent");
      const { data } = await removeStudent(uid);

      if (data.success) {
        dispatch(
          showAlert({
            title: "Success",
            message: "The User has been successfully deleted.",
          })
        );

        dispatch(actionCreator(UserActions.DELETE.SUCCESS));
      } else {
        throw new Error("The User has not been deleted.");
      }
    } catch (error) {
      dispatch(
        showAlert({
          title: "Error",
          message: "The User has not been deleted.",
        })
      );
      dispatch(
        actionCreator(
          UserActions.DELETE.FAILURE,
          error.message || JSON.stringify(error),
          true
        )
      );
    }
  };
};

export const createNewAdmin = (newUser: TNewUser) => {
  return async (dispatch: TDispatch) => {
    const addUserToAdmin = firebase.functions().httpsCallable("addUserToAdmin");
    dispatch(actionCreator(UserActions.CREATE.REQUESTED));

    addUserToAdmin(newUser)
      .then(function (result) {
        const { data } = result;
        const isSuccess = data.success;

        if (!isSuccess) {
          const errorMessage =
            get(data, "error.errorInfo.message") ||
            "The User has not been created.";

          throw new Error(errorMessage);
        } else {
          dispatch(actionCreator(UserActions.CREATE.SUCCESS));

          dispatch(
            showAlert({
              title: "Success",
              message: "The User has been successfully created.",
            })
          );

          if (!newUser.organization) {
            return dispatch(_sendOutNewAdminEmail(newUser.email));
          }
        }
      })
      .catch((error) => {
        const errPayload = error.message || JSON.stringify(error);

        dispatch(actionCreator(UserActions.CREATE.FAILURE, errPayload, true));

        dispatch(
          showAlert({
            title: "Error",
            message: errPayload,
          })
        );
      });
  };
};

export const _sendOutNewAdminEmail = (email: string) => {
  return (dispatch: TDispatch) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .catch((err) => {
        dispatch(
          actionCreator(
            UserActions.SEND_EMAIL.FAILURE,
            getMessageFromError(err),
            true
          )
        );
      });
  };
};

export const processUpgrade = (paydata: TPaymentData) => {
  return async (dispatch: TDispatch, getState: () => RootState) => {
    dispatch(actionCreator(UserActions.UPGRADE.REQUESTED));

    const authId = getState().firebase.auth.uid;
    const processCustomerPayment = firebase
      .functions()
      .httpsCallable("processCustomerPayment");

    try {
      const result = await processCustomerPayment({ ...paydata, uid: authId });

      if (result.data.success) {
        dispatch(actionCreator(UserActions.UPGRADE.SUCCESS));

        dispatch(
          showAlert({
            title: "Success",
            message: "The Student has been successfully upgraded.",
          })
        );
      } else {
        throw new Error("The Student has not been upgraded.");
      }
    } catch (error) {
      dispatch(
        showAlert({
          title: "Error",
          message: "The Student has not been upgraded.",
        })
      );

      return dispatch(
        actionCreator(
          UserActions.UPGRADE.FAILURE,
          "The Student has not been upgraded.",
          true
        )
      );
    }
  };
};

export const updateAdmin = (uid: string, data: Partial<TUser>) => {
  return async (dispatch: TDispatch) => {
    dispatch(actionCreator(UserActions.UPDATE.REQUESTED));
    const updateAdmin = firebase.functions().httpsCallable("updateAdmin");

    try {
      await updateAdmin({ uid, ...data });

      dispatch(actionCreator(UserActions.UPDATE.SUCCESS));
      dispatch(
        showAlert({
          title: "Success",
          message: "The User has been successfully update.",
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        actionCreator(
          UserActions.UPDATE.FAILURE,
          error.message || JSON.stringify(error),
          true
        )
      );
      dispatch(
        showAlert({
          title: "Success",
          message: "The User has not been updated.",
        })
      );
    }
  };
};

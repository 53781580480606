import Input from "@material-ui/core/Input";
import { color, fontWeight } from "../../styles/style";
import { Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import theme from "../../styles/theme";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const CheckIcon = styled(CheckCircleIcon)`
  display: inline-block;
  margin-right: 0.5rem;
  color: ${color.greenDark};
`;

export const ReadOnlyInput = styled(Input)`
  &:before {
    border-bottom: 0;
  }
`;

export const EmailDate = styled(Typography)`
  display: flex;
`;

export const AssociatedOrganization = styled(Typography)`
  color: ${color.secondaryDark};
  font-weight: ${fontWeight.semiBold};
  margin-bottom: 1rem;
`;

export const ReadOnlyValue = styled.span`
  display: inline-block;
  margin-top: ${theme.spacing(2)}px;
  font-size: 1rem;
`;

export const ResendEmailWrapper = styled.div`
  margin-bottom: ${theme.spacing(1)};
  margin-top: ${theme.spacing(3)};
  position: relative;
`;

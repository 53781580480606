import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import IconEdit from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import {
  Card,
  CardContent,
  CardDirty,
  CardHeader,
} from "../../containers/Card";
import Button from "../../elements/Button";
import { color, fontWeight } from "../../styles/style";
import { updateQuestion } from "../../store/actions/questionActions";
import { ToggleBtn, ToggleBtnGroup } from "../../components/ToggleBtn";

const styles = () => ({
  card: {
    display: "flex",
    width: "100%",
  },
  content: {
    paddingTop: 0,
    fontSize: 14,
    fontWeight: fontWeight.semiBold,
    color: color.blueDarkest,
  },
  groupContent: {
    backgroundColor: color.grey,
  },
  questionCopy: {
    fontSize: 14,
    lineHeight: "initial",
    fontWeight: fontWeight.regular,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

class CardFieldEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      isDirty: false,
      oldContent: props.content,
      newContent: props.content,
    };
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.saveFieldValue = this.saveFieldValue.bind(this);
    this.cancelFieldChange = this.cancelFieldChange.bind(this);
  }

  toggleEditMode() {
    this.setState((state) => ({
      editMode: !state.editMode,
    }));
  }

  cancelFieldChange() {
    const { oldContent } = this.state;
    this.setState({ newContent: oldContent, editMode: false });
  }

  saveFieldValue() {
    const { newContent } = this.state;
    const { qid, level, qbank } = this.props;
    const uobj = {
      correctAnswer: newContent,
    };
    this.props.updateQuestion(level, qbank, qid, uobj).then(() => {
      this.setState({
        editMode: false,
        newContent: newContent,
        oldContent: newContent,
      });
      this.props.cardAction(newContent);
    });
  }

  handleSelectChange = (event, correctAnswer) => {
    this.setState({ newContent: correctAnswer });
  };

  render() {
    const { classes, label } = this.props;
    const { editMode, newContent, oldContent } = this.state;
    return (
      <Grid item xs={12}>
        {editMode ? (
          <Card>
            <CardDirty isDirty={newContent !== oldContent}>
              <CardHeader
                action={
                  <IconButton
                    onClick={this.toggleEditMode}
                    disabled={newContent !== oldContent}
                  >
                    <IconEdit />
                  </IconButton>
                }
                title={label}
              />

              <CardContent className={classes.content}>
                <ToggleBtnGroup
                  className={classes.groupContent}
                  value={newContent}
                  exclusive
                  onChange={this.handleSelectChange}
                >
                  <ToggleBtn selected={newContent === "A"} value="A">
                    A
                  </ToggleBtn>
                  <ToggleBtn selected={newContent === "B"} value="B">
                    B
                  </ToggleBtn>
                  <ToggleBtn selected={newContent === "C"} value="C">
                    C
                  </ToggleBtn>
                  <ToggleBtn selected={newContent === "D"} value="D">
                    D
                  </ToggleBtn>
                </ToggleBtnGroup>
              </CardContent>
              <CardContent className={classes.actions}>
                <Button
                  color="default"
                  variant="text"
                  onClick={this.cancelFieldChange}
                >
                  CANCEL
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  onClick={this.saveFieldValue}
                  disabled={newContent === oldContent}
                >
                  SAVE
                </Button>
              </CardContent>
            </CardDirty>
          </Card>
        ) : (
          <Card>
            <CardHeader
              action={
                <IconButton onClick={this.toggleEditMode}>
                  <IconEdit />
                </IconButton>
              }
              title={label}
            />
            <ToggleBtnGroup value={newContent} exclusive>
              <ToggleBtn disabled selected={true} value={newContent}>
                {newContent}
              </ToggleBtn>
            </ToggleBtnGroup>
          </Card>
        )}
      </Grid>
    );
  }
}

CardFieldEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    qbank: state.questionStatus.qbank,
    level: state.questionStatus.level,
    status: state.questionStatus.status,
    qid: state.questionStatus.qid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuestion: (level, qbank, qid, uObj) =>
      dispatch(updateQuestion(level, qbank, qid, uObj)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CardFieldEdit);

import React, { Component } from "react";
import { connect } from "react-redux";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import styled from "styled-components/macro";
import Divider from "@material-ui/core/Divider";

import { color } from "../../../styles/style";
import { Card } from "../../../containers/Card";
import { MainScreen } from "../../../containers/Main";
import AvatarCard from "../../../components/AvatarCard";
import PromoSection from "../../../promos/PromoSection";
import BillingScreen from "../../../screens/BillingScreen";
import {
  sendVerificationEmail,
  signOut,
} from "../../../store/actions/authActions";
import ProfileDetails from "../../../components/ProfileDetails";
import { updateStudent } from "../../../store/actions/studentActions";

const ExtLink = ({ className, children, url }) => (
  <a className={className} href={url} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const BottomLinksContent = styled.div`
  white-space: pre;
  display: flex;
  padding: 16px;
  color: ${color.blueDarkest};
  font-size: 12px;
  > a {
    color: ${color.blueDarkest};
    font-size: 12px;
    text-decoration: underline;
  }
`;

function BottomLinks() {
  return (
    <BottomLinksContent>
      {`Read our `}
      <ExtLink url="https://www.jdadvising.com/terms-of-use-app/">
        Terms of Use
      </ExtLink>
      {` and `}
      <ExtLink url="https://www.jdadvising.com/terms-of-use-app/">
        Privacy Policy
      </ExtLink>
      {`.`}
    </BottomLinksContent>
  );
}

class Profile extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  updateStudent = (sobj) => {
    const studentObj = {
      ...sobj,
      uid: this.props.userId,
    };

    this.props.updateStudent(studentObj);
  };

  render() {
    const { user, userId, accountType } = this.props;
    const { value } = this.state;

    return (
      <MainScreen title="Profile">
        <Grid item xs={12} sm={4}>
          <Grid container spacing={3} direction={"column"}>
            <Grid item>
              <AvatarCard user={user} />
            </Grid>
            <Grid item>
              <PromoSection isCol={true} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Card>
            <Tabs value={value} onChange={this.handleChange}>
              <Tab label="Settings" />
              {user.upgradedDate && accountType === "pro" && (
                <Tab label={"BILLING"} />
              )}
            </Tabs>
            {value === 0 && (
              <ProfileDetails
                student={{
                  ...user,
                  uid: userId,
                }}
                adminView={this.props.isAdmin}
                actionUpdate={this.updateStudent}
              />
            )}
            {value === 1 && accountType === "pro" && (
              <BillingScreen user={user} />
            )}
            <Divider />
            <BottomLinks />
          </Card>
        </Grid>
      </MainScreen>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.firebase.profile,
  userId: state.firebase.auth.uid,
  accountType: state.firebase.profile.accountType,
  isVerified: state.firebase.auth.emailVerified,
  isAdmin: state.firebase.profile.token.claims.role === "admin",
});

const mapDispatchToProps = {
  signOut,
  sendVerificationEmail,
  updateStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import MomentUtils from "@date-io/moment";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  mapper,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from "react-redux-firebase";
import GenericTable from "../../../../components/GenericTable";
import LoadingModal from "../../../../components/LoadingModal";
import Button from "../../../../elements/Button";
import {
  addOffer,
  setProAccount,
  updateOffer,
} from "../../../../store/actions/organizationsActions";
import { RootState } from "../../../../store/createReduxStore";
import {
  validate,
  validatePercentage,
  validateRequired,
} from "../../../../utils/validator";
import moment from "moment";
import { Link } from "./StyledOffers";
import firebase from "firebase/app";
import { TOrganization } from "../../../../../types";
import {TOffer} from "../../../../types/TYPES";

type TOffers = {
  organizationId: string;
};

type TFields = {
  name: string;
  code: string;
  startDate: Date | null;
  endDate: Date | null;
  discount: string;
};

const DiscountOfferWrapper = styled.div`
  border-bottom: 1px solid black;
  padding: 10px;
  margin-bottom: 30px;
`;

const Offers: React.FC<TOffers> = ({ organizationId, ...props }: TOffers) => {
  const dispatch = useDispatch();

  const isOrganizationUser = !!useSelector(
    (state: RootState) => state.firebase.profile.token.claims.organization
  );
  const [organization, setOrganization] = useState<TOrganization | null>(null);
  const status = useSelector(
    (state: RootState) => state.organizationsReducer.status
  );
  const offers =
    useSelector(
      (state: RootState) => state.firestore.ordered.organizationOffers
    ) || [];
  const [editOffer, setEditOffer] = useState<TOffer | null>(null);

  const [fields, setFields] = useState<TFields>({
    name: "",
    code: "",
    startDate: null,
    endDate: null,
    discount: "0",
  });
  const [errors, setErrors] = useState<Record<string, string>>({
    name: "",
    code: "",
    startDate: "",
    endDate: "",
    discount: "",
  });

  const toConnect:
    | string
    | string[]
    | ReduxFirestoreQuerySetting
    | ReduxFirestoreQuerySetting[]
    | mapper<unknown, (string | ReduxFirestoreQuerySetting)[]> = organizationId
    ? [
        {
          collection: "offers",
          where: ["organizationId", "==", organizationId],
          storeAs: "organizationOffers",
        },
      ]
    : [];

  useFirestoreConnect(toConnect);

  const getOrganization = async () => {
    return await firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .get()
      .then((doc) => doc.data());
  };

  useEffect(() => {
    getOrganization().then(setOrganization);
  }, []);

  useEffect(() => {
    if (editOffer) {
      const offer = offers.find((offer) => offer.id === editOffer.id);

      if (offer) {
        setEditOffer(offer);
      }
    } else if (isOrganizationUser && offers.length) {
      const offer = offers.find((offer) => offer.isActive);

      setFields({
        name: offer.name,
        code: offer.code,
        startDate: new Date(offer.startDate.seconds * 1000),
        endDate: offer.endDate.seconds
          ? new Date(offer.endDate.seconds * 1000)
          : null,
        discount: offer.discount.toString(),
      });
    }
  }, [offers, isOrganizationUser]);

  const onChange = (field: string) => (e) => {
    if (!isOrganizationUser) {
      let val = e;

      if (field === "startDate" || field === "endDate") {
        val = e;
      } else {
        val = e.target.value;
      }

      if (field === "code") {
        val = e.target.value.toUpperCase();
      }

      if (field === "discount") {
        val = e.target.value;

        if (val === fields.discount) {
          val = val.substr(0, val.length - 1);
        }

        if (val.length > 1) {
          val = val.replace(/^0+/, "");
        }

        val = val.replace(/\D/g, "");

        if (+val >= 100) {
          val = "100";
        }
      }

      setFields({
        ...fields,
        [field]: val,
      });
    }
  };

  const validateFields = () => {
    const errors = validate(fields, {
      name: [validateRequired],
      code: [validateRequired],
      startDate: [validateRequired],
      discount: [validatePercentage],
    });

    setErrors(errors);

    return !Object.keys(errors).length;
  };

  const onEdit = (id: string) => () => {
    const offer = offers.find((offer) => offer.id === id);

    if (offer) {
      setEditOffer(offer);
      setFields({
        name: offer.name,
        code: offer.code,
        startDate: new Date(offer.startDate.seconds * 1000),
        endDate: offer.endDate.seconds
          ? new Date(offer.endDate.seconds * 1000)
          : null,
        discount: offer.discount.toString(),
      });

      setErrors({});
    }
  };

  const onCancelEdit = () => {
    setEditOffer(null);
    setFields({
      name: "",
      code: "",
      startDate: null,
      endDate: null,
      discount: "",
    });

    setErrors({});
  };

  const onAddOffer = () => {
    if (validateFields()) {
      dispatch(
        addOffer({
          ...fields,
          organizationId,
          startDate: new Date(fields.startDate),
          endDate: new Date(fields.endDate),
          discount: +fields.discount,
        })
      );
    }
  };

  const onUpdateOffer = () => {
    if (validateFields()) {
      dispatch(
        updateOffer({
          ...fields,
          organizationId,
          id: editOffer.id,
          startDate: new Date(fields.startDate),
          endDate: new Date(fields.endDate),
          discount: +fields.discount,
        })
      );
    }
  };

  const onToggleOfferStatus = () => {
    if (editOffer) {
      dispatch(
        updateOffer({
          id: editOffer.id,
          organizationId,
          isActive: !editOffer.isActive,
        })
      );
    }
  };

  const discountOfferHandler = () => {
    dispatch(
      setProAccount({
        id: organizationId,
        willFundPro: organization.willFundPro,
      })
    );
  };

  return (
    organization && (
      <Grid container spacing={3}>
        <LoadingModal open={status === "loading"} />
        <DiscountOfferWrapper>
          <FormControlLabel
            label="Organization will fund Students Pro MBE Qbank Account"
            control={
              <Checkbox
                onChange={discountOfferHandler}
                checked={organization && !!organization.willFundPro}
                color="secondary"
              />
            }
          />
        </DiscountOfferWrapper>

        {organization && !organization.willFundPro && (
          <>
            <Grid container item spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={!!isOrganizationUser}
                  label="Discount Name"
                  error={!!errors.name}
                  helperText={errors.name || " "}
                  value={fields.name}
                  inputProps={{ maxLength: 255 }}
                  onChange={onChange("name")}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={!!isOrganizationUser}
                  label="Discount Code"
                  error={!!errors.code}
                  helperText={errors.code || " "}
                  value={fields.code}
                  inputProps={{ maxLength: 50 }}
                  onChange={onChange("code")}
                />
              </Grid>

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container item spacing={3} alignItems={"flex-end"}>
                  <Grid item xs={6}>
                    <DatePicker
                      fullWidth
                      disabled={isOrganizationUser}
                      clearable
                      format="MM/DD/yyyy"
                      placeholder="Discount Start Date"
                      minDate={fields.endDate || new Date()}
                      error={!!errors.startDate}
                      helperText={errors.startDate || " "}
                      value={fields.startDate}
                      onChange={onChange("startDate")}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DatePicker
                      fullWidth
                      clearable
                      disabled={isOrganizationUser}
                      format="MM/DD/yyyy"
                      minDate={fields.startDate || new Date()}
                      placeholder="Discount End Date"
                      error={!!errors.endDate}
                      helperText={errors.endDate || " "}
                      value={fields.endDate}
                      onChange={onChange("endDate")}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={!!isOrganizationUser}
                  label="Discount Percentage"
                  error={!!errors.discount}
                  helperText={errors.discount || " "}
                  value={
                    fields.discount.length
                      ? fields.discount + "%"
                      : fields.discount
                  }
                  inputProps={{ maxLength: 4 }}
                  onChange={onChange("discount")}
                />
              </Grid>
            </Grid>

            {!isOrganizationUser ? (
              editOffer ? (
                <Grid item container>
                  <Grid item container xs spacing={3}>
                    <Grid item>
                      <Button color={"success"} onClick={onUpdateOffer}>
                        Update
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button onClick={onCancelEdit}>Cancel</Button>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Button color={"danger"} onClick={onToggleOfferStatus}>
                      {editOffer.isActive ? "INACTIVATE" : "ACTIVATE"}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <Button onClick={onAddOffer}>+ Add Offer</Button>
                </Grid>
              )
            ) : null}

            {!isOrganizationUser && (
              <Grid item container>
                <Typography variant={"subtitle1"}>
                  Historical Organization Offers
                </Typography>

                <GenericTable
                  selectable={false}
                  colHeaders={[
                    {
                      id: "name",
                      disablePadding: false,
                      label: "Name",
                      selector: "name",
                    },
                    {
                      id: "dateRange",
                      disablePadding: false,
                      label: "Start Date – End Date",
                      selector: "dateRange",
                      size: "lg",
                    },
                    {
                      id: "discount",
                      disablePadding: false,
                      label: "Discount",
                      selector: "discount",
                    },
                    {
                      id: "status",
                      disablePadding: false,
                      label: "Status",
                      selector: "status",
                    },
                    {
                      id: "edit",
                      disablePadding: false,
                      label: "",
                      selector: "edit",
                    },
                  ]}
                  data={offers.map((item) => ({
                    ...item,
                    discount: item.discount + "%",
                    status: item.isActive ? "Active" : "Inactive",
                    dateRange:
                      moment(item.startDate.seconds * 1000).format(
                        "MM/DD/YYYY"
                      ) +
                      (item.endDate.seconds
                        ? ` - ${moment(item.endDate.seconds * 1000).format(
                            "MM/DD/YYYY"
                          )}`
                        : ""),
                    edit: <Link onClick={onEdit(item.id)}>Edit</Link>,
                  }))}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    )
  );
};

export default Offers;

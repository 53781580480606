import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import Grid from "@material-ui/core/Grid";
import IconEdit from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardDirty,
  CardHeader,
} from "../../containers/Card";
import Button from "../../elements/Button";
import { color, fontWeight } from "../../styles/style";
import { updateQuestion } from "../../store/actions/questionActions";

const styles = () => ({
  card: {
    display: "flex",
    width: "100%",
  },
  content: {
    paddingTop: 0,
    fontSize: 14,
    fontWeight: fontWeight.semiBold,
    color: color.blueDarkest,
  },
  questionCopy: {
    fontSize: 14,
    lineHeight: "initial",
    fontWeight: fontWeight.regular,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

class CardFieldEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      isDirty: false,
      oldContent: props.content,
      newContent: props.content,
    };
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.saveFieldValue = this.saveFieldValue.bind(this);
    this.cancelFieldChange = this.cancelFieldChange.bind(this);
  }

  toggleEditMode() {
    this.setState((state) => ({
      editMode: !state.editMode,
    }));
  }

  cancelFieldChange() {
    const { oldContent } = this.state;
    this.setState({ newContent: oldContent, editMode: false });
  }

  saveFieldValue() {
    const { newContent } = this.state;
    const { fieldKey, qid, level, qbank } = this.props;
    const uobj = {
      [fieldKey]: newContent,
    };
    this.props.updateQuestion(level, qbank, qid, uobj).then(() => {
      this.setState({
        editMode: false,
      });
    });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes, label } = this.props;
    const { editMode, newContent, oldContent } = this.state;
    return (
      <Grid item xs={12}>
        {editMode ? (
          <Card>
            <CardDirty isDirty={newContent !== oldContent}>
              <CardHeader
                action={
                  <IconButton
                    onClick={this.toggleEditMode}
                    disabled={newContent !== oldContent}
                  >
                    <IconEdit />
                  </IconButton>
                }
                title={label}
              />

              <CardContent className={classes.content}>
                <TextField
                  id="standard-multiline-flexible"
                  multiline
                  value={this.state.newContent}
                  onChange={this.handleChange("newContent")}
                  className={classes.textField}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    classes: {
                      root: classes.questionCopy,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </CardContent>
              <CardContent className={classes.actions}>
                <Button
                  color="default"
                  variant="text"
                  onClick={this.cancelFieldChange}
                >
                  CANCEL
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  onClick={this.saveFieldValue}
                  disabled={newContent === oldContent}
                >
                  SAVE
                </Button>
              </CardContent>
            </CardDirty>
          </Card>
        ) : (
          <Card>
            <CardHeader
              action={
                <IconButton onClick={this.toggleEditMode}>
                  <IconEdit />
                </IconButton>
              }
              title={label}
            />

            <CardContent
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: newContent,
              }}
            />
          </Card>
        )}
      </Grid>
    );
  }
}

CardFieldEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  qbank: state.questionStatus.qbank,
  level: state.questionStatus.level,
  status: state.questionStatus.status,
  qid: state.questionStatus.qid,
});

const mapDispatchToProps = { updateQuestion };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CardFieldEdit);

import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import { CardContent } from "../../containers/Card";
import { formatDateTime } from "../../utils/formatDateTime";
import { MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { formatPhoneNumber } from "../../utils/format";
import {
  validate,
  validateEmail,
  validateName,
  validatePhoneNumber,
  validateYear,
  validateZip,
} from "../../utils/validator";
import { TOrganization, TStudent } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { updateStudentDialog } from "../../_CONS/modalTypes";
import { resetStudentView } from "../../store/actions/studentActions";
import {
  AssociatedOrganization,
  CheckIcon,
  EmailDate,
  ReadOnlyInput,
  ReadOnlyValue,
  ResendEmailWrapper,
} from "./StyledProfileDetails";
import { RootState } from "../../store/createReduxStore";
import lawSchools, { howHeard } from "../../_CONS/lawSchools";
import Button from "../../elements/Button";
import LoadingModal from "../LoadingModal";
import Dialog from "../Dialog";
import moment from "moment";
import useOrganization from "../../hooks/useOrganization";

type TProps = {
  adminView: boolean;
  student: TStudent;
  actionUpdate?: (student: Partial<TStudent>) => void;
  actionEmail?: () => void;
};

type TFields = {
  accountType: string;
  firstName: string;
  lastName: string;
  zip: string;
  phone: string;
  email: string;
  graduationYear: string;
  school: string;
  howHeard: string;
  howHeardNotes: string;
};

const ProfileDetails = (props: TProps) => {
  const dispatch = useDispatch();

  const organization = useOrganization<TOrganization>(
    props.student.organization
  );
  const status = useSelector((state: RootState) => state.userStatus.status);

  const [isFilled, setFilledFlag] = useState(false);
  const [requestConfirmation, setRequestConfirmation] = useState(false);
  const [errors, setErrors] = useState<Partial<TFields>>({});

  const [fields, setFields] = useState<TFields>({
    accountType: props.student.accountType,
    firstName: props.student.firstName,
    lastName: props.student.lastName,
    zip: props.student.zip || "",
    phone: formatPhoneNumber(props.student.phone) || "",
    email: props.student.email,
    graduationYear: props.student.graduationYear
      ? props.student.graduationYear.toString()
      : "",
    school: props.student.school || null,
    howHeard: props.student.howHeard || null,
    howHeardNotes: props.student.howHeardNotes || "",
  });

  const checkRequiredFields = (fields) => {
    const required = [
      "firstName",
      "lastName",
      "zip",
      "email",
      "graduationYear",
      "school",
      "howHeard",
    ];

    return !required.some((field) => !fields[field]);
  };

  const validateFields = () => {
    const phoneValidators = fields.phone ? [validatePhoneNumber] : [];

    const errors = validate(fields, {
      firstName: [validateName],
      lastName: [validateName],
      zip: [validateZip],
      phone: phoneValidators,
      email: [validateEmail],
      graduationYear: [validateYear],
    });

    setErrors(errors);

    return !Object.keys(errors).length;
  };

  const onChange =
    (name) =>
    (e, data = null) => {
      let value = e.target.value;

      if (name === "howHeard" || name === "school") {
        value = data;
      }

      if (name === "phone" && value.length > fields.phone.length) {
        value = formatPhoneNumber(value);
      }

      const newFields = {
        ...fields,
        [name]: value,
      };

      setFields(newFields);
      setFilledFlag(checkRequiredFields(newFields));
    };

  const handleSubmitBtn = (e: MouseEvent) => {
    e.preventDefault();
    const { student } = props;

    if (fields.email !== student.email) {
      setRequestConfirmation(true);
    } else {
      updateStudent();
    }
  };

  const updateStudent = () => {
    const {
      email,
      firstName,
      lastName,
      graduationYear,
      howHeard,
      howHeardNotes,
      school,
      accountType,
      phone,
      zip,
    } = fields;
    const { student } = props;

    const toUpdate = {
      uid: student.uid,
      firstName,
      lastName,
      email,
      accountType,
      graduationYear: +graduationYear,
      howHeardNotes,
      howHeard,
      school,
      phone,
      zip,
      status: accountType !== student.accountType ? "new" : "current",
    };

    if (validateFields()) {
      props.actionUpdate(toUpdate);
    }
  };

  const resendEmail = (event) => {
    if (props.adminView) {
      event.preventDefault();
      props.actionEmail();
    }
  };

  const onSuspendAccount = (e: MouseEvent) => {
    e.preventDefault();
    const {
      student: { uid, isActive },
      adminView,
    } = props;

    if (adminView) {
      props.actionUpdate({
        uid,
        isActive: !isActive,
      });
    }
  };

  const handleDialogAction = () => {
    setRequestConfirmation(false);
    updateStudent();
  };

  const handleDialogClose = () => {
    setRequestConfirmation(false);
    dispatch(resetStudentView());
  };

  const { student, adminView } = props;

  return (
    <form>
      <LoadingModal open={status === "loading"} />

      <Dialog
        {...(adminView
          ? updateStudentDialog.UPDATE_EMAIL_ADMIN
          : updateStudentDialog.UPDATE_EMAIL_STUDENT)}
        type={"confirm"}
        open={requestConfirmation}
        buttonLabels={["No", "Yes"]}
        onCancel={handleDialogClose}
        onConfirm={handleDialogAction}
      />

      {adminView && (
        <>
          <CardContent>
            <Typography variant="subtitle2" gutterBottom align="right">
              Record Last Updated: {formatDateTime(student.updatedDate)}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Account Details
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel>Account Type</InputLabel>

                  <Select
                    value={fields.accountType}
                    onChange={onChange("accountType")}
                    MenuProps={{ id: "menu-account-type" }}
                    data-testid="accountType"
                  >
                    <MenuItem value="trial">Trial</MenuItem>
                    <MenuItem value="pro">Pro</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel shrink>Status</InputLabel>
                  <ReadOnlyValue data-testid="status">
                    {student.status}
                  </ReadOnlyValue>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel>Creation Date/Time</InputLabel>
                  <ReadOnlyInput
                    value={formatDateTime(student.startDate)}
                    readOnly
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </>
      )}

      <CardContent>
        <AssociatedOrganization variant="subtitle2" gutterBottom>
          Associated Organization:{" "}
          {organization.data ? organization.data.name : ""}
        </AssociatedOrganization>

        <Typography variant="h6" gutterBottom>
          Student Details
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              error={!!errors.firstName}
              helperText={errors.firstName}
              name="firstName"
              value={fields.firstName}
              inputProps={{ maxLength: 255 }}
              onChange={onChange("firstName")}
              data-testid="firstName"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              error={!!errors.lastName}
              helperText={errors.lastName}
              name="lastName"
              value={fields.lastName}
              inputProps={{ maxLength: 255 }}
              onChange={onChange("lastName")}
              data-testid="lastName"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Zip Code"
              error={!!errors.zip}
              helperText={errors.zip}
              name="zip"
              value={fields.zip}
              inputProps={{ maxLength: 11 }}
              onChange={onChange("zip")}
              data-testid="zip"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              error={!!errors.phone}
              helperText={errors.phone}
              name="phone"
              value={fields.phone}
              inputProps={{ maxLength: 16 }}
              onChange={onChange("phone")}
              data-testid="phone"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              error={!!errors.email}
              helperText={errors.email}
              name="email"
              value={fields.email}
              inputProps={{ maxLength: 512 }}
              onChange={onChange("email")}
              data-testid="email"
            />
          </Grid>

          {adminView && student.status === "new" && student.isActive === true && (
            <Grid item xs={12} sm={6}>
              <ResendEmailWrapper>
                <Button onClick={resendEmail} variant="text" color="primary">
                  Re-Send Email
                </Button>
              </ResendEmailWrapper>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Law School Graduation Year"
              error={!!errors.graduationYear}
              helperText={errors.graduationYear}
              name="graduationYear"
              value={fields.graduationYear}
              inputProps={{ maxLength: 4 }}
              onChange={onChange("graduationYear")}
              data-testid="graduationYear"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl margin="normal" fullWidth>
              <Autocomplete
                autoSelect
                value={fields.school}
                options={lawSchools}
                renderInput={(params) => (
                  <TextField fullWidth {...params} label="Law School" />
                )}
                onChange={onChange("school")}
                data-testid="school"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl margin="normal" fullWidth>
              <Autocomplete
                autoSelect
                value={fields.howHeard}
                options={howHeard}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    label="How Did You Hear About Us?"
                  />
                )}
                onChange={onChange("howHeard")}
                data-testid="howHeard"
              />
            </FormControl>
          </Grid>
        </Grid>

        {fields.howHeard === "Other (Please Specify Below)" && (
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                margin="normal"
                name="howHeardNotes"
                label="How Did You Hear About Us?"
                value={fields.howHeardNotes}
                onChange={onChange("howHeardNotes")}
                data-testid="howHeardNotes"
              />
            </Grid>
          </Grid>
        )}
      </CardContent>

      {!!student.organization && !!adminView && (
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Organization Details
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl margin="normal">
                <InputLabel>Organization Name</InputLabel>
                <ReadOnlyInput
                  value={organization.data ? organization.data.name : ""}
                  readOnly
                />
              </FormControl>
            </Grid>

            <Grid item container alignItems={"center"} spacing={3}>
              <Grid item>
                <Button
                  onClick={resendEmail}
                  variant="contained"
                  color="secondary"
                >
                  Resend Welcome Email
                </Button>
              </Grid>

              {!!student.emailDate && (
                <Grid item>
                  <EmailDate>
                    <CheckIcon />

                    <Typography>
                      Email sent on{" "}
                      {moment(student.emailDate.seconds * 1000).format("LLL")}
                    </Typography>
                  </EmailDate>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      )}

      <Divider />
      <CardContent>
        <Grid container direction="row" justify="space-between">
          <Button
            onClick={handleSubmitBtn}
            variant="contained"
            color="primary"
            disabled={!isFilled}
            data-testid="updateStudent"
          >
            Update Student
          </Button>

          {adminView && (
            <Button
              color="danger"
              variant="contained"
              onClick={onSuspendAccount}
              data-testid={
                student.isActive ? "Suspend Account" : "Activate Account"
              }
            >
              {student.isActive ? "Suspend Account" : "Activate Account"}
            </Button>
          )}
        </Grid>
      </CardContent>
    </form>
  );
};

ProfileDetails.displayName = "ProfileDetails";

export default ProfileDetails;

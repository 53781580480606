import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PublishIcon from "@material-ui/icons/Publish";
import IconRemoveRedEye from "@material-ui/icons/RemoveRedEye";
import Search from "@material-ui/icons/Search";
import {
  BatchArea,
  FilterArea,
  FilterInputArea,
  FilterInputLabel,
  FilterInputLabelArea,
  FilterInputTextField,
  StyledToolbar,
} from "./StyledEnhancedTableToolbar";

const EnhancedTableToolbar = (props) => {
  const { numSelected, isDraft, superAdmin, filterOn } = props;
  return (
    <StyledToolbar
      disableGutters={true}
      variant="dense"
      isActive={numSelected > 0}
    >
      <FilterArea isActive={numSelected > 0}>
        <Tooltip title="Search list">
          <IconButton
            aria-label="Search list"
            disableRipple
            color="inherit"
            onClick={props.toggleFilter}
          >
            <Search />
          </IconButton>
        </Tooltip>
        {filterOn && (
          <FilterInputArea>
            <FilterInputLabelArea isActive={numSelected > 0}>
              <FilterInputLabel color="inherit" variant="subtitle2">
                ID Search:
              </FilterInputLabel>
            </FilterInputLabelArea>
            <FilterInputTextField
              id="qsearch"
              name="qsearch"
              margin="dense"
              onChange={props.handleChange}
            />
          </FilterInputArea>
        )}
      </FilterArea>

      <BatchArea>
        {numSelected > 0 && (
          <Typography color="inherit" variant="subtitle2">
            {numSelected} selected
          </Typography>
        )}
        {numSelected > 0 && (
          <>
            <Tooltip title="Preview">
              <IconButton
                color="secondary"
                aria-label="Preview"
                disableRipple
                onClick={(e) => props.action(e, "preview")}
              >
                <IconRemoveRedEye />
              </IconButton>
            </Tooltip>
            {isDraft && superAdmin && (
              <Tooltip title="Publish">
                <IconButton
                  disableRipple
                  aria-label="Publish"
                  onClick={(e) => props.action(e, "publish")}
                >
                  <PublishIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </BatchArea>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  isDraft: PropTypes.bool.isRequired,
};

export default EnhancedTableToolbar;

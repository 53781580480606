import React from "react";
import styled from "styled-components/macro";
import { Typography } from "@material-ui/core";
import { color } from "../../styles/style";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelCircleIcon from "@material-ui/icons/Cancel";

export const CheckIcon = styled(CheckCircleIcon)`
  margin-right: 0.5rem;
`;

export const CancelIcon = styled(CancelCircleIcon)`
  margin-right: 0.5rem;
`;

export const ImportResult = styled(({ success, ...props }) => (
  <Typography {...props} />
))`
  display: flex;
  color: ${({ success }) => (success ? color.greenDark : color.redDark)};
`;

export const Hidden = styled.div`
  visibility: hidden;
  position: absolute;
  top: -100%;
  left: -100%;
`;

import { FirebaseError } from "firebase";
import firebase from "../api/firebaseAdmin";
import { TAsyncAction, TStateAction } from "../../types/TYPES";
import { getMessageFromError } from "../../utils/firebaseErrorMessage";
import { actionCreator } from "../../utils/actionCreator";
import { getAsyncActions } from "../../utils/getAsyncActions";

type TAuthAction = {
  LOGIN: TAsyncAction;
  SIGNUP: TAsyncAction;
  RESET_PASSWORD: TAsyncAction;
  LOGOUT: string;
  SEND_EMAIL: string;
};

export const AuthAction: TAuthAction = {
  LOGOUT: "auth/logout",
  SEND_EMAIL: "auth/sendEmail",
  ...getAsyncActions({
    SIGNUP: "auth/signup",
    LOGIN: "auth/login",
    RESET_PASSWORD: "RESET_PASSWORD",
  }),
} as TAuthAction;

type TDispatch = (action: TStateAction) => any;

export const sendVerificationEmail = () => {
  return (dispatch: TDispatch) => {
    const user = firebase.auth().currentUser;
    user
      .sendEmailVerification()
      .then(() => {
        dispatch(actionCreator(AuthAction.SEND_EMAIL, "email-verification"));
      })
      .catch((err: FirebaseError) => {
        dispatch(
          actionCreator(
            AuthAction.SEND_EMAIL,
            getMessageFromError(err.code),
            true
          )
        );
      });
  };
};

export const forgotPassword = (email: string) => {
  return async (dispatch: TDispatch) => {
    try {
      // await firebase.auth().sendPasswordResetEmail(email);
      dispatch({ type: AuthAction.SEND_EMAIL });
    } catch (e) {
      dispatch(
        actionCreator(AuthAction.SEND_EMAIL, getMessageFromError(e.code), true)
      );
    }
  };
};

export const signIn = (credentials: { email: string; password: string }) => {
  return async (dispatch: TDispatch) => {
    dispatch({ type: AuthAction.LOGIN["REQUESTED"] });

    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password);
      dispatch({ type: AuthAction.LOGIN["SUCCESS"] });
    } catch (e) {
      dispatch(
        actionCreator(
          AuthAction.LOGIN["FAILURE"],
          getMessageFromError(e.code),
          true
        )
      );
    }
  };
};

export const signOut = () => {
  return (dispatch: TDispatch) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: AuthAction.LOGOUT });
      });
  };
};

export const signUp = (newUser) => {
  return async (dispatch: TDispatch) => {
    dispatch(actionCreator(AuthAction.SIGNUP["REQUESTED"]));

    const auth = firebase.auth();
    const student = {
      ...newUser,
      initials: newUser.firstName[0] + newUser.lastName[0],
      accountType: "trial",
      accountLevel: "free",
      studentBank: "students-free",
      studentType: "web",
      status: "new",
      isActive: true,
      startDate: new Date(),
    };

    const signUp = firebase.functions().httpsCallable("createNewStudent");

    try {
      const {
        data: { success, error },
      } = await signUp(student);

      if (!success) {
        dispatch(actionCreator(AuthAction.SIGNUP["FAILURE"], error, true));
      } else {
        dispatch({ type: AuthAction.SIGNUP["SUCCESS"] });
        auth.signInWithEmailAndPassword(newUser.email, newUser.password);
      }
    } catch (e) {
      dispatch(
        actionCreator(
          AuthAction.SIGNUP.FAILURE,
          typeof e === "string" ? e : JSON.stringify(e),
          true
        )
      );
    }
  };
};

export const resetPassword = (
  code: string,
  password: string,
  email?: string
) => {
  return async (dispatch: TDispatch) => {
    dispatch({ type: AuthAction.RESET_PASSWORD["REQUESTED"] });

    try {
      await firebase.auth().confirmPasswordReset(code, password);

      if (email) {
        await firebase.auth().signInWithEmailAndPassword(email, password);
        dispatch({ type: AuthAction.RESET_PASSWORD["SUCCESS"] });
      }
    } catch (e) {
      console.log(e);
      dispatch(
        actionCreator(AuthAction.RESET_PASSWORD["FAILURE"], e.message, true)
      );
    }
  };
};

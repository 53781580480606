const subjectTopics = {
  CRI: {
    label: "Criminal Law and Procedure",
    topics: {
      I: {
        label: "Homicide",
        subtopics: {
          A: {
            label: "Intended killings",
            subsubtopics: {
              1: {
                label: "Premeditation, deliberation",
              },
              2: { label: "Provocation" },
            },
          },
          B: {
            label: "Unintended killings",
            subsubtopics: {
              1: { label: "Intent to injure" },
              2: { label: "Reckless and negligent killings" },
              3: { label: "Felony murder" },
              4: { label: "Misdemeanor manslaughter" },
            },
          },
        },
      },
      II: {
        label: "Other crimes",
        subtopics: {
          A: {
            label: "Theft and receiving stolen goods",
          },
          B: {
            label: "Robbery",
          },
          C: {
            label: "Burglary",
          },
          D: {
            label: "Assault and battery",
          },
          E: {
            label: "Rape; statutory rape",
          },
          F: {
            label: "Kidnapping",
          },
          G: {
            label: "Arson",
          },
          H: {
            label: "Possession offenses",
          },
        },
      },
      III: {
        label: "Inchoate crimes; parties",
        subtopics: {
          A: {
            label: "Inchoate offenses",
            subsubtopics: {
              1: { label: "Attempts" },
              2: { label: "Conspiracy" },
              3: { label: "Solicitation" },
            },
          },
          B: { label: "Parties to crime" },
        },
      },
      IV: {
        label: "General principles",
        subtopics: {
          A: {
            label: "Acts and omissions",
          },
          B: {
            label: "State of mind",
            subsubtopics: {
              1: { label: "Required mental state" },
              2: { label: "Strict liability" },
              3: { label: "Mistake of fact or law" },
            },
          },
          C: {
            label: "Responsibility",
            subsubtopics: {
              1: { label: "Mental disorder" },
              2: { label: "Intoxication" },
            },
          },
          D: { label: "Causation" },
          E: { label: "Justification and excuse" },
          F: { label: "Jurisdiction" },
        },
      },
      V: {
        label: "Constitutional protection of accused persons",
        subtopics: {
          A: { label: "Arrest, search and seizure" },
          B: { label: "Confessions and privilege against self-incrimination" },
          C: { label: "Lineups and other forms of identification" },
          D: { label: "Right to counsel" },
          E: { label: "Fair trial and guilty pleas" },
          F: { label: "Double jeopardy" },
          G: { label: "Cruel and unusual punishment" },
          H: { label: "Burdens of proof and persuasion" },
          I: { label: "Appeal and error" },
        },
      },
    },
  },
  CIV: {
    label: "Civil Procedure",
    topics: {
      I: {
        label: "Jurisdiction and venue",
        subtopics: {
          A: {
            label:
              "Federal subject-matter jurisdiction (federal question, diversity, supplemental, and removal)",
          },
          B: {
            label: "Personal jurisdiction",
          },
          C: {
            label: "Service of process and notice",
          },
          D: {
            label: "Venue, forum non conveniens, and transfer",
          },
        },
      },
      II: {
        label: "Law applied by federal courts",
        subtopics: {
          A: {
            label: "State law in federal court",
          },
          B: {
            label: "Federal common law",
          },
        },
      },
      III: {
        label: "Pretrial procedures",
        subtopics: {
          A: {
            label: "Preliminary injunctions and temporary restraining orders",
          },
          B: {
            label: "Pleadings and amended and supplemental pleadings",
          },
          C: {
            label: "Rule 11",
          },
          D: {
            label: "Joinder of parties and claims (including class actions)",
          },
          E: {
            label:
              "Discovery (including e-discovery), disclosure, and sanctions",
          },
          F: {
            label: "Adjudication without a trial",
          },
          G: {
            label: "Pretrial conference and order",
          },
        },
      },
      IV: {
        label: "Jury trials",
        subtopics: {
          A: {
            label: "Right to jury trial",
          },
          B: {
            label: "Selection and composition of juries",
          },
          C: {
            label: "Requests for and objections to jury instructions",
          },
          D: {
            label: "ZXZXZXZXZXZXZXZXX Missing",
          },
        },
      },
      V: {
        label: "Motions",
        subtopics: {
          A: {
            label:
              "Pretrial motions, including motions addressed to face of pleadings, motions to dismiss, and summary judgment motions",
          },
          B: {
            label:
              "Motions for judgments as a matter of law (directed verdicts and judgments notwithstanding the verdict)",
          },
          C: {
            label:
              "Posttrial motions, including motions for relief from judgment and for new trial",
          },
        },
      },
      VI: {
        label: "Verdicts and judgments",
        subtopics: {
          A: { label: "Defaults and dismissals" },
          B: { label: "Jury verdicts—types and challenges" },
          C: { label: "Judicial findings and conclusions" },
          D: { label: "Effect; claim and issue preclusion" },
        },
      },
      VII: {
        label: "Appealability and review",
        subtopics: {
          A: { label: "Availability of interlocutory review" },
          B: { label: "Final judgment rule" },
          C: { label: "Scope of review for judge and jury" },
        },
      },
    },
  },
  EVD: {
    label: "Evidence",
    topics: {
      I: {
        label: "Presentation of evidence",
        subtopics: {
          A: {
            label: "Introduction of evidence",
            subsubtopics: {
              1: { label: "Requirement of personal knowledge" },
              2: { label: "Refreshing recollection" },
              3: { label: "Objections and offers of proof" },
              4: { label: "Lay opinions" },
              5: { label: "Competency of witnesses" },
              6: { label: "Judicial notice" },
              7: { label: "Roles of judge and jury" },
              8: { label: "Limited admissibility" },
            },
          },
          B: {
            label: "Presumptions",
          },
          C: {
            label: "Mode and order",
            subsubtopics: {
              1: { label: "Control by court" },
              2: { label: "Scope of examination" },
              3: { label: "Form of questions" },
              4: { label: "Exclusion of witnesses" },
            },
          },
          D: {
            label: "Impeachment, contradiction, and rehabilitation",
            subsubtopics: {
              1: { label: "Inconsistent statements and conduct" },
              2: { label: "Bias and interest" },
              3: { label: "Conviction of crime" },
              4: { label: "Specific instances of conduct" },
              5: { label: "Character for truthfulness" },
              6: {
                label: "Ability to observe, remember, or relate accurately",
              },
              7: { label: "Impeachment of hearsay declarants" },
              8: { label: "Rehabilitation of impeached witnesses" },
              9: { label: "Contradiction" },
            },
          },
          E: {
            label: "Proceedings to which evidence rules apply",
          },
        },
      },
      II: {
        label: "Relevancy and reasons for excluding relevant evidence",
        subtopics: {
          A: {
            label: "Probative value",
            subsubtopics: {
              1: {
                label: "Relevancy",
              },
              2: {
                label:
                  "Exclusion for unfair prejudice, confusion, or waste of time",
              },
            },
          },
          B: {
            label: "Authentication and identification",
          },
          C: {
            label: "Character and related concepts",
            subsubtopics: {
              1: { label: "Admissibility of character" },
              2: { label: "Methods of proving character" },
              3: { label: "Habit and routine practice" },
              4: { label: "Other crimes, acts, transactions, and events" },
              5: { label: "Prior sexual misconduct of a defendant" },
            },
          },
          D: {
            label: "Expert testimony",
            subsubtopics: {
              1: { label: "Qualifications of witnesses" },
              2: { label: "Bases of testimony" },
              3: { label: "Ultimate issue rule" },
              4: { label: "Reliability and relevancy" },
              5: { label: "Proper subject matter for expert testimony" },
            },
          },
          E: {
            label: "Real, demonstrative, and experimental evidence",
          },
        },
      },
      III: {
        label: "Privileges and other policy exclusions",
        subtopics: {
          A: {
            label: "Spousal immunity and marital communications",
          },
          B: { label: "Attorney-client and work product" },
          C: { label: "Physician/psychotherapist-patient" },
          D: { label: "Other privileges" },
          E: { label: "Insurance coverage" },
          F: { label: "Remedial measures" },
          G: {
            label:
              "Compromise, payment of medical expenses, and plea negotiations",
          },
          H: { label: "Past sexual conduct of a victim" },
        },
      },
      IV: {
        label: "Writings, recordings, and photographs",
        subtopics: {
          A: { label: "Requirement of original" },
          B: { label: "Summaries" },
          C: { label: "Completeness rule" },
        },
      },
      V: {
        label: "Hearsay and circumstances of its admissibility",
        subtopics: {
          A: {
            label: "Definition of hearsay",
            subsubtopics: {
              1: { label: "What is hearsay" },
              2: { label: "Prior statements by witness" },
              3: { label: "Statements attributable to party-opponent" },
              4: { label: "Multiple hearsay" },
            },
          },
          B: {
            label: "Present sense impressions and excited utterances",
          },
          C: {
            label: "Statements of mental, emotional, or physical condition",
          },
          D: {
            label: "Statements for purposes of medical diagnosis and treatment",
          },
          E: { label: "Past recollection recorded" },
          F: { label: "Business records" },
          G: { label: "Public records and reports" },
          H: { label: "Learned treatises" },
          I: { label: "Former testimony; depositions" },
          J: { label: "Statements against interest" },
          K: { label: "Other exceptions to the hearsay rule" },
          L: { label: "Right to confront witnesses" },
        },
      },
    },
  },
  TRT: {
    label: "Torts",
    topics: {
      I: {
        label: "Intentional torts",
        subtopics: {
          A: {
            label:
              "Harms to the person, such as assault, battery, false imprisonment, and infliction of mental distress; and harms to  property interests, such as trespass to land and chattels, and conversion",
          },
          B: {
            label: "Defenses to claims for physical harms",
            subsubtopics: {
              1: { label: "Consent " },
              2: {
                label:
                  "Privileges and immunities: protection of self and others; protection of property interests; parental discipline; protection of public interests; necessity; incomplete privilege",
              },
            },
          },
        },
      },
      II: {
        label: "Negligence",
        subtopics: {
          A: {
            label:
              "The duty question, including failure to act, unforeseeable plaintiffs, and obligations to control the conduct of third parties",
          },
          B: {
            label: "The standard of care",
            subsubtopics: {
              1: {
                label:
                  "The reasonably prudent person: including children, physically and mentally impaired individuals, professional people, and other special classes ",
              },
              2: {
                label: "Rules of conduct derived from statutes and custom ",
              },
            },
          },
          C: {
            label:
              "Problems relating to proof of fault, including res ipsa loquitur",
          },
          D: {
            label: "Problems relating to causation",
            subsubtopics: {
              1: { label: "But for and substantial causes" },
              2: { label: "Harms traceable to multiple causes" },
              3: {
                label:
                  "Questions of apportionment of responsibility among multiple tortfeasors, including joint and several liability",
              },
            },
          },
          E: {
            label: "Limitations on liability and special rules of liability",
            subsubtopics: {
              1: {
                label:
                  "Problems relating to “remote” or “unforeseeable” causes, “legal” or “proximate” cause, and “superseding” causes",
              },
              2: { label: "Claims against owners and occupiers of land" },
              3: {
                label:
                  "Claims for mental distress not arising from physical harm; other intangible injuries ",
              },
              4: { label: "Claims for pure economic loss" },
            },
          },
          F: {
            label: "Liability for acts of others",
            subsubtopics: {
              1: { label: "Employees and other agents" },
              2: { label: "Independent contractors and nondelegable duties" },
            },
          },
          G: {
            label: "Defenses",
            subsubtopics: {
              1: {
                label:
                  "Contributory fault, including common law contributory negligence and last clear chance, and the various forms of comparative negligence",
              },
              2: { label: "Assumption of risk" },
            },
          },
        },
      },
      III: {
        label: "Strict liability and products liability",
      },
      IV: {
        label: "Other torts",
        subtopics: {
          A: { label: "Claims based on nuisance, and defenses" },
          B: {
            label:
              "Claims based on defamation and invasion of privacy, defenses, and constitutional limitations",
          },
          C: { label: "Claims based on misrepresentations, and defenses " },
          D: {
            label:
              "Claims based on intentional interference with business relations, and defenses",
          },
        },
      },
    },
  },

  CON: {
    label: "Constitutional Law",
    topics: {
      I: {
        label: "The nature of judicial review",
        subtopics: {
          A: {
            label:
              "Organization and relationship of state and federal courts in a federal system",
          },
          B: {
            label: "Jurisdiction",
            subsubtopics: {
              1: {
                label: "Congressional power to define and limit",
              },
              2: {
                label: "The Eleventh Amendment and state sovereign immunity",
              },
            },
          },
          C: {
            label: "Judicial review in operation",
            subsubtopics: {
              1: {
                label:
                  "The “case or controversy” requirement, including the prohibition on advisory opinions, standing, ripeness, and mootness",
              },
              2: { label: "The “adequate and independent state ground” " },
              3: { label: "Political questions and justiciability" },
            },
          },
        },
      },
      II: {
        label: "The separation of powers",
        subtopics: {
          A: {
            label: "The powers of Congress",
            subsubtopics: {
              1: {
                label: "Commerce, taxing, and spending powers",
              },
              2: {
                label: "War, defense, and foreign affairs powers",
              },
              3: {
                label: "Power to enforce the 13th, 14th, and 15th Amendments",
              },
              4: { label: "Other powers" },
            },
          },
          B: {
            label: "The powers of the president",
            subsubtopics: {
              1: {
                label: "As chief executive, including the “take care” clause",
              },
              2: { label: "As commander in chief " },
              3: { label: "Treaty and foreign affairs powers " },
              4: { label: "Appointment and removal of officials " },
            },
          },
          C: {
            label: "Federal interbranch relationships",
            subsubtopics: {
              1: { label: "Congressional limits on the executive" },
              2: {
                label:
                  "The presentment requirement and the president`s power to veto or to withhold action",
              },
              3: { label: "Nondelegation doctrine" },
              4: { label: "Executive, legislative, and judicial immunities" },
            },
          },
        },
      },
      III: {
        label: "The relation of nation and states in a federal system",
        subtopics: {
          A: {
            label: "Intergovernmental immunities",
            subsubtopics: {
              1: { label: "Federal immunity from state law" },
              2: {
                label:
                  "State immunity from federal law, including the 10th Amendment ",
              },
            },
          },
          B: {
            label: "Federalism-based limits on state authority",
            subsubtopics: {
              1: { label: "Negative implications of the commerce clause" },
              2: { label: "Supremacy clause and preemption " },
              3: { label: "Authorization of otherwise invalid state action " },
            },
          },
        },
      },
      IV: {
        label: "Individual rights",
        subtopics: {
          A: {
            label: "State action",
          },
          B: {
            label: "Due process",
            subsubtopics: {
              1: {
                label: "Substantive due process",
                subsubsubtopics: {
                  a: { label: "Fundamental rights " },
                  b: { label: "Other rights and interests " },
                },
              },
              2: {
                label: "Procedural due process",
              },
            },
          },
          C: {
            label: "Equal protection",
            subsubtopics: {
              1: { label: "Fundamental rights " },
              2: { label: "Classifications subject to heightened scrutiny " },
              3: { label: "Rational basis review " },
            },
          },
          D: { label: "Takings" },
          E: {
            label:
              "Other protections, including the privileges and immunities clauses, the contracts clause, unconstitutional conditions, bills of attainder, and ex post facto laws",
          },
          F: {
            label: "First Amendment freedoms",
            subsubtopics: {
              1: {
                label: "Freedom of religion and separation of church and state",
                subsubsubtopics: {
                  a: { label: "Free exercise" },
                  b: { label: "Establishment " },
                },
              },
              2: {
                label: "Freedom of expression",
                subsubsubtopics: {
                  a: {
                    label: "Content-based regulation of protected expression ",
                  },
                  b: {
                    label: "Content-neutral regulation of protected expression",
                  },
                  c: { label: "Regulation of unprotected expression " },
                  d: { label: "Regulation of commercial speech" },
                  e: {
                    label:
                      "Regulation of, or impositions upon, public school students, public employment, licenses, or benefits based upon exercise of expressive or associational rights",
                  },
                  f: { label: "Regulation of expressive conduct" },
                  g: { label: "Prior restraint, vagueness, and overbreadth" },
                },
              },
              3: {
                label: "Freedom of the press",
              },
              4: {
                label: "Freedom of association",
              },
            },
          },
        },
      },
    },
  },
  KSL: {
    label: "Contracts",
    topics: {
      I: {
        label: "Formation of contracts",
        subtopics: {
          A: {
            label:
              "Mutual assent (including offer and acceptance, and unilateral, bilateral, and implied-in-fact contracts)",
          },
          B: { label: "Indefiniteness and absence of terms" },
          C: { label: "Consideration (bargained-for exchange)" },
          D: {
            label:
              "Obligations enforceable without a bargained-for exchange (including reliance and restitution)",
          },
          E: { label: "Modification of contracts" },
        },
      },
      II: {
        label: "Defenses to enforceability",
        subtopics: {
          A: { label: "Incapacity to contract" },
          B: { label: "Duress and undue influence" },
          C: { label: "Mistake and misunderstanding" },
          D: { label: "Fraud, misrepresentation, and nondisclosure" },
          E: { label: "Illegality, unconscionability, and public policy" },
          F: { label: "Statute of frauds" },
        },
      },
      III: {
        label: "Contract content and meaning",
        subtopics: {
          A: { label: "Parol evidence" },
          B: { label: "Interpretation" },
          C: { label: "Omitted and implied terms" },
        },
      },
      IV: {
        label: "Performance, breach, and discharge",
        subtopics: {
          A: { label: "Conditions (express and constructive)" },
          B: { label: "Excuse of conditions" },
          C: {
            label:
              "Breach (including material and partial breach, and anticipatory repudiation)",
          },
          D: { label: "Obligations of good faith and fair dealing" },
          E: {
            label: "Express and implied warranties in sale-of-goods contracts",
          },
          F: {
            label:
              "Other performance matters (including cure, identification, notice, and risk of loss)",
          },
          G: {
            label:
              "Impossibility, impracticability, and frustration of purpose",
          },
          H: {
            label:
              "Discharge of duties (including accord and satisfaction, substituted contract, novation, rescission, and release)",
          },
        },
      },
      V: {
        label: "Remedies",
        subtopics: {
          A: {
            label:
              "Expectation interest (including direct, incidental, and consequential damages)",
          },
          B: { label: "Causation, certainty, and foreseeability" },
          C: {
            label:
              "Liquidated damages and penalties, and limitation of remedies",
          },
          D: { label: "Avoidable consequences and mitigation of damages" },
          E: { label: "Rescission and reformation " },
          F: { label: "Specific performance and injunction " },
          G: { label: "Reliance and restitution interests" },
          H: { label: "Remedial rights of breaching parties" },
        },
      },
      VI: {
        label: "Third-party rights",
        subtopics: {
          A: { label: "Third-party beneficiaries " },
          B: { label: "Assignment of rights and delegation of duties" },
        },
      },
    },
  },
  RPL: {
    label: "Real Property",
    topics: {
      I: {
        label: "Ownership of real property",
        subtopics: {
          A: {
            label: "Present estates and future interests",
            subsubtopics: {
              1: {
                label: "Present estates",
                subsubsubtopics: {
                  a: { label: "Fees simple " },
                  b: { label: "Defeasible fees" },
                  c: { label: "Life estates" },
                },
              },
              2: {
                label: "Future interests ",
                subsubsubtopics: {
                  a: { label: "Reversions " },
                  b: { label: "Remainders, vested and contingent " },
                  c: { label: "Executory interests " },
                  d: {
                    label: "Possibilities of reverter, powers of termination ",
                  },
                  e: {
                    label:
                      "Rules affecting these interests (including survivorship, class gifts, waste, and cy pres) ",
                  },
                },
              },
            },
          },
          B: {
            label: "Cotenancy",
            subsubtopics: {
              1: { label: "Types: tenancy in common and joint tenancy" },
              2: {
                label: "Rights and obligations of cotenants",
                subsubsubtopics: {
                  a: { label: "Partition" },
                  b: { label: "Severance" },
                  c: { label: "Relations among cotenants " },
                },
              },
            },
          },
          C: {
            label: "Landlord-tenant law",
            subsubtopics: {
              1: { label: "Types of tenancies" },
              2: { label: "Possession and rent" },
              3: { label: "Transfers by landlord or tenant" },
              4: {
                label:
                  "Termination (including surrender, mitigation of damages, anticipatory breach, and security deposits)",
              },
              5: { label: "Habitability and suitability " },
            },
          },
          D: {
            label: "Special problems",
            subsubtopics: {
              1: {
                label:
                  "Rule against perpetuities: common law rule and statutory reforms",
              },
              2: {
                label:
                  "Alienability, descendibility, and devisability of present and future interests",
              },
              3: { label: "Fair housing/discrimination" },
              4: {
                label:
                  "Conflicts of law related to disputes involving real property",
              },
            },
          },
        },
      },
      II: {
        label: "Rights in real property",
        subtopics: {
          A: {
            label: "Restrictive covenants",
            subsubtopics: {
              1: { label: "Nature and type" },
              2: { label: "Creation" },
              3: { label: "Scope" },
              4: { label: "Transfer" },
              5: { label: "Termination" },
              6: {
                label:
                  "Property owners` associations and common interest ownership communities ",
              },
            },
          },
          B: {
            label: "Easements, profits, and licenses",
            subsubtopics: {
              1: {
                label: "Nature and type ",
              },
              2: {
                label: "Methods of creation",
                subsubsubtopics: {
                  a: { label: "Express " },
                  b: { label: "Implied " },
                  c: { label: "Prescription " },
                },
              },
              3: {
                label: "Scope and apportionment",
              },
              4: {
                label: "Transfer",
              },
              5: {
                label: "Termination",
              },
            },
          },
          C: {
            label: "Fixtures ",
          },
          D: {
            label: "Zoning (fundamentals other than regulatory taking)",
            subsubtopics: {
              1: { label: "Zoning laws" },
              2: { label: "Protection of pre-existing property rights" },
              3: { label: "Rezoning and other zoning changes" },
            },
          },
        },
      },
      III: {
        label: "Real estate contracts",
        subtopics: {
          A: {
            label: "Real estate brokerage",
          },
          B: {
            label: "Creation and construction",
            subsubtopics: {
              1: { label: "Statute of frauds and exceptions " },
              2: { label: "Essential terms" },
              3: { label: "Time for performance" },
              4: { label: "Remedies for breach" },
            },
          },
          C: {
            label: "Marketability of title",
          },
          D: {
            label: "Equitable conversion (including risk of loss)",
          },
          E: {
            label: "Options and rights of first refusal",
          },
          F: {
            label: "Fitness and suitability",
          },
          G: {
            label: "Merger",
          },
        },
      },
      IV: {
        label: "Mortgages/security devices ",
        subtopics: {
          A: {
            label: "Types of security devices",
            subsubtopics: {
              1: {
                label: "Mortgages (including deeds of trust)",
                subsubsubtopics: {
                  a: { label: "In general " },
                  b: { label: "Purchase money mortgages " },
                  c: { label: "Future advance mortgages " },
                },
              },
              2: { label: "Installment land contracts " },
              3: { label: "Absolute deeds as security " },
            },
          },
          B: {
            label: "Security relationships",
            subsubtopics: {
              1: {
                label: "Necessity and nature of obligation ",
              },
              2: {
                label: "Mortgage theories: title, lien, and intermediate ",
              },
              3: {
                label: "Rights and duties prior to foreclosure ",
              },
              4: {
                label: "Right to redeem and clogging the equity of redemption ",
              },
            },
          },
          C: {
            label: "Transfers",
            subsubtopics: {
              1: {
                label: "By mortgagor",
                subsubsubtopics: {
                  a: { label: "Assumption and transfer subject to" },
                  b: { label: "Rights and obligations" },
                  c: {
                    label:
                      "Application of subrogation and suretyship principles",
                  },
                  d: {
                    label:
                      "Restrictions on transfer (including due-on-sale clauses)",
                  },
                },
              },
              2: {
                label: "By mortgagee ",
              },
            },
          },
          D: {
            label: "Discharge of the mortgage",
            subsubtopics: {
              1: {
                label: "Payment (including prepayment)",
              },
              2: {
                label: "Deed in lieu of foreclosure ",
              },
            },
          },
          E: {
            label: "Foreclosure",
            subsubtopics: {
              1: {
                label: "Types",
              },
              2: {
                label: "Acceleration",
              },
              3: {
                label: "Parties to the proceeding ",
              },
              4: {
                label: "Deficiency and surplus ",
              },
              5: {
                label: "Redemption after foreclosure",
              },
            },
          },
        },
      },
      V: {
        label: "Titles",
        subtopics: {
          A: {
            label: "Adverse possession",
          },
          B: {
            label: "Transfer by deed",
            subsubtopics: {
              1: {
                label: "Requirements for deed",
              },
              2: {
                label: "Types of deeds (including covenants for title)",
              },
              3: {
                label: "Drafting, review, and negotiation of closing documents",
              },
              4: {
                label: "Persons authorized to execute documents",
              },
            },
          },
          C: {
            label: "Transfer by operation of law and by will",
            subsubtopics: {
              1: { label: "In general" },
              2: { label: "Ademption" },
              3: { label: "Exoneration" },
              4: { label: "Lapse" },
            },
          },
          D: {
            label: "Title assurance systems",
            subsubtopics: {
              1: {
                label: "Recording acts",
                subsubsubtopics: {
                  a: { label: "Types" },
                  b: { label: "Indexes" },
                  c: { label: "Chain of title" },
                  d: {
                    label: "Hidden risks (e.g., undelivered or forged deed)",
                  },
                },
              },
              2: {
                label: "Title insurance ",
              },
            },
          },
          E: {
            label:
              "Special problems (including estoppel by deed and judgment and tax liens)",
          },
        },
      },
    },
  },
};
export default subjectTopics;

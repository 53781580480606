type TSyncActions = {
  [key: string]: string;
};

type TAsyncActions = {
  [key: string]: {
    REQUESTED: string;
    SUCCESS: string;
    FAILURE: string;
  };
};

export const getAsyncActions = (actions: TSyncActions): TAsyncActions => {
  const result: {
    [key in keyof typeof actions]: {
      REQUESTED: string;
      SUCCESS: string;
      FAILURE: string;
    };
  } = {};

  Object.keys(actions).forEach((action: string) => {
    result[action] = {
      REQUESTED: actions[action] + "/requested",
      SUCCESS: actions[action] + "/success",
      FAILURE: actions[action] + "/failure",
    };
  });

  return result;
};

import React, { Component } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
/* style lib */
/* UI lib components */
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

/* GLOBAL ELEMENTS */
import SignUpCta from "../../../elements/SignUpCta";
import AuthErrorTxt from "../../../elements/AuthErrorTxt";
import LandingInfoText from "../../../elements/LandingInfoText";
/* STYLES & UTILS */
import { forgotPassword } from "../../../store/actions/authActions";

class ForgotPassword extends Component {
  state = {
    email: "",
    emailSent: false,
    infoText: "You should receive an email with a link to reset your password",
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleEmailSubmit = (e) => {
    e.preventDefault();
    this.props.forgotPassword(this.state.email);
  };

  render() {
    const { error, emailSent } = this.props;
    return (
      <>
        {emailSent && (
          <LandingInfoText>
            You should receive an email with a link to reset your password
          </LandingInfoText>
        )}
        <form onSubmit={this.handleEmailSubmit}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={this.handleChange}
            />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <Button
              type="submit"
              variant="contained"
              fullWidth={true}
              color="primary"
            >
              Send Reset Email
            </Button>
            <AuthErrorTxt>{error ? <p>{error}</p> : null}</AuthErrorTxt>
            <SignUpCta>
              <Link
                to={{
                  pathname: "/",
                }}
              >
                Sign in.
              </Link>
            </SignUpCta>
            {/* <SignUpCta>
             <Button onClick={this.toggleAuthType}>Need to Sign in?</Button>
             </SignUpCta> */}
            <SignUpCta>
              Dont have an account?{" "}
              <Link
                to={{
                  pathname: "/sign-up",

                  state: { isSignUp: true },
                }}
              >
                Sign up.
              </Link>
            </SignUpCta>
          </FormControl>
        </form>
      </>
    );
  }
}

ForgotPassword.propTypes = {};

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    auth: state.firebase.auth,
    emailSent: !state.auth.error && state.auth.action === "send-email",
    role: state.firebase.profile.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (creds) => dispatch(forgotPassword(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

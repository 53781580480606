import React from "react";
import { get } from "lodash";
import { compose } from "redux";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components/macro";
import { firebaseConnect, isLoaded } from "react-redux-firebase";

import Loading from "../../components/Loading";
import BasicBanner from "../BasicBanner";
import { color, fontType, util } from "../../styles/style";

type TProps = PropsFromRedux & {
  promos?: any;
  student?: any;
  isCol?: boolean;
};

const BannerWrapper = styled.div`
  background-color: ${color.blueDarkest};
  padding: ${util.padding};
  border-radius: ${util.radiusSmall};
  font-size: ${fontType.copy};
  color: ${color.blueLightest};
  display: flex;
  width: 100%;
  height: 100px;
  position: relative;
`;

const PromoSection: React.FC<TProps> = ({ promos, student, isCol = false }) => {
  if (isLoaded(student) && isLoaded(promos)) {
    let promo;
    if (student.accountType === "trial") {
      promo = promos["trial"];
      return <BasicBanner isCol={isCol || false} promo={promo} />;
    } else {
      const qTotal = get(student, "all.total");
      const qCorrect = get(student, "all.correct");
      const overall = (qCorrect / qTotal) * 100;

      if (overall > 64) {
        promo = promos["pass"];
      } else if (overall <= 64) {
        promo = promos["fail"];
      } else {
        promo = promos["default"];
      }
      return <BasicBanner isCol={isCol} promo={promo} />;
    }
  }

  return (
    <BannerWrapper>
      <Loading />
    </BannerWrapper>
  );
};

const mapStateToProps = ({ firebase }) => ({
  promos: firebase.data.promos,
  student: firebase.profile,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(firebaseConnect(["promos"]), connector)(PromoSection);

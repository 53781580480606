const initState = null;

const setupStatus = (state = initState, action) => {
  switch (action.type) {
    case "SETUP_STATUS":
      return action.payload;

    default:
      return state;
  }
};

export default setupStatus;

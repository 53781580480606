import React from "react";
import ReactExport from "react-data-export";
import { TStudent } from "../../../../../types";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const titleStyle = {
  font: {
    sz: "12",
    bold: true,
    color: {
      rgb: "FF117ba6",
    },
  },
};

const headerStyle = {
  font: {
    sz: "11",
    bold: true,
  },
};

const textStyle = {
  font: {
    sz: "11",
  },
};

const studentColumns = [
  {
    title: "Account Type",
    style: headerStyle,
    width: { wch: 15 },
  },
  {
    title: "Creation Date/Time",
    style: headerStyle,
    width: { wch: 20 },
  },
  {
    title: "Student First Name",
    style: headerStyle,
    width: { wch: 20 },
  },
  {
    title: "Student Last Name",
    style: headerStyle,
    width: { wch: 20 },
  },
  {
    title: "Email Address",
    style: headerStyle,
    width: { wch: 25 },
  },
  {
    title: "Status",
    style: headerStyle,
    width: { wch: 11 },
  },
];

interface IExcelExport {
  button: Element;
  students: TStudent[];
  searchPerformed: boolean;
  searchOptions: Array<{
    label: string;
    value: string | number | boolean | Date;
  }>;
}

function ExcelExport({ students, button, searchOptions }: IExcelExport) {
  const titleDate = new Date().toLocaleDateString().replace(/\//g, "-");

  const searchInfo = searchOptions.map((option) => {
    if (option.value === null) {
      option.value = "";
    } else if (typeof option.value === "boolean") {
      option.value = option.value ? "Yes" : "No";
    } else if (
      option.value &&
      typeof option.value === "object" &&
      option.value.toLocaleString
    ) {
      option.value = option.value.toLocaleString("en-US");
    }

    return [
      { value: `${option.label}`, style: headerStyle },
      { value: `${option.value}`, style: textStyle },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
    ];
  });

  const studentsData = students.map((student) => [
    { value: student.accountType, style: textStyle },
    {
      value: new Date(student.startDate.seconds * 1000).toLocaleString("en-US"),
      style: textStyle,
    },
    { value: student.firstName, style: textStyle },
    { value: student.lastName, style: textStyle },
    { value: student.email, style: textStyle },
    { value: student.isActive ? "Active" : "Inactive", style: textStyle },
  ]);

  const table = [
    {
      columns: [
        {
          title: "Student Export",
          width: { wpx: 110 },
          style: titleStyle,
        },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
      ],
      data: searchInfo,
    },
    {
      ySteps: 1,
      columns: studentColumns,
      data: studentsData,
    },
  ];

  return (
    <div>
      <ExcelFile
        filename={"Student Export Report " + titleDate}
        element={button}
      >
        <ExcelSheet dataSet={table} name="Organization" />
      </ExcelFile>
    </div>
  );
}

export default React.memo(ExcelExport, (prev, next) => {
  if (next.searchPerformed || (!prev.students.length && next.students.length)) {
    return false;
  }

  return true;
});

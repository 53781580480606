import React, { Component } from "react";

import * as d3 from "d3";
import { subjectCodes } from "../../_CONS/qbanks";

import Loading from "../../components/Loading";

import ChartSideBySide from "../ChartSideBySide";

class BySubjectBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphdata: null,
    };
  }

  componentDidMount() {
    this.prepareDataForCharts()
  }

  prepareDataForCharts() {
    const gdata = this.createChartData();

    const dividedData = () => {
      const dataArray = []

      if (gdata.length > 6) {
        dataArray.push([...gdata.splice(0, 6)])
        dividedData()
      }
      dataArray.push(gdata)

      return dataArray
    }

    const modifedData = dividedData()
    this.setState({ graphdata: modifedData });
  }

  createChartData() {
    const { data } = this.props;

    const bySubjectCode = d3
      .nest()
      .key(function (d) {
        return d.subjectCode;
      })
      .entries(data);

    const mymp = bySubjectCode.map(function (item) {
      const innerarray = item.values;
      const total = innerarray.length;

      const correct = innerarray.filter((q) => q.isCorrect === true).length;
      const wrong = total - correct;

      if (!subjectCodes[item.key]) return

      const subjectObject = subjectCodes[item.key];
      const rawLabel = subjectObject.label;
      const rawLabelArray = rawLabel.split(" ");
      let subjectLabel;
      const word1 = rawLabelArray[0];
      if (rawLabelArray.length === 2 || rawLabelArray.length === 4) {
        const word2 = rawLabelArray[1];
        subjectLabel = `${word1} ${word2}`;
      }
      else if (rawLabelArray.length > 2) {
        const word2 = rawLabelArray[1];
        const word3 = rawLabelArray[2];
        subjectLabel = `${word1} ${word2} ${word3}`;
      } else {
        subjectLabel = `${word1}`;
      }

      return {
        subject: item.key,
        rawLabel,
        subjectLabel,
        total: total,
        incorrect: wrong,
        correct,
        topArea: 1,
      };
    });

    return mymp;
  }

  render() {
    const { width, height, minWidth } = this.props;
    const { graphdata } = this.state;

    return (
      <>
        {graphdata ? (
          <>
            {graphdata.map((data, index) => (
              <ChartSideBySide
                isLegend={index < 1}
                data={data}
                width={width}
                height={height}
                minWidth={minWidth}
              />
            ))}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

BySubjectBar.propTypes = {};

export default BySubjectBar;

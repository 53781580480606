import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

//PROD
const config = {
  apiKey: "AIzaSyCVRUYoWH5b3K6Jt9QGq_jFL81WkIiDWAM",
  authDomain: "mbe-qbank.firebaseapp.com",
  databaseURL: "https://mbe-qbank.firebaseio.com",
  projectId: "mbe-qbank",
  storageBucket: "mbe-qbank.appspot.com",
  messagingSenderId: "560557331651",
  appId: "1:560557331651:web:a8d880539e6766e3",
};

// DEV
// const config = {
//   apiKey: "AIzaSyAgfrUffAGvuj0fJhDGBQTtAx64cemcC3M",
//   authDomain: "jdadv-dev.firebaseapp.com",
//   databaseURL: "https://jdadv-dev-default-rtdb.firebaseio.com",
//   projectId: "jdadv-dev",
//   storageBucket: "jdadv-dev.appspot.com",
//   messagingSenderId: "401477360603",
//   appId: "1:401477360603:web:b60b5a233415bdff7ae5fe",
//   measurementId: "G-FFD9Y73XDW",
// };

firebase.initializeApp(config);
export default firebase;

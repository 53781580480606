import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useParams } from "react-router";
import { useFirestoreConnect } from "react-redux-firebase";
import styled from "styled-components/macro";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import { MainScreen } from "../../../../containers/Main";
import AvatarCard from "../../../../components/AvatarCard";
import { Card, CardContent } from "../../../../containers/Card";
import { RootState } from "../../../../store/createReduxStore";
import { TOrganization } from "../../../../../types";
import Profile from "../Profile";
import { get } from "lodash";
import { actionTypes } from "redux-firestore";
import Users from "../Users";
import Students from "../Students";
import OrganizationLink from "../OrganizationLink";
import Offers from "../Offers/Offers";
import LoadingModal from "../../../../components/LoadingModal";
import BackButton from "../../../../elements/BackButton";
import Tests from "../Tests";
import { TUser } from "../../../../types/TYPES";

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;

type RouteParams = {
  id: string;
};

const tabs = ["PROFILE", "USERS", "STUDENTS", "TESTS", "OFFERS", "LINK"];

const View = (props) => {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  useFirestoreConnect([
    {
      collection: "organizations",
      doc: params.id,
      storeAs: "currentOrganization",
    },
    {
      collection: "users",
      where: [
        ["organization", "==", params.id],
        ["role", "==", "admin"],
      ],
      storeAs: "organizationUsers",
    },
  ]);
  const [tabIndex, setTabIndex] = useState(0);
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState("");
  const organizationUsers: TUser[] = useSelector(
    (state: RootState) => state.firestore.ordered.organizationUsers || []
  );
  const organization: TOrganization | null = useSelector((state: RootState) =>
    get(state.firestore.data, "currentOrganization")
  );
  useEffect(() => {
    const pathname = props.location.pathname;
    const tabIndex = tabs.indexOf(
      pathname.substr(pathname.lastIndexOf("/") + 1).toUpperCase()
    );

    if (tabIndex >= 0) {
      setTabIndex(tabIndex);
    } else {
      setTabIndex(0);
    }
  }, [props.location.pathname]);

  useEffect(() => {
    if (avatarFile) {
      setAvatarSrc(URL.createObjectURL(avatarFile));
    }
  }, [avatarFile]);

  useEffect(() => {
    if (organization && !avatarFile) {
      setAvatarSrc(organization.avatarUrl);
    }
  }, [organization]);

  useEffect(() => {
    return () => {
      setAvatarFile(null);
      setAvatarSrc("");
      dispatch({
        type: actionTypes.CLEAR_DATA,
        preserve: { data: ["currentOrganization"] },
      });
    };
  }, []);

  const handleChange = (event, tabIndex) => {
    setTabIndex(tabIndex);
  };

  const goBackScreen = (e) => {
    const { history } = props;
    history.push(`/admin/organizations`);
    e.preventDefault();
  };

  if (!organization) {
    return null;
  }

  return (
    <Screen>
      <MainScreen
        title="Organization Profile"
        leftSideItem={<BackButton onClick={goBackScreen} />}
        spacing={3}
      >
        <LoadingModal open={organization.id !== params.id} />

        <Grid item md={4} xs={12}>
          <Grid container spacing={3} direction={"column"}>
            <Grid item>
              <AvatarCard
                avatarSrc={avatarSrc}
                withUploader
                onUpload={setAvatarFile}
                user={{
                  initials: organization.name
                    .replace(/\s/g, "")
                    .substr(0, 2)
                    .toUpperCase(),
                  name: organization.name,
                }}
              />
            </Grid>
            <Grid item />
          </Grid>
        </Grid>

        <Grid item md={8} xs={12}>
          <Card>
            <>
              <Tabs
                value={tabIndex}
                scrollButtons={"auto"}
                variant={"scrollable"}
                onChange={handleChange}
              >
                {tabs.map((tab, index) => (
                  <Tab
                    disableRipple
                    label={tab}
                    key={index}
                    onClick={() => {
                      const { history, match } = props;
                      history.push(match.url + "/" + tab.toLowerCase());
                    }}
                  />
                ))}
              </Tabs>

              <CardContent>
                <Switch>
                  <Route path={`${props.match.url}/users`} component={Users} />
                  <Route
                    path={`${props.match.url}/students`}
                    component={Students}
                  />
                  <Route
                    path={`${props.match.url}/tests`}
                    component={() => <Tests organizationId={organization.id} />}
                  />
                  <Route
                    path={`${props.match.url}/offers`}
                    component={() => (
                      <Offers organizationId={organization.id} />
                    )}
                  />
                  <Route
                    path={`${props.match.url}/link`}
                    component={OrganizationLink}
                  />
                  <Route
                    path={`${props.match.url}/`}
                    component={() => (
                      <Profile
                        avatarFile={avatarFile}
                        organization={organization}
                        organizationUsers={organizationUsers}
                      />
                    )}
                  />
                </Switch>
              </CardContent>
            </>
          </Card>
        </Grid>
      </MainScreen>
    </Screen>
  );
};

View.displayName = "OrganizationView";

export default View;

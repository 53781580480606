import { TStateAction } from "../../types/TYPES";
import { PromoActions } from "../actions/promoActions";

type TPromoState = {
  status: "loading" | "failure" | "success" | "";
  error: string;
};

const initialState: TPromoState = {
  status: "",
  error: "",
};

const promoReducer = (
  state = initialState,
  action: TStateAction
): TPromoState => {
  const { payload } = action;

  switch (action.type) {
    case PromoActions.CREATE.REQUESTED:
    case PromoActions.UPDATE.REQUESTED:
      return {
        ...state,
        status: "loading",
        error: "",
      };

    case PromoActions.CREATE.SUCCESS:
    case PromoActions.UPDATE.SUCCESS:
      return {
        ...state,
        status: "loading",
        error: "",
      };

    case PromoActions.CREATE.FAILURE:
    case PromoActions.UPDATE.FAILURE:
      return {
        ...state,
        status: "failure",
        error: payload,
      };

    default:
      return state;
  }
};

export default promoReducer;

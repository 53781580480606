import { useCallback, useState } from "react";
import firebase from "../store/api/firebaseAdmin";

type TResponse<T> = [
  {
    isLoading: boolean;
    error: string;
    data: T | null;
  },
  (uid: string) => void
];

function useUser<T>(): TResponse<T> {
  const [state, setState] = useState({
    isLoading: true,
    error: "",
    data: null,
  });

  const get = (uid: string) => {
    if (uid) {
      setState({ ...state, isLoading: true });

      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setState({
              error: "",
              isLoading: false,
              data: doc.data(),
            });
          } else {
            setState({
              error: "The User doesn't exist.",
              isLoading: false,
              data: null,
            });
          }
        })
        .catch((e) => {
          setState({
            error: e.message,
            isLoading: false,
            data: null,
          });
        });
    } else {
      setState({
        error: "",
        isLoading: false,
        data: null,
      });
    }
  };

  return [state, useCallback(get, [])];
}

export default useUser;

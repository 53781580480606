import { Card, Input } from "@material-ui/core";
import styled from "styled-components/macro";
import theme from "../../styles/theme";

const { subtitle1, body2 } = theme.typography;

export const QuestionCard = styled(Card)`
  .MuiCardContent-root {
    padding: ${theme.spacing(2)}px;
  }

  .mb-3 {
    margin-bottom: ${theme.spacing(3)}px;
  }

  .mr-3 {
    margin-right: ${theme.spacing(3)}px;
  }
`;

export const TitleInput = styled(Input)`
  margin-bottom: ${theme.spacing(2.4)}px;
  font-size: ${subtitle1.fontSize};
  font-weight: ${subtitle1.fontWeight};
`;

export const AnswerInput = styled(Input)`
  font-size: ${body2.fontSize};
  line-height: 1.5;
  font-family: inherit;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  margin: 0px;
  resize: none;
`;

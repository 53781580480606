import React from "react";
import { Label, QTWrapper, Value } from "./StyledQuestionTotal";

const QuestionTotal = (props) => {
  const { total } = props;

  return (
    <QTWrapper>
      <Label>Total Questions</Label>
      <Value>{total}</Value>
    </QTWrapper>
  );
};

export default QuestionTotal;

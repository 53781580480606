import React, { Component } from "react";
import styled from "styled-components/macro";
import { color, util } from "../../../../styles/style";

const SideBarWrapper = styled.aside`
  background-color: ${color.white};
  width: 100%;
  z-index: 5;
  position: relative;
`;

const SideBarExtras = styled.div`
  padding: ${util.padding};
`;

const Line = styled.hr`
  background-color: ${color.blueLightest};
  border: 0;
  height: 1px;
  width: 100%;
`;

class PreviewSidebar extends Component {
  render() {
    return (
      <SideBarWrapper>
        <Line />
        {this.props.children && (
          <SideBarExtras>{this.props.children}</SideBarExtras>
        )}
      </SideBarWrapper>
    );
  }
}

export default PreviewSidebar;

import React from "react";
import PropTypes from "prop-types";
/*** DATA, STORE and STATE  ***/
/*** ROUTER  ***/
/*** STYLE  ***/
import { withStyles } from "@material-ui/core/styles";
/*** UI LIBS  ***/
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import faqs from "./faq";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
  },
  copy: {
    marginBottom: 10,
    fontSize: theme.typography.pxToRem(14),
  },
});

class HelpExpandPanels extends React.Component {
  state = {
    expanded: null,
  };

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        {faqs.map((item, index) => {
          return (
            <Accordion
              key={`panel${index}`}
              expanded={expanded === `panel${index}`}
              onChange={this.handleChange(`panel${index}`)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant={"body2"} className={classes.heading}>
                  {item.panelTitle}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant={"body2"}
                  className={classes.copy}
                  dangerouslySetInnerHTML={{
                    __html: item.panelCopy,
                  }}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  }
}

HelpExpandPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HelpExpandPanels);

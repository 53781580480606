import React from "react";
import styled from "styled-components/macro";
import SimpleTable from "../../components/SimpleTable";
import StatsRowFlat from "../../components/StatsRowFlat";
import { subjectCodes } from "../../_CONS/qbanks";

type TProps = {
  section: string;
  appSection: string;
  items: any[];
  loadCurrentItem: (id: string) => void;
  resetGuide?: () => void;
};

const TableStats = styled.div`
  margin: 1rem 0;
`;

const Container = styled.div`
  width: 100%;
  padding: 0;
  overflow: hidden;
`;

const ResultsPanel: React.FC<TProps> = (props: TProps) => {
  const { section, loadCurrentItem, appSection } = props;

  const items = props.items.map((item) => {
    const questionsPerSubject = item.questions.reduce((result, question) => {
      const targetIndex = result.findIndex(
        (q) => q.subjectCode === question.subjectCode
      );
      const subject = subjectCodes[question.subjectCode]
        ? subjectCodes[question.subjectCode].label
        : question.subjectCode;

      if (targetIndex >= 0) {
        result[targetIndex] = {
          subject,
          subjectCode: question.subjectCode,
          number: result[targetIndex].number + 1,
        };
      } else {
        result.push({
          subject,
          subjectCode: question.subjectCode,
          number: 1,
        });
      }

      return [...result];
    }, []);

    return {
      ...item,
      questionsPerSubject,
    };
  });

  const correct = items.reduce((acc, item) => acc + item.questionsCorrect, 0);
  const wrong = items.reduce((acc, item) => acc + item.questionsWrong, 0);
  const total = correct + wrong
  const rawoverall = (correct / total) * 100;
  const overall = isNaN(rawoverall) ? "0" : rawoverall.toFixed(1);
  const stats = {
    total,
    correct,
    wrong,
    overall,
  };

  return (
    <Container>
      <h2 id="tableTitle">{section}</h2>
      <TableStats>
        <StatsRowFlat stats={stats} section={section} appSection={appSection} />
      </TableStats>
      <SimpleTable data={items} setCurrentItem={loadCurrentItem} />
    </Container>
  );
};

export default ResultsPanel;

import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { color } from "../../../styles/style";

export const CardDirty = styled.div`
  ${(props) =>
    props.isDirty &&
    `
    background-color: ${color.blueLightest};
  `};
`;

CardDirty.propTypes = {
  isDirty: PropTypes.bool.isRequired,
};

export default CardDirty;

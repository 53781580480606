import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";

import { MainScreen } from "../../../../containers/Main";
import { FormControl } from "../../../../containers/Form";
import LoadingModal from "../../../../components/LoadingModal";
import { Card, CardContent } from "../../../../containers/Card";
import {
  createNewAdmin,
  resetUserView,
} from "../../../../store/actions/adminActions";
import BackButton from "../../../../elements/BackButton";

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;

class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      role: "admin",
      status: "active",
    };
    this.goBackScreen = this.goBackScreen.bind(this);
  }

  componentDidMount() {
    this.resetFormFields();
  }

  resetFormFields() {
    this.setState({
      email: "",
      firstName: "",
      lastName: "",
      password: Math.random().toString(36).slice(-8),
    });
  }

  goBackScreen(e) {
    const { history } = this.props;
    history.push(`/admin/users`);
    e.preventDefault();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSelectChange = (event) => {
    this.setState({ accountType: event.target.value });
  };

  handleDialogCancel = () => {
    this.props.resetUserView();
    this.props.history.push(`/admin/users`);
  };

  handleDialogConfirm = (type) => {
    const { resetUserView } = this.props;

    if (type === "create-user-error") {
      resetUserView();
    } else {
      resetUserView();
      this.resetFormFields();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { isDialogOpen, dialogStatus, dialogType, ...sobj } = this.state;
    const { createNewAdmin } = this.props;
    const studentObj = {
      ...sobj,
    };
    createNewAdmin(studentObj);
  };

  render() {
    const { email, password, firstName, lastName } = this.state;
    const { status } = this.props;

    return (
      <>
        <LoadingModal open={status === "loading"} />

        <Screen>
          <MainScreen
            title="New User"
            leftSideItem={<BackButton onClick={this.goBackScreen} />}
            spacing={3}
          >
            <Grid item xs={12}>
              <Card>
                <form onSubmit={this.handleSubmit}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs>
                        <FormControl margin="normal" required>
                          <InputLabel htmlFor="firstName">
                            First Name
                          </InputLabel>
                          <Input
                            type="text"
                            name="firstName"
                            value={firstName}
                            id="firstName"
                            autoFocus
                            onChange={this.handleChange}
                          />
                        </FormControl>
                        <FormControl margin="normal" required>
                          <InputLabel htmlFor="lastName">Last Name</InputLabel>
                          <Input
                            type="text"
                            name="lastName"
                            value={lastName}
                            id="lastName"
                            onChange={this.handleChange}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs>
                        <FormControl margin="normal" required>
                          <InputLabel htmlFor="email">Email Address</InputLabel>
                          <Input
                            id="email"
                            name="email"
                            value={email}
                            autoComplete="email"
                            onChange={this.handleChange}
                          />
                        </FormControl>
                        <FormControl margin="normal" required>
                          <InputLabel htmlFor="passwordg">Password</InputLabel>
                          <Input
                            name="passwordg"
                            type="text"
                            value={password}
                            id="passwordg"
                            readOnly
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          Create User
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </Card>
            </Grid>
          </MainScreen>
        </Screen>
      </>
    );
  }
}

New.propTypes = {
  status: PropTypes.string,
};

const mapStateToProps = ({ userStatus }) => ({
  status: userStatus.status,
});

const mapDispatchToProps = {
  createNewAdmin,
  resetUserView,
};

export default connect(mapStateToProps, mapDispatchToProps)(New);

import React from "react";
import MuiTab from "@material-ui/core/Tab";
import { TabProps } from "material-ui";
import styled from "styled-components/macro";

const StyledTab = styled(MuiTab)`
  texttransform: initial;
  minwidth: 72;

  &:hover: {
    color: #40a9ff;
    opacity: 1;
  }

  &$tabSelected: {
    color: #1890ff;
    fontweight: theme.typography.fontWeightMedium;
  }

  &:focus: {
    color: #40a9ff;
  }
`;

const Tab: React.FC<TabProps> = (props) => (
  <StyledTab {...props}>{props.children}</StyledTab>
);

export default Tab;

import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Button from "../../elements/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect, ConnectedProps } from "react-redux";
import {
  CloseButton,
  Container,
  Content,
  Info,
  Text,
} from "./StyledPervasiveNotification";

type TProps = PropsFromRedux & {
  id: string;
  text: string;
  buttonText: string;
  onAction: () => void;
};

const PervasiveNotification: React.FC<TProps> = (props: TProps) => {
  const storageKey = `PervasiveNotification_${props.id}`;
  const [isClosed, setClosedFlag] = useState(
    sessionStorage.getItem(storageKey) === "closed"
  );

  useEffect(() => {
    if (props.authAction === "logout") {
      sessionStorage.setItem(storageKey, "open");
    }
  }, [props.authAction]);

  const onClose = () => {
    sessionStorage.setItem(storageKey, "closed");
    setClosedFlag(true);
  };

  if (
    isClosed ||
    props.userRole !== "student" ||
    props.accountType !== "trial"
  ) {
    return null;
  }

  return (
    <Container data-testid="pervasiveNotification">
      <Content>
        <Info>
          <StarBorderIcon fontSize={"large"} />
          <Text variant={"subtitle2"} align={"center"}>
            {props.text}
          </Text>
        </Info>
        <Grid container justify={"center"}>
          <Button
            data-testid="notificationAction"
            color={"secondary"}
            onClick={() => props.onAction()}
          >
            {props.buttonText}
          </Button>
        </Grid>
      </Content>

      <CloseButton title={"close notification"} onClick={onClose}>
        <CancelIcon color={"disabled"} fontSize={"large"} />
      </CloseButton>
    </Container>
  );
};

const mapStateToProps = ({ firebase: { profile }, auth }) => ({
  userRole: profile.isEmpty ? null : profile.role,
  authAction: auth.action,
  accountType: profile.accountType,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PervasiveNotification);

import PropTypes from "prop-types";
/*** ui and style ***/
import styled from "styled-components/macro";

const drawerWidth = 300;
const contentShift = {
  width: `calc(100% - ${drawerWidth}px)`,
  transition: `all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms`,
};
const GuideFooter = styled.div`
  width: 100%;
  background: #cfdae6;
  bottom: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  ${(props) => props.isDesktop && contentShift}
`;
GuideFooter.propTypes = {
  isDesktop: PropTypes.bool,
};

export default GuideFooter;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Card, CardHeader } from "../../containers/Card";
import firebase from "../../store/api/firebaseAdmin";
import EditPromo from "./EditPromo";
import NewBanner from "../../promos/NewBanner";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    textTransform: "uppercase",
  },
});

class Promos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoList: [],
    };
    this.promoRef = firebase.database().ref("promos");
  }

  onListUpdate = (snapshot) => {
    const items = [];
    snapshot.forEach(function (childsnap) {
      items.push({ id: childsnap.key, data: childsnap.val() });
    });
    this.setState({
      promoList: items,
    });
  };

  componentDidMount() {
    this.promoRef.on("value", (snapshot) => this.onListUpdate(snapshot));
  }

  componentWillUnmount() {
    this.promoRef.off();
  }

  render() {
    const { classes } = this.props;
    const { promoList } = this.state;

    return (
      <Card>
        <CardHeader title="Promotional CTAs" />
        {/* {promoList.length > 0 ? ( */}
        {promoList.map((item) => {
          return (
            <Accordion key={item.id} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant={"body2"} className={classes.heading}>
                  {item.data.type}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EditPromo pid={item.id} promo={item.data} />
              </AccordionDetails>
            </Accordion>
          );
        })}

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant={"body2"} className={classes.heading}>
              default
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <NewBanner />
          </AccordionDetails>
        </Accordion>
        {/* )} */}
        <></>
      </Card>
    );
  }
}

Promos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Promos);

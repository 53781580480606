import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Card, CardContent } from "../../containers/Card";
import ResultBox from "../../elements/ResultBox";

const StatsRowCards = ({ allTotal, allCorrect }) => {
  const rawOverall = (allCorrect / allTotal) * 100;
  const total = isNaN(allTotal) ? 0 : allTotal;
  const overall = isNaN(rawOverall) ? "0" : rawOverall.toFixed(1);
  const correct = allCorrect > 0 ? allCorrect : 0;
  const wrong = allTotal - allCorrect;
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} sm={4}>
        <Card>
          <CardContent>
            <ResultBox stat={overall} label="Overall" percent />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm>
        <Card>
          <CardContent>
            <ResultBox stat={total} label="Questions" />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm>
        <Card>
          <CardContent>
            <ResultBox stat={correct} label="Correct" type="correct" />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm>
        <Card>
          <CardContent>
            <ResultBox stat={wrong} label="Incorrect" type="wrong" />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
StatsRowCards.propTypes = {
  allTotal: PropTypes.number,
  allCorrect: PropTypes.number,
};

export default StatsRowCards;

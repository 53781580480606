import React from "react";
import { Route, Switch } from "react-router-dom";
import StudyGuideList from "./StudyGuideList";
import StudyGuideBuilder from "./StudyGuideBuilder";
import Guide from "../../../screens/Guide";
import { Redirect } from "react-router";

const Study = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/guide`} component={Guide} />
    <Route path={`${match.url}/build`} component={StudyGuideBuilder} />

    <Route exact path={`${match.url}`} component={StudyGuideList} />
    <Redirect to={match.url} />
  </Switch>
);

export default Study;

import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Grid, TextField} from "@material-ui/core";
import entries from 'lodash/entries'
import Button from "../../elements/Button";
import {
  CancelIcon,
  CheckIcon,
  Hidden,
  ImportResult,
} from "./StyledStudentImport";
import {importStudents, TImportRow} from "../../store/actions/studentActions";
import * as XLSX from "xlsx";
import ExportReport from "./ExportReport";
import {RootState} from "../../store/createReduxStore";
import LoadingModal from "../LoadingModal";
import {showAlert} from "../../store/actions/alertActions";

type TProps = {
  organizationId: string;
  onFinish?: (file: File) => void;
};

const StudentImport = ({organizationId, onFinish}: TProps) => {
  const dispatch = useDispatch();
  const exportToExcelRef = useRef(null);

  const status = useSelector((state: RootState) => state.userStatus.status);

  const [file, setFile] = useState(null);
  const [importResult, setImportResult] = useState(null);
  const [importLog, setImportLog] = useState([]);

  useEffect(() => {
    if (importLog.length) {
      exportToExcelRef.current.click();
    }
  }, [importLog]);

  const onUpload = () => {
    if (organizationId && file) {
      const reader = new FileReader();

      if (file.name.endsWith(".xlsx")) {
        reader.onload = (e) => {
          const document = XLSX.read(e.target.result, {type: "binary"});
          const sheetName = document.SheetNames[0];

          const data: TImportRow[] = XLSX.utils
            .sheet_to_json(document.Sheets[sheetName]).map((item) => {
              entries(item).forEach(([key, value]) => {
                item[key] = typeof value === "string" ? value.trim() : value
              })

              return item
            }) as TImportRow[];

          dispatch(
            importStudents(file, data, organizationId, (response) => {
              setImportResult(data.length - response.length);
              setImportLog(
                response.map(
                  (item: { reason: string; [key: string]: unknown }) =>
                    item.reason
                )
              );

              typeof onFinish === "function" && onFinish(file);
            })
          );
        };

        reader.readAsBinaryString(file);
      } else {
        dispatch(
          showAlert({
            title: "Error",
            message: 'The file should have ".xlsx" extension',
          })
        );
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <LoadingModal open={status === "loading"}/>

      <Grid item container xs={12} justify={"space-between"}>
        <Grid item container spacing={3}>
          <Grid item>
            <TextField
              disabled
              label="Choose your file"
              value={file ? file.name : ""}
            />
          </Grid>

          <Grid item>
            <Button component={"label"} htmlFor={"file"}>
              Browse
            </Button>
          </Grid>

          <Grid item>
            <Button disabled={!file} onClick={onUpload}>
              Import
            </Button>
          </Grid>

          <Grid item xs={12}>
            {importResult !== null && (
              <ImportResult variant={"subtitle2"} success={!!importResult}>
                {importResult > 0 ? <CheckIcon/> : <CancelIcon/>}
                {importResult} student records imported successfully.
              </ImportResult>
            )}
          </Grid>

          <Hidden>
            <input
              id={"file"}
              type={"file"}
              accept={".xlsx"}
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
          </Hidden>
        </Grid>
      </Grid>

      <Hidden>
        <ExportReport
          data={importLog}
          button={<button ref={exportToExcelRef}>Export to excel</button>}
        />
      </Hidden>
    </Grid>
  );
};

export default StudentImport;

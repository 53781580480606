import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import { openGuide } from "../../../../store/actions/guideActions";
import StudyGridBox from "../../../../components/StudyGridBox";
import PromoSection from "../../../../promos/PromoSection";
import { MainScreen } from "../../../../containers/Main";
import InfoCard from "../../../../components/InfoCard";
import Button from "../../../../elements/Button";
import { Typography } from "@material-ui/core";
import TITLES from "../../../../_CONS/TITLES";

class StudyGuideList extends Component {
  state = {
    guideId: null,
  };
  setCurrentStudyGuide = (id, e) => {
    e.preventDefault();
    this.setState({ guideId: id });

    const guideObj = {
      section: "guide",
      guideType: "studyguides",
      guideId: id,
    };
    this.props.openGuide(guideObj).then(() => {
      this.props.history.push(`${this.props.history.location.pathname}/guide`);
    });
  };

  render() {
    const { guides, match } = this.props;

    return (
      <MainScreen title="Study Aids">
        <Grid item xs={12}>
          <InfoCard
            title={TITLES.STUDY.title}
            txt={
              <>
                <Typography paragraph>{TITLES.STUDY.copy}</Typography>
                <Typography variant="subtitle1" display="inline">
                  Please note:{" "}
                </Typography>
                <Typography display="inline">{TITLES.STUDY.note}</Typography>
              </>
            }
            divider={true}
          >
            <Button
              component={Link}
              to={`${match.url}/build`}
              aria-label="Build a Study Aid"
            >
              Build a Study Aid
            </Button>
            <Button
              color="secondary"
              component={Link}
              to={`/student/review`}
              aria-label="Review Study Aids"
            >
              Review Study Aids
            </Button>
          </InfoCard>
        </Grid>
        <Grid item xs={12}>
          <PromoSection />
        </Grid>
        {guides &&
          guides.map((item) => {
            return (
              <StudyGridBox
                item={{
                  ...item,
                  questionsTotal: item.questions
                    ? item.questions.length
                    : item.questionsTotal,
                }}
                title={item.name}
                isComplete={item.completed}
                onClick={(e) => this.setCurrentStudyGuide(item.id, e)}
                key={item.id}
              />
            );
          })}
      </MainScreen>
    );
  }
}

const mapStateToProps = (state) => {
  const studyGuides = state.firestore.ordered.studyGuides;
  return {
    studentId: state.auth.uid,
    guides: studyGuides
      ? studyGuides.filter((guide) => guide.completed === false)
      : [],
  };
};

const mapDispatchToProps = { openGuide };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StudyGuideList);

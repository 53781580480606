import React, { useEffect, useRef, useState } from "react";
import { MainScreen } from "../../../containers/Main";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import {
  validate,
  validateCvv,
  validateExpDate,
  validateOffer,
} from "../../../utils/validator";
import Button from "../../../elements/Button";
import usStates from "../../../_CONS/usStates";
import {
  processUpgrade,
  resetUserView,
} from "../../../store/actions/adminActions";
import { resetSetupStatus } from "../../../store/actions/setupActions";
import { compose } from "redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import ThankYouMessage from "./parts/ThankYouMessage";
import {
  ButtonLoader,
  CompleteButtonWrapper,
  FormWrapper,
  OldPrice,
  Price,
  SelectFormControll,
  Subtitle,
} from "./StyledUpgrade";
import useOffers from "../../../hooks/useOffers";
import BackButton from "../../../elements/BackButton";
import { PRO_PRICE } from "../../../_CONS/CONSTS";

type TOffer = {
  id: string;
  valid: boolean;
  message: string;
};

type TProps = RouteComponentProps & PropsFromRedux;

const Upgrade: React.FC<TProps> = ({ userStatus, ...props }: TProps) => {
  const offers = useOffers({
    organizationId: props.organizationId,
    isActive: true,
  });

  const [offer, setOffer] = useState<null | TOffer>(null);
  const discountCodeTimer = useRef(null);
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({
    firstName: props.firstName,
    lastName: props.lastName,
    address1: "",
    address2: "",
    city: "",
    zip: "",
    state: "default",
    cardNumber: "",
    expDate: "",
    cvv: "",
    code: "",
  });

  useEffect(() => {
    offers.get();
  }, [props.organizationId]);

  useEffect(() => {
    if (offers.data.length) {
      const offerData = offers.data[0];

      setFields({
        ...fields,
        code: offerData.code,
      });

      if (validateOffer(offerData)) {
        setOffer({
          id: offerData.id,
          valid: true,
          message:
            "Your price: $" +
            (PRO_PRICE - PRO_PRICE * (offerData.discount / 100)).toFixed(2),
        });
      }
    } else {
      setOffer(null);
    }
  }, [offers.data]);

  useEffect(() => {
    if (discountCodeTimer.current) {
      clearTimeout(discountCodeTimer.current);
    }

    discountCodeTimer.current = setTimeout(async () => {
      if (fields.code) {
        offers.get({
          organizationId: props.organizationId,
          isActive: true,
          code: fields.code,
        });
      } else {
        setOffer(null);
      }
    }, 500);
  }, [fields.code]);

  const areFieldsFilled = () => {
    const {
      firstName,
      lastName,
      address1,
      city,
      zip,
      state,
      cardNumber,
      expDate,
      cvv,
    } = fields;

    return (
      firstName &&
      lastName &&
      address1 &&
      city &&
      zip &&
      state !== "default" &&
      cardNumber &&
      expDate &&
      cvv
    );
  };

  const onChange = (field: string) => (e) => {
    let value = e.target.value;

    if (field === "expDate") {
      value = value.replace(/[A-z]/, "");
      if (fields.expDate.length === 1 && value.length === 2) {
        value += "/";
      } else if (value.length === 3) {
        value = value.replace(/.$/, "/");
      }
    } else if (field === "cvv") {
      value = value.replace(/\D/, "");
    } else if (field === "cardNumber") {
      value = value.replace(/\D/, "");
    }

    setFields({
      ...fields,
      [field]: value,
    });
  };

  const completeOrder = () => {
    const errors = validate(fields, {
      cvv: [validateCvv],
      expDate: [validateExpDate],
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      props.processUpgrade({
        ...fields,
        offer: offer ? offer.id : null,
      });
    }
  };

  const onFinish = () => {
    props.resetSetupStatus();
    props.history.push("/student");
  };

  if (props.isProAccount && props.accountStatus === "current") {
    return <Redirect to="/student" />;
  }

  return (
    <MainScreen
      spacing={3}
      title={props.isProAccount ? "" : "Upgrade to a Qbank Pro Account!"}
      leftSideItem={
        !props.isProAccount && <BackButton onClick={props.history.goBack} />
      }
    >
      {props.isProAccount ? (
        <ThankYouMessage orderNumber={"12321390213"} onClick={onFinish} />
      ) : (
        <Grid item container direction={"column"}>
          <Grid item>
            {offer && offer.valid ? (
              <>
                <OldPrice>Qbank Pro: ${PRO_PRICE}</OldPrice>
                <Price>{offer && offer.message}</Price>
              </>
            ) : (
              <Price>Qbank Pro: ${PRO_PRICE}</Price>
            )}

            <Subtitle>Enter Discount Code</Subtitle>
          </Grid>

          <FormWrapper item container direction={"column"}>
            <FormControl margin="normal" required>
              <InputLabel htmlFor="firstName">Code</InputLabel>

              <Input
                id="code"
                name="code"
                value={fields.code}
                onChange={onChange("code")}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </FormControl>
          </FormWrapper>

          <Grid item>
            <Subtitle>Customer Information</Subtitle>
          </Grid>

          <FormWrapper item container direction={"column"}>
            <FormControl margin="normal" required>
              <InputLabel htmlFor="firstName">First Name</InputLabel>

              <Input
                id="firstName"
                name="firstName"
                value={fields.firstName}
                onChange={onChange("firstName")}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </FormControl>

            <FormControl margin="normal" required>
              <InputLabel htmlFor="lastName">Last Name</InputLabel>

              <Input
                value={fields.lastName}
                name="lastName"
                id="lastName"
                onChange={onChange("lastName")}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </FormControl>

            <FormControl margin="normal" required>
              <InputLabel htmlFor="address">Billing Address 1</InputLabel>

              <Input
                value={fields.address1}
                name="address"
                id="address"
                onChange={onChange("address1")}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </FormControl>

            <FormControl margin="normal">
              <InputLabel htmlFor="address2">Billing Address 2</InputLabel>

              <Input
                value={fields.address2}
                name="address2"
                id="address2"
                onChange={onChange("address2")}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </FormControl>

            <FormControl margin="normal" required>
              <InputLabel htmlFor="city">City</InputLabel>

              <Input
                value={fields.city}
                name="city"
                id="city"
                onChange={onChange("city")}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </FormControl>

            <Grid item container alignItems={"flex-end"}>
              <SelectFormControll margin={"normal"}>
                <InputLabel shrink={true} htmlFor="select-subject-code">
                  State
                </InputLabel>
                <Select
                  required
                  value={fields.state}
                  onChange={onChange("state")}
                >
                  <MenuItem value="default">Select...</MenuItem>
                  {usStates.map((state) => (
                    <MenuItem key={state} value={state} selected>
                      {" "}
                      {state}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </SelectFormControll>

              <FormControl margin={"normal"} required>
                <InputLabel htmlFor="zip">Zip</InputLabel>

                <Input
                  value={fields.zip}
                  name="zip"
                  id="zip"
                  onChange={onChange("zip")}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </FormControl>
            </Grid>
          </FormWrapper>

          <Grid item>
            <Subtitle>Payment Method</Subtitle>
          </Grid>

          <FormWrapper item container direction={"column"}>
            <FormControl margin="normal" required>
              <InputLabel htmlFor="cardNumber">Credit Card Number</InputLabel>

              <Input
                value={fields.cardNumber}
                name="cardNumber"
                id="cardNumber"
                onChange={onChange("cardNumber")}
                inputProps={{
                  maxLength: 19,
                }}
              />
            </FormControl>

            <FormControl margin="normal" error={!!errors["expDate"]}>
              <InputLabel htmlFor="expDate">Expiration Date</InputLabel>

              <Input
                value={fields.expDate}
                name="expDate"
                id="expDate"
                onChange={onChange("expDate")}
                inputProps={{
                  maxLength: 5,
                }}
              />
            </FormControl>

            <FormControl margin="normal" error={!!errors["cvv"]}>
              <InputLabel htmlFor="cvv">Security Code</InputLabel>

              <Input
                value={fields.cvv}
                name="cvv"
                id="cvv"
                onChange={onChange("cvv")}
                inputProps={{
                  maxLength: 4,
                }}
              />
            </FormControl>

            <CompleteButtonWrapper>
              <Button
                disabled={!areFieldsFilled() || userStatus.status === "loading"}
                onClick={completeOrder}
              >
                Complete My Order
              </Button>
              {userStatus.status === "loading" && <ButtonLoader size={24} />}
            </CompleteButtonWrapper>
          </FormWrapper>
        </Grid>
      )}
    </MainScreen>
  );
};

const mapStateToProps = ({ firebase: { profile, auth }, userStatus }) => ({
  firstName: profile.firstName,
  organizationId: profile.organization,
  studentId: auth.uid,
  lastName: profile.lastName,
  isProAccount: profile.accountType === "pro",
  accountStatus: profile.status,
  userStatus,
});

const mapDispatchToProps = {
  resetUserView,
  processUpgrade,
  resetSetupStatus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

Upgrade.displayName = "Upgrade";

export default compose(connector, withRouter)(Upgrade);

export default <T extends unknown>(input: string): T => {
  const isValidSearch = /^\??\w+=.*&?.*/.test(input);

  if (typeof input === "string" && isValidSearch) {
    const normalized = input.replace(/(^\?)|(&$)/, "");
    const params = normalized.split("&") || [normalized];

    const paramsMap = params.reduce((result: any, param: string) => {
      const pair = param.split("=");

      result[pair[0]] = pair[1];
      return result;
    }, {});

    return paramsMap;
  }

  return {} as T;
};

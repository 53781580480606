import React, { Component } from "react";

import { Bar, BarChart, Legend, ResponsiveContainer, XAxis } from "recharts";

import { color } from "../../styles/style";

const RenderLabel = (props) => {
  const { x, y, width, value, data, index, dataKey } = props;
  const dataObj = data[index];
  const ybuffer = -10;

  const fillColor = dataKey === "incorrect" ? color.redDark : color.secondary;
  if (value > 0) {
    return (
      <g>
        <text
          x={x + width / 2}
          y={y + ybuffer}
          fill={fillColor}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="13px"
          fontWeight="bold"
        >
          {dataObj[dataKey]}
        </text>
      </g>
    );
  }
  return null;
};

class ChartSideBySide extends Component {
  render() {
    const { data, width, height, minWidth, isLegend } = this.props;

    return (
      <ResponsiveContainer width={width} height={height} minWidth={minWidth}>
        <BarChart
          data={data}
          margin={{ top: 30, right: 10, left: 10, bottom: 10 }}
          maxBarSize={60}
        >
          <XAxis
            axisLine={false}
            tickLine={true}
            stroke={color.dark}
            height={15}
            style={{ fontSize: 13, fontWeight: 600 }}
            dataKey="subjectLabel"
          />
          {isLegend &&
            <Legend
              verticalAlign="top"
              align="right"
              iconType="circle"
              iconSize={10}
              height={56}
            />}
          <Bar
            stackId="a"
            type="monotone"
            dataKey="correct"
            fill={color.secondaryLight}
            label={<RenderLabel dataKey="correct" data={data} />}
            radius={[8, 8, 8, 8]}
          />
          <Bar
            stackId="b"
            type="monotone"
            dataKey="incorrect"
            fill={color.redLightest}
            label={<RenderLabel dataKey="incorrect" data={data} />}
            radius={[8, 8, 8, 8]}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

ChartSideBySide.propTypes = {};

export default ChartSideBySide;

export const getMessageFromError = (error: string): string => {
  switch (error) {
    case "auth/wrong-password":
    case "auth/invalid-email":
      return "Invalid login or password";
    case "auth/user-not-found":
      return "User not found";
    default:
      return "Unknown error";
  }
};

import { useCallback } from "react";
import XLSX from "xlsx";

import firebase from "../../../../store/api/firebaseAdmin";
import { TStudent } from "../../../../../types";
import convertToString from "../../../../utils/msToString";
import { subjectCodes } from "../../../../_CONS/qbanks";

import useReportData from "./useGetReportData";
import { studyInfoDefault, testsInfoDefault } from "./CONSTANTS";


interface UseGenerateExelProps {
  prepareStudentForExcel: (
    orgId: string, studentsIsChosen?: boolean
  ) => Promise<string[][]>,
  generateTimeReport: () => Promise<void>
}

export const useGenerateExcel = (
  { chosenStudents, organizations, chosenOrganization }:
    { chosenStudents: TStudent[], organizations: any[], chosenOrganization: string }
): UseGenerateExelProps => {

  const { getStudentsByOrgId, getTestStatistic, getStudyStatistic } = useReportData()

  const generateStudentData = useCallback(async (studyInfo, testInfo, organization, studentData) => {

    const studyData = [
      await studyInfo[`${subjectCodes.CON.label}`],
      await studyInfo[`${subjectCodes.KSL.label}`],
      await studyInfo[`${subjectCodes.CRI.label}`],
      await studyInfo[`${subjectCodes.CRI.label}`],
      await studyInfo[`${subjectCodes.EVD.label}`],
      await studyInfo[`${subjectCodes.TRT.label}`],
      await studyInfo[`${subjectCodes.RPL.label}`],
    ]
    const testData = [
      await testInfo['Practice Test 1'],
      await testInfo['Practice Test 2'],
      await testInfo['Practice Test 3'],
      await testInfo['Practice Test 4'],
      await testInfo['Practice Final Part 1'],
      await testInfo['Practice Final Part 2'],
      await testInfo['Practice Final Part 3'],
      await testInfo['Practice Final Part 4'],
    ]

    const totalCorrectAnswers = !!studentData.all ? studentData.all.correct : '-'
    const totalAnswered = !!studentData.all ? studentData.all.total : '-'
    const totalPercentage = !!+totalCorrectAnswers ?
      `${(totalCorrectAnswers / totalAnswered * 100).toFixed(0)}%` : '-'

    return [
      organization,
      studentData.firstName,
      studentData.lastName,
      studentData.email,
      !!totalCorrectAnswers ? totalCorrectAnswers : '-',
      !!totalAnswered ? totalAnswered : '-',
      totalPercentage,
      ...(studyData.flat()),
      ...(testData.flat())
    ]
  }, [])

  const prepareStudentForExcel = async (orgId: string, studentsIsChosen = false): Promise<string[][]> => {
    const excelStudents = [];

    if (studentsIsChosen) {
      for (const student of chosenStudents) {
        const testInfo = !!student.all && student.all.total ? await getTestStatistic(student) : testsInfoDefault
        const studyInfo = !!student.all && student.all.total ? await getStudyStatistic(student) : studyInfoDefault
        await firebase
          .firestore()
          .collection("users")
          .doc(student.id.trim())
          .get()
          .then(async (doc) => {
            const studentData = doc.data();
            const organization = organizations.find((item) => item.id === student.organization).name || ''
            const generatedData = await generateStudentData(studyInfo, testInfo, organization, studentData)

            excelStudents.push(generatedData)
          });
      }

      return excelStudents;
    } else {
      const studentsDocs = await getStudentsByOrgId(orgId).then(docs => docs.docs)

      if (studentsDocs.lenght < 1) excelStudents.push([organizations.find((item) => item.id === orgId).name]);

      for (let doc of studentsDocs) {
        const student = doc.data();
        const testInfo = !!student.all && student.all.total ? await getTestStatistic(student) : testsInfoDefault
        const studyInfo = !!student.all && student.all.total ? await getStudyStatistic(student) : studyInfoDefault
        const organization = organizations.find((item) => item.id === student.organization).name || ''
        const generatedData = await generateStudentData(studyInfo, testInfo, organization, student)

        excelStudents.push(generatedData)
      };

      return excelStudents;
    }
  }

  const generateTimeReport = useCallback(async (): Promise<void> => {

    const exelUsers = []
    const usersDocs = await firebase
      .firestore()
      .collection("users")
      .get()
      .then(data => data.docs)

    usersDocs.forEach(item => {
      let testsTime: number = 0
      let studytime: number = 0

      if (item.data().timings) {
        if (item.data().timings.testguides)
          testsTime = (Object.values(item.data().timings.testguides) as number[])
            .reduce((acc: number, item: number) => acc + item)

        if (item.data().timings.studyguides)
          studytime = (Object.values(item.data().timings.studyguides) as number[])
            .reduce((acc: number, item: number) => acc + item)
      }

      const totalSessionTime = (!testsTime && !studytime) ? ' ' : convertToString(testsTime + studytime)
      exelUsers.push({
        ['Student Last Name']: item.data().firstName,
        ['Student First Name']: item.data().lastName,
        ['Student Email']: item.data().email,
        ['Total Session Time']: totalSessionTime
      })
    })

    const sortedStudents = exelUsers.sort((a, b) => a['Student Last Name'].localeCompare(b['Student Last Name']))

    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.book_new();
    ws['!cols'] = [{ width: 20 }, { width: 20 }, { width: 40 }, { width: 20 }]
    XLSX.utils.sheet_add_json(ws, sortedStudents, { origin: "A5", });
    XLSX.utils.sheet_add_aoa(ws, [["Session Time Report"],])
    XLSX.utils.book_append_sheet(workbook, ws, "Session Time report");
    XLSX.writeFile(workbook, "session-time-report.xlsx", { type: "file" })
  }, [])

  return { prepareStudentForExcel, generateTimeReport };
}

export default useGenerateExcel;
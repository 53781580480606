import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { range as d3Range } from "d3";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronRight from "@material-ui/icons/ChevronRight";

import Button from "../../../elements/Button";
import DateTime from "../../../elements/DateTime";
import { MainScreen } from "../../../containers/Main";
import BySubjectBar from "../../../charts/BySubjectBar";
import PromoSection from "../../../promos/PromoSection";
import ResultBoxOverall from "../../../elements/ResultBoxOverall";
import { Card, CardContent, CardHeader } from "../../../containers/Card";
import StudentFAQ from "../../../components/StudentFAQ";
import { Box } from "@material-ui/core";
import { SETUP, STUDENT } from "../../../_CONS/routes";
import { minUpdateTime } from "../../../constants/CONSTS";

class Dashboard extends Component {
  state = {
    data: d3Range(34).map(Math.random),
    currentIndex: null,
  };

  render() {
    const { match, sgQs, tgQs, sgQsCorrect, tgQsCorrect, allQs, allQsCorrect, user, history } = this.props;

    if (history.location.pathname === STUDENT) {
      if (!user.rebuilded || (user.rebuilded < minUpdateTime)) {
        this.props.history.push(`${SETUP}/?force_setup`);
      }
    }

    return (
      <MainScreen title="Dashboard" rightSideItem={<DateTime>{new Date()}</DateTime>}>
        {allQs.length > 0 && (
          <>
            <Grid item xs={4}>
              <Card>
                <CardContent>
                  <ResultBoxOverall total={allQs.length} correct={allQsCorrect.length} label="Correct Overall" />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <CardContent>
                  <ResultBoxOverall total={sgQs.length} correct={sgQsCorrect.length} label="Correct Study Aids" />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <CardContent>
                  <ResultBoxOverall total={tgQs.length} correct={tgQsCorrect.length} label="Correct Tests" />
                </CardContent>
              </Card>
            </Grid>
          </>
        )}

        <Grid item container direction="column" md={6} xs={12}>
          <StudentFAQ />
        </Grid>

        <Grid item container wrap md={6} xs={12}>
          <Grid item xs={12} sm={6} md={12}>
            <Card>
              <CardHeader
                action={
                  <IconButton component={Link} to={`${match.url}/study`}>
                    <ChevronRight />
                  </IconButton>
                }
                title="Study Aids"
              />
              <Divider />
              <CardContent>
                <Button component={Link} to={`${match.url}/study/build`} aria-label="Build a Study Aid">
                  Build A Study Aid
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Hidden smUp>
            <Grid xs={12}>
              <Box mb={3} />
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={6} md={12}>
            <Card>
              <CardHeader
                action={
                  <IconButton component={Link} to={`${match.url}/test`}>
                    <ChevronRight />
                  </IconButton>
                }
                title="Tests"
              />
              <Divider />
              <CardContent>
                <Button component={Link} to={`${match.url}/test`} aria-label="Start a Test">
                  START A TEST
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <PromoSection />
        </Grid>
        {allQs.length > 0 && (
          <Grid container item xs={12}>
            <Card>
              <CardHeader title="Performance by Subject" />
              <Hidden xsDown>
                <CardContent>
                  <BySubjectBar data={allQs} width="100%" height={300} minWidth={800} />
                </CardContent>
              </Hidden>
              <Divider />
              <CardContent>
                <Button
                  component={Link}
                  to={`${match.url}/review`}
                  aria-label="Review"
                  color="default"
                  variant="outlined"
                >
                  Review
                </Button>
              </CardContent>
            </Card>
          </Grid>
        )}
      </MainScreen>
    );
  }
}

Dashboard.propTypes = {
  studentId: PropTypes.string,
  student: PropTypes.object,
  sgQs: PropTypes.array,
  tgQs: PropTypes.array,
  sgQsCorrect: PropTypes.array,
  tgQsCorrect: PropTypes.array,
  allQs: PropTypes.array,
  allQsCorrect: PropTypes.array,
  overall: PropTypes.number,
};

const mapStateToProps = state => {
  const { studyGuides, testGuides } = state.firestore.ordered;

  const sgQs = studyGuides
    ? studyGuides
        .map(guide => !!guide.completed && guide.questions)
        .flat()
        .filter(q => q !== undefined && q.answered)
    : [];

  const tgQs = testGuides
    ? testGuides
        .map(guide => !!guide.completed && guide.questions)
        .flat()
        .filter(q => q !== undefined && q.answered)
    : [];

  const allQs = [...sgQs, ...tgQs];

  const sgQsCorrect = sgQs.length ? sgQs.filter(q => q.isCorrect) : [];
  const tgQsCorrect = tgQs.length ? tgQs.filter(q => q.isCorrect) : [];
  const allQsCorrect = allQs.length > 0 ? allQs.filter(q => q.isCorrect) : [];

  return {
    user: state.firebase.profile,
    studentId: state.auth.uid,
    student: state.firestore.data.student,
    sgQs,
    tgQs,
    sgQsCorrect,
    tgQsCorrect,
    allQs,
    allQsCorrect,
    overall: allQsCorrect.length,
  };
};

export default connect(mapStateToProps)(Dashboard);

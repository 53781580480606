// @ts-nocheck
import React, { FunctionComponent, ReactNode } from "react";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import {
  HeaderInfo,
  HeaderTitle,
  Main,
  MainContent,
  ScreenHeader,
} from "./StyledMainScreen";
import PervasiveNotification from "../../../components/PervasiveNotification";
import { RouteComponentProps, withRouter } from "react-router";

type TProps = RouteComponentProps & {
  title?: string;
  spacing?: GridSpacing;
  leftSideItem?: ReactNode;
  rightSideItem?: ReactNode;
  children: ReactNode;
  withoutHeader?: boolean;
};

const MainScreen: FunctionComponent<TProps> = ({
  title = "",
  spacing = 3,
  leftSideItem,
  rightSideItem,
  withoutHeader,
  children,
  ...props
}: TProps) => {
  return (
    <Main>
      <PervasiveNotification
        id={"proAccountNotification"}
        text={
          "Want access to over 1,500 Official MBE Questions? Improve your odds of success by upgrading to a Qbank Pro Account today!"
        }
        buttonText={"Get Started!"}
        onAction={() => props.history.push("/student/upgrade")}
      />

      <MainContent>
        {leftSideItem && (
          <Grid container spacing={spacing} data-testid={"leftScreenAction"}>
            <Grid item xs={12}>
              {leftSideItem}
            </Grid>
          </Grid>
        )}

        {!withoutHeader && (
          <ScreenHeader>
            <Grid
              container
              justify="space-between"
              alignItems="flex-end"
              spacing={spacing}
            >
              <Grid item>
                <HeaderTitle>{title}</HeaderTitle>
              </Grid>
              {rightSideItem && (
                <Grid container justify="flex-end" item xs={6}>
                  <HeaderInfo>{rightSideItem}</HeaderInfo>
                </Grid>
              )}
            </Grid>
          </ScreenHeader>
        )}
        <Grid container spacing={spacing}>
          {children}
        </Grid>
      </MainContent>
    </Main>
  );
};

export default withRouter(MainScreen);

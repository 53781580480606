import styled from "styled-components/macro";
import { color, fontWeight } from "../../styles/style";
import { mediaMinDevice } from "../../styles/responsive";

export const TestCard = styled.div`
  color: ${color.dark};
  min-height: 100%;
  ${(props) =>
    props.isComplete &&
    `
    background-color: ${color.secondary};
    color: ${color.white};
    && label {
      color: ${color.white};
    }
  `};
`;

export const Stat = styled.div`
  font-size: 1.8rem;
  font-weight: ${fontWeight.bold};
  & span {
    font-weight: normal;
    margin-left: -7px;
  }
  ${mediaMinDevice.tablet} {
    font-size: 2.5rem;
  }
  ${mediaMinDevice.laptopL} {
    font-size: 3rem;
  }
`;

export const TimeStat = styled.div`
  font-size: 1.75rem;
  opacity: 0.9;
  font-weight: ${fontWeight.bold};
`;

export const Label = styled.label`
  color: ${color.blueLight};
  font-size: 0.825rem;
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
  margin-bottom: 10px;
  ${mediaMinDevice.tablet} {
    font-size: 1rem;
  }
  ${mediaMinDevice.laptopL} {
    font-size: 1.15rem;
  }
`;

export const TimeLabel = styled.label`
  color: ${color.blueLight};
  font-size: 0.825rem;
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
  margin-bottom: 10px;
  ${mediaMinDevice.tablet} {
    font-size: 1rem;
  }
  ${mediaMinDevice.laptopL} {
    font-size: 1.15rem;
  }
`;

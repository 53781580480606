import React, { Component } from "react";
import styled from "styled-components/macro";
import { mediaMinDevice } from "../../styles/responsive";

import QuestionTitle from "./QuestionTitle";
import QuestionCopy from "./QuestionCopy";
import AnswerItem from "./AnswerItem";
import FeedbackCard from "./FeedbackCard";
import FeedbackTitle from "./FeedbackTitle";
import FeedbackCopy from "./FeedbackCopy";
import Loading from "../../components/Loading";

const AnswersArea = styled.div`
  padding: 2rem 0 9rem;
  ${mediaMinDevice.tabletL} {
    padding: 3rem 0;
  }
`;
const FeedbackArea = styled.div`
  margin: 2rem 0 0;
`;

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerItems: ["A", "B", "C", "D"],
      userSelectedAnswer: null,
    };
    this.checkItemType = this.checkItemType.bind(this);
  }

  toggleAnswerItem(key, e) {
    const { selectUserAnswer } = this.props;
    selectUserAnswer(key);
    e.preventDefault();
  }

  checkItemType(key) {
    const { content, userAnswer } = this.props;
    const { correctAnswer } = content.qdata;

    let buttonType = "normal";
    if (key === userAnswer) {
      if (userAnswer === correctAnswer) {
        buttonType = "success";
      } else {
        buttonType = "wrong";
      }
    } else {
      if (key === correctAnswer) {
        buttonType = "success";
      }
    }
    return buttonType;
  }

  render() {
    const { content, hasFeedback, userAnswer } = this.props;
    const { answerItems } = this.state;

    const qobj = content !== null ? content.qdata : null;
    if (qobj) {
      return (
        <>
          <QuestionTitle>{`Question #${content.qnum}`}</QuestionTitle>
          <QuestionCopy dangerouslySetInnerHTML={{ __html: qobj.question }} />
          {hasFeedback && (
            <FeedbackArea>
              <FeedbackCard>
                <FeedbackTitle>Explanation</FeedbackTitle>
                <FeedbackCopy
                  className="correctFeedback"
                  dangerouslySetInnerHTML={{
                    __html: qobj.correctFeedback,
                  }}
                />
                <FeedbackCopy
                  className="feedback1"
                  dangerouslySetInnerHTML={{
                    __html: qobj.feedback1,
                  }}
                />
                {qobj.feedback2 && (
                  <FeedbackCopy
                    className="feedback2"
                    dangerouslySetInnerHTML={{
                      __html: qobj.feedback2,
                    }}
                  />
                )}
                {qobj.feedback3 && (
                  <FeedbackCopy
                    className="feedback3"
                    dangerouslySetInnerHTML={{
                      __html: qobj.feedback3,
                    }}
                  />
                )}
              </FeedbackCard>
            </FeedbackArea>
          )}
          {hasFeedback ? (
            <AnswersArea>
              {answerItems.map((itemKey) => (
                <AnswerItem
                  key={itemKey}
                  itemLetter={itemKey}
                  itemCopy={qobj[itemKey]}
                  type={this.checkItemType(itemKey)}
                />
              ))}
            </AnswersArea>
          ) : (
            <AnswersArea>
              {answerItems.map((itemKey) => (
                <AnswerItem
                  key={itemKey}
                  itemLetter={itemKey}
                  itemCopy={qobj[itemKey]}
                  type={userAnswer === itemKey ? "selected" : "normal"}
                  onClick={(e) => this.toggleAnswerItem(itemKey, e)}
                />
              ))}
            </AnswersArea>
          )}
        </>
      );
    }
    return <Loading />;
  }
}

export default Question;

import { useCallback } from "react";

import { TOrganization, TStudent } from "../../../../../types";
import { calculateStudentTotals } from "../../../../utils/getTotalThemes";
import firebase from "../../../../store/api/firebaseAdmin";
import { mergeTotals } from "../../../../utils/getTotalThemes";
import { subjectCodes } from "../../../../_CONS/qbanks";
interface UseReportDataProps {
  getOrganizations: (organizationUser: string | undefined) => Promise<any[]>,
  getStudents: (orgId: string) => Promise<any[]>,
  getStudentsByOrgId: (orgId: string) => any,
  getTestStatistic: (student: Partial<TStudent>) => Promise<{}>,
  getStudyStatistic: (student: Partial<TStudent>) => Promise<{}>,
  setReportDataIfNot: (
    chosenStudents: any,
    freeTotalThemes: any,
    paidTotalThemes: any
  ) => Promise<any>
}

const useReportData = (): UseReportDataProps => {
  const getAnswersStats = useCallback((
    { questionsCorrect, questionsTotalAnswered }: {
      questionsCorrect: number,
      questionsTotalAnswered: number
    }
  ) => {
    const persentage = !isNaN(questionsCorrect) && !!questionsCorrect ?
      `${(questionsCorrect / questionsTotalAnswered * 100).toFixed(1)}%` :
      '-'

    return [
      !isNaN(questionsCorrect) && !!questionsCorrect ? questionsCorrect : '-',
      !isNaN(questionsTotalAnswered) && !!questionsTotalAnswered ? questionsTotalAnswered : '-',
      persentage
    ]
  }, [])

  const getTestStatistic = useCallback(async (student: Partial<TStudent>): Promise<{}> => {
    const id = student.uid || student.id

    const testsInfo = {
      ["Practice Test 1"]: ['-', '-', '-'],
      ["Practice Test 2"]: ['-', '-', '-'],
      ["Practice Test 3"]: ['-', '-', '-'],
      ["Practice Test 4"]: ['-', '-', '-'],
      ["Practice Final Part 1"]: ['-', '-', '-'],
      ["Practice Final Part 2"]: ['-', '-', '-'],
      ["Practice Final Part 3"]: ['-', '-', '-'],
      ["Practice Final Part 4"]: ['-', '-', '-'],
    }

    if (!id) return testsInfo

    await firebase
      .firestore()
      .collection(student.studentBank.trim())
      .doc(id.trim())
      .collection("testguides")
      .get()
      .then((snapshot) => {
        snapshot.forEach(item => {
          let questionsTotalAnswered: number = 0
          let questionsCorrect: number = 0

          if (item.data()['questions'] && student.all)
            item.data()['questions'].forEach(question => {
              if (question.answered) questionsTotalAnswered++
              if (question.isCorrect) questionsCorrect++
            })
          testsInfo[`${item.data().name}`] = getAnswersStats({ questionsCorrect, questionsTotalAnswered })
        })
      });

    return testsInfo
  }, [])

  const getStudyStatistic = useCallback(async (student: Partial<TStudent>): Promise<{}> => {
    const id = student.uid || student.id
    const studyInfo = {
      ["Constitutional Law"]: ['-', '-', '-'],
      ["Contracts"]: ['-', '-', '-'],
      ["Criminal Law and Procedure"]: ['-', '-', '-'],
      ["Civil Procedure"]: ['-', '-', '-'],
      ["Evidence"]: ['-', '-', '-'],
      ["Torts"]: ['-', '-', '-'],
      ["Real Property"]: ['-', '-', '-'],
      ["Business Organization"]: ['-', '-', '-'],
      ["Secured Transactions"]: ['-', '-', '-'],
      ["Wills and Trusts"]: ['-', '-', '-'],
    }

    if (!id) return studyInfo

    await firebase
      .firestore()
      .collection(student.studentBank.trim())
      .doc(id.trim())
      .collection("studyguides")
      .get()
      .then((snapshot) => {
        snapshot.forEach(item => {
          const guideData = item.data()
          if (item.data()['questions'] && student.all && guideData.completed) {
            item.data()['questions'].filter(question => Object.keys(subjectCodes).includes(question.subjectCode)).forEach(question => {
              const topic = studyInfo[`${subjectCodes[question.subjectCode]['label']}`]
              const isAnswered = question.answered
              const isCorrect = question.isCorrect
              isCorrect ? topic[0] = isNaN(topic[0]) ? 1 : topic[0] + 1 : undefined
              isAnswered ? topic[1] = isNaN(topic[1]) ? 1 : topic[1] + 1 : undefined
            })
          }
        })
      });
    for (let subject in studyInfo) {
      const persentage = getAnswersStats({
        questionsCorrect: +studyInfo[subject][0],
        questionsTotalAnswered: +studyInfo[subject][1]
      })
      studyInfo[subject][2] = persentage[2]
    }

    return studyInfo
  }, [])

  const getOrganizations = useCallback(async (organizationUser: string | undefined): Promise<TOrganization[]> => {
    const organizations = [];

    if (organizationUser) {
      await firebase
        .firestore()
        .collection("organizations")
        .doc(organizationUser.trim())
        .get()
        .then((doc) => {
          organizations.push(doc.data());
        });

    } else {
      await firebase
        .firestore()
        .collection("organizations")
        .get()
        .then((doc) => {
          doc.forEach((doc) => organizations.push(doc.data()));
        });
    }

    return organizations;
  }, [])


  const getStudents = useCallback(async (orgId: string): Promise<TStudent[]> => {
    const students = [];
    await firebase
      .firestore()
      .collection("users")
      .where("organization", "==", orgId.trim())
      .where("role", "==", "student")
      .where("status", "==", "current")
      .get()
      .then((doc) => {
        doc.forEach((doc) => students.push({ id: doc.id, ...doc.data() }));
      });

    return students;
  }, [])

  const getStudentsByOrgId = useCallback(async (orgId: string) => {
    return await firebase
      .firestore()
      .collection("users")
      .where("organization", "==", orgId)
      .where("role", "==", "student")
      .where("status", "==", "current")
      .get();
  }, [])

  const setReportDataIfNot = useCallback(async (
    chosenStudents: any,
    freeTotalThemes: any,
    paidTotalThemes: any
  ): Promise<any> => {
    for (const student of chosenStudents) {

      if (!student.report) {
        await firebase
          .firestore()
          .collection(student.studentBank.trim())
          .doc(student.id.trim())
          .collection("sgquestions")
          .get()
          .then((snapshot) => {
            const studentTotals = calculateStudentTotals(snapshot.docs);
            const mergedTotalResult = mergeTotals(
              student.accountLevel === "free" ? freeTotalThemes : paidTotalThemes,
              studentTotals
            );
            firebase
              .firestore()
              .collection("users")
              .doc(student.id.trim())
              .update({ report: mergedTotalResult });
          });
      }
    }
  }, [])

  return {
    getOrganizations,
    getStudents,
    getStudentsByOrgId,
    setReportDataIfNot,
    getTestStatistic,
    getStudyStatistic
  };
}

export default useReportData;
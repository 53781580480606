import React from "react";
import { Route, Switch } from "react-router-dom";
import List from "./List";
import View from "./View";
import New from "./New";
import NewOrganizationUser from "./Users/New";
import ViewOrganizationUser from "./Users/View";
import ViewOrganizationStudent from "./Students/View";
import NewOrganizationStudent from "./Students/New";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { OrganizationAdminRoutes } from "../../../_CONS/routes";
import { Redirect } from "react-router";

const Organizations = ({ match }) => {
  const profile = useSelector((state) => state.firebase.profile);
  const isOrganizationUser = !!get(profile, "token.claims.organization");

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/view/:id/user/view/:userId`}
        component={ViewOrganizationUser}
      />
      <Route
        exact
        path={`${match.url}/view/:id/student/view/:studentId`}
        component={ViewOrganizationStudent}
      />
      <Route
        exact
        path={`${match.url}/view/:id/student/new`}
        component={NewOrganizationStudent}
      />
      <Route path={`${match.url}/view/:id`} component={View} />

      {!isOrganizationUser && (
        <>
          <Route
            exact
            path={`${match.url}/:id/user/new`}
            component={NewOrganizationUser}
          />
          <Route exact path={`${match.url}/new`} component={New} />
          <Route exact path={`${match.url}`} component={List} />
        </>
      )}

      <Redirect to={OrganizationAdminRoutes.STUDENTS} />
    </Switch>
  );
};

export default Organizations;

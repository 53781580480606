// @ts-nocheck
import React, { Component } from "react";
import { compose } from "redux";
import { chunk, get } from "lodash";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import { Redirect, RouteComponentProps } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import Timer from "../../components/Timer";
import Question from "../../screens/Question";
import Loading from "../../components/Loading";
import modalTypes from "../../_CONS/modalTypes";
import * as routes from "../../_CONS/routes";
import QuestionEnd from "../../screens/QuestionEnd";
import BaseDialog from "../../components/BaseDialog";
import ToggleSwitch from "../../elements/ToggleSwitch";
import QuestionStart from "../../screens/QuestionStart";
import QuestionCount from "../../elements/QuestionCount";
import QuestionNotes from "../../components/QuestionNotes";
import { timeToMinutes } from "../../utils/formatDateTime";
import {
  clearGuide,
  endGuide,
  fetchQuestionById,
  openGuide,
  pauseGuide,
  resetGuide,
  reviewGuide,
  updateGuideQuestionList,
} from "../../store/actions/guideActions";
import { TDialog, TGuide, TGuideQuestion } from "../../../types";
import QuestionFooterNav from "../../components/QuestionFooterNav";
import QuestionNavigator from "../../components/QuestionNavigator";
import { ToolDrawer, ToolDrawerControl } from "../../components/ToolDrawer";
import {
  ToolBar,
  ToolBarBtn,
  ToolBarHandle,
  ToolBarTimer,
} from "../../components/ToolBar";
import {
  GuideContent,
  GuideFooter,
  GuideQuestion,
  GuideWrapper,
} from "../../containers/Guide";
import Button from "../../elements/Button";
import theme from "../../styles/theme";
import getSectionFromUrl from "../../utils/getSectionFromUrl";
import { TStatus } from "../../types/TYPES";
import { updateGuide } from './../../store/actions/guideActions';

type TProps = TPropsFromRedux &
  RouteComponentProps & {
    currentGuide: TGuide;
  };

type TState = {
  isLoading: boolean;
  dialog: TDialog | null;
  questionIndex: number;
  questionNumber: number;
  questionId: string;
  userAnswer: string;
  userNotes: string;
  answered: boolean;
  totalQuestions: number;
  phase: string;
  currentQuestion: any;
  time: number;
  startTime: number;
  timeIsOn: boolean;
  savingNotesStatus: boolean;
  isPaused: boolean;
  showNotes: boolean;
  showFeedback: boolean;
  showTimer: boolean;
  isTimer: boolean;
  showQuestionNavigator: boolean;
  isToolDrawerOpen: boolean;
  questionsLeft: number;
  questionFocus: boolean;
  timeTotal: number;
  timeComplete: boolean;
  overTime: boolean;
  timerOver: boolean;
  duration: number;
  qlist: TGuideQuestion[];
};

class Guide extends Component<TProps, TState> {
  realtimeDuration: number;
  child: React.RefObject<Timer>;
  focusRef: React.RefObject<HTMLInputElement>;
  guideRef: React.RefObject<HTMLInputElement>;
  timerDown: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      dialog: null,
      duration: 0,
      qlist: [],
      questionIndex: -1,
      questionNumber: -1,
      questionId: "",
      userAnswer: "",
      userNotes: "",
      answered: false,
      totalQuestions: 0,
      phase: "start",
      currentQuestion: null,
      time: 0,
      startTime: 0,
      timeIsOn: false,
      savingNotesStatus: false,
      isPaused: false,
      showNotes: false,
      showFeedback: false,
      showTimer: false,
      isTimer: false,
      showQuestionNavigator: true,
      isToolDrawerOpen: true,
      questionsLeft: -1,
      questionFocus: true,
      timeTotal: 0,
      timeComplete: false,
      overTime: false,
      timerOver: false,
    };

    this.child = React.createRef();
    this.focusRef = React.createRef();
    this.guideRef = React.createRef();
    this.timerDown = React.createRef();
  }

  componentDidMount() {
    const { guideType, guideId } = this.props;

    window.addEventListener("beforeunload", this.saveTimerBeforeCloseTab);

    this.props
      .openGuide({ guideType, guideId, status: "" as TStatus })
      .then(() => {
        this.guideRef.current && this.guideRef.current.focus();
      });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.saveTimerBeforeCloseTab)

    if (this.props.guideId && this.state.phase === "live") {
      this.updateGuideDuration();
    }
  }

  saveTimerBeforeCloseTab = (e) => {
    const confirmationMessage = "";
    (e || window.event).returnValue = confirmationMessage;

    const duration = this.realtimeDuration;
    const {
      showTimer,
      showNotes,
      showQuestionNavigator,
      qlist,
      questionIndex,
      questionsLeft,
    } = this.state;

    const { currentGuide, guideType } = this.props;
    const guideId = currentGuide.uid;

    const updateObject = {
      questionIndex,
      paused: true,
      pausedDate: new Date(),
      duration,
      questions: qlist,
      questionsLeft,
      showQuestionNavigator,
      showTimer,
      showNotes: showNotes ? showNotes : false,
    };


    this.props.pauseGuide(guideId, guideType, updateObject)
    this.updateDuration()


    return confirmationMessage
  }

  updateGuideDuration = async () => {
    const { uid, type } = this.props.currentGuide;

    if (uid && type && this.realtimeDuration) {
      await this.props.updateGuide({
        uid,
        type,
        duration: this.realtimeDuration,
      });
    }
  };

  startGuide = async (customTime: string | null) => {
    const currentGuide: TGuide = this.props.currentGuide;
    let timeTotal: number;
    const customTimeMins = timeToMinutes(customTime);
    const qindex = currentGuide.questionIndex ? currentGuide.questionIndex : 0;
    const duration = currentGuide.duration ? currentGuide.duration : 0;
    const startTime = Date.now() - duration;
    if (!currentGuide.questions) return

    const qlist = [...currentGuide.questions];
    const questionsLeft = qlist.filter((question) => !question.answered).length;

    const newState = {
      phase: "live",
      totalQuestions: qlist.length,
      showFeedback: currentGuide.showFeedback,
      showTimer: currentGuide.showTimer,
      showNotes: currentGuide.showNotes ? currentGuide.showNotes : false,
      showQuestionNavigator: currentGuide.showQuestionNavigator
        ? currentGuide.showQuestionNavigator
        : true,
      duration,
      qlist,
      questionsLeft,
      startTime,
      timerOver: false,
    };

    try {
      if (!currentGuide.started) {
        await this.props.updateGuide({
          uid: currentGuide.uid,
          type: currentGuide.type,
          customTime: customTime ? customTimeMins : 0,
          showTimer: customTime ? customTimeMins > 0 : false,
          started: true,
        });
      }

      if (customTimeMins) {
        timeTotal = customTimeMins * 60000;
      } else {
        const { time, customTime } = currentGuide;
        timeTotal = customTime > 0 ? customTime * 60000 : time * 600000;
      }

      this.setState({
        ...newState,
        timeTotal: timeTotal,
        showTimer: customTimeMins > 0 || currentGuide.showTimer,
        overTime: duration >= timeTotal,
      });

      this.loadGuideQuestion(qindex, qlist);
    } catch (e) {
      console.log(e);

      this.setState({
        dialog: {
          open: true,
          ...(modalTypes.FIREBASE_ERROR as TDialog),
        },
      });
    }
  };

  gotoQuestion = (qid) => {
    const { qlist } = this.state;
    const index = qlist.findIndex((question) => {
      if (question.qid === qid) {
        return true;
      }
      return false;
    });

    const qindex = index < 0 ? 0 : index;

    this.loadGuideQuestion(qindex, qlist);
  };

  loadGuideQuestion = (qnum, qlist) => {
    const { isDesktop, qColl } = this.props;
    const { isToolDrawerOpen } = this.state;

    const tdo = isDesktop ? isToolDrawerOpen : false;

    const current = qlist[qnum];
    const currentQuestionId = current.qid;

    this.props.fetchQuestionById(currentQuestionId, qColl).then((doc) => {
      if (doc.exists) {
        this.setState({
          phase: "live",
          questionIndex: qnum,
          questionNumber: qnum + 1,
          questionId: currentQuestionId,
          userAnswer: current.userAnswer,
          answered: current.answered,
          currentQuestion: doc.data(),
          userNotes: current.userNotes ? current.userNotes : "",
          isToolDrawerOpen: tdo,
          questionFocus: true,
        });
      }

      window.scrollTo(0, 0);
    });
  };

  continueToNext = () => {
    const { questionIndex, totalQuestions, qlist } = this.state;
    const finalIndex = totalQuestions - 1;
    const nextQuestionIndex = questionIndex + 1;
    if (nextQuestionIndex > finalIndex) {
      this.loadGuideQuestion(0, qlist);
    } else {
      this.loadGuideQuestion(nextQuestionIndex, qlist);
    }
  };

  continueToPrev = () => {
    const { questionIndex, qlist } = this.state;
    const nextQuestionIndex = questionIndex - 1;
    const qindex = nextQuestionIndex < 0 ? 0 : nextQuestionIndex;
    this.loadGuideQuestion(qindex, qlist);
  };

  pauseGuide = () => {
    const duration = this.realtimeDuration;
    const {
      showTimer,
      showNotes,
      showQuestionNavigator,
      qlist,
      questionIndex,
      questionsLeft,
    } = this.state;

    if (showTimer) {
      this.child.current.pauseTimer();
    }

    const { currentGuide, guideType } = this.props;
    const guideId = currentGuide.uid;
    const updateObject = {
      questionIndex,
      paused: true,
      pausedDate: new Date(),
      duration,
      questions: qlist,
      questionsLeft,
      showQuestionNavigator,
      showTimer,
      showNotes: showNotes ? showNotes : false,
    };

    this.props
      .pauseGuide(guideId, guideType, updateObject)
      .then(() => {
        this.setState({ dialog: null, isPaused: true });
        this.props.clearGuide();
        this.props.history.push(`/student`);
      })
      .catch(() => {
        this.setState({
          dialog: {
            open: true,
            ...(modalTypes.FIREBASE_ERROR as TDialog),
          },
        });
      });
  };

  endGuide = () => {
    const duration = this.realtimeDuration;
    const { showTimer, qlist, questionIndex, questionsLeft } = this.state;

    if (showTimer) {
      this.child.current.pauseTimer();
    }

    const { currentGuide, guideType } = this.props;

    const questionsTotal = qlist.length;
    const questionsCorrect = qlist.reduce(
      (result: number, item) => (item.isCorrect ? result + 1 : result),
      0
    );
    const questionsWrong = questionsTotal - questionsCorrect;

    const updateObject = {
      duration,
      questionIndex,
      questionsLeft,
      questionsTotal,
      questionsWrong,
      questionsCorrect,
      completed: true,
      completedDate: new Date(),
      questions: qlist.map((question) => ({ ...question, answered: true })),
    };

    this.props
      .endGuide(currentGuide.uid, guideType, updateObject)
      .then((success) => {
        if (success) {
          this.setState({
            dialog: null,
            phase: "end",
          });
        } else {
          this.showErrorDialog(() => {
            this.setState({
              dialog: null,
              phase: "end",
            });
          });
        }
      });
  };

  resetGuide = () => {
    this.props
      .resetGuide(this.props.currentGuide)
      .then(() => {
        this.setState({ dialog: null }, () => {
          this.props.history.push(`/student`);
        });
      })
      .catch(() => {
        this.setState({
          dialog: {
            open: true,
            ...(modalTypes.FIREBASE_ERROR as TDialog),
          },
        });
      });
  };

  reviewGuide = () => {
    const { currentGuide, guideType } = this.props;
    const guideId = currentGuide.uid;

    const obj = {
      guideType: guideType,
      guideId: guideId,
      status: "" as TStatus,
      queries: null,
    };
    this.props.reviewGuide(obj).then(() => {
      this.props.history.push(`/student/review/${guideType}/overview`);
    });
  };

  toggleToolDrawer = (e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    this.setState((state) => ({
      isToolDrawerOpen: !state.isToolDrawerOpen,
    }));
  };

  toggleShowTimer = () => {
    this.setState((state) => ({
      showTimer: !state.showTimer,
      // duration: Date.now() - state.startTime,
    }));
  };

  showTimesUp = () => {
    this.setState({ timerOver: true });
    this.showDialog("timesup");
  };

  changeUserNotes = (event) => {
    this.setState({ userNotes: event.target.value });
  };

  changeSideBarNotesToggle = () => {
    this.setState((state) => ({
      showNotes: !state.showNotes,
    }));
  };

  toggleQuestionNavigator = () => {
    this.setState((state) => ({
      showQuestionNavigator: !state.showQuestionNavigator,
    }));
  };

  saveUserNotes = (note) => {
    this.setState({ savingNotesStatus: true });
    this.updateUserNotes(note);
  };

  updateDuration = (time) => {
    this.realtimeDuration = time;
  };

  updateUserNotes = (note) => {
    const { currentGuide, guideType } = this.props;
    const { questionIndex, qlist } = this.state;

    const oldQobj = qlist[questionIndex];
    const newQobj = {
      ...oldQobj,
      userNotes: note,
    };
    const updatedQlist = [...qlist];
    updatedQlist[questionIndex] = newQobj;

    this.props
      .updateGuideQuestionList(currentGuide.uid, guideType, updatedQlist)
      .then((success) => {
        if (success) {
          this.setState({
            qlist: updatedQlist,
            userNotes: note,
            savingNotesStatus: false,
          });
        } else {
          this.showErrorDialog();
        }
      });
  };

  onUserAnswer = (key: string) => {
    const { answered, isPaused, showFeedback } = this.state;

    if ((answered && showFeedback) || isPaused) return;

    this.setState(
      {
        userAnswer: key,
        isLoading: true,
      },
      this.checkUserAnswer
    );
  };

  checkUserAnswer = () => {
    const {
      questionIndex,
      userAnswer,
      userNotes,
      currentQuestion,
      showFeedback,
      qlist,
    } = this.state;
    const { currentGuide, guideType } = this.props;
    const { correctAnswer } = currentQuestion && currentQuestion.qdata;
    const isCorrect = userAnswer === correctAnswer;
    const oldQobj = qlist[questionIndex];
    const newQobj = {
      ...oldQobj,
      answered: true,
      isCorrect: isCorrect,
      correctAnswer: correctAnswer,
      userAnswer: userAnswer,
      userNotes: userNotes,
    };
    const updatedQlist = [...qlist];
    updatedQlist[questionIndex] = newQobj;
    const questionsLeft = updatedQlist.filter(
      (question) => !question.answered
    ).length;

    this.props
      .updateGuideQuestionList(currentGuide.uid, guideType, updatedQlist)
      .then(() => {
        if (showFeedback) {
          this.setState({
            answered: true,
            isLoading: false,
            qlist: updatedQlist,
            questionsLeft,
          });
        } else {
          this.setState({
            qlist: updatedQlist,
            questionsLeft,
            isLoading: false,
          });

          if (questionIndex === qlist.length - 1) {
            this.showDialog("complete");
          } else {
            this.continueToNext();
          }
        }
      });
    this.updateGuideDuration();
  };

  toggleKeyFocus = () => {
    this.setState({ questionFocus: false });
  };

  toggleKeyBlur = () => {
    this.setState({ questionFocus: true });
  };

  captureKeyDown(e) {
    const { questionFocus } = this.state;
    if (!questionFocus) {
      return;
    }
    const keyPressed = e.key;
    const keyVal = keyPressed.toUpperCase();
    let targetKey = false;
    switch (keyVal) {
      case "A":
        targetKey = true;
        break;
      case "B":
        targetKey = true;
        break;
      case "C":
        targetKey = true;
        break;
      case "D":
        targetKey = true;
        break;
      case "ARROWRIGHT":
        targetKey = false;
        this.continueToNext();
        break;
      case "ARROWLEFT":
        targetKey = false;
        this.continueToPrev();
        break;
      default:
        targetKey = false;
    }

    if (targetKey) {
      this.onUserAnswer(keyVal);
    }
  }

  closeDialog = () => {
    this.setState({
      dialog: null,
    });
  };

  handleDialogCancel = (type: string) => {
    this.closeDialog();
  };

  setDialogLoading = () => {
    this.setState((state) => ({
      dialog: {
        ...state.dialog,
        isLoading: true,
      },
    }));
  };

  handleDialogConfirm = (type: string) => {
    switch (type) {
      case "error":
        this.closeDialog();
        break;
      case "complete-study":
      case "complete-test":
      case "timesup":
        this.setDialogLoading();
        this.endGuide();
        break;
      case "pause-study":
      case "pause-test":
        this.setDialogLoading();
        this.pauseGuide();
        break;
      case "reset-test":
      case "reset-study":
        this.setDialogLoading();
        this.resetGuide();
        break;
    }
  };

  showErrorDialog = (onClose?: (type: string) => void) => {
    const dialog = {
      open: true,
      ...(modalTypes.FIREBASE_ERROR as TDialog),
    };

    if (onClose) {
      dialog.onConfirmAction = onClose;
    }

    this.setState({ dialog });
  };

  showDialog = (type: "complete" | "pause" | "timesup" | "reset") => {
    const guideType = this.props.currentGuide.type.toUpperCase();
    const dialogType = type.toUpperCase();

    this.setState({
      dialog: {
        open: true,
        ...modalTypes[`${dialogType}_${guideType}GUIDES`],
      },
    });
  };

  render() {
    const { currentGuide, isDesktop, level, status, guideId, match } =
      this.props;
    const {
      isLoading,
      phase,
      dialog,
      currentQuestion,
      questionId,
      questionNumber,
      questionIndex,
      totalQuestions,
      answered,
      userAnswer,
      userNotes,
      showTimer,
      showQuestionNavigator,
      showNotes,
      showFeedback,
      savingNotesStatus,
      qlist,
      isToolDrawerOpen,
      timeTotal,
    } = this.state;

    const revealFeedback = answered === true && showFeedback === true;

    if (!guideId) {
      return (
        <Redirect to={`${routes.STUDENT}/${getSectionFromUrl(match.path)}`} />
      );
    }
    return (
      <GuideWrapper tabIndex={-1} ref={this.guideRef}>
        {(isLoading || status === "loading") && <Loading />}

        <BaseDialog
          open={!!dialog && dialog.open}
          onConfirmAction={this.handleDialogConfirm}
          onCancelAction={this.handleDialogCancel}
          {...dialog}
        />

        {currentGuide ? (
          <>
            {phase === "start" && (
              <QuestionStart
                studentLevel={level}
                guide={currentGuide}
                guideAction={this.startGuide}
              />
            )}

            {phase === "end" && (
              <QuestionEnd
                guide={currentGuide}
                guideAction={this.reviewGuide}
              />
            )}
          </>
        ) : (
          <Loading />
        )}
        <GuideContent
          ref={this.focusRef}
          tabIndex={-1}
          onKeyDown={(event) => this.captureKeyDown(event)}
        >
          {phase === "live" && currentQuestion && (
            <>
              {isDesktop ? (
                <ToolBarHandle
                  isOpen={isToolDrawerOpen}
                  onClick={(e) => this.toggleToolDrawer(e)}
                />
              ) : (
                <ToolBar>
                  <ToolBarTimer>
                    <Timer
                      invisible={!showTimer}
                      totalTime={timeTotal}
                      overTime={this.state.timerOver}
                      duration={this.state.duration}
                      onTimerChange={this.updateDuration}
                      onTimerComplete={this.showTimesUp}
                      ref={this.child}
                    />
                  </ToolBarTimer>

                  <ToolBarBtn
                    isActive={isToolDrawerOpen}
                    onClick={this.toggleToolDrawer}
                  />
                </ToolBar>
              )}

              <ToolDrawer
                isDesktop={isDesktop}
                anchor={isDesktop ? "right" : "top"}
                variant="persistent"
                open={isToolDrawerOpen}
                onClose={this.toggleToolDrawer}
              >
                <ToolDrawerControl justify="center" padding={0}>
                  <Button color="dark" onClick={() => this.showDialog("pause")}>
                    Pause Test
                  </Button>
                </ToolDrawerControl>

                <ToolDrawerControl justify="center" padding={theme.spacing(2)}>
                  <Button
                    color="danger"
                    onClick={() => this.showDialog("reset")}
                  >
                    Reset Test
                  </Button>
                </ToolDrawerControl>

                {isDesktop && (
                  <Collapse in={showTimer} timeout="auto">
                    <Timer
                      overTime={this.state.timerOver}
                      totalTime={timeTotal}
                      duration={this.state.duration}
                      onTimerComplete={this.showTimesUp}
                      onTimerChange={this.updateDuration}
                      ref={this.child}
                    />
                  </Collapse>
                )}
                <ToggleSwitch
                  labelOn="Hide Timer"
                  labelOff="Show Timer"
                  isChecked={showTimer}
                  onPress={this.toggleShowTimer}
                />

                <ToolDrawerControl>
                  <QuestionCount
                    totalQuestions={totalQuestions}
                    currentQuestionNumber={questionNumber}
                  />
                </ToolDrawerControl>

                <Divider />

                <QuestionNavigator
                  totalQuestions={qlist.length}
                  showFeedback={showFeedback}
                  pageSize={25}
                  pages={chunk(qlist, 25)}
                  isExpanded={showQuestionNavigator}
                  setToggleExpand={this.toggleQuestionNavigator}
                  currentQuestionId={questionId}
                  questionIndex={questionIndex}
                  navAction={this.gotoQuestion}
                />
                <QuestionNotes
                  key={currentQuestion.qid}
                  notes={userNotes}
                  loading={savingNotesStatus}
                  isExpanded={showNotes}
                  setToggleExpand={this.changeSideBarNotesToggle}
                  handleNoteChange={this.changeUserNotes}
                  handleNoteSave={this.saveUserNotes}
                  toggleFocus={this.toggleKeyFocus}
                  toggleBlur={this.toggleKeyBlur}
                />
              </ToolDrawer>

              <GuideQuestion
                isReview={false}
                isDesktop={isDesktop}
                isToolDrawerOpen={isToolDrawerOpen}
              >
                <Question
                  content={currentQuestion}
                  hasFeedback={revealFeedback}
                  userAnswer={userAnswer}
                  selectUserAnswer={(data) => {
                    this.onUserAnswer(data);
                  }}
                />
              </GuideQuestion>
              <GuideFooter isDesktop={isDesktop && isToolDrawerOpen}>
                <QuestionFooterNav
                  isLoading={isLoading}
                  isReview={false}
                  totalQuestions={totalQuestions}
                  currentQuestionNumber={questionNumber}
                  nextQuestion={this.continueToNext}
                  prevQuestion={this.continueToPrev}
                  completeTest={() => this.showDialog("complete")}
                />
              </GuideFooter>
            </>
          )}
        </GuideContent>
      </GuideWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const isDesktop = state.appReducer.isDesktop;
  const guideType = state.guideStatus.guideType;
  const qColl = guideType === "testguides" ? "tgquestions" : "sgquestions";
  const levelQbank = state.firebase.profile.studentBank;
  const level = state.firebase.profile.accountLevel
    ? state.firebase.profile.accountLevel
    : state.firebase.profile.accountType;

  const studentId = state.firebase.auth.uid;
  return {
    status: state.guideStatus.status,
    guideId: state.guideStatus.guideId,
    level,
    levelQbank,
    guideType: guideType,
    qColl: qColl,
    qid: state.guideStatus.qid,
    studentId,
    isDesktop,
    currentGuide: get(state, "firestore.data.guideCurrent"),
  };
};

const mapDispatchToProps = {
  openGuide,
  updateGuide,
  fetchQuestionById,
  updateGuideQuestionList,
  endGuide,
  pauseGuide,
  resetGuide,
  clearGuide,
  reviewGuide,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  connector,
  firestoreConnect((props: any) => {
    if (!props.guideId) return [];

    return [
      {
        collection: get(props, "levelQbank"),
        doc: get(props, "studentId"),
        subcollections: [
          { collection: get(props, "guideType"), doc: get(props, "guideId") },
        ],
        storeAs: "guideCurrent",
      },
    ];
  })
)(Guide);

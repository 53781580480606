import React from "react";
import { Redirect } from "react-router";
import { Route, Switch } from "react-router-dom";
import Guide from "../../../screens/Guide";
import TestGuideList from "./TestGuideList";

const Test = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/guide`} component={Guide} />

    <Route exact path={`${match.url}`} component={TestGuideList} />
    <Redirect to={match.url} />
  </Switch>
);

Test.propTypes = {};

export default Test;

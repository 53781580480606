import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "../../elements/Button";
import SimpleLinearProgress from "../../elements/SimpleLinearProgress";
import { color } from "../../styles/style";

const NotesWrapper = styled.aside`
  background-color: ${color.white};
  width: 100%;
  z-index: 5;
  position: relative;
`;

class QuestionNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iNotes: props.notes ? props.notes : "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleChange(event) {
    this.setState({ iNotes: event.target.value });
    event.preventDefault();
  }

  handleSave(event) {
    const { iNotes } = this.state;
    this.props.handleNoteSave(iNotes);
    event.preventDefault();
  }

  render() {
    const { loading, isExpanded, setToggleExpand, notes } = this.props;
    const { iNotes } = this.state;

    return (
      <NotesWrapper>
        <Accordion expanded={isExpanded} onChange={setToggleExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Notes
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              label="Add a note"
              multiline
              fullWidth
              rows="4"
              margin="normal"
              variant="outlined"
              value={iNotes}
              onFocus={this.props.toggleFocus}
              onBlur={this.props.toggleBlur}
              onChange={this.handleChange}
            />
            <Button
              color="secondary"
              size="small"
              fullWidth
              aria-label="Save"
              disabled={notes === iNotes || loading}
              onClick={this.handleSave}
            >
              {loading ? <SimpleLinearProgress /> : "SAVE"}
            </Button>
          </AccordionDetails>
        </Accordion>
        <Divider />
      </NotesWrapper>
    );
  }
}

QuestionNotes.propTypes = {
  notes: PropTypes.string,
  notesExpanded: PropTypes.bool,
  loading: PropTypes.bool,
  setToggleExpand: PropTypes.func,
  handleNoteChange: PropTypes.func,
  handleNoteSave: PropTypes.func,
};
// Specifies the default values for props:
QuestionNotes.defaultProps = {
  notesExpanded: true,
  loading: false,
};
export default QuestionNotes;

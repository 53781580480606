import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import Dashboard from "./Dashboard";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
`;

class SetupPathway extends Component {
  state = {
    auth: true,
    anchorEl: null,
  };

  handleChange = (event) => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { match } = this.props;

    return (
      <Container>
        <Switch>
          <Route exact path={`${match.url}`} component={Dashboard} />
        </Switch>
      </Container>
    );
  }
}

export default withRouter(SetupPathway);

// @ts-nocheck

import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

import Grid from "@material-ui/core/Grid";
import ReceiptIcon from "@material-ui/icons/Receipt";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { TOrganization, TStudent } from "../../../../types";
import LoadingModal from "../../../components/LoadingModal";
import BaseDialog from "../../../components/BaseDialog";
import Button from "../../../elements/Button";
import { Screen } from "../../../screens/Student/StyledStudent";
import Select from "../../../elements/Select";
import { MainScreen } from "../../../containers/Main";
import { RootState } from "../../../store/createReduxStore";
import { getTotalThemes } from "../../../utils/getTotalThemes";

import useReportData from "./utils/useGetReportData";
import { ErrorState } from "./utils/models/types";
import ReportExport from './exel-export/ReportExport'
import { useGenerateExcel } from "./utils/useGenerateExcelData";
import {
  defaultErrorState,
  REPORT,
  STATUS,
  STUDENT_ROLE
} from "./utils/CONSTANTS";

import { Bordered, Content, Flex } from "./utils/styled";

const Reports: React.FC = () => {
  const [students, setStudents] = useState<TStudent[] | null>(null);
  const [organizations, setOrganizations] = useState<TOrganization[]>([]);
  const [chosenStudents, setChosenStudents] = useState<TStudent[]>([]);
  const [chosenOrganization, setChosenOrganization] = useState("");
  const [isShowReport, setIsShowReport] = useState(false);
  const [status, setStatus] = useState("");
  const [error, setError] = useState<ErrorState>(defaultErrorState);

  const generatedStudents = useRef<string[][] | undefined>()

  const profile = useSelector((state: RootState) => state.firebase.profile);
  const organization = useSelector(
    (state: RootState) => state.firestore.data.currentOrganization
  );
  const userOrganization = get(profile, "token.claims.organization");
  const isOrganizationUser = organization && organization.id === userOrganization;

  const {
    getOrganizations,
    getStudents,
    getStudentsByOrgId,
    setReportDataIfNot,
  } = useReportData()

  const {
    prepareStudentForExcel,
    generateTimeReport
  } = useGenerateExcel({ chosenStudents, organizations, chosenOrganization })

  const showReport = (): void => {
    generatedStudents.current = undefined
    setIsShowReport((prev) => !prev);
  };

  const onOrganizationChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setChosenOrganization(event.target.value);
    setChosenStudents([]);
  };

  const onStudentChange = (event: React.ChangeEvent<HTMLSelectElement>, newValue: TStudent[]): void => {
    setChosenStudents(newValue);
  };

  const handleDialogConfirm = (): void => {
    setError((prev) => ({ ...prev, show: false, error: null }));
  };

  const generateSessionReport = async () => {
    setStatus(STATUS.LOADING);
    await generateTimeReport()
    setStatus(STATUS.DEFAULT);
  }

  const generateAllOrganizationsReport = async (
    freeTotalThemes,
    paidTotalThemes
  ): Promise<void> => {
    try {
      const excelStudents: Array<any> = [];

      await setReportDataIfNot(chosenStudents, freeTotalThemes, paidTotalThemes);

      for (const org of organizations) {
        const organizationStudents = await prepareStudentForExcel(org.id)
        excelStudents.push(organizationStudents)
      }
      generatedStudents.current = excelStudents.filter(el => !!el[0]).flat()
      setStatus(STATUS.DEFAULT);
    } catch (error) {
      setError({ show: true, error: error });
      throw error;
    }
  };

  const generateSingleOrganizationReport = async (
    freeTotalThemes,
    paidTotalThemes
  ): Promise<void> => {
    try {
      const organizationStudents = [];
      await getStudentsByOrgId(chosenOrganization.trim()).then((doc) => {
        doc.forEach((student) =>
          organizationStudents.push({ ...student.data(), id: student.id })
        );
      });
      await setReportDataIfNot(organizationStudents, freeTotalThemes, paidTotalThemes);

      const excelStudents = await prepareStudentForExcel(
        chosenOrganization.trim(),
      );
      generatedStudents.current = excelStudents
      setStatus(STATUS.DEFAULT);
    } catch (error) {
      setError({ show: true, error: error });
      throw error;
    }
  };

  const generateChosenStudentsReport = async (
    freeTotalThemes,
    paidTotalThemes
  ): Promise<void> => {
    try {
      await setReportDataIfNot(chosenStudents, freeTotalThemes, paidTotalThemes);

      const excelStudents = await prepareStudentForExcel(
        chosenOrganization.trim(),
        true
      );
      generatedStudents.current = excelStudents
      setStatus(STATUS.DEFAULT);
    } catch (error) {
      setError({ show: true, error: error });
      throw error;
    }
  };

  const generate = async (): Promise<void> => {
    try {
      setStatus(STATUS.LOADING);
      generatedStudents.current = undefined
      const paidTotalThemes = await getTotalThemes(STUDENT_ROLE.PAID);
      const freeTotalThemes = await getTotalThemes(STUDENT_ROLE.FREE);
      if (chosenOrganization === "all") {
        await generateAllOrganizationsReport(freeTotalThemes, paidTotalThemes);
      }
      if (chosenOrganization !== "all" && !chosenStudents.length) {
        await generateSingleOrganizationReport(
          freeTotalThemes,
          paidTotalThemes
        );
      }
      if (chosenOrganization !== "all" && !!chosenStudents.length) {
        await generateChosenStudentsReport(freeTotalThemes, paidTotalThemes);
      }
    } catch (error) {
      setError({ show: true, error: error });
      throw error;
    }
  };


  useEffect(() => {
    getOrganizations(userOrganization)
      .then(setOrganizations)
      .catch((error) => setError({ show: true, error: error }));
  }, []);

  useEffect(() => {
    if (chosenOrganization) {
      getStudents(chosenOrganization)
        .then(setStudents)
        .catch((error) => setError({ show: true, error: error }));
    }
  }, [chosenOrganization]);

  return (
    <Screen>
      <MainScreen spacing={3} title={REPORT.REPORTS}>
        <LoadingModal open={status === STATUS.LOADING} />
        <BaseDialog
          open={error.show}
          title={error.title}
          content={error.error}
          onConfirmAction={handleDialogConfirm}
          buttons={[{ label: "close", color: "primary", action: "confirm" }]}
        />
        <Bordered>
          <Flex>
            <Typography variant="subtitle1">
              {REPORT.STUDENT_TEST_REPORT}
            </Typography>
            <Button
              color="primary"
              startIcon={<ReceiptIcon />}
              onClick={showReport}
            >
              {REPORT.GENERATE_REPORT}
            </Button>
          </Flex>
          {/* <Flex>
            <Typography variant="subtitle1">
              {REPORT.SESSION_REPORT}
            </Typography>
            <Button
              color="primary"
              startIcon={<ReceiptIcon />}
              onClick={generateSessionReport}
            >
              {REPORT.GENERATE_REPORT}
            </Button>
          </Flex> */}
        </Bordered>
        {isShowReport && (
          <Content>
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <Select
                  value={chosenOrganization}
                  style={{ minWidth: 400 }}
                  onChange={onOrganizationChange}
                  name={"organization"}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    {REPORT.CHOOSE_ORGANIZATION}
                  </MenuItem>
                  {!isOrganizationUser && (
                    <MenuItem value="all">
                      <em>{REPORT.ALL_ORGANIZATIONS}</em>
                    </MenuItem>
                  )}
                  {organizations &&
                    organizations
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((organization) => (
                        <MenuItem key={organization.id} value={organization.id}>
                          {organization.name}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>
              {chosenOrganization !== "all" && students && (
                <Grid item>
                  <Autocomplete
                    multiple
                    value={chosenStudents}
                    onChange={onStudentChange}
                    id="combo-box-demo"
                    style={{ minWidth: 300 }}
                    options={
                      students &&
                      students.sort((a, b) =>
                        a.firstName.localeCompare(b.firstName)
                      )
                    }
                    getOptionLabel={(option: TStudent) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={REPORT.CHOOSE_STUDENT}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item>
                <Button
                  color="primary"
                  disabled={!chosenOrganization}
                  onClick={generate}
                >
                  {REPORT.GENERATE}
                </Button>
              </Grid>
              {chosenOrganization === "all" && (
                <Typography color={"error"}>
                  {REPORT.WARNING_INFO}
                </Typography>
              )}
            </Grid>
            {!!generatedStudents.current &&
              <ReportExport
                students={generatedStudents.current}
                organizations={{ allOrganizations: organizations, chosenOrganization }}
              />}
          </Content>
        )}
      </MainScreen>
    </Screen >
  );
};

export default memo(Reports);

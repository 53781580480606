import React from "react";
import Table from "@material-ui/core/Table";
import { IconButton } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { withStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";

import SimpleDate from "../../elements/SimpleDate";
import SimpleDuration from "../../elements/SimpleDuration";
import {
  CorrectTableCell,
  QuestionPerSubject,
  styles,
  TableWrap,
  WrongTableCell,
} from "./StyledSimpleTable";

type TRow = {
  uid: string;
  name: string;
  duration: number;
  completedDate: any;
  questionsCorrect: number;
  questionsTotal: number;
  questionsPerSubject?: any[];
};

type TProps = {
  classes: {
    [key: string]: string;
  };
  data: TRow[];
  setCurrentItem: (uid: string) => void;
};

const SimpleTable: React.FC<TProps> = (props: TProps) => {
  const { classes, data, setCurrentItem } = props;

  return (
    <TableWrap>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>Test Name</TableCell>
            <TableCell className={classes.tableCell}>Date</TableCell>
            <TableCell className={classes.tableCell}>Duration</TableCell>
            <TableCell className={classes.tableCell}>Overall</TableCell>
            <TableCell className={classes.tableCell}># of Questions</TableCell>
            <CorrectTableCell className={classes.tableCell}>
              Correct
            </CorrectTableCell>
            <WrongTableCell className={classes.tableCell}>
              Incorrect
            </WrongTableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow key={row.uid}>
                <TableCell className={classes.cellWeight}>{row.name}</TableCell>
                <TableCell className={classes.cellWeight}>
                  <SimpleDate>{row.completedDate}</SimpleDate>
                </TableCell>
                <TableCell className={classes.cellWeight}>
                  <SimpleDuration>{row.duration}</SimpleDuration>
                </TableCell>
                <TableCell className={classes.cellWeight}>
                  {`${(
                    (row.questionsCorrect / row.questionsTotal) *
                    100
                  ).toFixed(1)}%`}
                </TableCell>
                {row.questionsPerSubject ? (
                  <TableCell className={classes.cellWeight} variant="body">
                    {row.questionsPerSubject.map((item) => (
                      <QuestionPerSubject key={item.subjectCode}>
                        {item.subject}: {item.number}
                      </QuestionPerSubject>
                    ))}
                  </TableCell>
                ) : (
                  <TableCell className={classes.cellWeight} variant="body">
                    {row.questionsTotal}
                  </TableCell>
                )}

                <CorrectTableCell>{row.questionsCorrect}</CorrectTableCell>
                <WrongTableCell>
                  {row.questionsWrong}
                </WrongTableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.iconCell}
                >
                  <IconButton
                    size="small"
                    color="secondary"
                    title={"Review"}
                    aria-label={"Review"}
                    onClick={(e) => setCurrentItem(row.uid)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableWrap>
  );
};

export default withStyles(styles)(SimpleTable);

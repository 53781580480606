// @ts-nocheck
import { GuideActions } from "./guideActions";
import { actionCreator } from "../../utils/actionCreator";
import { FIREBASE } from "../../constants/CONSTS";
import { getFirebaseInstance } from "../../utils/getFirebaseInstance";

type TStudyGuideConfig = {
  subjectCodes: {
    [key: string]: {
      label: string;
      value: string;
      num: 10;
    };
  };
  showNotes: boolean;
  showFeedback: boolean;
  showTimer: boolean;
};

export const buildStudyGuide = (guide: TStudyGuideConfig) => {
  return async (dispatch, getState) => {
    dispatch(
      actionCreator(GuideActions.BUILD, {
        status: "loading",
        guideType: "studyguides",
      })
    );
    const firestore = getFirebaseInstance();
    const studentId = getState().firebase.auth.uid;
    const studentBank = getState().firebase.profile.studentBank;
    const studentReference = firestore.collection(studentBank).doc(studentId);

    const { subjectCodes, ...restGuide } = guide;

    const questionsPerSubject = Object.values(subjectCodes).filter(subject => subject.num > 0);

    const totalQuestions = Object.keys(subjectCodes).reduce((sum, next) => sum + subjectCodes[next].num, 0);

    let studyGuideId: string;

    try {
      const newCount = await firestore.runTransaction(transaction => {
        return transaction.get(studentReference).then(student => {
          if (!student.exists) {
            throw new Error("No Student Doc!");
          }
          const currentCount = student.data().sgCount ? student.data().sgCount : 0;
          const newCount = currentCount + 1;
          transaction.update(studentReference, { sgCount: newCount });
          return newCount;
        });
      });

      const studyGuides = firestore.collection(studentBank).doc(studentId).collection(FIREBASE.STUDY_GUIDES);

      const studyGuideDoc = studyGuides.doc();
      studyGuideId = studyGuideDoc.id;
      const studyGuideObject = {
        ...restGuide,
        uid: studyGuideId,
        name: `Study Aid ${newCount}`,
        guideNum: newCount,
        type: "study",
        createdDate: new Date(),
        completed: false,
        questionsPerSubject,
        questionsCorrect: 0,
        questionsWrong: 0,
        time: -1,
        questionsTotal: Number(totalQuestions),
      };
      studyGuideDoc.set(studyGuideObject).then(() => {
        dispatch(_getNewGuideQuestions(studyGuideId, studyGuideObject)).then(() => {
          dispatch(
            actionCreator(GuideActions.BUILD, {
              status: "success",
              guideId: studyGuideId,
              guideType: "studyguides",
            })
          );
        });
      });
    } catch (error) {
      dispatch(actionCreator(GuideActions.BUILD, error.message || JSON.stringify(error), true));
    }
  };
};

const _getNewGuideQuestions = (guideId, guide) => {
  return async (dispatch, getState) => {
    const firestore = getFirebaseInstance();

    const authId = getState().firebase.auth.uid;
    const studentBank = getState().firebase.profile.studentBank;
    const questionsPerSubject = guide.questionsPerSubject.map(question => ({
      ...question,
      topics: question.topics.filter((topic, index) => topic.number > 0),
    }));
    let isSubThemesUsed = false;

    try {
      const queryResults = questionsPerSubject.map(async (topicObject, i) => {
        let query = await firestore
          .collection(studentBank)
          .doc(authId)
          .collection(topicObject.value)
          .where("selected", "==", false)
          .where("qdata.published", "==", true);

        if (topicObject.topics.length) {
          let questionsArr = [];

          await Promise.all(
            topicObject.topics.map(async item => {
              return await query
                .where("topics", "array-contains", item.topic)
                .limit(topicObject.num)
                .get()
                .then(data => data.forEach(item => questionsArr.push(item)));
            })
          );

          return await Promise.all(questionsArr).then(values => {
            isSubThemesUsed = true;
            return values;
          });
        } else {
          return query.limit(topicObject.num).get();
        }
      });
      return await Promise.all(queryResults).then(function (querySnapshots) {
        return dispatch(_migrateResults(guideId, querySnapshots, isSubThemesUsed));
      });
    } catch (error) {
      console.error(error);
    }
  };
};

const _migrateResults = (guideId, questions, isSubThemesUsed) => {

  return async dispatch => {
    try {
      const combinedQuestions = questions.reduce((memo, acc) => {
        const questions = Array.isArray(acc) ? acc : acc.docs

        return [...memo, ...questions]
      }, []);
      const filteredQuestionsID = new Set()
      combinedQuestions.forEach(element => {
        filteredQuestionsID.add(element['id'])
      });
      const filteredQuestionsIdArray = [...filteredQuestionsID]
      const filteredQuestions = []

      combinedQuestions.forEach(question => {
        if (filteredQuestionsIdArray.includes(question.id)) {
          filteredQuestions.push(question)
          filteredQuestionsIdArray[filteredQuestionsIdArray.indexOf(question.id)] = null
        }
      }
      )

      dispatch(_migrateQuestions(guideId, filteredQuestions));
      dispatch(_updateGuidesQuestions(guideId, filteredQuestions));
    } catch (error) {
      console.error(error);
    }
  };
};

const _updateGuidesQuestions = (guideId, questionSets) => {
  return async (dispatch, getState) => {
    const firestore = getFirebaseInstance();
    const authId = getState().firebase.auth.uid;

    const studentLevel = getState().firebase.profile.studentBank;
    const studentReference = firestore.collection(studentLevel).doc(authId);
    const studyGuideReference = studentReference.collection(FIREBASE.STUDY_GUIDE_QUESTIONS);

    const guideRef = firestore.collection(studentLevel).doc(authId).collection(FIREBASE.STUDY_GUIDES).doc(guideId);

    let questionsCount = 0;
    const guideQuestions = [];
    questionSets.forEach(function (doc) {
      questionsCount = questionsCount + 1;
      const docReference = studyGuideReference.doc(doc.id);
      const { classification, subjectCode, qdata } = doc.data();
      const correctAnswer = qdata.correctAnswer;
      const classRaw = classification[0].split("-");
      const topicCode = classRaw[0].trim();
      let subTopicCode = "";
      if (classRaw.length > 1) {
        subTopicCode = classRaw[1].trim();
      }

      guideQuestions.push({
        qid: doc.id,
        qnum: questionsCount,
        subjectCode: subjectCode,
        classification: classification[0],
        answered: false,
        userAnswer: null,
        userNotes: null,
        correctAnswer,
        isCorrect: false,
      });

      docReference.update({
        guideId: guideId,
        qnum: questionsCount,
        selected: true,
        selectedDate: new Date(),
        topicCode,
        subTopicCode,
      });
    });

    return await guideRef.update({ questions: guideQuestions });
  };
};

const _migrateQuestions = (guideId, questions) => {
  // go to study-bo -- study-civ and change selected -> true selected date
  return (dispatch, getState) => {
    const firestore = getFirebaseInstance();
    const authId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;
    const studentRef = firestore.collection(levelQbank).doc(authId);
    const sgqRef = studentRef.collection("sgquestions");

    questions.forEach(function (q) {
      const batch = firestore.batch();
      const { qbank } = q.data();
      const bankRef = studentRef.collection(qbank).doc(q.id);
      batch.update(bankRef, {
        selected: true,
        selectedDate: new Date(),
      });
      const docRef = sgqRef.doc(q.id);

      batch.set(docRef, {
        ...q.data(),
        guideId: guideId,
        selected: true,
        selectedDate: new Date(),
      });
      return batch.commit();
    });
  };
};

export const buildTestGuide = (guide, gObj) => {
  return async (dispatch, getState) => {
    const firestore = getFirebaseInstance();
    const authId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;
    const guideId = guide.uid;

    if (guide.created) {
      return dispatch(
        actionCreator(
          GuideActions.BUILD,
          {
            guideId,
            status: "success",
            guideType: "testguides",
          },
          true
        )
      );
    }

    dispatch(
      actionCreator(GuideActions.BUILD, {
        status: "loading",
        guideType: "testguides",
      })
    );

    const guideCollectionRef = await firestore.collection(levelQbank).doc(authId).collection("testguides");

    const guideDocRef = guideCollectionRef.doc(guideId);

    await dispatch(_getNewTestGuideQuestions(guideId, guide, levelQbank));
    await guideDocRef
      .update({ ...guide, created: true, createdDate: new Date() })
      .then(() => {
        dispatch(
          actionCreator(GuideActions.BUILD, {
            guideId,
            status: "success",
            guideType: "testguides",
          })
        );
      })
      .catch(err => {
        dispatch(actionCreator(GuideActions.BUILD, err.message || JSON.stringify(err), true));
      });
  };
};

const _getNewTestGuideQuestions = (guideId, testGuide, levelQbank) => {
  return async (dispatch, getState) => {
    const firestore = getFirebaseInstance();
    const authId = getState().firebase.auth.uid;

    await firestore
      .collection(levelQbank)
      .doc(authId)
      .collection(testGuide.qbank)
      .orderBy("random")
      .limit(testGuide.questionsTotal)
      .get()
      .then(function (querySnapshot) {
        return dispatch(_convertQuestionsForTestGuide(guideId, querySnapshot, levelQbank));
      })
      .catch(err => {
        dispatch(actionCreator(GuideActions.BUILD, err.message || JSON.stringify(err), true));
      });
  };
};
const _convertQuestionsForTestGuide = (guideId, questions, levelQbank) => {
  return async (dispatch, getState) => {
    const firestore = getFirebaseInstance();
    const authId = getState().firebase.auth.uid;
    const tgQuestionsRef = await firestore.collection(levelQbank).doc(authId).collection("tgquestions");

    const guideRef = firestore.collection(levelQbank).doc(authId).collection("testguides").doc(guideId);
    const batch = firestore.batch();
    let qcount = 0;
    const guideQuestions = [];
    await questions.forEach(function (element) {
      qcount = qcount + 1;
      const docRef = tgQuestionsRef.doc(element.id);
      const { classification, subjectCode, qdata } = element.data();
      const correctAnswer = qdata.correctAnswer;
      const classRaw = classification[0].split("-");
      const topicCode = classRaw[0].trim();
      let subTopicCode = "";
      if (classRaw.length > 1) {
        subTopicCode = classRaw[1].trim();
      }

      guideQuestions.push({
        qid: element.id,
        qnum: qcount,
        subjectCode: subjectCode,
        classification: classification[0],
        answered: false,
        userAnswer: null,
        userNotes: null,
        correctAnswer,
        isCorrect: false,
      });
      batch.set(docRef, {
        ...element.data(),
        guideId: guideId,
        qnum: qcount,
        selected: true,
        selectedDate: new Date(),
        topicCode,
        subTopicCode,
      });
    });
    batch.update(guideRef, {
      questions: guideQuestions,
    });
    return batch.commit().then(async function () {
      return await Promise.resolve();
    });
  };
};

import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
} from "@material-ui/core";
import styled from "styled-components/macro";
import theme from "../../styles/theme";

export const Accordion = styled(MuiAccordion)`
  width: 100%;

  &.Mui-expanded {
    margin: 0;
  }

  &.Mui-expanded:before {
    opacity: 1;
  }

  & .mt-3 {
    margin-top: ${theme.spacing(3)}px;
  }

  & .mr-3 {
    margin-right: ${theme.spacing(3)}px;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: 64px;

  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
`;

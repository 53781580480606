// @ts-nocheck
import firebase from "../api/firebaseAdmin";
import { Dispatch } from "redux";

export const QuestionsActions = {
  ADD: "question/added",
  PUBLISH: "question/published",
  UNPUBLISH: "question/unpublished",
  REMOVE: "question/removed",
  RESET: "question/reset",
};

export const setQuestion = (qObj) => {
  return (dispatch: Dispatch) => {
    const { section, level, view, qbank, qid, questions, isDraft } = qObj;

    dispatch({
      type: "SET_QUESTION",
      section: section,
      level: level,
      view: view,
      qbank: qbank,
      questions: questions,
      qid: qid,
      status: isDraft ? "unpublished" : "published",
      isDraft,
    });
    return Promise.resolve();
  };
};

export const setQuestionView = (view) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "SET_QUESTION_VIEW",
      view: view,
    });
    return Promise.resolve();
  };
};

export const addQuestion = (uObj) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: QuestionsActions.ADD,
      payload: {
        status: uObj.status,
        section: uObj.section,
        level: uObj.level,
        qbank: uObj.qbank,
        view: uObj.view,
        qid: uObj.qid,
      },
    });
    return Promise.resolve();
  };
};

export const resetQuestionSection = (uObj) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: QuestionsActions.RESET,
      payload: {
        section: uObj.section,
        level: uObj.level,
        qbank: uObj.qbank,
        view: uObj.list,
      },
    });
    return Promise.resolve();
  };
};

export const sectionReset = (uObj) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "SECTION_RESET",
      section: uObj.section,
      level: uObj.level,
      qbank: uObj.qbank,
      view: uObj.list,
    });
    return Promise.resolve();
  };
};

export const _removeComplete = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: QuestionsActions.REMOVE,
      payload: {
        status: "removed",
      },
    });
    return Promise.resolve();
  };
};

export const setQbank = (uObj) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "SET_QBANK",
      section: uObj.section,
      level: uObj.level,
      qbank: uObj.qbank,
      isDraft: uObj.isDraft,
      view: "list",
    });
    return Promise.resolve();
  };
};

export const setQuestionById = (qid) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "SET_QUESTION_BY_ID",
      qid: qid,
      status: "set",
    });
    return Promise.resolve();
  };
};

export const saveQuestion = (level, qbank, uObj) => {
  return (dispatch: Dispatch) => {
    const levelQbank = `${level}/${qbank}`;
    const bankRef = firebase.ref(levelQbank);
    const qid = bankRef.push().key;
    const newObj = {
      ...uObj,
      uid: qid,
    };

    return bankRef
      .child(qid)
      .update(newObj)
      .then(() => {
        const setObj = {
          ...newObj,
          isDraft: true,
          qid: qid,
          questions: [qid],
        };
        dispatch(setQuestion(setObj));
      });
  };
};

export const updateQuestion = (level, qbank, qid, uObj) => {
  return () => {
    const levelQbank = `${level}/${qbank}`;

    return firebase.ref(levelQbank).child(qid).update(uObj);
  };
};

export const removeQuestion = (level, qbank, qid) => {
  return (dispatch: Dispatch) => {
    const levelQbank = `${level}/${qbank}`;

    return firebase
      .ref(levelQbank)
      .child(qid)
      .remove()
      .then(() => {
        dispatch(_removeComplete());
      });
  };
};

export const publishQuestionBatch = (coll, type, level, jdata) => {
  return async () => {
    const db = firebase.database();

    const qbankRef = db.ref(coll);
    const promises = [];
    await jdata.forEach((item) => {
      const newq = {
        ...item,
        type: type,
        level: level,
        published: true,
        publishedDate: new Date(),
      };
      promises.push(qbankRef.push(newq));
    });
    return Promise.all(promises);
  };
};

export const _publishQuestionToStudents = (qbank, qid, qdata) => {
  return () => {
    const db = firebase.database();
    return db.ref(`${qbank}/${qid}`).set(qdata);
  };
};

export const publishQuestions = (level, qbank, questions) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: QuestionsActions.PUBLISH,
      payload: {
        status: "loading",
      },
    });
    const levelQbank = `${level}/${qbank}`;
    const qbankRef = firebase.ref(levelQbank);
    const promises = [];
    await questions.forEach((item) => {
      promises.push(
        qbankRef.child(item).update({
          published: true,
          publishedDate: new Date(),
        })
      );
    });
    return Promise.all(promises).then(() => {
      dispatch({
        type: QuestionsActions.PUBLISH,
        payload: {
          status: "published",
        },
      });
    });
  };
};

export const unpublishQuestion = (level, qbank, qid) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: QuestionsActions.UNPUBLISH,
      payload: {
        status: "loading",
      },
    });

    const levelQbank = `${level}/${qbank}`;
    const qRef = firebase.ref(levelQbank).child(qid);
    const uObj = {
      published: false,
      archivedDate: new Date(),
    };

    return qRef.update(uObj).then(() => {
      dispatch({
        type: QuestionsActions.UNPUBLISH,
        payload: {
          status: "unpublished",
        },
      });
    });
  };
};

export const publishQuestion = (level, qbank, qid) => {
  return (dispatch: Dispatch) => {
    const levelQbank = `${level}/${qbank}`;
    const qRef = firebase.ref(levelQbank).child(qid);
    const uObj = {
      published: true,
      publishedDate: new Date(),
    };

    return qRef.update(uObj).then(() => {
      dispatch({
        type: QuestionsActions.PUBLISH,
        payload: {
          status: "published",
        },
      });
    });
  };
};

const convertToHtml = (inputString) => {
  const regpat = /(\r\n)?(\n)/gi;
  const replacement = "<br />";

  if (inputString) {
    return inputString.replace(regpat, replacement);
  }
  return;
};

export { convertToHtml };

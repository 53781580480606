// @ts-nocheck
import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as ArrowsLeft } from "../../assets/icons/ArrowsLeft.svg";
import IconArrowsUp from "../../assets/icons/IconArrowsUp";
import { color, zIndex } from "../../styles/style";

const ToolBar = styled.div`
  width: 100%;
  position: fixed;
  top: 56px;
  height: 56px;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  z-index: ${zIndex.toolBar};
  border-bottom: 1px solid ${color.blueLightest};
`;
const ToolBarTimer = styled.div``;

const Handle = styled.div`
  position: fixed;
  right: ${(props) => (props.isOpen ? "300px" : "0")};
  background-color: ${color.white};
  padding: 1.25rem 0.75rem;
  box-shadow: -5px 2px 7px -5px ${color.blueLightest};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;
  top: 8.5rem;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1290;

  & svg {
    transform: rotate(${(props) => (props.isOpen ? "180deg" : "0")});
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
`;
const ToolBarHandle = ({ ...props }) => (
  <Handle {...props}>
    <ArrowsLeft />
  </Handle>
);

const ToolBtn = styled.button`
  text-decoration: none;
  letter-spacing: 1.7px;
  border: none;
  outline: 0;
  padding: 0.5rem 1rem;
  box-shadow: none;
  border-radius: 290486px;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;

  background-color: ${(props) =>
    props.isActive ? `${color.secondary}` : `${color.grey}`};
`;
const ToolBtnText = styled.p`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 10px 0 0;
  padding: 0;
  color: ${(props) =>
    props.isActive ? `${color.white}` : `${color.blueDarkest}`};
`;

type TToolbar = {
  isActive: boolean;
  onClick: (e?: MouseEvent) => void;
};

const ToolBarBtn = ({ isActive, onClick }: TToolbar) => (
  <ToolBtn isActive={isActive} onClick={onClick}>
    <ToolBtnText isActive={isActive}>TOOLS</ToolBtnText>
    <IconArrowsUp isActive={isActive} />
  </ToolBtn>
);

export { ToolBar, ToolBarTimer, ToolBarHandle, ToolBarBtn };

import styled from "styled-components/macro";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { color, fontWeight } from "../../styles/style";

export const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const QuestionPerSubject = styled.span`
  display: block;
`;

export const WrongTableCell = withStyles({
  root: {
    backgroundColor: color.redLightest,
    color: color.redMain,
    fontWeight: fontWeight.bold,
  },
})(TableCell);

export const CorrectTableCell = withStyles({
  root: {
    backgroundColor: color.secondaryLight,
    color: color.secondaryDark,
    fontWeight: fontWeight.bold,
  },
})(TableCell);

export const styles = (theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 700,
    },
    tableCell: {
      fontWeight: 700,
      textTransform: "uppercase",
    },
    cellWeight: {
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
      },
    },
    iconCell: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
      },
    },
    studyDate: {
      fontSize: 12,
      marginTop: 5,
      color: color.blueDarkest,
    },
    studyStats: {
      paddingTop: 20,
      textAlign: "center",
    },
  });

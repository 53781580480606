import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import { MainScreen } from "../../../containers/Main";
import { Card, CardContent } from "../../../containers/Card";
import QuestionsTable from "../../../components/QuestionsTable";
import Button from "../../../elements/Button";
import Select from "../../../elements/Select";
import {
  addQuestion,
  resetQuestionSection,
  setQbank,
} from "../../../store/actions/questionActions";
import { study as qbanks } from "../../../_CONS/qbanks";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    fontSize: 18,
    fontWeight: 600,
  },
});

class Study extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      qpath: "paid/study-civ",
      qbank: "study-civ",
    };
    this.addNewQuestion = this.addNewQuestion.bind(this);
  }

  componentDidMount() {
    const { section } = this.props;
    if (section !== "study") {
      const qbank = qbanks[0].value;
      const qpath = `paid/${qbank}`;
      const uObj = {
        section: "study",
        level: "paid",
        qbank,
        view: "list",
      };
      this.props.resetQuestionSection(uObj).then(() => {
        this.setState({ qbank, qpath });
      });
    }
  }

  handleChange = (event) => {
    const qbank = event.target.value;
    const qpath = `paid/${qbank}`;
    const uObj = {
      section: "study",
      level: "paid",
      qbank,
      qpath,
    };
    this.props.setQbank(uObj).then(() => {
      this.setState({ qbank, qpath });
    });
  };

  addNewQuestion(e) {
    const { history, level, section, qbank } = this.props;
    e.preventDefault();
    const sObj = {
      status: "loading",
      section: section,
      level: level,
      qbank: qbank,
      view: "new",
      qid: "",
    };
    this.props
      .addQuestion(sObj)
      .then(() => history.push(`/admin/${section}/new`));
  }

  render() {
    const { qbank, qpath } = this.state;
    return (
      <MainScreen
        title="Study Questions"
        rightSideItem={
          <Button
            onClick={this.addNewQuestion}
            color="primary"
            variant="contained"
            aria-label="Add Question"
          >
            Add Question
          </Button>
        }
      >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Select
                value={this.state.qbank || ""}
                onChange={this.handleChange}
                name="qbank"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <em>Select Question Bank</em>
                </MenuItem>

                {qbanks.map((item) => {
                  return (
                    <MenuItem
                      key={item.value}
                      name={item.value}
                      value={item.value}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </CardContent>
            <Divider />
            {qbank && (
              <QuestionsTable
                qpath={qpath}
                qbank={qbank}
                section="study"
                level="paid"
              />
            )}
          </Card>
        </Grid>
      </MainScreen>
    );
  }
}

Study.propTypes = {
  section: PropTypes.string,
  level: PropTypes.string,
  qbank: PropTypes.string,
  status: PropTypes.string,
};

const mapStateToProps = (state) => ({
  section: state.questionStatus.section,
  level: state.questionStatus.level,
  qbank: state.questionStatus.qbank,
  status: state.questionStatus.status,
});

const mapDispatchToProps = {
  resetQuestionSection,
  setQbank,
  addQuestion,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Study);

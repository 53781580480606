import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const ITEM_HEIGHT = 48;

type TMoreMenu = {
  items: any[];
  onMenuAction: (e: any, value: string) => void;
};

const MoreMenu: React.FC<TMoreMenu> = ({ items, onMenuAction, ...props }) => {
  const [anchorEl, setAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);

  const onOpen = (event: {
    currentTarget: React.SetStateAction<
      Element | ((element: Element) => Element) | null | undefined
    >;
  }) => {
    setAnchorEl(event.currentTarget);
  };

  const onCLose = () => {
    setAnchorEl(null);
  };

  const onMenuClick = (value: string) => (e: any) => {
    onCLose();
    onMenuAction(e, value);
  };

  const open = !!anchorEl;

  return (
    <div {...props}>
      <IconButton
        aria-label="More"
        aria-owns={open ? "long-menu" : undefined}
        aria-haspopup="true"
        onClick={onOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onCLose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {Array.isArray(items) &&
          items.map((item) => {
            const value = typeof item === "string" ? item : item.value;
            const label = typeof item === "string" ? item : item.label;

            return (
              <MenuItem
                key={value}
                aria-label={label}
                onClick={onMenuClick(value)}
              >
                {label}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
};

export default MoreMenu;

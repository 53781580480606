const seedTests = [
  // {
  //   qbank: "test-ope01",
  //   name: "Practice Test 1",
  //   type: "test",
  //   testType: "ope",
  //   guideNum: 1,
  //   started: false,
  //   created: false,
  //   completed: false,
  //   disabled: false,
  //   maxQuestions: 100,
  // },
  // {
  //   qbank: "test-ope02",
  //   name: "Practice Test 2",
  //   type: "test",
  //   testType: "ope",
  //   guideNum: 2,
  //   started: false,
  //   created: false,
  //   completed: false,
  //   disabled: true,
  //   maxQuestions: 100,
  // },
  // {
  //   qbank: "test-ope03",
  //   name: "Practice Test 3",
  //   type: "test",
  //   testType: "ope",
  //   guideNum: 3,
  //   started: false,
  //   created: false,
  //   completed: false,
  //   disabled: true,
  //   maxQuestions: 100,
  // },
  // {
  //   qbank: "test-ope04",
  //   name: "Practice Test 4",
  //   type: "test",
  //   testType: "ope",
  //   guideNum: 4,
  //   started: false,
  //   created: false,
  //   completed: false,
  //   disabled: true,
  //   maxQuestions: 100,
  // },
  {
    qbank: "test-final01",
    name: "Practice Final Part 1",
    type: "test",
    testType: "final",
    guideNum: 5,
    started: false,
    created: false,
    completed: false,
    disabled: false,
    maxQuestions: 100,
  },
  {
    qbank: "test-final02",
    name: "Practice Final Part 2",
    type: "test",
    testType: "final",
    guideNum: 6,
    started: false,
    created: false,
    completed: false,
    disabled: true,
    maxQuestions: 100,
  },
  {
    qbank: "test-final03",
    name: "Practice Final Part 3",
    type: "test",
    testType: "final",
    guideNum: 6,
    started: false,
    created: false,
    completed: false,
    disabled: true,
    maxQuestions: 100,
  },
  {
    qbank: "test-final04",
    name: "Practice Final Part 4",
    type: "test",
    testType: "final",
    guideNum: 6,
    started: false,
    created: false,
    completed: false,
    disabled: true,
    maxQuestions: 100,
  },
];
export default seedTests;

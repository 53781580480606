import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { MainScreen } from "../../../../containers/Main";
import Button from "../../../../elements/Button";
import { RouteComponentProps } from "react-router";
import { RootState } from "../../../../store/createReduxStore";
import { ResetButton } from "./StyledList";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import usStates from "../../../../_CONS/usStates";
import { useFirestoreConnect } from "react-redux-firebase";
import { types } from "../../../../_CONS/organizationTypes";
import {
  deleteOrganization,
  resetOrganizationsStatus,
} from "../../../../store/actions/organizationsActions";
import Dialog from "../../../../components/Dialog";
import GenericTable from "../../../../components/GenericTable";

const newCols = [
  {
    id: "name",
    align: "left",
    disablePadding: false,
    label: "Name",
    selector: "name",
  },
  {
    id: "type",
    align: "left",
    disablePadding: false,
    label: "Type",
    selector: "type",
  },
  {
    id: "state",
    align: "left",
    disablePadding: false,
    label: "State",
    selector: "state",
  },
];

const List = (props: RouteComponentProps) => {
  useFirestoreConnect("organizations");

  const dispatch = useDispatch();
  const name = useRef("");
  const [showInactives, setShowInactives] = useState(true);
  const [state, setState] = useState("all");
  const [filtered, setFiltered] = useState([]);
  const [showConfirm, setConfirmFlag] = useState(false);
  const [organizationToDelete, setOrganizationToDelete] = useState(null);

  const organizations = useSelector(
    (state: RootState) => state.firestore.ordered.organizations || []
  );
  const status = useSelector(
    (state: RootState) => state.organizationsReducer.status
  );

  useEffect(() => {
    setFiltered(
      organizations.map((item) => ({
        ...item,
        state: item.state.toUpperCase(),
        type: types[item.type],
      }))
    );
  }, [organizations]);

  const goToScreen = (e: any) => {
    e.preventDefault();

    const { match, history } = props;
    history.push(`${match.url}/new`);
  };

  const filterStudents = () => {
    setFiltered(
      organizations
        .filter(
          (org) =>
            (state === "all" || state === org.state.toLowerCase()) &&
            (!name.current ||
              org.name.toLowerCase().indexOf(name.current.toLowerCase()) >=
                0) &&
            (showInactives || org.isActive)
        )
        .map((item) => ({
          ...item,
          state: item.state.toUpperCase(),
          type: types[item.type],
        }))
    );
  };

  const resetSearchOptions = () => {
    name.current = "";

    setShowInactives(true);
    setState("all");

    setFiltered(
      organizations.map((item) => ({
        ...item,
        state: item.state.toUpperCase(),
        type: types[item.type],
      }))
    );
  };

  const onChange = (field: string) => (e: any) => {
    if (field === "name") {
      name.current = e.target.value;
    } else if (field === "showInactives") {
      setShowInactives(!showInactives);
    } else if (field === "state") {
      setState(e.target.value);
    }
  };

  const onMenuAction = (e: any, id: string, action: string) => {
    if (action === "view") {
      const { match, history } = props;
      history.push(`${match.url}/view/${id}`);
    } else if (action === "delete") {
      setConfirmFlag(true);
      setOrganizationToDelete(id);
    }
  };

  const onCloseDialog = () => {
    dispatch(resetOrganizationsStatus());

    setConfirmFlag(false);
    setOrganizationToDelete(null);
  };

  const onConfirmDialog = () => {
    dispatch(deleteOrganization(organizationToDelete));
    setConfirmFlag(false);
    setOrganizationToDelete(null);
  };

  return (
    <MainScreen title="Organizations">
      <Dialog
        type={"confirm"}
        title={"Confirm"}
        content={"Are You sure You want to delete the organization?"}
        open={showConfirm || status === "loading"}
        isLoading={status === "loading"}
        onCancel={onCloseDialog}
        onConfirm={onConfirmDialog}
        buttonLabels={["CANCEL", "DELETE"]}
      />

      <Grid item xs={12}>
        <form>
          <Grid container spacing={3}>
            <Grid item container xs={12} spacing={3} alignItems={"flex-end"}>
              <Grid item>
                <FormControl>
                  <InputLabel htmlFor="name">Organization Name</InputLabel>
                  <Input
                    className={"md"}
                    id="name"
                    name="name"
                    onChange={onChange("name")}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <Select
                  className={"xs"}
                  name={"state"}
                  value={state}
                  onChange={onChange("state")}
                >
                  <MenuItem value={"all"}>All</MenuItem>

                  {usStates.map((state) => (
                    <MenuItem key={state} value={state.toLowerCase()}>
                      {state.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                checked={showInactives}
                label="View inactive organization"
                onChange={onChange("showInactives")}
                control={<Checkbox value={"showInactives"} />}
              />
            </Grid>

            <Grid
              container
              item
              direction="row"
              justify="space-between"
              xs={12}
            >
              <Grid item md={5} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  aria-label="Search"
                  onClick={filterStudents}
                >
                  Search
                </Button>

                <ResetButton
                  type="reset"
                  color="primary"
                  variant="outlined"
                  aria-label="Reset"
                  onClick={resetSearchOptions}
                >
                  Reset
                </ResetButton>
              </Grid>

              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={goToScreen}
                >
                  Add Organization
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <GenericTable
            defaultOrder={"asc"}
            defaultOrderBy={"name"}
            menuItems={["view", "delete"]}
            colHeaders={newCols}
            data={filtered}
            onMenuAction={onMenuAction}
          />
        </Card>
      </Grid>
    </MainScreen>
  );
};

export default List;

import React, { ChangeEvent, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { TGuide, TGuideQuestion, TStudent } from "../../../../types";
import { TRootState } from "../../../store/reducers";
import { get } from "lodash";
import { compose } from "redux";
import subjectTopics from "../../../_CONS/subjectTopics";
import { subjectCodes } from "../../../_CONS/qbanks";
import { Grid, Typography } from "@material-ui/core";
import {
  Accordion,
  AccordionSummary,
  List,
  LoaderWrapper,
  SectionTitle,
  SubjectInfo,
  TopicInfo,
} from "./StyledStudy";
import Chevron from "@material-ui/icons/KeyboardArrowDown";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Loading from "../../../components/Loading";
import { getTopicsFromClassification } from "../../../utils/getTopicsFromClassification";
import Button from "../../../elements/Button";
import Dialog from "../../../components/Dialog";
import {
  deleteStudyGuide,
  GuideActions,
} from "../../../store/actions/guideActions";
import { actionCreator } from "../../../utils/actionCreator";
import { question } from "../../../_CONS/modalTypes";

type TSubjectCode = keyof typeof subjectTopics;

type TTopic = {
  label: string;
  total: number;
  used: number;
};

type TSubjectTotals = {
  [key in TSubjectCode]: {
    label: string;
    total: number;
    used: number;
    topics: {
      [key: string]: TTopic;
    };
  };
};

type TGuideStat = {
  id: string;
  label: string;
  subjects: {
    [key in TSubjectCode]: {
      label: string;
      number: number;
      topics: {
        [key: string]: {
          label: string;
          number: number;
        };
      };
    };
  };
};

type TAccordion = {
  [key in TSubjectCode]: boolean;
};

type TStudy = TPropsFromRedux & {
  student: TStudent;
};

type TPropsFromRedux = ConnectedProps<typeof connector>;

const Study: React.FC<TStudy> = (props: TStudy) => {
  const [subjectTotals, setSubjectTotals] = useState<
    TSubjectTotals | Record<string, any>
  >({});
  const [areSubjectsLoading, setSubjectsLoading] = useState(true);
  const [areGuidesLoading, setGuidesLoading] = useState(true);
  const [guidesStat, setGuidesStat] = useState<TGuideStat[] | null>(null);
  const [qAccordion, setQAccordion] = useState<
    TAccordion | Record<string, any>
  >({});
  const [guidesAccordion, setGuidesAccordion] = useState<
    TAccordion | Record<string, any>
  >({});
  const [showDialog, setShowDialog] = useState(false);
  const [guideToReset, setGuideToReset] = useState(null);

  useEffect(() => {
    Object.keys(subjectTopics).reduce((result, item) => {
      return {
        ...result,
        [item]: false,
      };
    }, {});
  }, []);

  useEffect(() => {
    const { qbanks } = props;

    if (!props.qBanksLoading && !props.sgQuestionsLoading) {
      if (Object.keys(qbanks).length === Object.keys(subjectCodes).length) {
        const subjects = Object.keys(qbanks).reduce((result, shortCode) => {
          const qbank = qbanks[shortCode as TSubjectCode];

          if (!subjectTopics[shortCode]) {
            return result;
          } else {
            const topics = Object.entries(
              subjectTopics[shortCode].topics
            ).reduce((result, [key, topic]: [string, TTopic]) => {
              let total = 0;
              let used = 0;

              qbank.forEach((question: TGuideQuestion) => {
                const topics =
                  question.topics ||
                  getTopicsFromClassification(question.classification);

                if (topics.includes(key)) {
                  total++;
                  if (question.selected) {
                    used++;
                  }
                }
              });

              return {
                ...result,
                [key]: {
                  label: topic.label,
                  total,
                  used,
                },
              };
            }, {});

            if (qbank.length === 0) return { ...result };

            return {
              ...result,
              [shortCode]: {
                label: subjectTopics[shortCode].label,
                total: qbank.length,
                used: 0,
                topics: topics,
              },
            };
          }
        }, {});
        props.sgquestions.forEach((item) => {
          if (item.subjectCode && subjects[item.subjectCode]) {
            subjects[item.subjectCode].used += 1;
          }
        });
        setSubjectTotals(subjects);
      }
      setSubjectsLoading(false);
    } else {
      setSubjectsLoading(true);
    }
  }, [
    props.qbanks,
    props.sgquestions,
    props.qBanksLoading,
    props.sgQuestionsLoading,
  ]);

  useEffect(() => {
    if (!props.sgLoading && !props.qBanksLoading) {
      if (
        Object.keys(props.qbanks).length === Object.keys(subjectCodes).length
      ) {
        const guideStat = props.studyguides.map((guide: TGuide) => {
          const subjects = {};
          if (!guide.questions) return {
            id: guide.uid,
            label: guide.name,
            subjects,
          }
          guide.questions
            .filter(question => Object.keys(subjectCodes).includes(question.subjectCode))
            .forEach(({ qid, subjectCode }) => {
              const question: TGuideQuestion = props.qbanks[subjectCode].find(
                ({ id }) => id === qid
              );

              if (subjects[subjectCode]) {
                subjects[subjectCode].number++;
              } else {
                subjects[subjectCode] = {
                  number: 1,
                  label: subjectTopics[subjectCode].label,
                  topics: {},
                };
              }
              if(question && subjectTopics){
                const topics =
                    question.topics ||
                    getTopicsFromClassification(question.classification);
                if(topics){
                  topics.forEach((topicCode) => {
                    if(+topicCode === NaN) {
                      if (subjects[subjectCode].topics[topicCode]) {
                        subjects[subjectCode].topics[topicCode].number++;
                      } else {
                        subjects[subjectCode].topics[topicCode] = {
                          label: subjectTopics[subjectCode].topics[topicCode].label,
                          number: 1,
                        };
                      }
                    }
                  });
                }

              }

            });

          return {
            id: guide.uid,
            label: guide.name,
            subjects,
          };
        });

        setGuidesStat(guideStat as TGuideStat[]);
      }
      setGuidesLoading(false);
    } else {
      setGuidesLoading(true);
    }
  }, [props.studyguides, props.qbanks, props.sgLoading, props.qBanksLoading]);

  const onGuideExpand =
    (id: string) => (e: ChangeEvent<HTMLInputElement>, expanded: boolean) => {
      setGuidesAccordion({
        ...guidesAccordion,
        [id]: expanded,
      });
    };

  const onQuestionExpand =
    (subjectCode: TSubjectCode) =>
      (e: ChangeEvent<HTMLInputElement>, expanded: boolean) => {
        setQAccordion({
          ...qAccordion,
          [subjectCode]: expanded,
        });
      };

  const cancelReset = () => {
    setShowDialog(false);

    if (props.error) {
      props.resetError();
    }
  };

  const confirmReset = () => {
    props.deleteStudyGuide(
      props.student.uid,
      guideToReset,
      props.student.studentBank
    );
    setGuideToReset(null);
    setShowDialog(false);
  };

  const onReset = (guideId: string) => () => {
    setShowDialog(true);
    setGuideToReset(guideId);
  };

  const dialog = {
    title: "Delete Guide",
    type: (props.error ? "alert" : "confirm") as "alert" | "confirm",
    content:
      props.error ||
      "This action will delete the Study Guide from the Student Account and return questions back to the Study Guide Builder.",
    open: showDialog || props.status === "loading" || props.error,
    isLoading: props.status === "loading",
    buttonLabels: props.error ? [] : ["Cancel", "Confirm"],
  };

  const haveQuestionsBeenSetup = Object.values(subjectTotals).every(
    ({ total }) => {
      return !!total
    }
  );

  return (
    <>
      <div>
        <Dialog {...dialog} onCancel={cancelReset} onConfirm={confirmReset} />

        {!haveQuestionsBeenSetup ? (
          <SectionTitle variant="h6" gutterBottom>
            Questions have not been setup yet
          </SectionTitle>
        ) : (
          <>
            <SectionTitle variant="h6" gutterBottom>
              All Questions
            </SectionTitle>

            {areSubjectsLoading  ? (
              <LoaderWrapper>
                <Loading position={"static"} />
              </LoaderWrapper>
            ) : (
              Object.keys(subjectTotals).map((subjectCode: TSubjectCode) => {
                const subject = subjectTotals[subjectCode];
                const topics = Object.keys(subject.topics).map((topicCode) => {
                  const topic = subject.topics[topicCode];

                  return topic?(
                    <TopicInfo key={subjectCode + topicCode}>
                      <Typography className="title" variant={"body1"}>
                        {topic.label}
                      </Typography>

                      <Typography
                        className={`${topic.used >= topic.total ? "usedOff" : ""
                          }`}
                        variant={"body1"}
                      >
                        {topic.used} / {topic.total}
                      </Typography>
                    </TopicInfo>
                  ): null;
                });

                if (!subject.total) return null;

                return (
                  <Accordion
                    key={subjectCode}
                    expanded={!!qAccordion[subjectCode]}
                    onChange={onQuestionExpand(subjectCode)}
                  >
                    <AccordionSummary expandIcon={<Chevron />}>
                      <SubjectInfo>
                        <Typography className="title" variant={"body1"}>
                          {subject.label}
                        </Typography>

                        <Typography
                          className={`${subject.used >= subject.total ? "usedOff" : ""
                            }`}
                          variant={"body1"}
                        >
                          {subject.used} / {subject.total}
                        </Typography>
                      </SubjectInfo>
                    </AccordionSummary>

                    <AccordionDetails>
                      <List>{topics}</List>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            )}

            <SectionTitle variant="h6" gutterBottom>
              Study Guides
            </SectionTitle>
            {areGuidesLoading || !guidesStat ? (
              <LoaderWrapper>
                <Loading position={"static"} />
              </LoaderWrapper>
            ) : (
              guidesStat.map((guide) => {
                return (
                  <Accordion
                    key={guide.id}
                    expanded={!!guidesAccordion[guide.id]}
                    onChange={onGuideExpand(guide.id)}
                  >
                    <AccordionSummary expandIcon={<Chevron />}>
                      <SubjectInfo>
                        <Typography className="title" variant="body1">
                          {guide.label}
                        </Typography>
                      </SubjectInfo>
                    </AccordionSummary>

                    <AccordionDetails>
                      <List>
                        {Object.keys(guide.subjects).map((subjectCode) => {
                          const { label, number, topics } =
                            guide.subjects[subjectCode];

                          return (
                            <React.Fragment key={subjectCode}>
                              <SubjectInfo>
                                <Typography className="title" variant="body1">
                                  {label} ({number})
                                </Typography>
                              </SubjectInfo>

                              <List>
                                {Object.keys(topics).map((topicCode) => {
                                  const { label, number } = topics[topicCode];

                                  return (
                                    <TopicInfo key={topicCode}>
                                      <Typography
                                        className="title"
                                        variant="body1"
                                      >
                                        {label} ({number})
                                      </Typography>
                                    </TopicInfo>
                                  );
                                })}
                              </List>
                            </React.Fragment>
                          );
                        })}

                        <Grid container justify="flex-end">
                          <Grid item>
                            <Button color="danger" onClick={onReset(guide.id)}>
                              Reset Guide
                            </Button>
                          </Grid>
                        </Grid>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ firestore, guideStatus }: TRootState) => {
  const { sgquestions, studyguides } = firestore.ordered;
  const qbanks = Object.keys(subjectCodes).reduce(
    (result: Record<string, any>, qbank: string) => {
      if (firestore.ordered[qbank]) {
        result[qbank] = firestore.ordered[qbank].filter(question => !!question.qdata.published);
      }

      return result;
    },
    {}
  );

  return {
    sgquestions: sgquestions || [],
    studyguides: studyguides || [],
    qbanks: qbanks,
    status: guideStatus.status,
    error: guideStatus.error,
    qBanksLoading: Object.keys(subjectCodes).some(
      (code) => !!firestore.status.requesting[code]
    ),
    sgQuestionsLoading: !!firestore.status.requesting.sgquestions,
    sgLoading: !!firestore.status.requesting.studyguides,
  };
};

const mapDispatchToProps = {
  deleteStudyGuide,
  resetError: () => actionCreator(GuideActions.CLEAR),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const firestoreConnector = firestoreConnect((props: any) => {
  const qbanks = Object.keys(subjectTopics).map((key: string) => ({
    collection: get(props, "student.studentBank"),
    doc: get(props, "student.uid"),
    subcollections: [{ collection: `study-${key.toLowerCase()}` }],
    storeAs: key,
  }));

  return [
    {
      collection: get(props, "student.studentBank"),
      doc: get(props, "student.uid"),
      subcollections: [
        { collection: "sgquestions", where: ["selected", "==", true] },
      ],
      storeAs: "sgquestions",
    },
    {
      collection: get(props, "student.studentBank"),
      doc: get(props, "student.uid"),
      subcollections: [{ collection: "studyguides" }],
      storeAs: "studyguides",
    },
    ...qbanks,
  ];
});

Study.displayName = "Study";
export default compose(connector, firestoreConnector)(Study);

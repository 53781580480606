import React from "react";
// import PropTypes from 'prop-types';
import styled, { css } from "styled-components/macro";
import { color, fontType, fontWeight } from "../../styles/style";

const StatWrapper = styled.div`
  text-align: center;
`;

const Stat = styled.div`
  color: ${color.dark};
  font-size: 2rem;
  font-weight: ${fontWeight.bold};

  ${(props) =>
    props.type === "correct" &&
    css`
      color: ${color.secondary};
      font-weight: ${fontWeight.medium};
    `};
  ${(props) =>
    props.type === "wrong" &&
    css`
      color: ${color.redMain};
      font-weight: ${fontWeight.medium};
    `};
`;

const Label = styled.div`
  color: ${color.blueLight};
  font-size: ${fontType.copy};
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
  margin-bottom: 10px;
  ${(props) =>
    props.type === "correct" &&
    css`
      color: ${color.secondary};
      font-weight: ${fontWeight.medium};
    `};
  ${(props) =>
    props.type === "wrong" &&
    css`
      color: ${color.redMain};
      font-weight: ${fontWeight.medium};
    `};
`;
const ResultBox = (props) => {
  const stattext = props.percent ? `${props.stat}%` : `${props.stat}`;
  return (
    <>
      <StatWrapper>
        <Stat type={props.type}>{stattext}</Stat>
        <Label type={props.type}>{props.label}</Label>
        {props.children}
      </StatWrapper>
    </>
  );
};

ResultBox.propTypes = {};

export default ResultBox;
